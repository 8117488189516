import colors from '../../assets/sass/colors';

const walkingRadiusStyle = {
  lineLayout: {
    'line-join': 'round',
    'line-cap': 'round'
  },
  linePaint: {
    'line-color': colors.blaiseGray,
    'line-width': 2,
    'line-opacity': 1
  }
};

export default walkingRadiusStyle;
