import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { withAuth0 } from '@auth0/auth0-react';
import { withTranslation } from 'react-i18next';
import authenticatedAxiosInstance from '../../axios/axios-authorized';
import config from '../../config';
import ReactMapboxGl, { Marker, Layer, Feature } from 'react-mapbox-gl';

import RouteDetails from '../../components/Route/RouteDetails';
import NotFound from '../../components/Error/NotFound';
import useInterval from '../../higherOrderComponents/useInterval';
import CustomSnackbar from '../../components/Snackbar/CustomSnackbar';

import { Box, CircularProgress, Grid, TextField } from '@material-ui/core';
import busStopIcon from '../../assets/images/bus-stop.svg';
import busIcon from '../../assets/images/bus-solid.png';
import colors from '../../assets/sass/colors';

import { dateTimeFormat } from '../../utils/MomentTime';
import { RouteStatus } from '../../enums/statusType';

import { passengerAppNativeRealtime } from '../../firebase';
import { onValue, ref } from 'firebase/database';

const Map = ReactMapboxGl({
  accessToken: config.mapBoxToken
});

const RoutePage = ({ history, auth0, t }) => {
  const routeRefs = useRef([]);
  const { shiftId } = useParams();
  const [loading, setLoading] = useState(false);
  const [shift, setShift] = useState(null);
  const [shiftNotFound, setShiftNotFound] = useState(false);
  const [routeStops, setRouteStops] = useState([]);
  const [centerCoordinates, setCenterCoordinates] = useState(null);
  const [stopsCoordinates, setStopsCoordinates] = useState(null);
  const [routePath, setRoutePath] = useState(null);
  const [routeToShow, setRouteToShow] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: null
  });
  const [tablesLoaded, setTablesLoaded] = useState([]);
  const [shiftStatus, setShiftStatus] = useState('unknown');
  const [busLocation, setBusLocation] = useState({}); // Expects at least {longitude: x, latitude: y}; the same format stored on Firebase
  const [busId, setBusId] = useState(null);
  const [zoom, setZoom] = useState([12]);

  useEffect(() => {
    document.title = `${t('shift_details_title')} - ${t('trip_source_engine')}`;
    fetchShiftDetails('mount');
  }, []);

  useInterval(() => {
    if (shift?.routes.length > 0) {
      const routes = shift.routes;
      fetchRouteStops(routes);
    }
  }, 60000);

  useEffect(() => {
    if (shift?.routes?.length > 0) {
      const routes = shift.routes;
      fetchRouteStops(routes);
      setRouteToShow(routes);
    }
  }, [shift]);

  useEffect(() => {
    const hash = history?.location?.hash?.substring(1);
    if (hash) {
      scrollToTable(hash);
    }
  }, [tablesLoaded]);

  // Firebase listener
  useEffect(() => {
    if (busId) {
      const busRef = ref(passengerAppNativeRealtime, `bus_locations/${busId}`);
      const busUnsubscribe = onValue(
        busRef,
        (snap) => {
          const data = snap.val(); // Example return: {bearing: 0, latitude: 42.05222759337283, longitude: -82.60638932609953}
          setBusLocation(data);
        },
        (error) => {
          console.log(`Realtime db error: ${error}`);
        }
      );

      return () => busUnsubscribe();
    }
  }, [busId]);

  const fetchRouteStops = async (routeIds) => {
    const idArray = routeIds.map((a) => a.route_id);

    try {
      const response = await authenticatedAxiosInstance.axios.get(`/allRoutestops`, {
        params: {
          idArray
        }
      });

      if (response?.data) {
        setRouteStops(response.data);
        getAllCoords(response.data);
        fetchRoutesPath(idArray);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchShiftDetails = async (type) => {
    if (type === 'mount') {
      setLoading(true);
    }

    try {
      const response = await authenticatedAxiosInstance.axios.get(
        `/drivershift/getDetails/${shiftId}`
      );

      if (!response.data) {
        setShiftNotFound(true);
      }

      let shiftDetails = response?.data;

      setBusId(shiftDetails?.bus?.bus_id);

      const filteredRoutes = shiftDetails?.routes?.filter(
        // Filter out routes that are completed but do not have a departure time, these are pre-algo reboot routes
        (route) => {
          const isAlgoRebootRoute = route.is_completed && !route.departure_time && !route.is_active;
          return !isAlgoRebootRoute;
        }
      );
      shiftDetails = { ...shiftDetails, routes: filteredRoutes };

      setShift(shiftDetails);
      const isShiftCompleted = shiftDetails.routes.every((route) => {
        return route.is_completed === 1;
      });

      const containsCompleted = shiftDetails.routes.some((route) => route.is_completed === 1);
      const containsUpcoming = shiftDetails.routes.some(
        (route) =>
          route.is_active === 0 && route.is_completed === 0 && route.current_stop_sequence === 0
      );
      const isShiftActive =
        shiftDetails.routes.some((route) => {
          return route.is_active === 1;
        }) ||
        (containsCompleted && containsUpcoming);

      const isShiftUpcoming = shiftDetails.routes.some((route) => {
        return (
          route.is_active === 0 && route.is_completed === 0 && route.current_stop_sequence === 0
        );
      });

      if (isShiftCompleted && shiftDetails.routes.length > 0) {
        setShiftStatus(RouteStatus.COMPLETED);
      } else if (isShiftActive && shiftDetails.routes.length > 0) {
        setShiftStatus(RouteStatus.ONGOING);
      } else if (isShiftUpcoming && shiftDetails.routes.length > 0) {
        setShiftStatus(RouteStatus.UPCOMING);
      } else if (shiftDetails.routes.length === 0) {
        setShiftStatus(RouteStatus.NO_ROUTES);
      } else {
        setShiftStatus(RouteStatus.UNKNOWN);
      }
    } catch (err) {
      console.log(err);
      openSnackbar(t('load_driver_shift_error'), colors.red);
    } finally {
      if (type === 'mount') {
        setLoading(false);
      }
    }
  };

  const scrollToTable = (hash) => {
    const matchesHash = (element) => element.id === hash;
    const index = routeRefs?.current?.findIndex(matchesHash);

    const isloaded = tablesLoaded.every((loaded) => loaded === true);

    if (routeRefs.current && isloaded) {
      routeRefs.current[index]?.scrollIntoView({ behaviour: 'smooth' });
    }
  };

  const getAllCoords = (routes) => {
    // remove duplicate stops for mapping
    let uniqueStops = new Set();

    const filteredRouteStops = routes.filter((obj) => {
      const duplicates = uniqueStops.has(obj.bus_stop_id);
      uniqueStops.add(obj.bus_stop_id);

      return !duplicates;
    });

    let coordsLat = [];
    let coordsLng = [];
    let coordsRoute = [];

    filteredRouteStops?.forEach((route) => {
      coordsLat.push(Number(route.bus_stop?.lat));
      coordsLng.push(Number(route.bus_stop?.lon));
      coordsRoute.push([Number(route.bus_stop?.lon), Number(route.bus_stop?.lat)]);
    });

    const centerLat = coordsLat.reduce((a, b) => a + b, 0) / coordsLat.length;
    const centerLng = coordsLng.reduce((a, b) => a + b, 0) / coordsLng.length;

    setStopsCoordinates(coordsRoute);
    setCenterCoordinates([centerLng, centerLat]);
  };

  const fetchRoutesPath = async (idArray) => {
    try {
      let pathsForRoute = [];

      idArray.forEach(async (id) => {
        const response = await authenticatedAxiosInstance.axios.get(
          `/route/getRouteDriverFeedbackForTransit/${id}`
        );

        const routeShape = response?.data?.routeShape;

        if (routeShape.length > 0) {
          pathsForRoute.push(routeShape);
        }

        if (pathsForRoute.length > 0) {
          setRoutePath(pathsForRoute.flat());
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const openSnackbar = (snackbarString, color) => {
    setSnackbar({ ...snackbar, open: true, message: snackbarString, color: color });
  };

  const closeSnackbar = (reason) => {
    if (reason !== 'clickaway') {
      setSnackbar({ ...snackbar, open: false });
    }
  };

  const linePaint = (ReactMapboxGl.LinePaint = {
    'line-color': colors.blaiseGreen,
    'line-width': 1
  });

  const lineLayout = {
    'line-cap': 'round'
  };

  return (
    <>
      <CustomSnackbar
        message={snackbar.message}
        open={snackbar.open}
        onClose={() => closeSnackbar()}
        snackbarColor={snackbar.color}
      />
      <Box m={4}>
        {shiftNotFound && !loading && <NotFound title={'404'} message={t('page_not_found')} />}
        {loading && (
          <Grid container style={{ marginTop: '20px' }} justify="center">
            <CircularProgress />
          </Grid>
        )}
        {shift && !loading && (
          <>
            <h2>{t('shift_details_title')}</h2>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  name="driver"
                  value={shift.driver?.driver_name}
                  variant="outlined"
                  fullWidth
                  id="driver"
                  label={t('driver')}
                  inputProps={{
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="bus_name"
                  value={shift.bus?.bus_name}
                  variant="outlined"
                  fullWidth
                  id="bus_name"
                  label={t('bus_name')}
                  inputProps={{
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="start"
                  value={dateTimeFormat(shift.start_time)}
                  variant="outlined"
                  fullWidth
                  id="start"
                  label={t('start_shift')}
                  inputProps={{
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="route_status"
                  value={t(`history_${shiftStatus}`)}
                  variant="outlined"
                  fullWidth
                  id="route_status"
                  label={t('route_status')}
                  inputProps={{
                    readOnly: true
                  }}
                />
              </Grid>
              {routeStops && routeStops.length > 0 && centerCoordinates && stopsCoordinates && (
                <Grid item xs={12}>
                  <Map
                    containerStyle={{ height: '300px' }}
                    style={'mapbox://styles/mapbox/light-v10'}
                    zoom={zoom}
                    center={centerCoordinates}
                    onZoom={(e) => setZoom([e.getZoom()])}
                  >
                    {routePath && (
                      <Layer type="line" paint={linePaint} layout={lineLayout}>
                        <Feature coordinates={routePath} />
                      </Layer>
                    )}
                    {(!routePath || routePath.length === 0) && (
                      <Layer type="line" paint={linePaint} layout={lineLayout}>
                        <Feature coordinates={stopsCoordinates} />
                      </Layer>
                    )}
                    {stopsCoordinates.map((stop, index) => {
                      const title = routeStops[index].bus_stop?.name;
                      return (
                        <Marker
                          coordinates={stop}
                          anchor="bottom"
                          style={{ marginTop: '6px' }}
                          key={`${index}: ${stop}`}
                        >
                          <img
                            src={busStopIcon}
                            style={{ height: '12px' }}
                            alt={title}
                            title={title}
                          />
                        </Marker>
                      );
                    })}
                    {/* Bus realtime location*/}
                    {busLocation?.longitude &&
                      busLocation?.latitude &&
                      shiftStatus === RouteStatus.ONGOING && (
                        <Marker
                          coordinates={[busLocation?.longitude || 0, busLocation?.latitude || 0]}
                          key="realtimeBus"
                        >
                          <img src={busIcon} style={{ height: '1.5em' }} />
                        </Marker>
                      )}
                  </Map>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {!routeToShow && <h4 style={{ textAlign: 'center' }}>{t('no_routes')}</h4>}
                {routeToShow &&
                  routeToShow.map((route, index) => {
                    return (
                      <div
                        key={index}
                        id={route.route_id}
                        ref={(el) => (routeRefs.current[index] = el)}
                      >
                        <RouteDetails
                          setTablesLoaded={setTablesLoaded}
                          id={route.route_id}
                          auth0={auth0}
                          t={t}
                          route={route}
                        />
                      </div>
                    );
                  })}
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </>
  );
};

export default withTranslation('common')(withAuth0(RoutePage));
