import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Button } from '@material-ui/core';
import colors from '../../assets/sass/colors';
import './TicketBundleItem.scss';

const BROWSER_LANGUAGE = navigator.language.substring(0, 2);

const TicketBundleItem = ({ ticketBundle, selectedBundle, setSelectedBundle }) => {
  const { t } = useTranslation('common');

  const bundleMetadata = ticketBundle?.product_metadata
    ? JSON.parse(ticketBundle.product_metadata)
    : null;

  const getTranslatedPriceInfo = (metadata) => {
    let bundleDescription;

    switch (BROWSER_LANGUAGE) {
      case 'fr':
        bundleDescription = metadata?.descriptionFr;
        break;
      case 'es':
        bundleDescription = metadata?.descriptionEs;
        break;
      case 'en':
      default:
        bundleDescription = metadata?.descriptionEn;
    }

    if (bundleDescription) {
      return `(${bundleDescription})`;
    } else {
      return null;
    }
  };

  return (
    <Button
      className={`${
        selectedBundle?.name === ticketBundle.name && 'selected-ticket-bundle'
      } ticket-bundle`}
      key={ticketBundle.name}
      onClick={() => setSelectedBundle(ticketBundle)}
    >
      <Typography variant="subtitle1">
        <Box>{ticketBundle.name}</Box>
      </Typography>
      <Box display="flex" alignItems="flex-end" flexDirection="column">
        <Box>
          <Typography variant="subtitle2">
            {bundleMetadata?.bundleCreditAmount && (
              <>
                {t('get_x_credits_for', {
                  amount: (bundleMetadata.bundleCreditAmount / 100).toFixed(2)
                })}
              </>
            )}
            <Typography display="inline" variant="h6" style={{ fontWeight: 'bold' }}>
              ${(ticketBundle.unit_amount / 100).toFixed(2)}
            </Typography>
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" style={{ color: colors.blaisePurple }}>
            {getTranslatedPriceInfo(bundleMetadata)}
          </Typography>
        </Box>
      </Box>
    </Button>
  );
};

export default TicketBundleItem;
