import React, { useEffect, useState, memo } from 'react';
import { Box, CircularProgress, Grid, Paper, Modal, TextField, Tooltip } from '@material-ui/core';
import DataGridTable from '../../components/DataGrid/DataGridTable';
import { timeOnlyFormat } from '../../utils/MomentTime';
import authenticatedAxiosInstance from '../../axios/axios-authorized';

const RouteManifestModal = memo(({ t, routeToShow, modalOpen, setModalOpen }) => {
  const [passengersList, setPassengersList] = useState(null);
  const [loading, setLoading] = useState(false);
  const routeId = routeToShow?.route_id;

  useEffect(() => {
    fetchPassengersOnRoute(routeId);
  }, []);

  const fetchPassengersOnRoute = async (routeId) => {
    try {
      setLoading(true);

      const response = await authenticatedAxiosInstance.axios.get(
        `/route/getPassengersForRouteForTransit/${routeId}`
      );

      const rideProposals = response?.data?.data?.ride_proposals;

      let passengerList = [];

      rideProposals.forEach((proposal) => {
        // We want to include no shows in the manifest
        const isNoShow = proposal.is_cancelled === 1 && proposal.has_boarded === 0;

        if (proposal.pickup_stop && (proposal.is_cancelled === 0 || isNoShow)) {
          const pickupProposal = {
            first_name: proposal.passenger?.first_name,
            last_name: proposal.passenger?.last_name,
            action: 'Pickup',
            stop_name: proposal.pickup_stop.name,
            time: proposal.pickup_time
          };
          passengerList.push(pickupProposal);
        }
        if (proposal.dropoff_stop && (proposal.is_cancelled === 0 || isNoShow)) {
          const dropoffProposal = {
            first_name: proposal.passenger?.first_name,
            last_name: proposal.passenger?.last_name,
            action: 'Dropoff',
            stop_name: proposal.dropoff_stop.name,
            time: proposal.dropoff_time
          };
          passengerList.push(dropoffProposal);
        }
      });

      setPassengersList(passengerList);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  // For default sorting:
  const sortModel = [{ field: 'time', sort: 'asc' }];

  // Datagrid requires ids for rows:
  let passengersListCopy;
  if (passengersList) {
    passengersListCopy = passengersList.map((passenger, index) => {
      return {
        ...passenger,
        id: index
      };
    });
  }

  const columnStyle = { flex: 0.5, align: 'center', headerAlign: 'center' };
  const columnStyleWide = { flex: 1, align: 'center', headerAlign: 'center' };

  const columns = [
    {
      field: 'first_name',
      headerName: t('first_name'),
      ...columnStyle
    },
    {
      field: 'last_name',
      headerName: t('last_name'),
      ...columnStyle
    },
    {
      field: 'action',
      headerName: t('action'),
      ...columnStyle,
      valueFormatter: (params) => {
        const actionType = params.row?.action;
        if (actionType === 'Pickup') {
          return t('pickup');
        } else {
          return t('dropoff');
        }
      }
    },
    {
      field: 'stop_name',
      headerName: t('bus_stop'),
      ...columnStyleWide,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.stop_name}>
            <Box>{params.row.stop_name}</Box>
          </Tooltip>
        );
      }
    },
    {
      field: 'time',
      headerName: t('time'),
      ...columnStyle,
      valueFormatter: (params) => {
        const timeAtStop = timeOnlyFormat(params.row?.time);
        const timeLabel =
          params.row.action == 'Pickup'
            ? `${t('earliest')} ${timeAtStop}`
            : `${t('latest')} ${timeAtStop}`;
        if (timeLabel) {
          return timeLabel;
        } else {
          return ' - ';
        }
      }
    }
  ];

  return (
    <Modal open={modalOpen} onClose={() => setModalOpen(!modalOpen)} className="modal">
      <Paper elevation={0} style={{ overflow: 'auto', height: '75%', width: '80%' }}>
        <Box m={4}>
          <h2>{t('manifest')}</h2>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                name="id"
                value={routeId}
                variant="outlined"
                fullWidth
                id="id"
                label={t('id')}
              />
            </Grid>
            {loading && (
              <Grid container style={{ marginTop: '20px' }} justify="center">
                <CircularProgress />
              </Grid>
            )}
            {!loading && passengersListCopy && (
              <Grid item xs={12} style={{ marginTop: '20px' }}>
                <DataGridTable columns={columns} rows={passengersListCopy} sortModel={sortModel} />
              </Grid>
            )}
          </Grid>
        </Box>
      </Paper>
    </Modal>
  );
});

export default RouteManifestModal;
