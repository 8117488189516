import React, { useEffect, useState } from 'react';
import {
  Button,
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  Modal,
  TextField,
  Typography
} from '@material-ui/core';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import { DateTimePicker, LocalizationProvider } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import colors from '../../assets/sass/colors';
import moment from 'moment';

/* Used For Create Single Shift and Edit Shift (Could be seperated in future to improve readability) */
const SingleShiftModal = ({
  isOpen,
  closeModal,
  drivers,
  buses,
  depots,
  isEditing,
  isSubmitting,
  shiftInPast,
  shiftWithinCutoffPeriod,
  driverId,
  busId,
  startDepotId,
  endDepotId,
  startTime,
  endTime,
  deadheading,
  handleSubmitEditShift,
  handleSubmitCreateShift,
  requestShiftChanges,
  showRequestShift,
  handleRequestDriverShift,
  parent,
  hasPermissionLastMinuteShiftChange
}) => {
  const { t } = useTranslation('common');
  const [busOptions, setBusOptions] = useState(buses);
  const [checkedLastMinuteChange, setCheckedLastMinuteChange] = useState(false);

  useEffect(() => {
    // Operators can only edit bus within same fleet
    if (isEditing) {
      const currBus = buses.find((bus) => bus.bus_id === busId);
      const fleetId = currBus?.bus_fleet_id;

      fleetId && setBusOptions(buses.filter((bus) => bus.bus_fleet_id === fleetId));
    } else {
      setBusOptions(buses);
    }
  }, []);

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setCheckedLastMinuteChange(false);
        closeModal();
      }}
      disableEnforceFocus={true}
    >
      <Grid className="driverShiftsModal">
        <Grid
          item
          sm={12}
          style={{
            padding: '1rem 2rem',
            borderBottom: `1px solid ${colors.gray300}`
          }}
        >
          <Typography
            style={{
              color: colors.gray800,
              fontWeight: 'bold',
              fontSize: '1.25rem'
            }}
          >
            {isEditing ? t('update_driver_shift') : t('create_driver_shift')}
          </Typography>
        </Grid>

        <Grid container spacing={2} style={{ padding: '1rem 1.5rem' }}>
          <Grid item sm={12}>
            <h2 className="inputLabel">{t('driver_information')}</h2>
          </Grid>

          {/* Driver */}
          <Grid item sm={6}>
            <TextField
              select
              variant="outlined"
              label={t('driver')}
              required
              fullWidth
              value={driverId}
              onChange={(e) => parent.setState({ editDriverId: e.target.value })}
              disabled={isSubmitting || shiftInPast}
            >
              {drivers?.map((driver) => (
                <MenuItem key={driver.driver_id} value={driver.driver_id}>
                  {driver.driver_name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Bus */}
          <Grid item sm={6}>
            <TextField
              select
              variant="outlined"
              label={t('bus')}
              required
              fullWidth
              value={busId}
              onChange={(e) => parent.setState({ editBusId: e.target.value })}
              disabled={isSubmitting || shiftInPast}
            >
              {busOptions?.map((bus) => (
                <MenuItem key={bus.bus_id} value={bus.bus_id}>
                  {bus.bus_name ? bus.bus_name : bus.bus_number}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item sm={12}>
            <h2 className="inputLabel">{t('shift_start')}</h2>
          </Grid>

          {/* Start Depot */}
          <Grid item sm={6}>
            <TextField
              select
              variant="outlined"
              label={t('start_depot')}
              required
              fullWidth
              value={startDepotId}
              onChange={(e) => parent.setState({ startDepotId: e.target.value })}
              disabled={
                (isSubmitting || shiftInPast || shiftWithinCutoffPeriod) &&
                !hasPermissionLastMinuteShiftChange
              }
            >
              {depots?.map((stop) => (
                <MenuItem key={stop.code} value={stop.code}>
                  #{stop.code}: {stop.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Start Time */}
          <Grid item sm={6}>
            <LocalizationProvider dateAdapter={MomentUtils}>
              <DateTimePicker
                label={t('start_time')}
                value={startTime}
                onChange={(date) =>
                  parent.setState({ editStartTime: moment(date).seconds(0).milliseconds(0) })
                }
                minDate={new Date()}
                disabled={
                  (isSubmitting || shiftInPast || shiftWithinCutoffPeriod) &&
                  !hasPermissionLastMinuteShiftChange
                }
                InputProps={{ style: { height: '56px' } }}
                renderInput={(props) => (
                  <TextField {...props} variant="outlined" helperText={null} required fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item sm={12}>
            <h2 className="inputLabel">{t('shift_end')}</h2>
          </Grid>

          {/* End Depot */}
          <Grid item sm={6}>
            <TextField
              select
              variant="outlined"
              label={t('end_depot')}
              required
              fullWidth
              value={endDepotId}
              onChange={(e) => parent.setState({ endDepotId: e.target.value })}
              disabled={
                (isSubmitting || shiftInPast || shiftWithinCutoffPeriod) &&
                !hasPermissionLastMinuteShiftChange
              }
            >
              {depots?.map((stop) => (
                <MenuItem key={stop.code} value={stop.code}>
                  #{stop.code}: {stop.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* End Time */}
          <Grid item sm={6}>
            <LocalizationProvider dateAdapter={MomentUtils}>
              <DateTimePicker
                label={t('end_time')}
                value={endTime}
                onChange={(date) =>
                  parent.setState({ editEndTime: moment(date).seconds(0).milliseconds(0) })
                }
                minDate={startTime ? startTime : new Date()}
                disabled={
                  (isSubmitting || shiftInPast || shiftWithinCutoffPeriod) &&
                  !hasPermissionLastMinuteShiftChange
                }
                InputProps={{ style: { height: '56px' } }}
                renderInput={(props) => (
                  <TextField {...props} variant="outlined" helperText={null} required fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>

          {/* Deadheading */}
          <Grid item sm={12}>
            <TextField
              select
              variant="outlined"
              label={t('end_time_corresponds_to')}
              required
              fullWidth
              value={deadheading}
              onChange={(e) => parent.setState({ editDeadheading: e.target.value })}
              disabled={
                (isSubmitting || shiftInPast || shiftWithinCutoffPeriod) &&
                !hasPermissionLastMinuteShiftChange
              }
            >
              <MenuItem key={'depot'} value={true}>
                {t('depot_return_time')}
              </MenuItem>
              <MenuItem key={'drop_off'} value={false}>
                {t('last_drop_off_time')}
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Box
          display="flex"
          justifyContent="flex-end"
          gridGap="1rem"
          style={{
            borderTop: `1px solid ${colors.gray300}`,
            padding: '1rem 2rem'
          }}
        >
          {isSubmitting ? (
            <Grid container item xs={12} justify="center">
              <CircularProgress />
            </Grid>
          ) : showRequestShift ? (
            <Grid item sm={12} style={{ textAlign: 'center' }}>
              <p>
                {requestShiftChanges
                  ? t('request_driver_shift_changes')
                  : t('request_driver_shift')}
              </p>
              <Button
                onClick={() => handleRequestDriverShift()}
                color="secondary"
                variant="outlined"
              >
                {t('request')}
              </Button>
            </Grid>
          ) : (
            <>
              <Grid item sm={12} style={{ textAlign: 'center' }}>
                {hasPermissionLastMinuteShiftChange && shiftWithinCutoffPeriod ? (
                  <Grid style={{ textAlign: 'left' }}>
                    <Typography
                      style={{
                        color: colors.blaiseRed,
                        fontWeight: 'bold'
                      }}
                    >
                      {t('last_minute_driver_shift_change_warning')}
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => setCheckedLastMinuteChange(event.target.checked)}
                          style={{
                            color: colors.blaiseGreen
                          }}
                        />
                      }
                      label={t('checkbox_last_minute_driver_shift_change')}
                    />
                  </Grid>
                ) : (
                  <></>
                )}

                <Grid item style={{ marginTop: '20px' }}>
                  <Button
                    onClick={closeModal}
                    style={{ marginRight: '20px' }}
                    color="secondary"
                    variant="outlined"
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    onClick={() =>
                      isEditing ? handleSubmitEditShift() : handleSubmitCreateShift()
                    }
                    color="primary"
                    variant="contained"
                    disabled={
                      (isEditing && shiftInPast) ||
                      (!checkedLastMinuteChange && shiftWithinCutoffPeriod)
                    }
                  >
                    {isEditing ? t('update') : t('create')}
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Grid>
    </Modal>
  );
};

export default SingleShiftModal;
