import React from 'react';
import create from 'zustand';
import shallow from 'zustand/shallow';

export const driverShiftStore = create((set) => ({
  driverShiftsAreEndingSoon: false,
  noShiftsAfterDate: null,
  setDriverShiftsAreEndingSoon: (value) => set({ driverShiftsAreEndingSoon: value }),
  setNoShiftsAfterDate: (date) => set({ noShiftsAfter: date })
}));

export const driverShiftHOC = (BaseComponent) => (props) => {
  const store = driverShiftStore(
    (state) => ({
      driverShiftsAreEndingSoon: state.driverShiftsAreEndingSoon,
      noShiftsAfterDate: state.noShiftsAfterDate,
      setDriverShiftsAreEndingSoon: state.setDriverShiftsAreEndingSoon,
      setNoShiftsAfterDate: state.setNoShiftsAfterDate
    }),
    shallow
  );

  return <BaseComponent {...props} driverShiftStore={store} />;
};
