import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';

const ConfirmationDialog = ({ open, onClose, confirmAction, text, title }) => {
  const { t } = useTranslation('common');
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);

    await confirmAction();
  };

  // User has to click cancel to close the dialog
  const handleClose = (event, reason) => {
    if (reason && reason == 'backdropClick') return;
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="confirmation dialog"
      aria-describedby="confirmation dialog"
    >
      <DialogTitle id="dialogTitle">{title}</DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="center" style={styles.contentContainer}>
          {loading ? <CircularProgress /> : <DialogContentText>{text}</DialogContentText>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleConfirm} disabled={loading}>
          {t('confirm')}
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClose}
          disabled={loading}
          autoFocus
        >
          {t('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(ConfirmationDialog);

const styles = {
  contentContainer: {
    width: 300
  }
};
