import React, { Component } from 'react';
import { faPen, faTrash, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../../assets/sass/colors';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomSnackbar from '../../Snackbar/CustomSnackbar';
import DeleteModal from '../../DeleteModal/DeleteModal';
import AddFleet from '../AddFleet/AddFleet';
import './FleetTable.scss';
import { withAuth0 } from '@auth0/auth0-react';
import { withTranslation } from 'react-i18next';
import DataGridTable from '../../DataGrid/DataGridTable';
import { currentUserHOC } from '../../../store/user';
import authenticatedAxiosInstance from '../../../axios/axios-authorized';

const BROWSER_LANG = navigator.language.substring(0, 2);

class FleetTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      busFleet: null, // stores the busFleet data from api call
      refreshFleet: false, // state determines refresh rate of table
      deleteModal: false, // show/hide delete modal
      editMode: false, // turns on editMode for a specific row
      addMode: this.props.add, // renders AddRow component
      snackbar: {
        // open/close snackbar element, message is the string that is passed to the component
        open: false,
        message: ''
      },
      editIndex: null, // default index for row in editMode
      deleteId: null, // default bus fleet id in deleteModal
      isSubmitting: false
    };

    this.initialBusFleet = null;
  }

  componentDidMount() {
    this.getFleets();
  }

  async componentDidUpdate() {
    // This is called when add, edit, and delete are false
    // refreshFleet state is used so that the table is not endlessly refreshing
    if (
      !this.state.addMode &&
      !this.state.editMode &&
      !this.state.deleteModal &&
      this.state.refreshFleet
    ) {
      await authenticatedAxiosInstance.axios
        .get(`/busfleets?searchType=1`)
        .then((res) => {
          this.setState({
            busFleet: res.data,
            refreshFleet: false
          });
          this.initialBusFleet = []; // creates an array for the initial bus fleet to compare differences for edits
          this.state.busFleet.forEach((fleet) =>
            this.initialBusFleet.push({
              bus_fleet_id: fleet.bus_fleet_id,
              bus_type: fleet.bus_type,
              wheelchair_capacity: fleet.wheelchair_capacity,
              bike_capacity: fleet.bike_capacity,
              nondisabled_capacity: fleet.nondisabled_capacity,
              bus_quantity: fleet.buses?.length || fleet.bus_quantity,
              operating_cost: fleet.operating_cost,
              min_passengers: fleet.min_passengers
            })
          );
        })
        .catch((error) => {
          // in the case that the database returns no busfleets, an empty array is set as busFleet state
          if (error.response.status === 404) {
            const busFleet = [];
            this.setState({
              busFleet,
              refreshFleet: false
            });
          } else {
            alert(this.props.t('request_not_completed'));
          }
        });
    }
  }

  getFleets = async () => {
    await authenticatedAxiosInstance.axios
      .get(`/busfleets?searchType=1`)
      .then((res) => {
        const busFleet = res.data;
        this.setState({
          busFleet,
          refreshFleet: false
        });

        this.initialBusFleet = []; // creates an array for the initial bus fleet to compare differences for edits
        this.state.busFleet.forEach((fleet) =>
          this.initialBusFleet.push({
            bus_fleet_id: fleet.bus_fleet_id,
            bus_type: fleet.bus_type,
            wheelchair_capacity: fleet.wheelchair_capacity,
            bike_capacity: fleet.bike_capacity,
            nondisabled_capacity: fleet.nondisabled_capacity,
            bus_quantity: fleet.buses?.length || fleet.bus_quantity,
            operating_cost: fleet.operating_cost,
            min_passengers: fleet.min_passengers
          })
        );
      })
      .catch((err) => {
        // in the case that the database returns no busfleets, an empty array is set as busFleet state
        if (err.response && err.response.status === 404) {
          const busFleet = [];
          this.setState({
            busFleet,
            refreshFleet: false
          });
        } else if (err.response && err.response.status === 403) {
          alert(this.props.t('no_permission'));
        } else {
          alert(this.props.t('request_not_completed'));
        }
      });
  };

  // snackbar message and color are passed as props to indicate what to display
  openSnackbar = (snackbarString, color) => {
    this.setState({
      snackbar: {
        open: true,
        message: snackbarString,
        color: color
      }
    });
  };

  // snackbar closes except for when user clicks outside of component
  closeSnackbar = (reason) => {
    if (reason !== 'clickaway') {
      this.setState({ snackbar: { open: false } });
    }
  };

  handleChange = (event, index) => {
    const target = event.target;
    const value = target.value;
    const id = target.id;

    // handles changes for editing busFleet state
    this.setState(() => {
      let busFleetCopy = this.state.busFleet[index];
      busFleetCopy[id] = value;
      return busFleetCopy;
    });
  };

  // checks if any changes have been made for a specific bus fleet
  isDifferent = (originalBusFleet, updatedBusFleet, index) => {
    return !(
      originalBusFleet[index].bus_type === updatedBusFleet[index].bus_type &&
      originalBusFleet[index].nondisabled_capacity ===
        updatedBusFleet[index].nondisabled_capacity &&
      originalBusFleet[index].wheelchair_capacity === updatedBusFleet[index].wheelchair_capacity &&
      originalBusFleet[index].bike_capacity === updatedBusFleet[index].bike_capacity &&
      originalBusFleet[index].operating_cost === updatedBusFleet[index].operating_cost &&
      originalBusFleet[index].min_passengers === updatedBusFleet[index].min_passengers
    );
  };

  activateEdit = (e, fleetIndex) => {
    this.setState({
      editMode: true,
      editIndex: fleetIndex
    });
  };

  showDelete = (e, fleetIndex) => {
    this.setState({
      deleteModal: true,
      deleteId: this.state.busFleet[fleetIndex].bus_fleet_id
    });
  };

  toggleAdd = () => {
    this.setState({
      addMode: !this.state.addMode,
      refreshFleet: true
    });
  };

  cancelChanges = () => {
    this.setState({
      addMode: false,
      editMode: false,
      deleteModal: false,
      busFleet: this.initialBusFleet,
      deleteId: null,
      editIndex: null,
      refreshFleet: true
    });
  };

  validateValues = () => {
    let index = this.state.editIndex;
    let {
      bus_type,
      wheelchair_capacity,
      bike_capacity,
      nondisabled_capacity,
      operating_cost,
      min_passengers
    } = this.state.busFleet[index];
    try {
      if (parseInt(wheelchair_capacity) < 0)
        throw new Error(this.props.t('no_negative_wheelchair_capacity'));
      if (parseInt(bike_capacity) < 0) throw new Error(this.props.t('no_negative_bike_capacity'));
      if (parseInt(nondisabled_capacity) < 0)
        throw new Error(this.props.t('no_negative_nondisabled_capacity'));
      if (parseInt(operating_cost) < 0) throw new Error(this.props.t('no_negative_operating_cost'));
      if (parseInt(min_passengers) < 0) throw new Error(this.props.t('no_negative_num_passengers'));
      if (parseInt(min_passengers) > parseInt(nondisabled_capacity))
        throw new Error(this.props.t('min_cannot_be_greater_than_max'));
      if (
        bus_type.length === '' ||
        bike_capacity === '' ||
        wheelchair_capacity === '' ||
        nondisabled_capacity === '' ||
        operating_cost === '' ||
        min_passengers === ''
      )
        throw new Error(this.props.t('all_fields_necessary'));

      this.updateValues(index);
    } catch (err) {
      this.openSnackbar(err.message, colors.red);
    }
  };

  // updates the values in the database based on new values entered
  updateValues = async () => {
    let index = this.state.editIndex;
    try {
      if (!this.isDifferent(this.initialBusFleet, this.state.busFleet, index))
        throw new Error(this.props.t('no_changes'));
    } catch (err) {
      this.openSnackbar(err.message, colors.blaiseGray);
      this.setState({ editMode: false, refreshFleet: true });
      return;
    }

    this.setState({
      isSubmitting: true
    });

    const reqBody = {
      bus_type: this.state.busFleet[index].bus_type,
      wheelchair_capacity: this.state.busFleet[index].wheelchair_capacity,
      bike_capacity: this.state.busFleet[index].bike_capacity,
      nondisabled_capacity: this.state.busFleet[index].nondisabled_capacity,
      operating_cost: this.state.busFleet[index].operating_cost,
      min_passengers: this.state.busFleet[index].min_passengers
    };

    authenticatedAxiosInstance.axios
      .put(`/busfleets/${this.state.busFleet[index].bus_fleet_id}`, reqBody)
      .then(() => {
        this.initialBusFleet = reqBody;
        this.openSnackbar(this.props.t('bus_fleet_modified'), colors.blaiseGreen);
        this.setState({
          editMode: false,
          editIndex: null,
          refreshFleet: true
        });
      })
      .catch(() => {
        this.openSnackbar(this.props.t('fleet_not_modified'), colors.red);
      })
      .then(() => this.setState({ isSubmitting: false }));
  };

  onDeleteFleet = async () => {
    this.setState({
      isSubmitting: true
    });

    authenticatedAxiosInstance.axios
      .delete(`/busfleets/${this.state.deleteId}`)
      .then(() => {
        this.openSnackbar(this.props.t('fleet_delete_success'), colors.blaiseGreen);
        this.cancelChanges();
      })
      .catch((err) => {
        const messageLang = `message_${BROWSER_LANG}`;
        err.response?.data?.[messageLang]
          ? this.openSnackbar(err.response.data[messageLang], colors.red)
          : this.openSnackbar(this.props.t('fleet_delete_error'), colors.red);
      })
      .then(() => this.setState({ isSubmitting: false }));
  };

  // renders the actions icons in the default view
  renderDefaultIcons = (fleet, fleetIndex) => {
    return (
      <Box display="flex" flexDirection="row" justifyContent="space-evenly" width="100%">
        <Fab
          color="primary"
          size="small"
          className="editButton"
          variant="extended"
          onClick={(e) => this.activateEdit(e, fleetIndex)}
        >
          <FontAwesomeIcon icon={faPen} color={colors.white} size="1x" className="editIcon" />
        </Fab>
        <Fab
          color="secondary"
          size="small"
          className="deleteButton"
          variant="extended"
          onClick={(e) => this.showDelete(e, fleetIndex)}
        >
          <FontAwesomeIcon icon={faTrash} color={colors.white} size="1x" className="deleteIcon" />
        </Fab>
      </Box>
    );
  };

  // renders the action icons in editMode
  renderEditIcons = (fleetIndex) => {
    if (this.state.editIndex === fleetIndex) {
      return this.state.isSubmitting ? (
        <CircularProgress />
      ) : (
        <Box display="flex" flexDirection="row" justifyContent="space-evenly" width="100%">
          <Fab
            color="primary"
            size="small"
            className="editButton"
            variant="extended"
            onClick={() => this.validateValues()}
          >
            <FontAwesomeIcon icon={faCheck} color={colors.white} size="1x" className="editIcon" />
          </Fab>
          <Fab
            size="small"
            className="deleteButton"
            variant="extended"
            onClick={this.cancelChanges}
          >
            <FontAwesomeIcon icon={faTimes} color={colors.white} size="1x" className="deleteIcon" />
          </Fab>
        </Box>
      );
    }
  };

  // renders table data as editable textfields when the edit button is clicked on a specific tablerow
  renderEditFields = (fleet, fleetIndex, column) => {
    // The user must not be able to edit the fleet id
    if (column.id === 'bus_fleet_id') {
      return (
        <TableCell
          className="tableCell"
          key={column.id}
          align={column.align}
          style={{ height: 34 }}
        >
          {fleet[column.id]}
        </TableCell>
      );
    }

    return (
      <TableCell
        className="editTableCell"
        align={column.align}
        key={column.id + '-' + this.state.busFleet.bus_fleet_id}
      >
        <TextField
          margin="dense"
          key={column.id}
          id={column.id}
          value={fleet[column.id]}
          type={column.id === 'bus_type' ? 'text' : 'number'}
          onChange={(e) => this.handleChange(e, fleetIndex)}
          style={{ width: 90, margin: 0 }}
          inputProps={{ style: { textAlign: column.align, fontSize: 14 } }}
        />
      </TableCell>
    );
  };

  // renders the table data as static text
  renderDefaultFields = (fleet, column) => {
    return (
      <TableCell className="tableCell" key={column.id} align={column.align} style={{ height: 34 }}>
        {fleet[column.id] + (column.id === 'operating_cost' ? '$/h' : '')}
      </TableCell>
    );
  };

  handleEditCellChange = ({ id, field, props }) => {
    // handles changes for editing busFleet state
    let busFleetCopy = this.state.busFleet;
    this.setState(() => {
      busFleetCopy[id][field] = props.value;
    });
    return busFleetCopy;
  };

  render() {
    const columnStyle = { width: 200, align: 'center', headerAlign: 'center' };
    const columnStyleWide = { width: 300, align: 'center', headerAlign: 'center' };

    const columns = [
      {
        field: 'bus_fleet_id',
        headerName: this.props.t('id'),
        ...columnStyle
      },
      {
        field: 'bus_type',
        headerName: this.props.t('fleet'),
        ...columnStyle,
        editable: true
      },
      {
        field: 'bus_quantity',
        headerName: this.props.t('num_vehicle'),
        ...columnStyle,
        editable: false,
        valueGetter: (params) => {
          return params.row.buses?.length || params.row.bus_quantity;
        }
      },
      {
        field: 'min_passengers',
        headerName: this.props.t('min_num_passengers'),
        ...columnStyleWide,
        editable: true
      },
      {
        field: 'nondisabled_capacity',
        headerName: this.props.t('max_nondisabled_capacity'),
        ...columnStyle,
        editable: true
      },
      {
        field: 'wheelchair_capacity',
        headerName: this.props.t('max_wheelchair_capacity'),
        ...columnStyleWide,
        editable: true
      },
      {
        field: 'bike_capacity',
        headerName: this.props.t('max_bike_capacity'),
        ...columnStyle,
        editable: true
      },
      {
        field: 'operating_cost',
        headerName: this.props.t('operating_cost_title'),
        ...columnStyle,
        editable: true
      },
      {
        field: 'button',
        headerName: this.props.t('actions'),
        headerAlign: 'center',
        renderCell: (params) =>
          !this.state.addMode &&
          (this.state.editMode
            ? this.renderEditIcons(params.id)
            : this.renderDefaultIcons(params.row, params.id)),
        sortable: false,
        disableColumnMenu: true
      }
    ];

    // copy of bus fleet with id for datagrid
    let busFleetCopy;
    if (this.state.busFleet) {
      busFleetCopy = this.state.busFleet.map((fleet, index) => {
        return {
          ...fleet,
          id: index
        };
      });
    }

    const { currentUser } = this.props.currentUserStore;

    return (
      <div>
        <Paper>
          <Box display="flex" justifyContent="flex-end" style={{ padding: '16px 0' }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={false}
              onClick={this.toggleAdd}
              data-testid="add-fleet-button"
            >
              {this.props.t('add_fleet_button_title')}
            </Button>
          </Box>
          {this.state.addMode && (
            <AddFleet
              open={this.state.addMode}
              toggleAdd={this.toggleAdd}
              snackbar={this.openSnackbar}
              currentUser={currentUser}
            />
          )}
          <div>
            {busFleetCopy && (
              <div>
                <DataGridTable
                  rows={busFleetCopy}
                  columns={columns}
                  pageSize={5}
                  editIndex={this.state.editIndex}
                  handleEditCellChange={this.handleEditCellChange}
                  checkboxSelection
                  disableSelectionOnClick
                />
              </div>
            )}
            {/* This text renders when the table is empty */}
            {this.state.busFleet == null ||
              (this.state.busFleet.length < 1 && !this.state.addMode && (
                <Box display="flex" justifyContent="center" className="emptyBox">
                  <div>{this.props.t('no_fleets')}</div>
                </Box>
              ))}
          </div>
        </Paper>

        {/* External Components which appear based on changing states */}
        <CustomSnackbar
          message={this.state.snackbar.message}
          open={this.state.snackbar.open}
          onClose={this.closeSnackbar}
          snackbarColor={this.state.snackbar.color}
        />
        <DeleteModal
          open={this.state.deleteModal}
          isSubmitting={this.state.isSubmitting}
          toggleModal={this.cancelChanges}
          deleteId={this.state.deleteId}
          onDeleteItem={this.onDeleteFleet}
          deleteMessage={this.props.t('confirm_delete_fleet')}
        />
      </div>
    );
  }
}

export default withTranslation('common')(withAuth0(currentUserHOC(FleetTable)));
