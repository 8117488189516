// React
import React, { useEffect, useState, useRef } from 'react';

// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
import { Autocomplete, Alert, AlertTitle } from '@material-ui/lab';
import { Box, Button, TextField, Typography } from '@material-ui/core';

// Assets
import { faMapPin } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import busStopIcon from '../../assets/images/bus-stop.svg';
import config from '../../config';
import colors from '../../assets/sass/colors';
import walkingRadiusStyle from './WalkingRadiusStyle';

// Others
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import ReactMapboxGl, { GeoJSONLayer, Layer, Feature } from 'react-mapbox-gl';
import { circle } from '@turf/turf';
import _ from 'lodash';
import { FeaturesType } from '../../enums/featuresType';
import { transitAgencyStore } from '../../store/transitAgency';
import './PinSearch.scss';

const Map = ReactMapboxGl({
  accessToken: config.mapBoxToken
});

const PinSearch = (props) => {
  const { t } = useTranslation('common');
  const [loading, setLoading] = useState(false);
  const [mapIsMoving, setMapIsMoving] = useState(false); // Disables the submit button when the map is moving
  const [testLocationCoords, setTestLocationCoords] = useState(props.originCenter); // Used for testing the center of the map
  const [followUserLocation, setFollowUserLocation] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [zoomLevel, setZoomLevel] = useState([11]); // default value for zoom
  const [dragOrSearch, setDragOrSearch] = useState('search'); // used in useEffect for zooming in
  const inputRef = useRef(null);
  const { features, geoJson } = transitAgencyStore();
  const hasTransfersFeature = features.some((feature) => feature.name === FeaturesType.TRANSFERS);

  const image = new Image(12, 12);
  image.src = busStopIcon;
  const images = ['busStopImage', image];

  useEffect(() => {
    // Auto focuses the input, allowing you to type immediately.
    inputRef.current.focus();
  }, []);

  // Rendering the dropdown options only when there are suggestions
  useEffect(() => {
    if (props.type === 'origin') {
      props.originDropdownOptions.length == 0 ? setOpenDropdown(false) : setOpenDropdown(true);
    } else {
      props.destDropdownOptions.length == 0 ? setOpenDropdown(false) : setOpenDropdown(true);
    }
  }, [props.originDropdownOptions, props.destDropdownOptions]);

  // Zoom in only when the user searches and selects a location, and the map finishes moving
  useEffect(() => {
    if (dragOrSearch === 'search') {
      setZoomLevel([15]);
    }
  }, [props.origin, props.destination]);

  const mapRef = useRef(null);

  const maxWalkingDistance = (props.maxWalkingTime * 60) / 1000; // user estimated to walk 1 m/s, and distance is in km

  // For displaying the walking radius
  const walkingRadiusData = circle(
    props.type == 'origin' ? props.originCenter : props.destinationCenter,
    maxWalkingDistance,
    {
      steps: 64,
      units: 'kilometers'
    }
  );

  const fetchLocationName = async (e) => {
    // Only fetch the location name if we are dragging on the map
    // This avoids address conflicts between Google and Mapbox
    if (dragOrSearch === 'drag') {
      try {
        const newCoords = [e.target.transform.center.lng, e.target.transform.center.lat];
        //setTestLocationCoords(newCoords); // Uncomment for testing the center of the map
        let response = (
          await axios.get(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${newCoords[0]},${newCoords[1]}.json?access_token=${config.mapBoxToken}&limit=1`
          )
        ).data;

        setZoomLevel([e.target.transform.zoom]);

        const newLocationName = response.features[0].place_name;

        if (props.type === 'origin') {
          props.updateOrigin(newLocationName, newCoords[1], newCoords[0]);
        } else {
          props.updateDestination(newLocationName, newCoords[1], newCoords[0]);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  // This is a workaround for a bug with mapbox library where the callback functions cannot see the updated state.
  // Reference: https://github.com/alex3165/react-mapbox-gl/issues/963
  const fetchLocationNameRef = useRef(fetchLocationName);
  fetchLocationNameRef.current = fetchLocationName; // Update reference with every render

  const mapOnDrag = _.throttle(() => {
    setDragOrSearch('drag');
  }, 500);

  const polygonPaint = (ReactMapboxGl.FillPaint = {
    'fill-color': ['coalesce', ['get', 'color'], colors.blaiseGreen],
    'fill-opacity': 0.3
  });

  return (
    <>
      <div
        style={{
          width: '100%',
          alignItems: 'center',
          marginBottom: 15
        }}
      >
        {loading || mapIsMoving ? (
          <CircularProgress />
        ) : (
          <>
            <Alert icon={false} severity="success">
              <AlertTitle>{t(props.type)}</AlertTitle>
              <Box style={{ fontSize: '10px' }}>{t('drag_pin_or_search')}</Box>
            </Alert>
          </>
        )}
      </div>
      <div
        style={{
          width: '100%',
          alignItems: 'center',
          marginBottom: 15
        }}
      >
        <Autocomplete
          open={openDropdown}
          onClose={() => setOpenDropdown(false)}
          name={props.type}
          required
          fullWidth
          id={props.type}
          freeSolo
          options={props.type == 'origin' ? props.originDropdownOptions : props.destDropdownOptions}
          value={props.type == 'origin' ? props.origin : props.destination}
          getOptionLabel={(option) => option.name || ''}
          filterOptions={(options) => options}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              required
              fullWidth
              label={t('type_to_search')}
              error={
                props.errorLocation === 'origin' ||
                props.errorLocation === 'destination' ||
                props.errorLocation === 'originAndDestination'
              }
              data-testid={props.type == 'origin' ? 'origin' : 'destination'}
              inputRef={inputRef}
            />
          )}
          onInputChange={(e, val, reason) => {
            props.type == 'origin'
              ? props.handleSearchInput(e, val, reason, true)
              : props.handleSearchInput(e, val, reason, false);
          }}
          onChange={(e, val) => {
            if (val) {
              props.type == 'origin'
                ? props.handleSelectLocation(val, true)
                : props.handleSelectLocation(val, false);
              props.setErrorLocation(null);
            } else {
              setZoomLevel([11]);
            }
          }}
          onFocus={() => {
            setDragOrSearch('search');
          }}
          disabled={props.isSubmitting || props.isTripConfirmed}
        />
      </div>

      <div
        style={{
          marginBottom: 15,
          height: '400px',
          position: 'relative',
          width: '100%'
        }}
      >
        <Map
          ref={(c) => (mapRef.current = c)}
          onDidFinishRenderingMapFully={followUserLocation}
          onRegionWillChange={() => setMapIsMoving(true)}
          onMoveEnd={(_, e) => fetchLocationNameRef.current(e)}
          onDrag={mapOnDrag}
          style={'mapbox://styles/mapbox/light-v10'}
          containerStyle={{ width: '100%', height: '400px', borderRadius: 10 }}
          center={props.type == 'origin' ? props.originCenter : props.destinationCenter}
          zoom={zoomLevel}
        >
          {geoJson && (
            <>
              <GeoJSONLayer data={geoJson} fillPaint={polygonPaint} />
              <Layer
                type="symbol"
                id="marker"
                layout={{ 'icon-image': 'busStopImage', 'icon-allow-overlap': true }}
                images={images}
                minZoom={14}
              >
                {props.busStops &&
                  props.busStops.map((stop) => {
                    const coordinates = [Number(stop.lon), Number(stop.lat)];
                    return <Feature key={stop.stopId} coordinates={coordinates} />;
                  })}
              </Layer>
              <GeoJSONLayer {...walkingRadiusStyle} data={walkingRadiusData} />
            </>
          )}

          {/* Use the layer below to check that the pin is at the center of the map, 
            import Layer and Feature to test */}
          {/* <Layer
            type="circle"
            id="markers"
            paint={{
              'circle-color': colors.blaiseRed,
              'circle-stroke-width': 1,
              'circle-radius': 2
            }}
          >
            <Feature coordinates={[testLocationCoords[0], testLocationCoords[1]]} />
          </Layer> */}
        </Map>
        {/* Middle static pin icon */}
        <FontAwesomeIcon
          icon={faMapPin}
          size="2x"
          // size equivalent to 18x32 px
          color={props.type === 'origin' ? colors.blaisePurple : colors.blaiseRed}
          style={{
            position: 'absolute',
            left: 'calc(50% - 9px)',
            top: '168px',
            zIndex: 20
          }}
        />
      </div>
      {hasTransfersFeature && (
        <>
          <Typography className="map-key-container">
            <Box
              className="map-key-circle"
              style={{
                backgroundColor: colors.blaiseGreen
              }}
            />
            {t('on_demand_zone')}
          </Typography>
          <Typography className="map-key-container">
            <Box
              className="map-key-circle"
              style={{
                backgroundColor: colors.fixedRouteOrange
              }}
            />
            {t('fixed_route_zone')}
          </Typography>
        </>
      )}
      <Typography className="map-key-container">
        <Box
          className="map-key-circle"
          style={{
            backgroundColor: colors.blaiseGray
          }}
        />
        {t('max_walking_distance_circle')}
      </Typography>
      <Box display="flex" justifyContent="center" gridGap={24} style={{ padding: '16px 0' }}>
        <Button
          variant="outlined"
          onClick={() => props.setOpenMap(false)}
          color="secondary"
          data-testid="submitLocation"
        >
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={() => props.submitPinSearch(props.type == 'origin')}
          color="primary"
          data-testid="submitLocation"
        >
          {t('submit')}
        </Button>
      </Box>
    </>
  );
};

export default PinSearch;
