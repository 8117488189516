import React, { useState } from 'react';
import { DataGrid, enUS, frFR } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/styles';
import colors from '../../assets/sass/colors';

const useStyles = makeStyles({
  root: {
    '& .MuiDataGrid-columnHeaderWrapper': {
      backgroundColor: colors.blaiseLightGreen
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 700,
      color: colors.blaiseGray
    },
    '& .super-app-theme--inactive': {
      backgroundColor: colors.blaiseLightGray
    }
  }
});

const DataGridTablePagination = (props) => {
  const classes = useStyles();

  let lang = enUS;
  if (navigator.language.substring(0, 2) === 'fr') {
    lang = frFR;
  }

  return (
    <div style={{ width: '100%' }} className={classes.root}>
      <DataGrid
        localeText={lang.props.MuiDataGrid.localeText}
        autoHeight
        getRowClassName={(params) => {
          if (params.row.status || params.row.item?.status) {
            return `super-app-theme--${params.row.status || params.row.item?.status}`;
          }
        }}
        rows={props.rows}
        columns={props.columns}
        pageSize={props.tablePageParameters?.pageSize}
        onPageSizeChange={(newPageSize) =>
          props.setPageSize((previousPageParameters) => ({
            ...previousPageParameters,
            pageSize: newPageSize.pageSize
          }))
        }
        rowCount={props.tablePageParameters?.rowCount ?? null}
        loading={props.tablePageParameters?.isLoading ?? null}
        page={props.tablePageParameters?.pageNumber ?? null}
        paginationMode="server"
        onPageChange={(newPage) => {
          props.setPageNumber((previousPageParameters) => ({
            ...previousPageParameters,
            pageNumber: newPage.page
          }));
        }}
        rowsPerPageOptions={[25, 50, 100]}
        sortingMode="server"
        pagination
        isCellEditable={(params) => params.row.id === props.editIndex}
        disableSelectionOnClick
        onEditCellChange={props.handleEditCellChange}
        sortModel={props.sortModel}
        onSortModelChange={(model) => {
          if (props.handleSortModelChange) {
            props.handleSortModelChange(model.sortModel);
            localStorage.setItem(props.pageKey, JSON.stringify(model.sortModel));
          }
        }}
        onColumnVisibilityChange={(col) => {
          if (!col.isVisible) {
            if (props.handleSetHiddenColumns) {
              props.handleSetHiddenColumns([...props.hiddenColumns, col.field]);
              localStorage.setItem(
                'blaise-rm-hidden-columns',
                JSON.stringify([...props.hiddenColumns, col.field])
              );
            }
          } else {
            const newHiddenColumns = props.hiddenColumns.filter((column) => column !== col.field);
            props.handleSetHiddenColumns(newHiddenColumns);
            localStorage.setItem('blaise-rm-hidden-columns', JSON.stringify(newHiddenColumns));
          }
        }}
      />
    </div>
  );
};

export default DataGridTablePagination;
