import React from 'react';
import create from 'zustand';
import shallow from 'zustand/shallow';

export const analyticsStore = create((set) => ({
  analyticsUrl: null,
  setAnalyticsUrl: (url) => set({ analyticsUrl: url })
}));

export const analyticsHOC = (BaseComponent) => (props) => {
  const store = analyticsStore(
    (state) => ({
      analyticsUrl: state.analyticsUrl,
      setAnalyticsUrl: state.setAnalyticsUrl
    }),
    shallow
  );

  return <BaseComponent {...props} analyticsStore={store} />;
};
