import React, { useState, useEffect } from 'react';
import { Box, Paper } from '@material-ui/core';
import ReactMapboxGl, { GeoJSONLayer, Layer, Feature } from 'react-mapbox-gl';
import busStopIcon from '../../../assets/images/bus-stop.svg';
import Upload from '../../Upload/Upload';
import CustomSnackbar from '../../Snackbar/CustomSnackbar';
import config from '../../../config';
import colors from '../../../assets/sass/colors';
import './SetupMap.scss';
import authenticatedAxiosInstance from '../../../axios/axios-authorized';
import { useTranslation } from 'react-i18next';

const Map = ReactMapboxGl({
  accessToken: config.mapBoxToken
});

const ZONE_STYLES = {
  'fill-color': ['coalesce', ['get', 'color'], colors.blaiseGreen],
  'fill-opacity': 0.3
};

const SetupMap = ({ transitAgencyId, centerLon, centerLat, getTransitAgencyInfo }) => {
  const [geojson, setGeojson] = useState(null);
  const [busStops, setBusStops] = useState([]);
  const [busStopImages, setBusStopImages] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: null
  });

  const { t } = useTranslation('common');

  useEffect(() => {
    fetchGeojson();
    fetchBusStops();
  }, []);

  const fetchGeojson = async () => {
    try {
      const responseGeoJSON = (
        await authenticatedAxiosInstance.axios.get(`/transitagencies/${transitAgencyId}/geojson`)
      ).data;
      //TODO: Add geojson validation.
      setGeojson(responseGeoJSON.zone_geojson);
    } catch (err) {
      console.log('Unable to fetch and set GeoJSON data:', err);
    }
  };

  const fetchBusStops = async () => {
    try {
      const result = await authenticatedAxiosInstance.axios.get(`/busstops/${transitAgencyId}`);

      const image = new Image(12, 12);
      image.src = busStopIcon;

      setBusStopImages(['busStopImage', image]);
      setBusStops(result.data.busStops);
    } catch (err) {
      console.log(`Unable to fetch stops: ${err}`);
    }
  };

  const onSuccessUpload = async () => {
    await fetchGeojson();
    await getTransitAgencyInfo(); // Get new center_lon and center_lat
    openSnackbar(t('upload_success'), colors.blaiseGreen);
  };

  const onFailureUpload = (e) => {
    console.log(e);
    openSnackbar(t('upload_fail'), colors.red);
  };

  const openSnackbar = (snackbarString, color) => {
    setSnackbar({
      open: true,
      message: snackbarString,
      color: color
    });
  };

  const closeSnackbar = (reason) => {
    if (reason !== 'clickaway') {
      setSnackbar({ ...snackbar, open: false });
    }
  };

  return (
    <>
      <CustomSnackbar
        message={snackbar.message}
        open={snackbar.open}
        onClose={() => closeSnackbar()}
        snackbarColor={snackbar.color}
      />
      <Paper className="formPaper" id="operating_zone">
        <Box
          p={0}
          m={0}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          marginTop="15px"
        >
          {/* eslint-disable-next-line */}
          <Map
            style="mapbox://styles/mapbox/light-v10"
            className="bordered"
            containerStyle={{
              marginTop: window.innerWidth / 1000,
              marginBottom: '20px',
              height: '400px'
            }}
            center={[centerLon, centerLat]}
            zoom={[10.5]}
          >
            <GeoJSONLayer data={geojson} fillPaint={ZONE_STYLES} />

            {/* Operating Zone Bus Stops */}
            {busStopImages.length > 0 && busStops.length > 0 && (
              <Layer
                type="symbol"
                id="marker"
                layout={{ 'icon-image': 'busStopImage', 'icon-allow-overlap': true }}
                minZoom={13}
                images={busStopImages}
              >
                {busStops.map((busStop) => (
                  <Feature
                    key={`${busStop.lon}${busStop.lat}`}
                    coordinates={[busStop.lon, busStop.lat]}
                  />
                ))}
              </Layer>
            )}
          </Map>
          <Upload
            uploadEndpoint={`${config.apiServer}/transitagencies/${transitAgencyId}/geojson`}
            fieldName="operating_zone"
            onSuccess={onSuccessUpload}
            onFailure={onFailureUpload}
            title={t('upload_operating_zone')}
          />
        </Box>
      </Paper>
    </>
  );
};

export default SetupMap;
