import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import dotenv from 'dotenv';
import { Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import theme from './assets/sass/theme';
import Navbar from './components/NavBar/NavBar';
import FleetManagement from './pages/Fleet/FleetManagement';
import BusManagement from './pages/Bus/BusManagement';
import StopManagement from './pages/Stop/StopManagement';
import DriverTable from './pages/Driver/DriverTable';
import EmployeeTable from './pages/Employee/EmployeeTable';
import PassengerManagement from './pages/PassengerManagement/PassengerManagement';
import DriverShiftTable from './components/DriverShiftTable/DriverShiftTable';
import RideManagement from './pages/Ride/RideManagement';
import RidePage from './pages/Ride/RidePage';
import RouteShiftManagement from './pages/RouteManagement/RouteShiftManagement';
import RoutesPage from './pages/RouteManagement/RoutePage';
import RequestByPhone from './pages/RequestByPhone/RequestByPhone';
import Profile from './pages/Profile/Profile';
import Setup from './pages/SystemParameters/Setup';
import './App.scss';
import history from './utils/history';
import { Router } from 'react-router-dom';
import Loading from './components/Loading/Loading';
import { withAuth0 } from '@auth0/auth0-react';
import Callback from './pages/Callback/Callback';
import config from './config';
import CustomSnackbar from './components/Snackbar/CustomSnackbar';
import * as Sentry from '@sentry/react';
import { currentUserHOC } from './store/user';
import { authHOC } from './store/auth';
import { accessTokenContainer } from './utils/authUtils';
import authenticatedAxiosInstance from './axios/axios-authorized';
import Analytics from './pages/Analytics/Analytics';
import { analyticsHOC } from './store/analytics';
import { hasFutureDriverShifts } from './utils/driverShift';
import RequestByPhoneDetails from './pages/RequestByPhoneDetails/RequestByPhoneDetails';
import { transitAgencyStoreHOC } from './store/transitAgency';

dotenv.config();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkingSession: true,
      snackbar: {
        open: false,
        message: '',
        color: '#ff0000'
      },
      hasBeenCalledOnce: false,
      hasCheckedForDriverShifts: false
    };

    accessTokenContainer.setAccessTokenSilently(this.props.auth0.getAccessTokenSilently);
  }

  async componentDidMount() {
    if (window.location.href === `${config.appUrl}/callback`) {
      this.setState({ checkingSession: false });
      return;
    }

    try {
      await this.props.auth0.silentAuth();
      this.forceUpdate();
    } catch (err) {
      if (err.error !== 'login_required') console.log(err.error);
    }

    this.setState({ checkingSession: false });
  }

  componentDidUpdate(prevProps) {
    const { accessToken } = this.props.authStore;
    const { currentUser } = this.props.currentUserStore;

    if (prevProps.auth0.isAuthenticated !== this.props.auth0.isAuthenticated) {
      this.getAuth0AccessToken();
    }

    if (prevProps.authStore.accessToken !== accessToken) {
      authenticatedAxiosInstance.createAuthenticatedAxiosInstance(accessToken);
      this.getCurrentUser();
    }

    if (
      currentUser &&
      prevProps.authStore.currentUser !== currentUser &&
      !this.state.hasBeenCalledOnce &&
      accessToken
    ) {
      this.getAnalyticsUrl();
      this.getTransitAgencyFeatures();
      this.getTransitAgencyGeoJson();
      this.setState({ hasBeenCalledOnce: true }); // Required because of weird racing condition going on between different API calls between onMount and onUpdate.
    }

    if (
      currentUser &&
      prevProps.authStore.currentUser !== currentUser &&
      !this.state.hasCheckedForDriverShifts &&
      accessToken
    ) {
      this.checkForDriverShifts();
    }
  }

  checkPermissions = (permissionString) => {
    if (this.props.auth0) {
      const permissions = this.props.auth0.user['http://blaisetransitagency.com/permissions'];
      if (permissions.includes(permissionString)) {
        return true;
      }
    }
    return false;
  };

  openSnackbar = (snackbarString, color) => {
    this.setState({
      snackbar: {
        open: true,
        message: snackbarString,
        color: color
      }
    });
  };

  // Snackbar closes except for when user clicks outside of component
  closeSnackbar = (reason) => {
    if (reason !== 'clickaway') {
      this.setState({ snackbar: { open: false } });
    }
  };

  getCurrentUser = async () => {
    const { setCurrentUser } = this.props.currentUserStore;

    try {
      const currentUserInfo = await authenticatedAxiosInstance.axios.get(`/currentUser`);

      setCurrentUser(currentUserInfo.data);
    } catch (err) {
      console.log('App::getCurrentUser', err);
    }
  };

  getAuth0AccessToken = async () => {
    const { setAccessToken } = this.props.authStore;

    try {
      const token = await accessTokenContainer.getAccessTokenSilently()({
        audience: config.apiAudience
      });

      setAccessToken(token);
    } catch (error) {
      console.log('App::getAuth0AccessToken', error);
    }
  };

  getAnalyticsUrl = async () => {
    const { currentUser } = this.props.currentUserStore;
    const { setAnalyticsUrl } = this.props.analyticsStore;

    try {
      const analyticsUrls = (
        await authenticatedAxiosInstance.axios.get(
          `/transitagencies/${currentUser.transit_agency_id}/analytics`
        )
      ).data;

      if (analyticsUrls) {
        setAnalyticsUrl(analyticsUrls);
      }
    } catch (err) {
      console.log('App::getAnalyticsUrl', err);
    }
  };

  getTransitAgencyFeatures = async () => {
    const { setFeatures } = this.props.transitAgencyStore;
    const { currentUser } = this.props.currentUserStore;

    try {
      const featuresResponse = (
        await authenticatedAxiosInstance.axios.get(
          `/features?transitAgencyId=${currentUser.transit_agency_id}`
        )
      ).data;

      setFeatures(featuresResponse);
    } catch (err) {
      console.log('App::getTransitAgencyFeatures', err);
    }
  };

  getTransitAgencyGeoJson = async () => {
    const { setGeoJson } = this.props.transitAgencyStore;
    const { currentUser } = this.props.currentUserStore;

    try {
      const responseGeoJSON = (
        await authenticatedAxiosInstance.axios.get(
          `/transitagencies/${currentUser.transit_agency_id}/geojson`
        )
      ).data;

      setGeoJson(responseGeoJSON.zone_geojson);
    } catch (err) {
      console.log('App::getTransitAgencyFeatures', err);
    }
  };

  checkForDriverShifts = () => {
    hasFutureDriverShifts();
    this.setState({ hasCheckedForDriverShifts: true });
  };

  render() {
    const { currentUser } = this.props.currentUserStore;
    const { isLoading, isAuthenticated, user, loginWithRedirect } = this.props.auth0;
    const { accessToken } = this.props.authStore;

    if (!isLoading && !user) return loginWithRedirect();
    if (isLoading || !currentUser || !accessToken || !this.state.hasBeenCalledOnce)
      return <Loading />;
    if (isAuthenticated) {
      return (
        <div>
          <Router history={history}>
            <ThemeProvider theme={theme}>
              <Navbar />
              <main id="pageWrap">
                {/* <Route path="/home" exact component={Home} /> */}
                <Route path="/analytics" exact component={Analytics} />
                {/* <Route path="/realtime" exact component={Realtime} /> */}
                {this.checkPermissions('manage:fleets') ? (
                  <Route path="/fleets/" exact component={FleetManagement} />
                ) : (
                  <Route path="/fleets/" exact component={Profile} />
                )}
                {this.checkPermissions('manage:buses') ? (
                  <Route path="/vehicles/" exact component={BusManagement} />
                ) : (
                  <Route path="/vehicles/" exact component={Profile} />
                )}
                {this.checkPermissions('manage:busStops') ? (
                  <Route path="/network/" exact component={StopManagement} />
                ) : (
                  <Route path="/network/" exact component={Profile} />
                )}
                {this.checkPermissions('manage:drivers') ? (
                  <Route path="/drivers/" exact component={DriverTable} />
                ) : (
                  <Route path="/drivers/" exact component={Profile} />
                )}
                {this.checkPermissions('manage:employees') ? (
                  <Route path="/employees/" exact component={EmployeeTable} />
                ) : (
                  <Route path="/employees/" exact component={Profile} />
                )}
                {this.checkPermissions('manage:driverShifts') ? (
                  <Route path="/driverShifts/" exact component={DriverShiftTable} />
                ) : (
                  <Route path="/driverShifts/" exact component={Profile} />
                )}
                {this.checkPermissions('manage:history') ? (
                  <Route path="/history/" exact component={RideManagement} />
                ) : (
                  <Route path="/history/" exact component={Profile} />
                )}
                {this.checkPermissions('manage:history') ? (
                  <Route exact path="/history/:tripId" component={RidePage} />
                ) : (
                  <Route path="/history/" exact component={Profile} />
                )}
                {this.checkPermissions('manage:routes') ? (
                  <Route exact path="/routes/" component={RouteShiftManagement} />
                ) : (
                  <Route path="/routes/" exact component={Profile} />
                )}
                {this.checkPermissions('manage:routes') ? (
                  <Route exact path="/routesForShift/:shiftId" component={RoutesPage} />
                ) : (
                  <Route path="/routes/" exact component={Profile} />
                )}
                {this.checkPermissions('manage:calledInRequests') ? (
                  <Route path="/requestByPhone/" exact component={RequestByPhone} />
                ) : (
                  <Route path="/requestByPhone/" exact component={Profile} />
                )}
                {this.checkPermissions('manage:calledInRequests') ? (
                  <Route
                    path="/requestByPhoneDetails/:tripId"
                    exact
                    component={RequestByPhoneDetails}
                  />
                ) : (
                  <Route path="/requestByPhoneDetails/:tripId" exact component={Profile} />
                )}
                {/* {this.checkPermissions("manage:paratransit") ?
                  <Route path="/paratransit/" exact component={ParatransitManagement} />
                  :
                  <Route path="/paratransit/" exact component={Profile} />
                } */}
                {this.checkPermissions('manage:systemParameters') ? (
                  <Route path="/settings/" exact component={Setup} />
                ) : (
                  <Route path="/settings/" exact component={Profile} />
                )}
                {this.checkPermissions('manage:passengers') ? (
                  <Route path="/passengers/" exact component={PassengerManagement} />
                ) : (
                  <Route path="/passengers/" exact component={Profile} />
                )}

                {/* Profile is used as default page as Home page is not included in MVP  */}
                <Route path="/" exact component={Profile} />
                <Route exact path="/callback" component={Callback} />

                <CustomSnackbar
                  message={this.state.snackbar.message}
                  open={this.state.snackbar.open}
                  onClose={this.closeSnackbar}
                  autoHideDuration={null}
                  snackbarColor={this.state.snackbar.color}
                />
              </main>
            </ThemeProvider>
          </Router>
        </div>
      );
    }
  }
}

export default withTranslation('common')(
  withAuth0(authHOC(currentUserHOC(analyticsHOC(transitAgencyStoreHOC(Sentry.withProfiler(App))))))
);
