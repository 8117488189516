/* eslint-disable no-undef */

const LocalStorageKeys = Object.freeze({
  // RideManagement.js
  RIDE_MANAGEMENT_SORTED_COLUMN: 'blaise-rm-sorted-column-ride-management',
  RIDE_MANAGEMENT_HIDDEN_COLUMNS: 'blaise-rm-hidden-columns',
  RIDE_MANAGEMENT_FILTER_START_DATE: 'blaise-rm-filter-startDate',
  RIDE_MANAGEMENT_FILTER_END_DATE: 'blaise-rm-filter-endDate',
  RIDE_MANAGEMENT_FILTER_STATUS: 'blaise-rm-filter-status',

  // RouteManagement.js
  ROUTE_SHIFT_MANAGEMENT_SORTED_COLUMN: 'blaise-rsm-sorted-column-route-shift-management',
  ROUTE_SHIFT_MANAGEMENT_FILTER_END_DATE: 'blaise-rsm-filter-endDate',
  ROUTE_SHIFT_MANAGEMENT_FILTER_START_DATE: 'blaise-rsm-filter-startDate'
});

module.exports = LocalStorageKeys;
