import React, { useEffect, useState } from 'react';
import '../../components/UI/multistep.scss';
import ProgressDots from '../../components/UI/ProgressDots';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Button,
  Typography,
  CircularProgress,
  Grid,
  Box,
  MenuItem,
  Select,
  InputLabel,
  TextField,
  Checkbox
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import moment from 'moment';
import ReceiptSummary from '../../components/Payment/ReceiptSummary';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import colors from '../../assets/sass/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faClock, faDollarSign, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import authenticatedAxiosInstance from '../../axios/axios-authorized';
import './PaymentModal.scss';
import '../../assets/sass/global.scss';
import { PaymentType } from '../../enums/PaymentType.js';
import { calculateDaysRemaining, dateOnlyFormatShortMonth } from '../../utils/MomentTime';
import { creditCardDateFormat } from '../../utils/dateTimeFormat';
import { EditRequestType } from '../../enums/EditRequestType';
import { isPayOnBoard } from '../../utils/isPayOnBoard';
import CustomSnackbar from '../../components/Snackbar/CustomSnackbar';
import TicketBundles from '../../components/TicketBundles/TicketBundles';

const passStateType = Object.freeze({
  LOADING: 'loading',
  ACTIVE: 'active',
  INACTIVE: 'inactive'
});

const EXPIRY_MONTHS_OPTIONS = Array.from({ length: 12 }, (_, i) => i + 1);
const EXPIRY_YEARS_OPTIONS = Array.from({ length: 6 }, (_, i) => i + new Date().getFullYear());
const BROWSER_LANG = navigator.language.substring(0, 2);

const PaymentModal = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: null
  });
  const [hasActivePass, setHasActivePass] = useState(passStateType.LOADING);
  const [savedCards, setSavedCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedCardToEdit, setSelectedCardToEdit] = useState(null);
  const [saveCardForLaterInitialCheckbox, setSaveCardForLaterInitialCheckbox] = useState(false);
  const [saveCardForLaterConsentCheckbox, setSaveCardForLaterConsentCheckbox] = useState(false);
  const [cardHasConsent, setCardHasConsent] = useState(false);
  const [updateCardExpiryDate, setUpdateCardExpiryDate] = useState({
    month: null,
    year: null
  });
  const [passPaymentMethod, setPassPaymentMethod] = useState(null);
  const [isPurchasingPass, setIsPurchasingPass] = useState(false);
  const [passengerPassInfo, setPassengerPassInfo] = useState({});
  const [availableTAPasses, setAvailableTAPasses] = useState([]);
  const [tripHasMultiplePassengers, setTripHasMultiplePassengers] = useState(false);

  const [transitAgencyPaymentTypes, setTransitAgencyPaymentTypes] = useState([]);

  const [passengerBalance, setPassengerBalance] = useState(0);

  useEffect(() => {
    if (props.passenger.connected_customer_id) {
      getSavedCards();
    }
    getPassengerBalance();
  }, []);

  useEffect(() => {
    checkIfMultiplePassengers();
  }, [props.passengers]);

  useEffect(() => {
    getTAPaymentTypes();

    if (props.editRequestMode) {
      setIsLoading(false);
      handleRadioChange(props.data?.paymentType);
    }
  }, [props.transitAgencyId]);

  useEffect(() => {
    const previousPaymentType = props.data?.paymentType;
    if (previousPaymentType) {
      props.setPaymentType(previousPaymentType);
    }
  }, [props.data?.paymentType]);

  useEffect(() => {
    if (!isSubmitting && props.paymentType) {
      props.calculateTripPrice(props.paymentType, 'depart');
      if (props.returnTrip) {
        props.calculateTripPrice(props.paymentType, 'return');
      }
    }

    if (props.paymentType === PaymentType.PASS) {
      getPassInfo();
    }
  }, [props.paymentType]);

  const openSnackbar = (snackbarString, color) => {
    setSnackbar({ ...snackbar, open: true, message: snackbarString, color: color });
  };

  const getPassengerBalance = async () => {
    try {
      const response = (
        await authenticatedAxiosInstance.axios.get(
          `/passengers/${props.passenger.passenger_id}/getPassengerBalance/${props.passenger.transit_agency_id}`
        )
      ).data;

      setPassengerBalance(response.balance);
    } catch (error) {
      console.log(error, 'could not retrieve passenger balance');
    }
  };
  const getSavedCards = async () => {
    try {
      const savedCards = (
        await authenticatedAxiosInstance.axios.post(`/payment/showCustomerPaymentMethods_v2`, {
          customer_id: props.passenger.connected_customer_id,
          transitAgencyId: props.passenger.transit_agency_id
        })
      ).data;

      setSavedCards(savedCards.data);
    } catch (error) {
      console.log('PaymentModal::getSavedCards', error);
    }
  };

  const removeSavedCard = async (cardId) => {
    try {
      await authenticatedAxiosInstance.axios.post(`/payment/removeCardFromCustomer_v2`, {
        paymentMethodId: cardId,
        transitAgencyId: props.passenger.transit_agency_id,
        passengerId: props.passenger.passenger_id
      });

      getSavedCards();
      setSelectedCard(PaymentType.PAY_WITH_NEW_CARD);
    } catch (error) {
      const messageLang = `message_${BROWSER_LANG}`;
      openSnackbar(error.response?.data[messageLang], colors.red);
    }
  };

  const editSavedCard = (card) => {
    setSelectedCard(card.card.fingerprint);
    setSelectedCardToEdit(card);
  };

  const updateSavedCard = async ({ card, id }) => {
    try {
      const reqBody = {
        paymentMethodId: id,
        expiryMonth: updateCardExpiryDate.month ?? card.exp_month,
        expiryYear: updateCardExpiryDate.year ?? card.exp_year,
        transitAgencyId: props.passenger.transit_agency_id
      };

      await authenticatedAxiosInstance.axios.post(
        `/payment/updateExpiryDateForCustomerCard_v2`,
        reqBody
      );

      getSavedCards();

      // Reset the month/year state so that there aren't problems if you edit 2 cards consecutively.
      setUpdateCardExpiryDate({ month: null, year: null });
    } catch (error) {
      console.log('PaymentModal::updateSavedCard', error);
    }
  };

  const isCardExpiryDateValid = (cardMonth, cardYear) => {
    // Months with Moment are 0 indexed, so +1 for corrent month.
    const currentMonth = moment().month() + 1;
    const currentYear = moment().year();

    if (currentYear >= cardYear && currentMonth > cardMonth) {
      return false;
    }

    return true;
  };

  const toggleSaveCardForLaterInitial = (checked) => {
    setSaveCardForLaterInitialCheckbox(checked);
    setSaveCardForLaterConsentCheckbox(false);
    setCardHasConsent(false);
  };

  const cancelSavedCardConsent = () => {
    setSaveCardForLaterInitialCheckbox(false);
    setSaveCardForLaterConsentCheckbox(false);
  };

  const getPassInfo = async () => {
    try {
      const passengersActivePassInfo = (
        await authenticatedAxiosInstance.axios.post(
          `/passengers/${props.passenger.passenger_id}/passes_v2`,
          { transitAgencyId: props.transitAgency.transit_agency_id }
        )
      ).data.filter(({ status }) => status === 'active' || status === 'trialing');

      if (passengersActivePassInfo.length !== 0) {
        // Passenger has a pass.
        setPassengerPassInfo(passengersActivePassInfo[0]);
        setHasActivePass(passStateType.ACTIVE);
      } else {
        await getAvailablePasses();
      }
    } catch (error) {
      console.log('PaymentModal::getPassInfo', error);
      openSnackbar(props.t('problem_getting_pass'), colors.red);
    }
  };

  const getAvailablePasses = async () => {
    // Passenger does not have a pass, so get available passes from TA.
    try {
      // Passenger does not have a pass, so get available passes from TA.
      const availablePasses = (
        await authenticatedAxiosInstance.axios.get(
          `/transitagencies/${props.passenger.transit_agency_id}/passes?isConnectedAccount=true`
        )
      ).data;

      const passes = {};
      // filter the passes based on passenger type
      for (const pass of availablePasses) {
        if (pass.passenger_type === props.passenger.type) {
          passes[props.passenger.type] = pass;
          break;
        } else if (!pass.passenger_type) {
          // this case occurs when TAs have passes that do not care about passenger typev
          passes['anyone'] = pass;
        }
      }

      const filteredPass = passes[props.passenger.type]
        ? passes[props.passenger.type]
        : passes['anyone'];

      setAvailableTAPasses({
        ...filteredPass,
        formattedStart: moment()
          .locale(BROWSER_LANG)
          .format(BROWSER_LANG === 'fr' ? 'D MMM' : 'MMM Do'),
        formattedEnd: moment()
          // interval_count - 1 is needed because of how we are doing custom products in Stripe. 30 day pass = 31 days in Stripe.
          .add(filteredPass.interval_count - 1, 'days')
          .locale(BROWSER_LANG)
          .format(BROWSER_LANG === 'fr' ? 'D MMM' : 'MMM Do')
      });
      setHasActivePass(passStateType.INACTIVE);
    } catch (error) {
      console.log('PaymentModal::getAvailablePasses', error);
      openSnackbar(props.t('problem_getting_pass'), colors.red);
    }
  };

  const purchasePass = async () => {
    setIsPurchasingPass(true);
    try {
      if (passPaymentMethod === 'cash') {
        await authenticatedAxiosInstance.axios.post(`/subscribe/cash`, {
          passengerId: props.passenger.passenger_id,
          priceId: availableTAPasses.price_stripe_id,
          transitAgencyId: props.passenger.transit_agency_id,
          isConnectedAccount: true
        });
      } else {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement)
        });

        if (!error) {
          try {
            await authenticatedAxiosInstance.axios.post('/subscribe', {
              isOneTime: true,
              passengerId: props.passenger.passenger_id,
              priceId: availableTAPasses.price_stripe_id,
              paymentMethodId: paymentMethod.id,
              transitAgencyId: props.passenger.transit_agency_id,
              isConnectedAccount: true
            });
          } catch (err) {
            openSnackbar(props.t('recheck_card'), colors.red);
          }
        }
      }

      await getPassInfo();
    } catch (error) {
      console.log('PaymentModal::purchasePass', error);
    } finally {
      setIsPurchasingPass(false);
    }
  };

  const checkIfMultiplePassengers = () => {
    const passengersPerType = [];

    Object.keys(props.passengers).forEach((group) => {
      return passengersPerType.push(props.passengers[group].length);
    });
    const sum = passengersPerType.reduce((accumulator, value) => {
      return accumulator + value;
    }, 0);

    setTripHasMultiplePassengers(sum > 1);
  };

  const handleRadioChange = (e) => {
    const value = props.editRequestMode ? e : e.target?.value;

    if (props.paymentType !== value) {
      props.setPaymentType(value);
    }
  };

  const addCardToCustomersStripeAccount = async (paymentMethodId) => {
    try {
      const reqBody = {
        passenger_id: props.passenger.passenger_id,
        payment_method_id: paymentMethodId,
        transit_agency_id: props.passenger.transit_agency_id
      };

      await authenticatedAxiosInstance.axios.post(`/payment/addCardToStripeCustomer`, reqBody);
    } catch (err) {
      console.log(err, 'addCardToCustomersStripeAccount error');
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    if (props.paymentType === PaymentType.CARD) {
      // If user does not select saved card or input info for new card
      if (!selectedCard && !elements.getElement(CardElement)) {
        openSnackbar(props.t('select_payment_method'), colors.red);
        setIsSubmitting(false);
        return;
      }

      if (selectedCard && selectedCard !== PaymentType.PAY_WITH_NEW_CARD) {
        // if a passenger is paying with a saved card
        // we don't need to (and can't) create a payment method (card element is not filled out)
        const findCard = savedCards.find((card) => selectedCard === card.card.fingerprint);
        await props.setPaymentMethod(findCard?.id);
        await props.setPaymentMethodForReturn(findCard?.id); // Needs to be outside of the `if (props.returnTrip)` conditional.

        await props.handleCreatePhoneRequest('depart');
        if (props.returnTrip) {
          await props.handleCreatePhoneRequest('return');
        }
      } else {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement)
        });

        props.setPaymentMethod(paymentMethod.id);
        // if there is a return trip, create another payment object and store (card element clears after depart trip submit)
        if (props.returnTrip) {
          const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement)
          });
          if (!error) {
            props.setPaymentMethodForReturn(paymentMethod.id);
          }
        }

        if (!error) {
          try {
            if (cardHasConsent && selectedCard === PaymentType.PAY_WITH_NEW_CARD) {
              await addCardToCustomersStripeAccount(paymentMethod.id);
            }
            props.setPaymentMethod(paymentMethod.id);
            await props.handleCreatePhoneRequest('depart');
            if (props.returnTrip) {
              await props.handleCreatePhoneRequest('return');
            }
          } catch (err) {
            openSnackbar(props.t('recheck_card'), colors.red);
          }
        }
      }
    }
    if (props.paymentType === PaymentType.TICKETS) {
      // if editing previsouly accepted trip, check for current passenger balance with anticipated ticket refund balance once original trip is cancelled
      let sufficientTicketFunds;
      if (
        props.editRequestType === EditRequestType.ACCEPTED &&
        passengerBalance + props.data.tripPrice >= props.price_obj.price
      ) {
        sufficientTicketFunds = true;
      }

      if (
        props.editRequestType === EditRequestType.REJECTED &&
        passengerBalance >= props.price_obj.price
      ) {
        sufficientTicketFunds = true;
      }

      if (props.editRequestMode && sufficientTicketFunds) {
        try {
          await props.handleCreatePhoneRequest('depart');
        } catch (err) {
          console.log(err);
        }
      } else if (!props.editRequestMode && passengerBalance >= props.price_obj.price) {
        try {
          await props.handleCreatePhoneRequest('depart');
          if (props.returnTrip) {
            await props.handleCreatePhoneRequest('return');
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        openSnackbar(props.t('insufficient_funds'), colors.red);
      }
    }

    if (props.paymentType === PaymentType.PASS && hasActivePass === passStateType.ACTIVE) {
      try {
        await props.handleCreatePhoneRequest('depart');
        if (props.returnTrip) {
          await props.handleCreatePhoneRequest('return');
        }
      } catch (err) {
        openSnackbar(props.t('something_wrong'), colors.red);
      }
    }

    if (isPayOnBoard(props, transitAgencyPaymentTypes)) {
      await props.handleCreatePhoneRequest('depart');
      if (props.returnTrip) {
        await props.handleCreatePhoneRequest('return');
      }
    }

    setIsSubmitting(false);
  };

  const getTAPaymentTypes = async () => {
    try {
      if (props.transitAgencyId) {
        const paymentTypes = (
          await authenticatedAxiosInstance.axios.get(
            `/transitagencies/${props.transitAgencyId}/paymentTypes`
          )
        ).data;

        setTransitAgencyPaymentTypes(paymentTypes);

        // Dont set default rule IF ride is free (so we can account for free ride in submit handler)
        if (props.price_obj.price > 0 && paymentTypes.length > 0) {
          props.setPaymentType(paymentTypes[0].payment_type);
        }
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const cardOptions = {
    style: {
      base: {
        iconColor: colors.blaiseGreen,
        color: colors.black,
        fontSize: '18px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#fce883'
        },
        '::placeholder': {
          color: colors.blaiseGray
        }
      },
      invalid: {
        iconColor: colors.red,
        color: colors.red
      }
    }
  };

  const receiptStyle = {
    borderRadius: '15px',
    boxShadow: `0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 ${colors.blaiseLightGreen}`,
    margin: '5% 0',
    padding: '4%'
  };

  const editRejectedTrip =
    props.editRequestMode &&
    props.editRequestType === EditRequestType.REJECTED &&
    props.data?.paymentType === 'card';

  return (
    <>
      <CustomSnackbar
        message={snackbar.message}
        open={snackbar.open}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        snackbarColor={snackbar.color}
      />
      <Grid container spacing={2} id="tripModal" display="flex">
        <Grid item sm={12} align="right" style={{ margin: '-10px 0' }}>
          <Button
            onClick={() => {
              const page = props.editRequestMode ? 2 : 3;
              props.modalSwitch(page);
            }}
            style={{ color: '#AAA' }}
          >
            {props.t('back')}
          </Button>
        </Grid>
        <Grid>
          <h2>{props.t('payment')}</h2>
        </Grid>
        {isLoading ? (
          <Grid item sm={12} align="center">
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <div style={{ width: '100%' }}>
              <div style={receiptStyle}>
                <ReceiptSummary
                  {...props}
                  price_obj={props.price_obj}
                  passengers={props.passengers}
                  passengerBalance={passengerBalance}
                  isLoading={props.isLoading}
                  paymentType={props.paymentType}
                  travelTimeData={props.travelTimeData}
                  time={props.time}
                />
              </div>
              {(transitAgencyPaymentTypes.length > 0 || editRejectedTrip) &&
                (props.price > 0 || props.price_obj.content || props.data.tripPrice) && (
                  <div>
                    {!props.editRequestMode && (
                      <FormControl variant="outlined" fullWidth required>
                        <InputLabel htmlFor="outlined-communication-native-simple">
                          {props.t('select_payment_method')}
                        </InputLabel>
                        <Select
                          name="position"
                          aria-label="position"
                          value={props.paymentType}
                          onChange={handleRadioChange}
                          label={props.t('select_payment_method')}
                          disabled={isSubmitting}
                        >
                          {transitAgencyPaymentTypes.map((paymentType) => (
                            <MenuItem
                              key={paymentType.payment_type_id}
                              value={paymentType.payment_type}
                            >
                              {BROWSER_LANG === 'en'
                                ? paymentType.translation.english
                                : paymentType.translation.french}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {transitAgencyPaymentTypes.some((e) => e.payment_type === PaymentType.CARD) &&
                    props.paymentType === PaymentType.CARD ? (
                      <>
                        {selectedCard && selectedCard !== PaymentType.PAY_WITH_NEW_CARD && (
                          <Alert severity="warning" style={{ marginTop: '16px' }}>
                            {props.t('confirm_last4')}
                          </Alert>
                        )}
                        <RadioGroup
                          aria-label="cardOptions"
                          name="cardOptions"
                          onChange={(e) => {
                            setUpdateCardExpiryDate({ month: null, year: null });
                            setSelectedCard(e.target.value);
                          }}
                          value={selectedCard}
                          style={{ margin: '16px 0' }}
                        >
                          {savedCards &&
                            savedCards.map((card) => (
                              <>
                                <Box
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: '16px'
                                  }}
                                  key={card.card.fingerprint}
                                >
                                  <FormControlLabel
                                    value={card.card.fingerprint}
                                    control={<Radio color="primary" />}
                                    label={
                                      <>
                                        <Box style={{ textTransform: 'capitalize' }}>
                                          {card.card.brand} ****{card.card.last4}
                                        </Box>
                                        {isCardExpiryDateValid(
                                          card.card.exp_month,
                                          card.card.exp_year
                                        ) ? (
                                          <Box
                                            style={{ color: colors.blaiseGray, fontSize: '14px' }}
                                          >
                                            {props.t('expires_on_x', {
                                              expiryDate: creditCardDateFormat(
                                                card.card.exp_month,
                                                card.card.exp_year
                                              )
                                            })}
                                          </Box>
                                        ) : (
                                          <Box
                                            style={{ color: colors.blaiseRed, fontSize: '14px' }}
                                          >
                                            {props.t('expired_on_x', {
                                              expiryDate: creditCardDateFormat(
                                                card.card.exp_month,
                                                card.card.exp_year
                                              )
                                            })}
                                          </Box>
                                        )}
                                      </>
                                    }
                                    labelPlacement="end"
                                  />
                                  <Button
                                    color="primary"
                                    onClick={() => {
                                      editSavedCard(card);
                                    }}
                                    disabled={selectedCardToEdit === card}
                                  >
                                    {props.t('edit')}
                                  </Button>
                                </Box>
                                {selectedCardToEdit === card &&
                                  selectedCard !== PaymentType.PAY_WITH_NEW_CARD && (
                                    <Grid style={{ marginBottom: '32px' }}>
                                      <TextField
                                        fullWidth
                                        name="cardNumber"
                                        value={`**** **** **** ${card.card.last4}`}
                                        variant="outlined"
                                        label={props.t('card_number')}
                                        disabled
                                        style={{ marginBottom: '16px' }}
                                      />
                                      <Grid container spacing={2} style={{ marginBottom: '8px' }}>
                                        {/* Expiry Month */}
                                        <Grid item xs={3}>
                                          <FormControl variant="outlined" fullWidth required>
                                            <InputLabel htmlFor="outlined-communication-native-simple">
                                              {props.t('expiry_month')}
                                            </InputLabel>
                                            <Select
                                              defaultValue={selectedCardToEdit.card.exp_month}
                                              onChange={(e) => {
                                                setUpdateCardExpiryDate((prevState) => ({
                                                  ...prevState,
                                                  month: e.target.value
                                                }));
                                              }}
                                              label={props.t('expiry_month')}
                                              disabled={props.isSubmitting}
                                              error={
                                                isCardExpiryDateValid(
                                                  updateCardExpiryDate.month ||
                                                    selectedCardToEdit.card.exp_month,
                                                  updateCardExpiryDate.year ||
                                                    selectedCardToEdit.card.exp_year
                                                ) === false
                                              }
                                            >
                                              {EXPIRY_MONTHS_OPTIONS.map((month) => {
                                                return (
                                                  <MenuItem value={month} key={month}>
                                                    {month}
                                                  </MenuItem>
                                                );
                                              })}
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                        {/* Expiry Year */}
                                        <Grid item xs={3}>
                                          <FormControl variant="outlined" fullWidth required>
                                            <InputLabel htmlFor="outlined-communication-native-simple">
                                              {props.t('expiry_year')}
                                            </InputLabel>
                                            <Select
                                              defaultValue={selectedCardToEdit.card.exp_year}
                                              onChange={(e) => {
                                                setUpdateCardExpiryDate((prevState) => ({
                                                  ...prevState,
                                                  year: e.target.value
                                                }));
                                              }}
                                              label={props.t('expiry_year')}
                                              disabled={props.isSubmitting}
                                              error={
                                                isCardExpiryDateValid(
                                                  updateCardExpiryDate.month ||
                                                    selectedCardToEdit.card.exp_month,
                                                  updateCardExpiryDate.year ||
                                                    selectedCardToEdit.card.exp_year
                                                ) === false
                                              }
                                            >
                                              {EXPIRY_YEARS_OPTIONS.map((year) => {
                                                return (
                                                  <MenuItem value={year} key={year}>
                                                    {year}
                                                  </MenuItem>
                                                );
                                              })}
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                      </Grid>
                                      <Box display="flex" justifyContent="space-between">
                                        <Button
                                          onClick={() => removeSavedCard(card?.id)}
                                          color="secondary"
                                          variant="outlined"
                                        >
                                          {props.t('remove')}
                                        </Button>
                                        <Box display="flex" gridGap={24}>
                                          <Button
                                            onClick={() => {
                                              setUpdateCardExpiryDate({ month: null, year: null });
                                              setSelectedCardToEdit(null);
                                            }}
                                          >
                                            {props.t('cancel')}
                                          </Button>
                                          <Button
                                            onClick={() => updateSavedCard(card)}
                                            color="primary"
                                            variant="contained"
                                            disabled={
                                              isCardExpiryDateValid(
                                                updateCardExpiryDate.month ||
                                                  selectedCardToEdit.card.exp_month,
                                                updateCardExpiryDate.year ||
                                                  selectedCardToEdit.card.exp_year
                                              ) === false
                                            }
                                          >
                                            {props.t('update')}
                                          </Button>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  )}
                              </>
                            ))}
                          <FormControlLabel
                            value={PaymentType.PAY_WITH_NEW_CARD}
                            control={<Radio color="primary" />}
                            label={props.t('new_card')}
                            labelPlacement="end"
                          />
                        </RadioGroup>
                        {selectedCard === PaymentType.PAY_WITH_NEW_CARD && (
                          <>
                            <form
                              style={{
                                paddingTop: '2%',
                                margin: '16px 0',
                                padding: '2%',
                                backgroundColor: colors.blaiseLightGreen,
                                boxShadow: `0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 ${colors.blaiseLightGreen}`,
                                borderRadius: '4px'
                              }}
                              data-testid="cardDataInput"
                            >
                              <CardElement options={cardOptions} />
                            </form>
                            <Box>
                              <FormControlLabel
                                value="saveCardForLaterInitial"
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={saveCardForLaterInitialCheckbox}
                                    onChange={(e) =>
                                      toggleSaveCardForLaterInitial(e.target.checked)
                                    }
                                  />
                                }
                                label={props.t('save_card_for_later')}
                                labelPlacement="end"
                                style={{ marginBottom: '16px' }}
                              />
                              {saveCardForLaterInitialCheckbox && !cardHasConsent && (
                                <Box className="consent-from-passenger-container">
                                  <Box
                                    style={{
                                      color: colors.blaiseGray,
                                      fontSize: '16px',
                                      fontWeight: '700',
                                      marginBottom: '16px'
                                    }}
                                  >
                                    {props.t('consent_from_passenger')}
                                  </Box>
                                  <Box
                                    style={{
                                      color: colors.buttonGreyText,
                                      fontSize: '16px',
                                      fontWeight: '700',
                                      marginBottom: '16px'
                                    }}
                                  >
                                    {props.t('consent_for_future_payments')}
                                  </Box>
                                  <FormControlLabel
                                    value="saveCardForLaterConsent"
                                    style={{ marginBottom: '16px' }}
                                    control={
                                      <Checkbox
                                        color="primary"
                                        checked={saveCardForLaterConsentCheckbox}
                                        onChange={(e) =>
                                          setSaveCardForLaterConsentCheckbox(e.target.checked)
                                        }
                                      />
                                    }
                                    label={props.t('consent_requested')}
                                    labelPlacement="end"
                                  />
                                  <Box display="flex" gridGap={24} justifyContent="center">
                                    <Button
                                      color="secondary"
                                      variant="outlined"
                                      onClick={cancelSavedCardConsent}
                                    >
                                      {props.t('cancel')}
                                    </Button>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      disabled={!saveCardForLaterConsentCheckbox}
                                      onClick={() => setCardHasConsent(true)}
                                    >
                                      {props.t('authorize')}
                                    </Button>
                                  </Box>
                                </Box>
                              )}
                              {cardHasConsent && (
                                <Box className="consent-from-passenger-container">
                                  {props.t('consent_future_payments_authorized')}
                                </Box>
                              )}
                            </Box>
                          </>
                        )}
                      </>
                    ) : transitAgencyPaymentTypes.some(
                        (e) => e.payment_type === PaymentType.TICKETS
                      ) && props.paymentType === PaymentType.TICKETS ? (
                      <TicketBundles
                        openSnackbar={props.openSnackbar}
                        passenger={props.passenger}
                        passengerBalance={passengerBalance}
                        getPassengerBalance={getPassengerBalance}
                        savedCards={savedCards}
                        addCardToCustomersStripeAccount={addCardToCustomersStripeAccount}
                      />
                    ) : null}
                    {props.paymentType === PaymentType.PASS && (
                      <div style={{ marginTop: '16px' }}>
                        {hasActivePass === passStateType.LOADING && (
                          <Grid className="pass-container-loading">
                            <CircularProgress />
                          </Grid>
                        )}
                        {hasActivePass === passStateType.ACTIVE && (
                          <Grid item sm={12} spacing={3} className="pass-container">
                            <Typography
                              variant="subtitle1"
                              style={{ fontWeight: 'bold', marginBottom: '8px' }}
                            >
                              {props.t('passenger_has_active_pass')}
                            </Typography>
                            <Typography variant="subtitle1">
                              {props.t('days_left_x', {
                                days_left: calculateDaysRemaining(
                                  moment().unix(),
                                  passengerPassInfo?.current_period_end
                                )
                              })}
                            </Typography>
                            <Typography variant="subtitle1">
                              {props.t('purchase_date_x', {
                                start_date: dateOnlyFormatShortMonth(
                                  passengerPassInfo?.current_period_start
                                )
                              })}
                            </Typography>
                            <Typography variant="subtitle1">
                              {props.t('expiry_date_x', {
                                end_date: dateOnlyFormatShortMonth(
                                  passengerPassInfo?.current_period_end
                                )
                              })}
                            </Typography>
                          </Grid>
                        )}
                        {hasActivePass === passStateType.INACTIVE && (
                          <Grid item sm={12} spacing={3} className="pass-container">
                            <Typography
                              variant="subtitle1"
                              style={{ fontWeight: 'bold', marginBottom: '8px' }}
                            >
                              {props.t('purchase_a_pass')}
                            </Typography>
                            <Box display="flex" justifyContent="space-between">
                              <Typography
                                variant="h6"
                                style={{ fontWeight: 'bold', marginBottom: '8px' }}
                              >
                                {props.t('unlimited_rides_for_30_days')}
                              </Typography>
                              <Typography
                                variant="h6"
                                style={{ fontWeight: 'bold', marginBottom: '8px' }}
                              >
                                <span>$</span>
                                {(availableTAPasses.unit_amount / 100).toFixed(2)}/
                                {props.t('pass-lowercase')}
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <Box className="icon-container">
                                <FontAwesomeIcon icon={faClock} color={colors.black} size="lg" />
                              </Box>
                              <Typography variant="subtitle1">
                                {props.t('validation_starts_immediately')}
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <Box className="icon-container">
                                <FontAwesomeIcon icon={faUserAlt} color={colors.black} size="lg" />
                              </Box>
                              <Typography variant="subtitle1">
                                {props.t('single_passenger_only')}
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <Box className="icon-container">
                                <FontAwesomeIcon
                                  icon={faDollarSign}
                                  color={colors.black}
                                  size="lg"
                                />
                              </Box>
                              <Typography variant="subtitle1">
                                {props.t('no_cancelation_or_refund')}
                              </Typography>
                            </Box>
                            <Alert
                              icon={false}
                              severity="success"
                              style={{ justifyContent: 'center', marginTop: '16px' }}
                            >
                              <AlertTitle gutterBottom={false} style={{ fontWeight: 'bold' }}>
                                {props.t('pass_valid_from')} {availableTAPasses?.formattedStart}{' '}
                                {props.t('to-alternative')} {availableTAPasses?.formattedEnd}
                              </AlertTitle>
                            </Alert>
                            <RadioGroup
                              aria-label="passPaymentType"
                              name="passPaymentType"
                              onChange={(e) => setPassPaymentMethod(e.target.value)}
                              value={passPaymentMethod}
                              style={{ margin: '16px 0' }}
                            >
                              <FormControlLabel
                                value="cash"
                                control={<Radio color="primary" />}
                                label={props.t('in_person')}
                                labelPlacement="end"
                              />
                              <FormControlLabel
                                value="oneTimePayment"
                                control={<Radio color="primary" />}
                                label={props.t('one_time_payment')}
                                labelPlacement="end"
                              />
                            </RadioGroup>
                            {passPaymentMethod === 'oneTimePayment' && (
                              <form
                                style={{
                                  paddingTop: '2%',
                                  marginBottom: '32px',
                                  padding: '2%',
                                  backgroundColor: colors.blaiseLightGreen,
                                  boxShadow: `0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 ${colors.blaiseLightGreen}`,
                                  borderRadius: '4px'
                                }}
                                data-testid="passDataInput"
                              >
                                <CardElement options={cardOptions} />
                              </form>
                            )}

                            <Box display="flex" justifyContent="center">
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ color: 'white', textTransform: 'none' }}
                                onClick={purchasePass}
                                disabled={!passPaymentMethod}
                                data-testid="passPaymentMethod"
                              >
                                {isPurchasingPass ? (
                                  <FontAwesomeIcon
                                    icon={faSpinner}
                                    spin
                                    color={colors.white}
                                    size="lg"
                                  />
                                ) : (
                                  props.t('purchase')
                                )}
                              </Button>
                            </Box>
                          </Grid>
                        )}
                      </div>
                    )}
                  </div>
                )}
            </div>
            <ProgressDots
              currentStep={props.progress.currentStep}
              totalSteps={props.progress.totalSteps}
            />
            {tripHasMultiplePassengers && props.paymentType === PaymentType.PASS && (
              <Grid sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Alert
                  icon={false}
                  severity="error"
                  style={{ justifyContent: 'center', margin: '16px 0' }}
                >
                  <AlertTitle gutterBottom={false} style={{ fontWeight: 'bold' }}>
                    {props.t('error')}
                  </AlertTitle>
                  {props.t('pass_with_multiple_passengers')}
                </Alert>
              </Grid>
            )}
            {/* HALP - Remove checkbox once finished testing. */}
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={props.testTransfers}
                  onChange={(e) => props.component.setState({ testTransfers: e.target.checked })}
                />
              }
              label="TEST TRANSFER TRIP REQUEST"
              labelPlacement="right"
            />
            <Grid
              item
              sm={12}
              style={{ minHeight: '100%', display: 'flex', justifyContent: 'center' }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={
                  isSubmitting ||
                  (tripHasMultiplePassengers && props.paymentType === PaymentType.PASS)
                }
                data-testid="toStep5"
              >
                {isSubmitting ? (
                  <FontAwesomeIcon icon={faSpinner} spin color={colors.white} size="lg" />
                ) : props.editRequestMode ? (
                  props.t('update')
                ) : (
                  props.t('submit')
                )}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default PaymentModal;
