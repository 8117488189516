import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TripDetails from '../../components/Ride/TripDetails';

const RidePage = () => {
  const { t } = useTranslation('common');
  const { tripId } = useParams();
  useEffect(() => {
    document.title = `${t('trip_details')} (#${tripId}) - ${t('trip_source_engine')}`;
  }, []);

  return <TripDetails tripId={tripId} />;
};

export default RidePage;
