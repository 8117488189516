import React from 'react';
import { Button, Grid } from '@material-ui/core';
import ProgressDots from '../../components/UI/ProgressDots';
import AddPassengerModalEntry from './AddPassengerModalEntry';
import '../../components/UI/multistep.scss';

const BROWSER_LANG = navigator.language.substring(0, 2);

function AddPassengerModal(props) {
  const deletePassenger = (type, index) => {
    var passengers = { ...props.passengers };

    passengers[type].splice(index, 1);

    props.setPassengers(passengers);
  };

  const handlePassengerTypeChange = (type, index, value) => {
    let passengers = { ...props.passengers };

    let passenger = passengers[type][index];
    passengers[type].splice(index, 1);

    passengers[value].push(passenger);
    props.setPassengers(passengers);
  };

  const handlePassengerAccommodationsChange = (type, index, accType, value) => {
    let passengers = { ...props.passengers };
    passengers[type][index][accType] = value;
    props.setPassengers(passengers);
  };

  return (
    <Grid container spacing={2} id="tripModal">
      <Grid item sm={12} align="right" style={{ margin: '-10px 0' }}>
        <Button onClick={() => props.modalSwitch(2)} style={{ color: '#AAA' }}>
          {props.t('back')}
        </Button>
      </Grid>
      <Grid container item sm={12} style={{ paddingTop: '20px' }}>
        <Grid item sm={12} align="left" style={{ margin: '-10px 0' }}>
          <h2>
            {props.editRequestMode ? props.t('update_passengers') : props.t('add_passengers')}
          </h2>
        </Grid>
        <Grid item sm={12} align="right" style={{ margin: '-10px 0' }}>
          <Button color="primary" variant="contained" onClick={props.addPassengers}>
            {props.t('add')}
          </Button>
        </Grid>
      </Grid>
      <div style={{ width: '100%' }}>
        {Object.keys(props.passengers).map((passengerType, passengerTypeIndex) => {
          const passType = props.passengerTypes.find((element) => element.type === passengerType);
          const translatedPassenger = BROWSER_LANG === 'en' ? passType?.english : passType?.french;
          return (
            <>
              {/* Filter out passenger type headers that aren't selected */}
              {props.passengers[passengerType].length > 0 && (
                <div key={passengerTypeIndex} style={{ paddingBottom: 10 }}>
                  <div style={{ color: 'gray', fontSize: 15 }}>{translatedPassenger}</div>
                  {props.passengers[passengerType].map((passenger, index) => {
                    return (
                      <AddPassengerModalEntry
                        key={index}
                        {...props}
                        deletePassenger={deletePassenger}
                        handlePassengerTypeChange={handlePassengerTypeChange}
                        handlePassengerAccommodationsChange={handlePassengerAccommodationsChange}
                        passenger={passenger}
                        index={index}
                        passengerType={passengerType}
                        passengerTypeIndex={passengerTypeIndex}
                        passengers={props.passengers}
                      />
                    );
                  })}
                </div>
              )}
            </>
          );
        })}
      </div>

      {/* TODO: refactor the triple dot to be dynamic based on number of items and step # */}
      <ProgressDots
        currentStep={props.progress.currentStep}
        totalSteps={props.progress.totalSteps}
      />
      <Grid item sm={12} style={{ textAlign: 'center' }}>
        <Button
          color="primary"
          variant="contained"
          onClick={props.validateStepThree}
          mt={2}
          data-testid="toStep4"
        >
          {props.t('next')}
        </Button>
      </Grid>
    </Grid>
  );
}

export default AddPassengerModal;
