import { getTimezoneOffset } from 'date-fns-tz';
import authenticatedAxiosInstance from '../axios/axios-authorized';

/** Compares the timezone of the browser with the timezone of the agency.
 @param {*} TAId the ID of the transit agency you want to compare the browser time with
 @returns An object that contains: is there is a timezone mismatch or not, the TA's timezone and the timezone offset.
 */

export const compareBrowserAndAgencyTZ = async (TAId) => {
  try {
    const timezone = (await authenticatedAxiosInstance.axios.get(`/transitagencies/${TAId}`)).data
      .timezone;

    const response = {
      timezoneMismatch: false,
      taTimezone: '',
      taTimezoneOffset: 0
    };

    response.taTimezone = timezone;
    /* Note that the built in date getTimezoneOffset is positive if local tz is behind UTC
      and the date fns getTimezoneOffset is positive if local tz is ahead of UTC
      thus multiplying one of them by -1 allows us to compare */
    const browserUTCOffset = (new Date().getTimezoneOffset() / 60) * -1;
    const transitAgencyUTCOffset = getTimezoneOffset(timezone) / (60 * 60 * 1000);

    response.taTimezoneOffset = transitAgencyUTCOffset;

    if (browserUTCOffset !== transitAgencyUTCOffset) {
      response.timezoneMismatch = true;
    }

    return response;
  } catch (err) {
    return err;
  }
};
