/* eslint-disable no-undef */
require('dotenv').config();

const config = {
  auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN,
  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  apiAudience: process.env.REACT_APP_AUTH0_AUDIENCE,
  login: process.env.REACT_APP_AUTH0_LOGIN,
  password: process.env.REACT_APP_AUTH0_PASSWORD,
  firstName: process.env.REACT_APP_CREATE_USER_FIRSTNAME,
  lastName: process.env.REACT_APP_CREATE_USER_LASTNAME,
  email: process.env.REACT_APP_CREATE_USER_EMAIL,
  phone: process.env.REACT_APP_CREATE_USER_PHONE,
  appUrl: process.env.REACT_APP_ADDR,
  apiServer: process.env.REACT_APP_API_ADDR,
  mapBoxToken: process.env.REACT_APP_MAPBOX_ACCESS_TKN,
  stripeKey: process.env.REACT_APP_STRIPE_KEY,
  version: process.env.REACT_APP_VERSION,
  // Firebase
  // routing-algo project
  routingAlgoFbApiKey: process.env.REACT_APP_ROUTING_ALGO_FB_API_KEY,
  routingAlgoFbAuthDomain: process.env.REACT_APP_ROUTING_ALGO_FB_AUTH_DOMAIN,
  routingAlgoFbProjectId: process.env.REACT_APP_ROUTING_ALGO_FB_PROJECT_ID,
  routingAlgoFbStorageBucket: process.env.REACT_APP_ROUTING_ALGO_FB_STORAGE_BUCKET,
  routingAlgoFbMessagingSenderId: process.env.REACT_APP_ROUTING_ALGO_FB_MESSAGING_SENDER_ID,
  routingAlgoFbAppId: process.env.REACT_APP_ROUTING_ALGO_FB_APP_ID,
  // passenger-app-native
  passengerAppNativeFbApiKey: process.env.REACT_APP_PASSENGER_APP_NATIVE_FB_API_KEY,
  passengerAppNativeFbAuthDomain: process.env.REACT_APP_PASSENGER_APP_NATIVE_FB_AUTH_DOMAIN,
  passengerAppNativeFbDatabaseUrl: process.env.REACT_APP_PASSENGER_APP_NATIVE_FB_DATABASE_URL, // routing-algo does not have this value!
  passengerAppNativeFbProjectId: process.env.REACT_APP_PASSENGER_APP_NATIVE_FB_PROJECT_ID,
  passengerAppNativeFbStorageBucket: process.env.REACT_APP_PASSENGER_APP_NATIVE_FB_STORAGE_BUCKET,
  passengerAppNativeFbMessagingSenderId:
    process.env.REACT_APP_PASSENGER_APP_NATIVE_FB_MESSAGING_SENDER_ID,
  passengerAppNativeFbAppId: process.env.REACT_APP_PASSENGER_APP_NATIVE_FB_APP_ID
};

export default config;
