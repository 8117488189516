import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  ListItemText,
  Select
} from '@material-ui/core';
import colors from '../../assets/sass/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import authenticatedAxiosInstance from '../../axios/axios-authorized';

const BROWSER_LANG = navigator.language.substring(0, 2);

export default function AddPassengerModalEntry(props) {
  const [selectedAccommodations, setSelectedAccommodations] = useState([]);
  const [accommodationOptions, setAccommodationOptions] = useState([]);

  useEffect(() => {
    setDefaultAccommodations(props.passengers);
    fetchAccommodations();
  }, []);
  const fetchAccommodations = async () => {
    try {
      const response = await authenticatedAxiosInstance.axios.get(
        `/allTransitAgencies/accomodations`
      );

      setAccommodationOptions(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const setDefaultAccommodations = (passengerObj) => {
    const defaultAccValues = passengerObj?.[props.passengerType]?.[props.index];

    if (typeof defaultAccValues === 'object') {
      const trueArray = Object.keys(defaultAccValues).filter((acc) => {
        if (acc !== 'default') {
          return defaultAccValues[acc];
        }
      });
      setSelectedAccommodations(trueArray);
    }
  };

  const handleAccommodationChange = (event) => {
    const {
      target: { value }
    } = event; // Returns as an array of all the selected value at once. Examples: [], ["bike"], ["bike, motorized_aid"]
    setSelectedAccommodations(typeof value === 'string' ? value.split(',') : value);

    // Assuming that the initial accommodation array contains all the keys listed in listOfAccommodation
    // Set all previous accommodation to false
    for (const acc of accommodationOptions) {
      props.handlePassengerAccommodationsChange(props.passengerType, props.index, acc, false);
    }
    // Set all the selectedAccommodations listed in value to true
    for (const acc of value) {
      props.handlePassengerAccommodationsChange(props.passengerType, props.index, acc, true);
    }
  };

  return (
    <div
      style={{
        width: '100%',
        flexDirection: 'row',
        display: 'flex',
        marginTop: 10,
        marginBottom: 10,
        alignItems: 'center',
        gap: '12px'
      }}
      key={props.index}
    >
      {/* Trash icon */}
      <div>
        <Fab
          size="small"
          color="secondary"
          variant="extended"
          onClick={() => props.deletePassenger(props.passengerType, props.index)}
        >
          <FontAwesomeIcon icon={faTrash} size="1x" color={colors.white} />
        </Fab>
      </div>

      {/* Passenger type dropdown */}
      <Grid item sm={6} key={props.index}>
        <FormControl fullWidth variant="outlined" required={true}>
          <InputLabel>{props.t('passenger_type')}</InputLabel>
          <Select
            value={props.passengerType}
            onChange={(e) => {
              props.handlePassengerTypeChange(props.passengerType, props.index, e.target.value);
            }}
            label="Passenger Type"
            disabled={props.isSubmitting}
          >
            {props.passengerTypes.map((type) => {
              const translatedPassenger = BROWSER_LANG === 'en' ? type.english : type.french;
              return (
                <MenuItem value={type.type} key={type.id}>
                  {translatedPassenger}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

      {/* Accommodation dropdown */}
      <Grid item sm={6}>
        <FormControl fullWidth variant="outlined">
          <InputLabel style={{ backgroundColor: 'white' }}>{props.t('accommodations')}</InputLabel>
          <Select
            multiple
            value={selectedAccommodations}
            onChange={handleAccommodationChange}
            renderValue={(selected) => selected.map((acc) => props.t(acc)).join(', ')}
          >
            {accommodationOptions &&
              accommodationOptions.map((accommodation) => (
                <MenuItem key={accommodation.name} value={accommodation.name}>
                  <Checkbox checked={selectedAccommodations.includes(accommodation.name)} />
                  <ListItemText
                    primary={
                      accommodation.translationName &&
                      (BROWSER_LANG == 'en'
                        ? accommodation.translationName.english
                        : accommodation.translationName.french)
                    }
                  />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
    </div>
  );
}
