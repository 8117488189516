import { PaymentType } from '../enums/PaymentType';

export const isPayOnBoard = (
  { paymentType, editRequestMode, price_obj },
  transitAgencyPaymentTypes
) => {
  return (
    paymentType === PaymentType.PAY_ON_BOARD ||
    paymentType === PaymentType.SMART_CARD ||
    (!transitAgencyPaymentTypes.length && !editRequestMode) ||
    price_obj.price === 0
  );
};
