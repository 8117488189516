import React, { Component } from 'react';
import { withAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import Joi from 'joi';
import i18next from 'i18next';
import { convertTimeToMinutes } from '../../utils/dateTimeFormat';
import Grid from '@material-ui/core/Grid';
import colors from '../../assets/sass/colors';
import PinSearch from '../../components/PinSearch/PinSearch';
import { addSeconds, addMinutes } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import { getCenter } from '../../utils/getCenter';
import AddPassengerModal from './AddPassengerModal';
import PaymentModal from './PaymentModal';
import PassengerTripDetailsModal from './PassengerTripDetailsModal';
import EditRequestWarning from '../../components/TripRequest/EditRequestWarning';
import PassengerSearch from './PassengerSearch';
import { v4 as uuid } from 'uuid';
import authenticatedAxiosInstance from '../../axios/axios-authorized';
import { PaymentType } from '../../enums/PaymentType';
import { currentUserHOC } from '../../store/user';
import StripeWrapper from '../../components/Payment/StripeWrapper';
import { transitAgencyStoreHOC } from '../../store/transitAgency';
import { FeaturesType } from '../../enums/featuresType';

// Things to do:
// a. We need to create a step 3 that injects stripe payments.
// b. We need to consider how we want to do free payments:
//    e.g. to show step 3, but to have a free button, or to skip step 3?
// c. We need to make code for the "edit" side of all the called-in creation requests
//    note that in the past edit and create shared same code, and I have not dealt with edit yet.
// d. If edit is prompted, then we might not want to show step 3, and we might want to disallow
//    changes to some of the trip details (like the time, or origin, or destination) since those affect price and algo
// e. At the end of everything, we want to able to get as close as possible to a manual e2e test.
//    This might require two segmented tests
// f. Joi error handling is showing key as the name. Instead we need to use the key to generate the internationalized error message.

// Trip request source
const RequestSourceType = Object.freeze({
  ENGINE: 'ENGINE'
});

class PhoneRequestModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      passengerName: this.props.data.name,
      passengerFirstName: this.props.data.firstName,
      passengerLastName: this.props.data.lastName,
      methodOfCommunication: this.props.data.communication,
      phoneNumber: this.props.data.phoneNumber,
      email: this.props.data.email,
      // language not set when opened for first time, default to english
      language: this.props.data.language ? this.props.data.language : 'en',
      origin: this.props.data.origin ? this.props.data.origin : '',
      tempOrigin: this.props.data.origin ? this.props.data.origin : '', // To allow the user to "cancel" in Pin Search without changing the final origin
      destination: this.props.data.destination ? this.props.data.destination : '',
      tempDestination: this.props.data.destination ? this.props.data.destination : '', // To allow the user to "cancel" in Pin Search without changing the final destination
      departureOrArrival: this.props.data.departureOrArrival
        ? this.props.data.departureOrArrival
        : 'departure',
      time:
        this.props.data.departureOrArrival === 'departure'
          ? this.props.data.earliestDeparture
          : this.props.data?.latestArrival,
      notes: this.props.data?.notes ? this.props.data.notes : '',
      paratransit: !!this.props.data.paratransit,
      demand: this.props.data.demand,
      rideProposal: this.props.data.rideProposal,
      isTripConfirmed: !!this.props.data.rideProposal,
      originDropdownOptions: [],
      destDropdownOptions: [],
      isSubmitting: false,
      minutesBeforeEarliestDeparture: 0,
      minutesForLatestDeparture: 0,
      openMap: false,
      mapType: 'origin',
      errorLocation: null,
      // Skip passenger search/warning if user is trying to edit a rejected trip
      step: this.props.editRequestType === 'rejected' ? 2 : 1,
      token: null,
      departTripPrice: 0,
      price_obj: {},
      paymentMethod: '',
      paymentMethodForReturn: {},
      paymentType: PaymentType.PAY_ON_BOARD,
      passengers: {},
      passengerTypes: [],
      selectedPassenger: null,
      loading: true,
      returnTrip: false,
      returnOrigin: null,
      tempReturnOrigin: null,
      returnDest: null,
      tempReturnDest: null,
      returnDepartureOrArrival: 'departure',
      returnTime: null,
      returnTripPrice: 0,
      originPickupDoor: false,
      dropoffDestDoor: false,
      originPickupWalkTime: null,
      dropoffDestWalkTime: null,
      travelTime: null,
      travelTimeData: null,
      sessionToken: uuid(),
      bundleId: uuid(),
      tempOriginCenter: [],
      tempDestinationCenter: [],
      busStops: null,
      hasSelection: false,
      // HALP - Remove once finishing testing transfer trip request.
      testTransfers: true
    };
  }

  async componentDidMount() {
    // This keeps a timeout that triggers the location autosearch at the end of waiting. Timer gets refreshed every time the user types.
    this.searchTriggerTimeout = null;

    if (
      this.props.transitAgency &&
      this.props.transitAgency.request_window_low &&
      this.props.transitAgency.request_window_high
    ) {
      const minsBeforeDeparture = convertTimeToMinutes(this.props.transitAgency.request_window_low);
      const returnMaxTimeInAdvance = convertTimeToMinutes(
        this.props.transitAgency.request_window_high
      );
      this.setState({
        minutesBeforeEarliestDeparture: minsBeforeDeparture,
        minutesForLatestDeparture: returnMaxTimeInAdvance,
        time: addMinutes(
          utcToZonedTime(new Date().toISOString(), this.props.taTimezone),
          minsBeforeDeparture
        )
      });
    }

    // Get extra details of Transit Agency
    const passengerTypes = await authenticatedAxiosInstance.axios.get(
      `/transitagencies/${this.props.transitAgencyId}/passengerTypes`
    );

    if (this.props.editRequestMode) {
      const passengerDemand = (
        await authenticatedAxiosInstance.axios.get(
          `/trip/${this.props.data?.rowId}/passenger_demand`
        )
      ).data;

      this.setState({ passengerTypes: passengerTypes.data, passengers: passengerDemand });

      this.setState(
        {
          selectedPassenger: {
            passenger_id: this.props.data?.passengerId,
            stripe_customer_id: this.props?.data.passengerStripeId,
            transit_agency_id: this.props.transitAgencyId
          }
        },
        () => this.selectPassenger(this.state.selectedPassenger, this.props.editRequestMode)
      );
      this.handleCalculateTravelTime();
    } else {
      let passengerObj = {};
      for (const passengerType of passengerTypes.data) {
        passengerObj[passengerType.type] = [];
      }
      this.setState({ passengerTypes: passengerTypes.data, passengers: passengerObj });
    }

    const busStops = await authenticatedAxiosInstance.axios.get(
      `/busstops/${this.props.transitAgencyId}`
    );

    const busStopObj = busStops.data?.busStops.map((stop) => ({
      stopId: stop.bus_stop_id,
      lat: stop.lat,
      lon: stop.lon
    }));

    const originalCenter = getCenter(
      this.state.mapType,
      this.state.origin,
      this.state.destination,
      this.props.transitAgency
    );

    this.setState({
      busStops: busStopObj,
      tempOriginCenter: originalCenter,
      tempDestinationCenter: originalCenter
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isSubmitting !== this.props.isSubmitting) {
      this.setState({ isSubmitting: this.props.isSubmitting });
    }

    if (
      prevState.origin !== this.state.origin ||
      prevState.destination !== this.state.destination
    ) {
      this.setState({
        travelTimeData: null
      });

      if (this.state.origin && this.state.destination) {
        this.handleCalculateTravelTime();
      }
    }

    // recalculate time if passenger demand changes
    // travel time takes into consideration the number of people on the trip
    if (
      Object.keys(prevState.passengers).length &&
      prevState.passengers !== this.state.passengers &&
      this.state.origin &&
      this.state.destination
    ) {
      this.handleCalculateTravelTime();
    }
  }

  handleCreateRequest = async (type) => {
    const { features } = this.props.transitAgencyStore;
    const hasTransfersFeature = features.some((feature) => feature.name === FeaturesType.TRANSFERS);

    this.setState({
      isSubmitting: true
    });

    let {
      origin,
      destination,
      departureOrArrival,
      time,
      notes,
      passengers,
      paymentMethod,
      paymentMethodForReturn,
      departTripPrice,
      returnOrigin,
      returnDest,
      returnDepartureOrArrival,
      returnTime,
      returnTripPrice,
      travelTimeData,
      bundleId,
      paymentType,
      testTransfers,
      price_obj
    } = this.state;
    // TODO: this isn't including all the info from the extra details (step 2)

    // set time if operator hasn't (they must have used default minimum time)
    if (!time) {
      time = moment()
        .add(this.state.minutesBeforeEarliestDeparture, 'minutes')
        .utc()
        .toDate()
        .toISOString();
    }

    // set time if operator hasn't for return
    if (type === 'return' && !returnTime) {
      returnTime = moment()
        .add(this.state.minutesBeforeEarliestDeparture, 'minutes')
        .utc()
        .toDate()
        .toISOString();
    }

    let priority;

    if (type === 'depart') {
      priority = departureOrArrival === 'departure' ? 0 : 1;
    }

    if (type === 'return') {
      priority = returnDepartureOrArrival === 'departure' ? 0 : 1;
    }
    const noCardPayments = [
      PaymentType.PAY_ON_BOARD,
      PaymentType.SMART_CARD,
      PaymentType.PASS,
      PaymentType.TICKETS
    ];

    if (type === 'return' && !noCardPayments.includes(paymentType)) {
      paymentMethod = paymentMethodForReturn;
    }

    let dep;
    if (type === 'depart') {
      if (departureOrArrival === 'departure') {
        dep = new Date(time);
      } else {
        dep = addSeconds(new Date(time), -travelTimeData?.finalTravelTime);
      }
    } else {
      if (returnDepartureOrArrival === 'departure') {
        dep = new Date(returnTime);
      } else {
        dep = addSeconds(new Date(returnTime), -travelTimeData?.finalTravelTime);
      }
    }

    let arr;
    if (type === 'depart') {
      if (departureOrArrival === 'departure') {
        arr = addSeconds(new Date(time), travelTimeData?.finalTravelTime);
      } else {
        arr = new Date(time);
      }
    } else {
      if (returnDepartureOrArrival === 'departure') {
        arr = addSeconds(new Date(returnTime), travelTimeData?.finalTravelTime);
      } else {
        arr = new Date(returnTime);
      }
    }

    dep = zonedTimeToUtc(dep, this.props.taTimezone)?.toISOString();
    arr = zonedTimeToUtc(arr, this.props.taTimezone)?.toISOString();

    let tripTotal = 0;
    if (hasTransfersFeature) {
      tripTotal = price_obj.content.priceHigh;
    } else if (paymentType !== PaymentType.PASS) {
      // HALP - what is type/tripType? Does this change things for Transfers?
      tripTotal = type === 'depart' ? departTripPrice : returnTripPrice;
    }

    const reqBody = {
      depDatetime: dep,
      arrDatetime: arr,
      origin_address:
        type === 'depart' ? [origin.lat, origin.lon] : [returnOrigin.lat, returnOrigin.lon],
      originAddressLabel: type === 'depart' ? origin.name : returnOrigin.name,
      destination_address:
        type === 'depart' ? [destination.lat, destination.lon] : [returnDest.lat, returnDest.lon],
      destinationAddressLabel: type === 'depart' ? destination.name : returnDest.name,
      transit_agency_id: this.props.transitAgency.transit_agency_id,
      priority: priority,
      demand: passengers,
      paymentMethodId: paymentMethod,
      paymentType,
      tripPrice: tripTotal,
      is_phoned_in: true,
      bundleId,
      notes,
      customerId: this.state.selectedPassenger.stripe_customer_id,
      requestMethod: RequestSourceType.ENGINE,
      // HALP - Wire up again once testing transfers trip request is finished.
      // canUseTransfers: hasTransfersFeature
      canUseTransfers: testTransfers
    };

    if (this.props.editRequestMode) {
      reqBody['tripRequestToEdit'] = this.props.data?.rowId;
      reqBody['rideProposalToCancel'] = this.props.data?.rideProposal?.ride_proposal_id;
      reqBody['editRequestType'] = this.props.editRequestType;
    }

    try {
      const path = this.props.editRequestMode ? 'editRequest_v2' : 'requests_v2';
      await authenticatedAxiosInstance.axios.post(
        `/passengers/${this.state.selectedPassenger.passenger_id}/${path}`,
        reqBody
      );

      this.props.handleCloseModal();
      this.props.openSnackbar(
        this.props.editRequestMode
          ? this.props.t('phone_request_edit_success')
          : this.props.t('phone_request_create_success'),
        colors.blaiseGreen
      );
    } catch (err) {
      const message_en = err?.response?.data?.error?.message?.message_en;
      const message_fr = err?.response?.data?.error?.message?.message_fr;

      if (message_en && message_fr) {
        this.props.openSnackbar(
          i18next.language.substr(0, 2) === 'fr' ? message_fr : message_en,
          colors.red
        );
      } else {
        let error = err?.response?.data?.error?.message;

        switch (error?.errorKey) {
          case 'invalid_date_format':
            this.props.openSnackbar(this.props.t('errors:invalid_date_format'), colors.red);
            this.setState({ errorLocation: 'time' });
            break;
          case 'origin_destination_too_close':
            this.props.openSnackbar(
              this.props.t('errors:origin_destination_too_close', {
                minDrivingTime: error.minDrivingTime,
                tripDur: error.tripDur
              }),
              colors.red
            );
            this.setState({ errorLocation: 'originAndDestination' });
            break;
          case 'origin_destination_same':
            this.props.openSnackbar(this.props.t('errors:origin_destination_same'), colors.red);
            this.setState({ errorLocation: 'originAndDestination' });
            break;
          case 'request_too_late':
            if (this.state.departureOrArrival === 'departure') {
              const taTime = utcToZonedTime(error.earliest_departure, this.props.taTimezone);
              let m = moment(taTime);
              let roundedUpTime =
                m.second() || m.millisecond()
                  ? m.add(1, 'minute').startOf('minute')
                  : m.startOf('minute');
              let earliest_departure = moment(roundedUpTime).format('MMMM Do YYYY, h:mm:ss a');
              this.props.openSnackbar(
                this.props.t('errors:earliest_departure_is', { earliest_departure }),
                colors.red
              );
            } else if (this.state.departureOrArrival === 'arrival') {
              const taTime = utcToZonedTime(error.earliest_arrival, this.props.taTimezone);
              let m = moment(taTime);
              let roundedUpTime =
                m.second() || m.millisecond()
                  ? m.add(1, 'minute').startOf('minute')
                  : m.startOf('minute');
              let earliest_arrival = moment(roundedUpTime).format('MMMM Do YYYY, h:mm:ss a');
              this.props.openSnackbar(
                this.props.t('errors:earliest_arrival_is', { earliest_arrival }),
                colors.red
              );
            }
            this.setState({ errorLocation: 'time' });
            break;
          case 'origin_outside_operating_zone':
            this.props.openSnackbar(
              this.props.t('errors:origin_outside_operating_zone'),
              colors.red
            );
            this.setState({ errorLocation: 'origin' });
            break;
          case 'destination_outside_operating_zone':
            this.props.openSnackbar(
              this.props.t('errors:destination_outside_operating_zone'),
              colors.red
            );
            this.setState({ errorLocation: 'destination' });
            break;
          case 'origin_and_destination_outside_operating_zone':
            this.props.openSnackbar(
              this.props.t('errors:origin_and_destination_outside_operating_zone'),
              colors.red
            );
            this.setState({ errorLocation: 'originAndDestination' });
            break;
          case 'beyond_request_window_high':
            this.props.openSnackbar(
              this.props.t('errors:beyond_request_window_high', {
                requestWindowHigh: error.requestWindowHigh
              }),
              colors.red
            );
            this.setState({ errorLocation: 'time' });
            break;
          case 'departure_not_in_op_hours':
            this.props.openSnackbar(this.props.t('errors:departure_not_in_op_hours'), colors.red);
            this.setState({ errorLocation: 'time' });
            break;
          case 'arrival_not_in_op_hours':
            this.props.openSnackbar(this.props.t('errors:arrival_not_in_op_hours'), colors.red);
            this.setState({ errorLocation: 'time' });
            break;
          case 'passenger_blocked':
            this.props.openSnackbar(this.props.t('errors:passenger_blocked'), colors.red);
            break;
          case 'passenger_access_expired':
            this.props.openSnackbar(this.props.t('errors:passenger_access_expired'), colors.red);
            break;
          case 'card_error':
            this.props.openSnackbar(this.props.t('errors:card_error'), colors.red);
            break;
          default:
            this.props.openSnackbar(this.props.t('errors:phone_request_create_error'), colors.red);
            break;
        }
      }

      this.setState({ isSubmitting: false });
    }
  };

  /**
   * Validates the data before submitting
   *
   * The .message({}) portion overrides the default error message
   * The object key is the error type. All errors can be found here: https://github.com/sideway/joi/blob/master/API.md#list-of-errors
   * Another way to check the error type is to inspect the error object that is thrown (error.details[0].type)
   */
  validateStepTwo = async () => {
    const schema = Joi.object({
      // passengerFirstName: Joi.string().required().max(100).messages({
      //   'string.base': this.props.t('validation_passenger_name_cannot_be_empty'),
      //   'any.required': this.props.t('validation_passenger_name_cannot_be_empty'),
      //   'string.max': this.props.t('validation_passenger_name_cannot_exceed_num_chars')
      // }),
      // passengerLastName: Joi.string().required().max(100).messages({
      //   'string.base': this.props.t('validation_passenger_name_cannot_be_empty'),
      //   'any.required': this.props.t('validation_passenger_name_cannot_be_empty'),
      //   'string.max': this.props.t('validation_passenger_name_cannot_exceed_num_chars')
      // }),
      // methodOfCommunication: Joi.string().required().valid('voice', 'sms', 'email').messages({
      //   'any.only': this.props.t('validation_method_coms_required')
      // }),
      // phoneNumber: Joi.string().required().messages({
      //   'string.base': this.props.t('validation_phone_required'),
      //   'any.required': this.props.t('validation_phone_required')
      // }),
      // email: Joi.string().allow('').optional().email({ tlds: false }).messages({
      //   'string.base': this.props.t('validation_email_required'),
      //   'string.email': this.props.t('validation_email_required')
      // }),
      // language: Joi.string().required().valid('en', 'fr').messages({
      //   'any.only': this.props.t('validation_language_required')
      // }),
      origin: Joi.object()
        .keys({
          lon: Joi.number().required(),
          lat: Joi.number().required()
        })
        .unknown()
        .required()
        .messages({
          'object.base': this.props.t('validation_origin_required')
        }),
      destination: Joi.object()
        .keys({
          lon: Joi.number().required(),
          lat: Joi.number().required()
        })
        .unknown()
        .required()
        .messages({
          'object.base': this.props.t('validation_destination_required')
        }),
      departureOrArrival: Joi.string()
        .required()
        .valid('departure', 'arrival')
        .messages({
          'any.required': this.props.t('validation_departure_or_arrival_required')
        }),
      time: Joi.alternatives(
        //Time is an object when creating a trip request
        Joi.object()
          .required()
          .messages({
            'object.base': this.props.t('validation_time_required')
          }),
        //Is a string when editing the trip request
        Joi.string()
          .required()
          .messages({
            'object.base': this.props.t('validation_time_required')
          })
      ),
      notes: Joi.string().allow('')
      // demand: Joi.number().positive().required(),
    });

    const { origin, destination, departureOrArrival, time, notes } = this.state;
    //Normal field validation with JOI
    const { error } = schema.validate({
      origin,
      destination,
      departureOrArrival,
      time,
      notes
      // demand,
    });

    if (error) {
      const errorMsg = error.details[0].message;
      this.props.openSnackbar(errorMsg, colors.red);
      return;
    }

    // go to step three or if in editing a request, go to step four
    this.setState({ step: this.props.editRequestMode ? 4 : 3 });
  };

  validateStepThree = async () => {
    let nextStep = 4;

    let numPassengers = 0;
    let paratransit = false;
    for (const passengerType in this.state.passengers) {
      numPassengers += this.state.passengers[passengerType].length;
      for (const passenger in this.state.passengers[passengerType]) {
        if (passenger.bike || passenger.wheelchair) {
          paratransit = true;
        }
      }
    }

    if (numPassengers <= 0) {
      this.props.openSnackbar(this.props.t('add_at_least_one_passenger'), colors.red);
      nextStep = 3;
    } else {
      this.setState({ paratransit });
    }

    this.setState({ step: nextStep });
  };

  handleSearchInput = (e, val, reason, isOrigin) => {
    // Clear the droptown suggestions if the search value is empty
    if (!val) {
      isOrigin
        ? this.setState({
            originDropdownOptions: []
          })
        : this.setState({
            destDropdownOptions: []
          });
      return;
    }

    // If the search string is less than 3 characters or if the reason the input was changed was because the user selected an option, don't run this function
    if (val.length < 3 || reason !== 'input') {
      return;
    }

    if (this.searchTriggerTimeout) {
      clearTimeout(this.searchTriggerTimeout);
    }

    this.searchTriggerTimeout = setTimeout(() => {
      this.handleSearchLocation(val, isOrigin);
    }, 1000);
  };

  handleSearchLocation = async (locationString, isOrigin) => {
    try {
      const response = await authenticatedAxiosInstance.axios.get(
        `/phoneRequests/searchLocation?locationString=${locationString}&sessionToken=${this.state.sessionToken}`
      );

      const suggestions = response.data?.suggestions;

      let options = suggestions.map((suggestion) => ({
        name: suggestion.description,
        id: suggestion.place_id
      }));

      this.setState({
        [isOrigin ? 'originDropdownOptions' : 'destDropdownOptions']: options
      });
    } catch (err) {
      console.log(err);
    }
  };

  // This will update the temporary origin / destination values that the user has NOT confirmed yet (they are used in Pin Search)
  handleSelectLocation = async (location, isOrigin) => {
    try {
      const response = await authenticatedAxiosInstance.axios.get(
        `/phoneRequests/selectLocation?placeId=${location?.id}&sessionToken=${this.state.sessionToken}`
      );

      const locationCoords = response.data?.location?.result?.geometry?.location;

      const locationObj = {
        name: location.name,
        lon: locationCoords.lng,
        lat: locationCoords.lat
      };

      if (isOrigin) {
        this.setState({ tempOrigin: locationObj });
        this.setState({ tempOriginCenter: [locationObj.lon, locationObj.lat] });

        if (this.state.returnTrip) {
          this.setState({ tempReturnDest: locationObj });
        }
      }

      if (!isOrigin) {
        this.setState({ tempDestination: locationObj });
        this.setState({ tempDestinationCenter: [locationObj.lon, locationObj.lat] });
        if (this.state.returnTrip) {
          this.setState({ tempReturnOrigin: locationObj });
        }
      }

      const newSessionToken = uuid();
      this.setState({ sessionToken: newSessionToken });
    } catch (err) {
      console.log(err);
    }
  };

  // Updates the final origin and destination, which are the coordinates submitted by the user with the Pin Search
  submitPinSearch = (isOrigin) => {
    if (isOrigin) {
      this.setState({ origin: this.state.tempOrigin });

      if (this.state.returnTrip) {
        this.setState({ returnDest: this.state.tempReturnDest });
      }
    }

    if (!isOrigin) {
      this.setState({ destination: this.state.tempDestination });

      if (this.state.returnTrip) {
        this.setState({ returnOrigin: this.state.tempReturnOrigin });
      }
    }

    this.setState({ openMap: false });
  };

  handleCalculateTravelTime = async () => {
    const originLat = this.state.origin?.lat;
    const originLon = this.state.origin?.lon;
    const destLat = this.state.destination?.lat;
    const destLon = this.state.destination?.lon;
    const passengerId = this.state.selectedPassenger?.passenger_id;
    const transitAgencyId = this.props.transitAgency?.transit_agency_id;
    const passengerDemand = this.state.passengers;

    try {
      const reqBody = {
        origin: {
          lat: originLat,
          lon: originLon
        },
        destination: {
          lat: destLat,
          lon: destLon
        },
        transitAgencyId,
        passengerId,
        passengerDemand
      };

      const travelTime = (
        await authenticatedAxiosInstance.axios.post(`/calculateTravelTime`, reqBody)
      ).data;

      if (travelTime) {
        this.setState({ travelTimeData: travelTime });
      }
    } catch (err) {
      console.log(err, 'err');
    }
  };

  // Updates the origin on the Pin Search map, which is not the final origin because the user has not confirmed it yet
  updateOrigin = (name, lat, lon) => {
    this.setState({
      tempOrigin: {
        name,
        lat,
        lon
      },
      tempOriginCenter: [lon, lat],
      originDropdownOptions: []
    });

    if (this.state.returnTrip) {
      this.setState({
        tempReturnDest: {
          name,
          lat,
          lon
        }
      });
    }
  };

  // Updates the destination on the Pin Search map, which is not the final destination because the user has not confirmed it yet
  updateDest = (name, lat, lon) => {
    this.setState({
      tempDestination: {
        name,
        lat,
        lon
      },
      tempDestinationCenter: [lon, lat],
      destDropdownOptions: []
    });

    if (this.state.returnTrip) {
      this.setState({
        tempReturnOrigin: {
          name,
          lat,
          lon
        }
      });
    }
  };

  setPaymentMethod = (paymentMethod) => {
    this.setState({ paymentMethod });
  };

  setPaymentType = (paymentType) => {
    return this.setState({ paymentType });
  };

  setPaymentMethodForReturn = (paymentMethodForReturn) => {
    this.setState({ paymentMethodForReturn });
  };

  resetHasSelection = async () => {
    this.setState({ hasSelection: false });
  };

  calculateTripPrice = async (paymentType, tripType) => {
    const { features } = this.props.transitAgencyStore;
    const hasTransfersFeature = features.some((feature) => feature.name === FeaturesType.TRANSFERS);
    let passengerId;

    if (this.props.editRequestMode) {
      passengerId = this.props.data?.passengerId;
    } else {
      passengerId = this.state.selectedPassenger.passenger_id;
    }

    let returnTrip;
    let departTrip = {
      departOriginLat: this.state.origin.lat,
      departOriginLon: this.state.origin.lon,
      departDestLat: this.state.destination.lat,
      departDestLon: this.state.destination.lon
    };
    if (this.state.returnTrip) {
      returnTrip = {
        returnOriginLat: this.state.returnOrigin.lat,
        returnOriginLon: this.state.returnOrigin.lon,
        returnDestLat: this.state.returnDest.lat,
        returnDestLon: this.state.returnDest.lon
      };
    }

    this.setState({ isLoading: true });
    try {
      const priceRequest = {
        origin_address:
          tripType === 'depart'
            ? [this.state.origin.lat, this.state.origin.lon]
            : [this.state.returnOrigin.lat, this.state.returnOrigin.lon],
        destination_address:
          tripType === 'depart'
            ? [this.state.destination.lat, this.state.destination.lon]
            : [this.state.returnDest.lat, this.state.returnDest.lon],
        bundleAddresses: this.state.returnTrip ? [departTrip, returnTrip] : [departTrip],
        transitAgencyId: this.props.transitAgencyId,
        passengerId: passengerId,
        payment_type: paymentType,
        demand: this.state.passengers,
        canUseTransfers: hasTransfersFeature
      };

      const price_obj = (
        await authenticatedAxiosInstance.axios.post(`/pricing/calculateTripPrice`, priceRequest)
      ).data;

      // price must be a whole number for card payment intent creation
      price_obj.price = parseInt(price_obj.price);

      // HALP - How does this change if it's a Transfers trip?
      if (tripType === 'depart') {
        this.setState({ departTripPrice: price_obj.price, isLoading: false });
      }
      if (tripType === 'return') {
        this.setState({ returnTripPrice: price_obj.price, isLoading: false });
      }

      this.setState({ price_obj: price_obj });
      return price_obj;
    } catch (error) {
      console.log(error.message);
    }
  };

  modalSwitch = (stepValue) => {
    this.setState({
      step: stepValue
    });
  };

  selectPassenger = async (item, isEdit = false) => {
    try {
      this.setState({ hasSelection: true });

      const passengerType = authenticatedAxiosInstance.axios.get(
        `/passengers/${item.passenger_id}/transitagency/${this.props.transitAgencyId}/passengerType`
      );

      const connectedAccount = authenticatedAxiosInstance.axios.post(
        `/payment/retrieveCustomerId_v2`,
        {
          passenger_id: item.passenger_id,
          transitAgencyId: this.props.transitAgencyId
        }
      );

      const [type, account] = await Promise.all([passengerType, connectedAccount]);

      this.setState({
        selectedPassenger: {
          ...item,
          type: type.data.type,
          connected_customer_id: account.data.connected_customer_id || null
        }
      });

      // if for an edit trip, we do not want to reset the passenger demand to the user's default
      if (isEdit) return;

      // Set default passenger type based on selected user
      // Using an independant try/catch in case the returned type is not valid
      try {
        let newPassengers = {};

        Object.keys(this.state.passengers).map((passengerType) => {
          if (passengerType === type.data.type) {
            newPassengers[passengerType] = [
              {
                default: true,
                wheelchair: false,
                bike: false,
                stroller: false,
                motorized_aid: false
              }
            ];
          } else {
            newPassengers[passengerType] = [];
          }
        });

        this.setState({ passengers: newPassengers });
      } catch (err) {
        console.log(`An error occurred while trying to set the default passenger type: ${err}`);
      }

      if (!this.props.editRequestMode) {
        this.modalSwitch(2);
      }
    } catch (err) {
      this.setState({ hasSelection: false });
      console.log(`An error has occurred in PhoneRequestModal::selectPassenger: ${err}`);
    }
  };

  addPassengers = () => {
    let passengers = { ...this.state.passengers };
    passengers[this.state.selectedPassenger['type']]?.push({
      default: false,
      wheelchair: false,
      bike: false,
      stroller: false,
      motorized_aid: false
    });
    this.setState({ passengers: passengers });
  };

  setPassengers = (passengers) => {
    this.setState({ passengers });
  };

  setOpenMap = (openMap) => {
    this.setState({ openMap });
  };

  setErrorLocation = (errorLocation) => {
    this.setState({
      errorLocation
    });
  };

  render() {
    const { currentUser } = this.props.currentUserStore;
    const { stripe_account: stripeAccount = '' } = currentUser?.transit_agency;

    return (
      <StripeWrapper stripeAccount={stripeAccount}>
        <div>
          {!this.state.openMap && this.state.step === 1 && !this.props.editRequestMode ? (
            <PassengerSearch
              progress={{ currentStep: 1, totalSteps: 4 }}
              selectPassenger={this.selectPassenger}
              listOfPassengers={this.props.listOfPassengers}
              hasSelection={this.state.hasSelection}
              resetHasSelection={this.resetHasSelection}
            />
          ) : (
            <EditRequestWarning {...this.props} modalSwitch={this.modalSwitch} />
          )}

          {!this.state.openMap && this.state.step === 2 && (
            <PassengerTripDetailsModal
              {...this.props}
              methodOfCommunication={this.state.methodOfCommunication}
              email={this.state.email}
              language={this.state.language}
              isSubmitting={this.state.isSubmitting}
              origin={this.state.origin}
              errorLocation={this.state.errorLocation}
              isTripConfirmed={this.state.isTripConfirmed}
              destination={this.state.destination}
              departureOrArrival={this.state.departureOrArrival}
              time={this.state.time}
              minutesBeforeEarliestDeparture={this.state.minutesBeforeEarliestDeparture}
              paratransit={this.state.paratransit}
              notes={this.state.notes}
              validateStepTwo={this.validateStepTwo}
              component={this}
              progress={{ currentStep: 2, totalSteps: 4 }}
              modalSwitch={this.modalSwitch}
              returnTrip={this.state.returnTrip}
              returnOrigin={this.state.returnOrigin}
              returnDest={this.state.returnDest}
              returnDepartureOrArrival={this.state.returnDepartureOrArrival}
              returnTime={this.state.returnTime}
              minutesForLatestDeparture={this.state.minutesForLatestDeparture}
              travelTimeData={this.state.travelTimeData}
              handleSelectLocation={this.handleSelectLocation}
              selectedPassenger={this.state.selectedPassenger}
              taTimezone={this.props.taTimezone}
              taUtcOffset={this.props.taUtcOffset}
            />
          )}

          {!this.state.openMap && this.state.step === 3 && this.state.passengerTypes && (
            <AddPassengerModal
              {...this.props}
              isSubmitting={this.state.isSubmitting}
              validateStepThree={this.validateStepThree}
              progress={{ currentStep: 3, totalSteps: 4 }}
              modalSwitch={this.modalSwitch}
              passengers={this.state.passengers}
              passengerTypes={this.state.passengerTypes}
              addPassengers={this.addPassengers}
              setPassengers={this.setPassengers}
            />
          )}

          {!this.state.openMap && this.state.step === 4 && (
            <div>
              <PaymentModal
                {...this.props}
                progress={{
                  currentStep: 4,
                  totalSteps: 4
                }}
                token={this.state.token}
                price={this.state.departTripPrice}
                price_obj={this.state.price_obj}
                passengers={this.state.passengers}
                setPaymentMethod={this.setPaymentMethod}
                setPaymentMethodForReturn={this.setPaymentMethodForReturn}
                paymentMethodObject={this.state.paymentMethod}
                setPaymentType={this.setPaymentType}
                paymentType={this.state.paymentType}
                handleCreatePhoneRequest={this.handleCreateRequest}
                modalSwitch={this.modalSwitch}
                passengerTypes={this.state.passengerTypes}
                passenger={this.state.selectedPassenger}
                calculateTripPrice={this.calculateTripPrice}
                isLoading={this.state.isLoading}
                isSubmitting={this.state.isSubmitting}
                returnTrip={this.state.returnTrip}
                returnOrigin={this.state.returnOrigin}
                returnDest={this.state.returnDest}
                returnDepartureOrArrival={this.state.returnDepartureOrArrival}
                returnTime={this.state.returnTime}
                returnTripPrice={this.state.returnTripPrice}
                origin={this.state.origin}
                destination={this.state.destination}
                departureOrArrival={this.state.departureOrArrival}
                time={this.state.time}
                travelTimeData={this.state.travelTimeData}
                // HALP - Remove testTransfers and component props after testing transfers trip request.
                testTransfers={this.state.testTransfers}
                component={this}
              />
            </div>
          )}

          {this.state.openMap && (
            <Grid container spacing={2} id="mapModal">
              <div style={{ width: '100%', height: '40%' }}>
                <PinSearch
                  style={{ width: '100%' }}
                  updateOrigin={this.updateOrigin}
                  type={this.state.mapType}
                  updateDestination={this.updateDest}
                  originDropdownOptions={this.state.originDropdownOptions}
                  origin={this.state.tempOrigin}
                  errorLocation={this.state.errorLocation}
                  destDropdownOptions={this.state.destDropdownOptions}
                  destination={this.state.tempDestination}
                  handleSelectLocation={this.handleSelectLocation}
                  isSubmitting={this.state.isSubmitting}
                  isTripConfirmed={this.state.isTripConfirmed}
                  handleSearchInput={this.handleSearchInput}
                  setErrorLocation={this.setErrorLocation}
                  submitPinSearch={this.submitPinSearch}
                  setOpenMap={this.setOpenMap}
                  originCenter={this.state.tempOriginCenter}
                  destinationCenter={this.state.tempDestinationCenter}
                  busStops={this.state.busStops}
                  maxWalkingTime={this.props.transitAgency.max_walking_distance}
                />
              </div>
            </Grid>
          )}
        </div>
      </StripeWrapper>
    );
  }
}

export default withTranslation(['common', 'errors'], { withRef: true })(
  withAuth0(currentUserHOC(transitAgencyStoreHOC(PhoneRequestModal)))
);
