import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dropzone from './Dropzone';
import Progress from './Progress';
import { faCheck, faFile, faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { withAuth0 } from '@auth0/auth0-react';
import { withTranslation } from 'react-i18next';

import colors from '../../assets/sass/colors';
import './Upload.scss';
import config from '../../config';

/**
 * Component that handles uploading files to a server
 * Modified from this tutorial: https://malcoded.com/posts/react-file-upload/
 *
 * Props:
 * title {String} Optional. Default: "". The title to be displayed near the dropzone
 * fieldName {String} Required. The field name processed on the backend. Must match upload.single(<fieldName>) on the backend endpoint
 * httpMethod {String} Optional. Default: POST. The HTTP method in all caps. eg: POST, GET, DELETE.
 * uploadEndpoint {String} Required. Endpoint where the files will be uploaded to
 * maxFileCount {Number} Optional. Default: 1. The number of files that can be uploaded at once
 * allowedFileTypes {Array} Optional. Default: []. An array of file extensions (with the dot). Eg: [.jpg, .jpeg]. An empty array allows any file type to be uploaded
 * TODO: maxFileSize {Number} Optional. Default: x. The max file size for each individual files.
 * onSuccess {Function} Optional. A funtion to be executed after successful upload
 * onFailure {Function} Optional. A function to be executed after receiving an error from upload endpoint
 */
class Upload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      uploadDone: false
    };
  }

  static defaultProps = {
    title: '',
    fieldName: null,
    httpMethod: 'POST',
    uploadEndpoint: null,
    maxFileCount: 1,
    maxFileSize: null,
    allowedFileTypes: [],
    onSuccess: null,
    onFailure: null
  };

  static propsTypes = {
    title: PropTypes.string,
    fieldName: PropTypes.string.isRequired,
    httpMethod: PropTypes.string,
    uploadEndpoint: PropTypes.string.isRequired,
    maxFileCount: PropTypes.number,
    maxFileSize: PropTypes.number,
    allowedFileTypes: PropTypes.array,
    onSuccess: PropTypes.func,
    onFailure: PropTypes.func
  };

  /**
   * Update state when files are added via Dropzone
   * @param {*} files
   */
  onFilesAdded = (files) => {
    if (this.props.maxFileCount === 1) files = files[0];

    this.setState((prevState) => ({
      files: prevState.files.concat(files)
    }));
  };

  /**
   * Renders the upload progress bar of a particular file
   * @param {*} file
   */
  renderProgress = (file) => {
    const uploadProgress = this.state.uploadProgress[file.name];
    const iconsState = {
      done: {
        icon: faCheck,
        color: colors.blaiseGreen
      },
      error: {
        icon: faTimes,
        color: colors.red
      },
      processing: {
        icon: faSpinner,
        color: colors.blaiseGreen
      }
    };

    return (
      <div className="ProgressWrapper">
        <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
        {uploadProgress && Object.keys(iconsState).includes(uploadProgress.state) && (
          <FontAwesomeIcon
            color={iconsState[uploadProgress.state].color}
            icon={iconsState[uploadProgress.state].icon}
            style={{ marginLeft: 10 }}
          />
        )}
      </div>
    );
  };

  /**
   * Stages all the files to upload queue. Upload of individual files is done by sendRequest method
   */
  uploadFiles = async () => {
    this.setState({ uploadProgress: {}, uploading: true });
    const promises = [];
    this.state.files.forEach((file) => {
      promises.push(this.sendRequest(file));
    });
    try {
      await Promise.all(promises);

      if (this.props.onSuccess) this.props.onSuccess();
    } catch (e) {
      if (this.props.onFailure) this.props.onFailure(e);
    } finally {
      this.setState({ uploadDone: true, uploading: false });
    }
  };

  /**
   * Uploads an individual file to the upload endpoint. Reports the current upload progress.
   * //TODO: Use axios instead? Not sure how axios is listening the load and error events.
   * @param {*} file
   */
  sendRequest = (file) => {
    // TODO: Rewrite this async logic and remove no-async-promise-executor rule from .eslintrc
    // https://stackoverflow.com/questions/64246605/async-promise-executor-functions
    // https://stackoverflow.com/questions/43083696/cant-throw-error-from-within-an-async-promise-executor-function
    return new Promise(async (resolve, reject) => {
      const req = new XMLHttpRequest();

      req.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = {
            state: 'pending',
            percentage: (event.loaded / event.total) * 100
          };
          this.setState({ uploadProgress: copy });
        }
      });

      req.upload.addEventListener('load', () => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: 'processing', percentage: 100 };
        this.setState({ uploadProgress: copy });

        //Wait for server response before resolving or rejecting promise
        req.onreadystatechange = () => {
          if (req.readyState === 4 && req.status !== 200) {
            copy[file.name] = { state: 'error', percentage: 0 };
            reject(new Error('The server has rejected the upload'));
          } else if (req.readyState === 4 && req.status === 200) {
            copy[file.name] = { state: 'done', percentage: 100 };
            resolve();
          }
          this.setState({ uploadProgress: copy });
        };
      });

      req.upload.addEventListener('error', () => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: 'error', percentage: 0 };
        this.setState({ uploadProgress: copy });
        reject(new Error('An error has occurred while uploading the file'));
      });

      const formData = new FormData();
      formData.append(this.props.fieldName, file, file.name);
      req.open(this.props.httpMethod, this.props.uploadEndpoint);
      req.setRequestHeader(
        'Authorization',
        `Bearer ${await this.props.auth0.getAccessTokenSilently({ audience: config.apiAudience })}`
      );
      req.send(formData);
    });
  };

  render() {
    return (
      <div className="Upload">
        <span className="Title">{this.props.title}</span>
        <div className="Content">
          <Dropzone
            onFilesAdded={this.onFilesAdded}
            disabled={
              this.state.uploading ||
              this.state.uploadDone ||
              this.state.files.length >= this.props.maxFileCount
            }
            multiple={this.props.maxFileCount !== 1}
            allowedFileTypes={this.props.allowedFileTypes}
          />
          <div className="Files">
            {this.state.files.map((file) => {
              return (
                <div key={file.name} className="Row">
                  <div className="RowItem">
                    <FontAwesomeIcon icon={faFile} />
                  </div>
                  <div className="RowItem">
                    <span className="Filename">{file.name}</span>
                  </div>
                  {this.renderProgress(file)}
                </div>
              );
            })}
          </div>
        </div>
        <div className="Actions">
          <Button
            disabled={!this.state.uploadDone && !this.state.files.length}
            onClick={() => this.setState({ files: [], uploadDone: false })}
            variant="outlined"
          >
            {this.props.t('upload_clear')}
          </Button>

          <Button
            disabled={this.state.files.length <= 0 || this.state.uploading || this.state.uploadDone}
            onClick={this.uploadFiles}
            variant="contained"
            color="primary"
            style={{ marginLeft: '10px' }}
          >
            {this.props.t('upload_button')}
          </Button>
        </div>
      </div>
    );
  }
}

export default withTranslation('common')(withAuth0(Upload));
