import React, { useEffect, useState } from 'react';
import config from '../../config';
import { withAuth0 } from '@auth0/auth0-react';
import { withTranslation } from 'react-i18next';
import ReactMapboxGl, { GeoJSONLayer, Layer, Feature } from 'react-mapbox-gl';
import busStopIcon from '../../assets/images/bus-stop.svg';
import shallow from 'zustand/shallow';
import StopModal from '../../components/Stops/StopModal';
import DataGridTable from '../../components/DataGrid/DataGridTable';
import CustomSnackbar from '../../components/Snackbar/CustomSnackbar';

import { Box, Button, CircularProgress, Fab, Paper, Tooltip } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import colors from '../../assets/sass/colors';
import './StopManagement.scss';
import { currentUserStore } from '../../store/user';
import authenticatedAxiosInstance from '../../axios/axios-authorized';
import { busStopModalType } from '../../enums/busStopModalType';

const Map = ReactMapboxGl({
  accessToken: config.mapBoxToken
});

const StopManagement = (props) => {
  const [addBusStopModalOpen, setAddBusStopModalOpen] = useState(false);
  const [editBusStopModalOpen, setEditBusStopModalOpen] = useState(false);
  const [deleteBusStopModalOpen, setDeleteBusStopModalOpen] = useState(false);
  const [loadingStops, setLoadingStops] = useState(false);
  const [stops, setStops] = useState(null);
  const [refreshStops, setRefreshStops] = useState(false);
  const [stopRow, setStopRow] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: null
  });
  const [centerCoordinates, setCenterCoordinates] = useState(null);
  const [geojson, setGeojson] = useState(null);
  // mapZoom needs to be a reactive variable in order for the zoom to be preserved after clicking bus stop (but useState setter is never needed)
  const [mapZoom] = useState([12]);

  const { currentUser } = currentUserStore(
    (state) => ({ currentUser: state.currentUser }),
    shallow
  );

  const image = new Image(12, 12);
  image.src = busStopIcon;
  const images = ['myImage', image];

  const paint = {
    'fill-color': colors.blaiseGreen,
    'fill-opacity': 0.3
  };

  useEffect(() => {
    document.title = `${props.t('network_title')} - ${props.t('trip_source_engine')}`;
    fetchBusStops();
  }, []);

  useEffect(() => {
    if (refreshStops) {
      fetchBusStops();
    }
  }, [refreshStops]);

  const fetchBusStops = async () => {
    try {
      setLoadingStops(true);
      const transitAgencyId = currentUser?.transit_agency_id;

      const response = await authenticatedAxiosInstance.axios.get(`/busstops/${transitAgencyId}`);

      const stops = response.data?.busStops.map((stop) => ({
        stopId: stop.bus_stop_id,
        code: stop.code,
        name: stop.name,
        hasShelter: Boolean(stop.abribus),
        isBreak: Boolean(stop.break_location),
        isDepot: Boolean(stop.is_depot),
        origin: {
          lat: stop.lat,
          lon: stop.lon
        }
      }));

      const responseGeojson = response?.data?.geojson?.geojson;
      let geojson;
      if (responseGeojson) {
        geojson = JSON.parse(responseGeojson);
      }
      const lat = response.data?.geojson?.center_lat;
      const lon = response.data?.geojson?.center_lon;

      setStops(stops);
      setCenterCoordinates([lon, lat]);
      setGeojson(geojson);
    } catch (err) {
      if (err.response && err.response.status === 403) {
        alert(props.t('no_permission'));
      } else {
        openSnackbar(props.t('stop_get_error'), colors.red);
      }
    } finally {
      setLoadingStops(false);
      setRefreshStops(false);
    }
  };

  const openSnackbar = (snackbarString, color) => {
    setSnackbar({
      open: true,
      message: snackbarString,
      color: color
    });
  };

  const closeSnackbar = (reason) => {
    if (reason !== 'clickaway') {
      setSnackbar({ ...snackbar, open: false });
    }
  };

  const editStop = (row) => {
    setStopRow(row);
    setEditBusStopModalOpen(true);
  };

  const deleteStop = (row) => {
    setStopRow(row);
    setDeleteBusStopModalOpen(true);
  };

  const columnStyle = { flex: 0.5, align: 'center', headerAlign: 'center' };
  const columnStyleWide = { flex: 1, align: 'center', headerAlign: 'center' };

  const columns = [
    { field: 'code', headerName: props.t('code'), ...columnStyle },
    {
      field: 'name',
      headerName: props.t('name'),
      ...columnStyleWide,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.name}>
            <Box>{params.row.name}</Box>
          </Tooltip>
        );
      }
    },
    {
      field: 'bus_shelter',
      headerName: props.t('bus_shelter'),
      ...columnStyle,
      sortable: false,
      valueGetter: (params) => {
        return props.t(params.row.hasShelter ? 'yes' : 'no');
      }
    },
    {
      field: 'break_location',
      headerName: props.t('break_location'),
      ...columnStyle,
      sortable: false,
      valueGetter: (params) => {
        return props.t(params.row.isBreak ? 'yes' : 'no');
      }
    },
    {
      field: 'bus_depot',
      headerName: props.t('bus_depot'),
      ...columnStyle,
      sortable: false,
      valueGetter: (params) => {
        return props.t(params.row.isDepot ? 'yes' : 'no');
      }
    },
    {
      field: 'button',
      headerName: props.t('actions'),
      ...columnStyle,
      renderCell: (params) => (
        <Box display="flex" style={{ gap: '10px' }}>
          <Fab
            color="primary"
            size="small"
            className="editButton"
            variant="extended"
            onClick={() => editStop(params.row)}
          >
            <FontAwesomeIcon icon={faPen} color={colors.white} size="1x" className="editIcon" />
          </Fab>
          <Fab
            color="secondary"
            size="small"
            variant="extended"
            onClick={() => deleteStop(params.row)}
          >
            <FontAwesomeIcon icon={faTrash} color={colors.white} size="1x" />
          </Fab>
        </Box>
      ),
      sortable: false,
      disableColumnMenu: true
    }
  ];

  let stopsCopy;
  if (stops) {
    stopsCopy = stops.map((stop, index) => {
      return {
        ...stop,
        id: index
      };
    });
  }

  return (
    <>
      {deleteBusStopModalOpen && (
        <StopModal
          type={busStopModalType.DELETE}
          row={stopRow}
          modalOpen={deleteBusStopModalOpen}
          setModalOpen={setDeleteBusStopModalOpen}
          setRefreshStops={setRefreshStops}
          openSnackbar={openSnackbar}
        />
      )}
      {addBusStopModalOpen && (
        <StopModal
          type={busStopModalType.ADD}
          modalOpen={addBusStopModalOpen}
          setModalOpen={setAddBusStopModalOpen}
          setRefreshStops={setRefreshStops}
          openSnackbar={openSnackbar}
        />
      )}
      {editBusStopModalOpen && (
        <StopModal
          type={busStopModalType.EDIT}
          row={stopRow}
          modalOpen={editBusStopModalOpen}
          setModalOpen={setEditBusStopModalOpen}
          setRefreshStops={setRefreshStops}
          openSnackbar={openSnackbar}
        />
      )}
      <div className="pageTitle">{props.t('network_title')}</div>
      <CustomSnackbar
        message={snackbar.message}
        open={snackbar.open}
        onClose={closeSnackbar}
        snackbarColor={snackbar.color}
      />
      <Paper>
        <Box display="flex" justifyContent="flex-end" style={{ padding: '16px 0' }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => setAddBusStopModalOpen(true)}
            data-testid="add-bus-stop-button"
          >
            {props.t('add_a_bus_stop')}
          </Button>
        </Box>
        {loadingStops && (
          <Box display="flex" justifyContent="center" className="emptyBox">
            <CircularProgress />
          </Box>
        )}
        {!loadingStops && centerCoordinates && geojson && (
          <Box mb={2}>
            <Map
              containerStyle={{ height: '450px' }}
              style={'mapbox://styles/mapbox/light-v10'}
              zoom={mapZoom}
              center={centerCoordinates}
            >
              <GeoJSONLayer data={geojson} fillPaint={paint} />
              <Layer
                type="symbol"
                id="marker"
                layout={{ 'icon-image': 'myImage', 'icon-allow-overlap': true }}
                images={images}
                minZoom={11}
              >
                {stops &&
                  stops.map((stop) => {
                    const stopCoords = [Number(stop.origin.lon), Number(stop.origin.lat)];
                    return (
                      <Feature
                        key={stop.stopId}
                        coordinates={stopCoords}
                        onClick={() => editStop(stop)}
                      />
                    );
                  })}
              </Layer>
            </Map>
          </Box>
        )}
        {!loadingStops && stopsCopy && centerCoordinates && geojson && (
          // want grid to load when map loads too
          <Box>
            <DataGridTable rows={stopsCopy} columns={columns} />
          </Box>
        )}
      </Paper>
    </>
  );
};

export default withTranslation('common')(withAuth0(StopManagement));
