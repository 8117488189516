export const getCenter = (mapType, origin, destination, transitAgency) => {
  if (mapType === 'origin' && origin) {
    // if origin not set yet, then start at TA centered position
    return [origin.lon, origin.lat];
  } else if (mapType === 'destination' && origin && !destination) {
    // if destination not set, then use origin starting point for destination
    return [origin.lon, origin.lat];
  } else if (mapType === 'destination' && destination) {
    return [destination.lon, destination.lat];
  } else {
    return [transitAgency.center_lon, transitAgency.center_lat];
  }
};
