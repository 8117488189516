import React from 'react';
import { Grid, Box, Divider, Typography, CircularProgress } from '@material-ui/core';
import { PaymentType } from '../../enums/PaymentType';
import { dateOnlyFormat, getArrivalTime, getDepartureTime } from '../../utils/MomentTime';
import colors from '../../assets/sass/colors';
import { transitAgencyStore } from '../../store/transitAgency';
import { FeaturesType } from '../../enums/featuresType';
import { useTranslation } from 'react-i18next';

const BROWSER_LANG = navigator.language.substring(0, 2);

const ReceiptSummary = (props) => {
  const { t } = useTranslation('common');
  const { features } = transitAgencyStore();
  const hasTransfersFeature = features.some((feature) => feature.name === FeaturesType.TRANSFERS);
  const price = props.price_obj?.price;

  const getTranslatedPassValue = (type) => {
    const passType = props.passengerTypes.find((element) => element.type === type);
    return BROWSER_LANG === 'en' ? passType?.english : passType?.french;
  };

  const getPriceTemplate = () => {
    if (props.isLoading) {
      return <CircularProgress size={30} />;
    } else if (props.price_obj.customPriceString) {
      return <Box>{props.t(props.price_obj.content.key)}</Box>;
    } else if (props.price_obj.isEstimation && props.paymentType !== PaymentType.PASS) {
      return (
        <Box>
          ${(props.price_obj.content.priceLow / 100).toFixed(2)} - $
          {(props.price_obj.content.priceHigh / 100).toFixed(2)}
        </Box>
      );
    } else if (price === 0 || props.paymentType === PaymentType.PASS) {
      return <Box>{props.t('free')}</Box>;
    } else {
      return <Box>${(price / 100).toFixed(2)}</Box>;
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item sm={12} spacing={3} style={{ paddingBottom: '5%' }}>
        <Typography variant="h6" style={{ fontWeight: 'bold', textAlign: 'center' }}>
          {props.t('receipt')}
        </Typography>
      </Grid>
      <Grid item sm={12} spacing={3} style={{ paddingBottom: '2%' }}>
        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
          {props.t('departing')}: {dateOnlyFormat(props.time)}
          {hasTransfersFeature ? (
            <Typography variant="subtitle1">{t('estimated_times_after_submit')}</Typography>
          ) : (
            <>
              {props.departureOrArrival === 'arrival' && (
                <Typography variant="subtitle1">
                  {props.t('passenger_departure_time')}{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {getDepartureTime(props.travelTimeData.finalTravelTime / 60, props.time)}
                  </span>
                </Typography>
              )}
              {props.departureOrArrival === 'departure' && (
                <Typography variant="subtitle1">
                  {props.t('passenger_arrival_time')}{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {getArrivalTime(props.travelTimeData.finalTravelTime / 60, props.time)}
                  </span>
                </Typography>
              )}
            </>
          )}
        </Typography>
      </Grid>
      <Grid item sm={12} spacing={3} style={{ paddingBottom: '10%' }}>
        <Typography variant="body1" style={{ display: 'flex', flexDirection: 'column' }}>
          <span>{props.t('origin')}</span>
          <span style={{ paddingBottom: '3%' }}>{props.origin.name}</span>
        </Typography>
        <Typography variant="body1" style={{ display: 'flex', flexDirection: 'column' }}>
          <span>{props.t('destination')}</span>
          <span>{props.destination.name}</span>
        </Typography>
      </Grid>
      {!props.editRequestMode && (
        <Grid item sm={12} spacing={3} style={{ paddingBottom: '5%' }}>
          {Object.keys(props.passengers).map((type) => {
            return (
              props.passengers[type].length > 0 && (
                <Typography
                  key={type}
                  variant="body1"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <span>{props.passengers[type].length}x</span>
                  <span>{getTranslatedPassValue(type)}</span>
                </Typography>
              )
            );
          })}
        </Grid>
      )}
      <Grid item sm={12} spacing={3}>
        <Box variant="body1" style={{ position: 'relative', fontWeight: 'bold' }}>
          <span>{props.t('total')}</span>
          <span style={{ position: 'absolute', right: '0' }}>{getPriceTemplate()}</span>
        </Box>
      </Grid>

      {/* Return trip */}
      {props.returnTrip && (
        <>
          <Grid item sm={12} spacing={3} style={{ paddingBottom: '5%', paddingTop: '5%' }}>
            <Divider variant="middle" />
          </Grid>

          <Grid item sm={12} spacing={3} style={{ paddingBottom: '2%' }}>
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
              {props.t('returning')}: {dateOnlyFormat(props.returnTime)}
            </Typography>
            {props.returnDepartureOrArrival === 'arrival' && (
              <Typography variant="subtitle1">
                {props.t('passenger_departure_time')}{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {getDepartureTime(props.travelTimeData.finalTravelTime / 60, props.returnTime)}
                </span>
              </Typography>
            )}
            {props.returnDepartureOrArrival === 'departure' && (
              <Typography variant="subtitle1">
                {props.t('passenger_arrival_time')}{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {getArrivalTime(props.travelTimeData.finalTravelTime / 60, props.returnTime)}
                </span>
              </Typography>
            )}
          </Grid>
          <Grid item sm={12} spacing={3} style={{ paddingBottom: '10%' }}>
            <Typography variant="body1" style={{ display: 'flex', flexDirection: 'column' }}>
              <span>{props.t('origin')}</span>
              <span style={{ paddingBottom: '3%' }}>{props.returnOrigin.name}</span>
            </Typography>
            <Typography variant="body1" style={{ display: 'flex', flexDirection: 'column' }}>
              <span>{props.t('destination')}</span>
              <span>{props.returnDest.name}</span>
            </Typography>
          </Grid>
          <Grid item sm={12} spacing={3} style={{ paddingBottom: '5%' }}>
            {Object.keys(props.passengers).map((type) => {
              return (
                props.passengers[type].length > 0 && (
                  <Typography
                    key={type}
                    variant="body1"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <span>{props.passengers[type].length}x</span>
                    <span>{getTranslatedPassValue(type)}</span>
                  </Typography>
                )
              );
            })}
          </Grid>
          <Grid item sm={12} spacing={3} style={{ paddingBottom: '10%' }}>
            <Typography
              variant="body1"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                fontWeight: 'bold',
                position: 'relative'
              }}
            >
              <span>{props.t('total')}</span>
              <span style={{ position: 'absolute', right: '0' }}>
                {props.price_obj.customPriceString ? (
                  <span>{props.t(props.price_obj.content.key)}</span>
                ) : (
                  <span>
                    {props.isLoading ? (
                      <CircularProgress size={30} />
                    ) : price === 0 || props.paymentType === PaymentType.PASS ? (
                      props.t('free')
                    ) : (
                      '$' + (price / 100).toFixed(2)
                    )}
                  </span>
                )}
              </span>
            </Typography>
          </Grid>
          <Grid item sm={12} spacing={3}>
            <Typography
              variant="subtitle1"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                fontWeight: 900,
                position: 'relative'
              }}
            >
              <span>{props.t('grand_total')}</span>
              <span style={{ position: 'absolute', right: '0' }}>
                {props.price_obj.customPriceString ? (
                  <span>{props.t(props.price_obj.content.key)}</span>
                ) : (
                  <span>
                    {props.isLoading ? (
                      <CircularProgress size={30} />
                    ) : (props.returnTripPrice === 0 && price === 0) ||
                      props.paymentType === PaymentType.PASS ? (
                      props.t('free')
                    ) : (
                      '$' + ((props.returnTripPrice + price) / 100).toFixed(2)
                    )}
                  </span>
                )}
              </span>
            </Typography>
          </Grid>
        </>
      )}
      {props.paymentType === PaymentType.TICKETS && (
        <>
          <Grid item sm={12} spacing={3} style={{ paddingBottom: '5%', paddingTop: '5%' }}>
            <Divider variant="middle" />
          </Grid>

          <Box style={{ padding: '4px', width: '100%' }}>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 700
              }}
            >
              {props.t('amount_to_charge')}
            </Typography>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle1">{props.t('blaise_balance')}</Typography>
              <Typography variant="subtitle1" style={{ color: colors.blaiseRed }}>
                -${((price + props?.returnTripPrice) / 100).toFixed(2)}
              </Typography>
            </Box>
            {props.passengerBalance > 0 && (
              <Box display="flex" justifyContent="space-between" style={{ marginTop: '18px' }}>
                <Typography variant="subtitle1" style={{ fontWeight: 700 }}>
                  {props.t('remaining_balance')}
                </Typography>
                {props.passengerBalance > price + props?.returnTripPrice ? (
                  <Typography
                    style={{
                      color: colors.blaiseGreen
                    }}
                  >
                    $
                    {((props.passengerBalance - (price + props?.returnTripPrice)) / 100).toFixed(2)}
                  </Typography>
                ) : (
                  <Typography
                    style={{
                      color: colors.blaiseRed
                    }}
                  >
                    -$
                    {Math.abs(
                      props.passengerBalance - (price + props?.returnTripPrice) / 100
                    ).toFixed(2)}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </>
      )}
    </Grid>
  );
};

export default ReceiptSummary;
