import React, { Component } from 'react';
import './Progress.scss';

/**
 * Progress bar component used in Upload component
 *
 * Props:
 * progress {Number} Current progress of the upload (0-100). In percent.
 */
export default class Progress extends Component {
  render() {
    return (
      <div className="ProgressBar">
        <div className="Progress" style={{ width: this.props.progress + '%' }} />
      </div>
    );
  }
}
