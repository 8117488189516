import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import colors from '../../../assets/sass/colors';
import {
  Button,
  CircularProgress,
  Grid,
  Box,
  MenuItem,
  TextField,
  Typography,
  Tooltip,
  IconButton
} from '@material-ui/core';
import { TimePicker, DatePicker, LocalizationProvider } from '@material-ui/pickers';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import moment from 'moment';
import './RecurrentShiftsModal.scss';

const backendErrorCodes = Object.freeze({
  INVALID_RECURRENCE: 'recurrence_time_past_error',
  INVALID_DURATION: 'shift_duration_is_invalid',
  NOT_DEPOTS: 'bus_stops_are_not_all_depots',
  INVALID_DRIVER: 'driver_not_exist',
  BEFORE_CUTOFF: 'shift_create_error_shift_before_cutoff_time'
});

const FormStep = ({
  drivers,
  buses,
  depots,
  closeModal,
  openSnackbar,
  goToStep,
  setShiftsToBeInserted,
  setErrors,
  setShiftFormData,
  postRecurrenceData
}) => {
  const WEEKDAYS = moment.weekdaysShort();
  const { t } = useTranslation('common');

  const [driverId, setDriverId] = useState('');
  const [busId, setBusId] = useState('');
  const [startDepot, setStartDepot] = useState('');
  const [endDepot, setEndDepot] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [recurrStart, setRecurrStart] = useState('');
  const [recurrEnd, setRecurrEnd] = useState('');
  const [deadheading, setDeadheading] = useState('');
  const [recurringDays, setRecurringDays] = useState(WEEKDAYS.slice(1, -1));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialErrors, setInitialErrors] = useState([]);

  useEffect(() => {
    setShiftFormData({
      busId,
      driverId,
      startTime,
      endTime,
      startDepot,
      endDepot,
      recurrStart,
      recurrEnd,
      recurringDays,
      deadheading
    });
  }, [
    busId,
    driverId,
    startTime,
    endTime,
    startDepot,
    endDepot,
    recurrStart,
    recurrEnd,
    recurringDays,
    deadheading
  ]);

  const validateInputValues = () => {
    return (
      busId &&
      driverId &&
      startTime &&
      endTime &&
      startDepot.toString() &&
      endDepot.toString() &&
      recurrStart &&
      recurrEnd &&
      recurringDays &&
      deadheading !== ''
    );
  };

  const handleCreateShifts = async () => {
    if (!validateInputValues()) {
      return openSnackbar(t('create_driver_shift_error'), colors.red);
    }

    setIsSubmitting(true);

    try {
      const driverShiftsResponse = await postRecurrenceData(true);
      setShiftsToBeInserted(driverShiftsResponse.data.toBeInserted);
      goToStep(3);
      setInitialErrors([]);
    } catch (err) {
      // Store errors, go to conflicts step if there are conflicts
      setInitialErrors(err.response.data.initialErrors);
      setErrors(err.response.data.errors);
      err.response.data.errors && goToStep(2);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Grid className="recurrentModal">
      <Grid
        item
        sm={12}
        style={{
          padding: '1rem 2rem',
          borderBottom: `1px solid ${colors.gray300}`
        }}
      >
        <Typography
          style={{
            color: colors.gray800,
            fontWeight: 'bold',
            fontSize: '1.25rem'
          }}
        >
          {t('create_recurrent_driver_shift')}
        </Typography>
      </Grid>

      <Grid style={{ overflowY: 'auto' }}>
        <Grid container spacing={2} style={{ padding: '1rem 1.5rem' }}>
          <Grid item sm={12}>
            <h2 className="inputLabel">{t('driver_information')}</h2>
          </Grid>

          {/* Driver */}
          <Grid item sm={6}>
            <TextField
              select
              variant="outlined"
              label={t('driver')}
              required
              fullWidth
              value={driverId}
              onChange={(e) => setDriverId(e.target.value)}
              error={initialErrors?.some((e) => e.errorKey === backendErrorCodes.INVALID_DRIVER)}
              disabled={isSubmitting}
            >
              {drivers?.map((driver) => (
                <MenuItem key={driver.driver_id} value={driver.driver_id}>
                  {driver.driver_name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Bus */}
          <Grid item sm={6}>
            <TextField
              select
              variant="outlined"
              label={t('bus')}
              required
              fullWidth
              value={busId}
              onChange={(e) => setBusId(e.target.value)}
              disabled={isSubmitting}
            >
              {buses?.map((bus) => (
                <MenuItem key={bus.bus_id} value={bus.bus_id}>
                  {bus.bus_name ? bus.bus_name : bus.bus_number}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item sm={12} style={{ marginTop: '0.5rem' }}>
            <h2 className="inputLabel">{t('shift_start')}</h2>
          </Grid>

          {/* Start Depot */}
          <Grid item sm={6}>
            <TextField
              select
              variant="outlined"
              label={t('start_depot')}
              required
              fullWidth
              value={startDepot}
              onChange={(e) => setStartDepot(e.target.value)}
              error={initialErrors?.some((e) => e.errorKey === backendErrorCodes.NOT_DEPOTS)}
              disabled={isSubmitting}
            >
              {depots?.map((stop) => (
                <MenuItem key={stop.code} value={stop.code}>
                  #{stop.code}: {stop.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Start Time */}
          <Grid item sm={6}>
            <LocalizationProvider dateAdapter={MomentUtils}>
              <TimePicker
                label={t('start_time')}
                value={startTime}
                onChange={(date) => setStartTime(moment(date).seconds(0).milliseconds(0))}
                minDate={new Date()}
                disabled={isSubmitting}
                InputProps={{ style: { height: '56px' } }}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    variant="outlined"
                    helperText={null}
                    error={initialErrors?.some(
                      (e) => e.errorKey === backendErrorCodes.INVALID_DURATION
                    )}
                    required
                    fullWidth
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item sm={12} style={{ marginTop: '0.5rem' }}>
            <h2 className="inputLabel">{t('shift_end')}</h2>
          </Grid>

          {/* End Depot */}
          <Grid item sm={6}>
            <TextField
              select
              variant="outlined"
              label={t('end_depot')}
              required
              fullWidth
              value={endDepot}
              onChange={(e) => setEndDepot(e.target.value)}
              error={initialErrors?.some((e) => e.errorKey === backendErrorCodes.NOT_DEPOTS)}
              disabled={isSubmitting}
            >
              {depots?.map((stop) => (
                <MenuItem key={stop.code} value={stop.code}>
                  #{stop.code}: {stop.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* End Time */}
          <Grid item sm={6}>
            <LocalizationProvider dateAdapter={MomentUtils}>
              <TimePicker
                label={t('end_time')}
                value={endTime}
                onChange={(date) => setEndTime(moment(date).seconds(0).milliseconds(0))}
                minDate={startTime ? startTime : new Date()}
                disabled={isSubmitting}
                InputProps={{ style: { height: '56px' } }}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    variant="outlined"
                    helperText={null}
                    error={initialErrors?.some(
                      (e) => e.errorKey === backendErrorCodes.INVALID_DURATION
                    )}
                    required
                    fullWidth
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          {/* Deadheading */}
          <Grid item sm={12}>
            <TextField
              select
              variant="outlined"
              label={t('end_time_corresponds_to')}
              required
              fullWidth
              value={deadheading}
              onChange={(e) => setDeadheading(e.target.value)}
              disabled={isSubmitting}
            >
              <MenuItem key={'depot'} value={1}>
                {t('depot_return_time')}
              </MenuItem>
              <MenuItem key={'drop_off'} value={0}>
                {t('last_drop_off_time')}
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item sm={12} style={{ marginTop: '0.5rem', display: 'flex', alignItems: 'center' }}>
            <h2 className="inputLabel">{t('recurrence')}</h2>
            <Tooltip title={t('recurrence_tooltip')}>
              <IconButton style={{ padding: 0, marginTop: '2px', marginLeft: '0.5rem' }}>
                <FontAwesomeIcon icon={faInfoCircle} color={colors.gray400} size="xs" />
              </IconButton>
            </Tooltip>
          </Grid>

          {/* Recurrence Start */}
          <Grid item sm={6}>
            <LocalizationProvider dateAdapter={MomentUtils}>
              <DatePicker
                label={t('recurrence_start')}
                value={recurrStart}
                onChange={(date) => setRecurrStart(date)}
                disabled={isSubmitting}
                minDate={new Date()}
                InputProps={{ style: { height: '56px' } }}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    variant="outlined"
                    fullWidth
                    helperText={null}
                    required
                    error={initialErrors?.some(
                      (e) =>
                        e.errorKey === backendErrorCodes.INVALID_RECURRENCE ||
                        e.errorKey === backendErrorCodes.BEFORE_CUTOFF
                    )}
                    data-testid="recurrenceStart"
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          {/* Recurrence End */}
          <Grid item sm={6}>
            <LocalizationProvider dateAdapter={MomentUtils}>
              <DatePicker
                label={t('recurrence_end')}
                value={recurrEnd}
                onChange={(date) => setRecurrEnd(date)}
                disabled={isSubmitting}
                minDate={recurrStart ? recurrStart : new Date()}
                InputProps={{ style: { height: '56px' } }}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    variant="outlined"
                    fullWidth
                    helperText={null}
                    required
                    error={initialErrors?.some(
                      (e) =>
                        e.errorKey === backendErrorCodes.INVALID_RECURRENCE ||
                        e.errorKey === backendErrorCodes.BEFORE_CUTOFF
                    )}
                    data-testid="recurrenceEnd"
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          {/* Applicable Days Of Week */}
          <Grid item sm={12}>
            <ToggleButtonGroup
              value={recurringDays}
              onChange={(events, days) => {
                setRecurringDays(days);
              }}
              style={{ height: '56px', display: 'flex' }}
            >
              {WEEKDAYS.map((weekday) => {
                return (
                  <ToggleButton key={weekday} value={weekday} style={{ flexGrow: 1 }}>
                    {weekday}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      </Grid>

      <Box
        display="flex"
        justifyContent="flex-end"
        gridGap="1rem"
        style={{
          borderTop: `1px solid ${colors.gray300}`,
          padding: '1rem 2rem'
        }}
      >
        {isSubmitting ? (
          <Grid container item xs={12} justify="center">
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Button onClick={closeModal} color="secondary" variant="outlined">
              {t('cancel')}
            </Button>
            <Button onClick={() => handleCreateShifts()} color="primary" variant="contained">
              {t('create')}
            </Button>
          </>
        )}
      </Box>
    </Grid>
  );
};

export default FormStep;
