import React, { useEffect, useState } from 'react';
import './Analytics.scss';
import { Grid, Box, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { analyticsStore } from '../../store/analytics';
import NotFound from '../../components/Error/NotFound';

const NAVBAR_WIDTH = 220;
const PADDING = 30;
const HEADER_SPACING = 30;

const Analytics = () => {
  const [iframeDimensions, setIframeDimensions] = useState({ height: 0, width: 0 });
  const { t } = useTranslation('common');
  const { analyticsUrl } = analyticsStore();

  useEffect(() => {
    document.title = `${t('analytics_title')} - ${t('trip_source_engine')}`;
    getAnalyticsIframeDimensions();
  }, []);

  const getAnalyticsIframeDimensions = () => {
    setIframeDimensions({
      height: window.innerHeight - PADDING * 2 - HEADER_SPACING * 2,
      width: window.innerWidth - NAVBAR_WIDTH - PADDING * 2 - 15 // 15 was needed to perfectly center things.
    });
  };

  return (
    <>
      {/* HEADER */}
      <Grid item xs={12} className="header-container">
        <Box className="page-title">{t('analytics_title')}</Box>
        {analyticsUrl.powerBIAppUrl && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={analyticsUrl.powerBIAppUrl}
            style={{ textDecoration: 'none' }}
          >
            <Button color="primary" variant="contained">
              {t('view_full_report')}
            </Button>
          </a>
        )}
      </Grid>

      {/* BODY */}
      <Grid container>
        {analyticsUrl.unsecuredUrl ? (
          <div
            dangerouslySetInnerHTML={{
              __html: `<iframe title="Blaise Analytics" width="${iframeDimensions.width}" height="${iframeDimensions.height}" src="${analyticsUrl.unsecuredUrl}" frameborder="0" allowFullScreen="true" />`
            }}
          />
        ) : (
          <NotFound title="Whoops" message={t('feature_not_available')} />
        )}
      </Grid>
    </>
  );
};

export default Analytics;
