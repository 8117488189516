import React, { Component, Fragment } from 'react';
import { faPen, faTrash, faCheck, faTimes, faTabletAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../assets/sass/colors';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import CustomSnackbar from '../../components/Snackbar/CustomSnackbar';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import { withTranslation } from 'react-i18next';
import { withAuth0 } from '@auth0/auth0-react';
import '../../components/Fleet/FleetTable/FleetTable.scss';
import DataGridTable from '../../components/DataGrid/DataGridTable';
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalAdd from '../../components/Modals/ModalAdd';
import authenticatedAxiosInstance from '../../axios/axios-authorized';

class DriverTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      drivers: null, // stores the drivers data from the api call
      refreshDrivers: false, // state determines refresh rate of table
      deleteModal: false, // show/hide delete modal
      editMode: false, // turns on editMode for a specific row
      addMode: this.props.add, // renders AddRow component
      snackbar: {
        // open/close snackbar element, message is the string that is passed to the component
        open: false,
        message: ''
      },
      editIndex: null, // default index for row in editMode
      deleteId: null, // default driver id in deleteModal,
      deleteIndex: null, // default driver index in deleteModal
      newDriver: {
        // stores the data when adding a new driver
        driver_name: '',
        driver_employee_number: '',
        is_active: 1
      },
      isSubmitting: false,
      modalOpen: false
    };

    this.initialDrivers = null;
  }

  async componentDidMount() {
    try {
      document.title = `${this.props.t('driver_management_title')} - ${this.props.t(
        'trip_source_engine'
      )}`;

      const response = await authenticatedAxiosInstance.axios.get(`/drivers?searchType=1`);

      this.setState({
        drivers: response.data,
        refreshDrivers: false
      });

      this.initialDrivers = [];
      this.state.drivers.map((driver) =>
        this.initialDrivers.push({
          driver_id: driver.driver_id,
          driver_name: driver.driver_name,
          driver_employee_number: driver.driver_employee_number,
          is_active: driver.is_active
        })
      );
    } catch (err) {
      if (err.response && err.response.status === 403) {
        alert(this.props.t('no_permission'));
      } else {
        this.openSnackbar(this.props.t('error_fetching_drivers'));
      }
    }
  }

  // Snackbar message and color are passed as props to indicate what to display
  openSnackbar = (snackbarString, color) => {
    this.setState({
      snackbar: {
        open: true,
        message: snackbarString,
        color: color
      }
    });
  };

  // Snackbar closes except for when user clicks outside of component
  closeSnackbar = (reason) => {
    if (reason !== 'clickaway') {
      this.setState({ snackbar: { open: false } });
    }
  };

  // Checks if any changes have been made for a specific driver
  isDifferent(originalDrivers, updatedDrivers, index) {
    return !(
      originalDrivers[index].driver_id === updatedDrivers[index].driver_id &&
      originalDrivers[index].driver_name === updatedDrivers[index].driver_name &&
      originalDrivers[index].driver_employee_number ===
        updatedDrivers[index].driver_employee_number &&
      originalDrivers[index].is_active === updatedDrivers[index].is_active
    );
  }

  // Activate the editing mode
  activateEdit = (e, driverIndex) => {
    this.setState({
      editMode: true,
      editIndex: driverIndex
    });
  };

  // Open the delete confirmation modal
  showDelete = (e, driverIndex) => {
    this.setState({
      deleteModal: true,
      deleteId: this.state.drivers[driverIndex].driver_id,
      deleteIndex: driverIndex
    });
  };

  // Toggle add mode
  toggleAdd = () => {
    this.setState({
      addMode: !this.state.addMode,
      // refreshDrivers: true,
      modalOpen: !this.state.modalOpen
    });
  };

  // Cancel any changes that are in progress on the text fields
  cancelChanges = () => {
    this.setState({
      addMode: false,
      editMode: false,
      deleteModal: false,
      drivers: this.initialDrivers,
      deleteId: null,
      editIndex: null
    });
  };

  // Updates the values in the database based on new values entered
  updateValues = async (event, index) => {
    this.validateNewRow(this.state.drivers[index]);

    this.setState({
      isSubmitting: true
    });

    try {
      const reqBody = {
        driver_name: this.state.drivers[index].driver_name,
        employee_number: this.state.drivers[index].driver_employee_number,
        is_active: this.state.drivers[index].is_active
      };

      await authenticatedAxiosInstance.axios.put(
        `/drivers/${this.state.drivers[index].driver_id}`,
        reqBody
      );

      await this.updateDrivers();

      this.openSnackbar(this.props.t('driver_modified'), colors.blaiseGreen);
      this.setState({
        editIndex: null
      });
    } catch (error) {
      this.openSnackbar(this.props.t('error_modifying_driver'), colors.red);
    }

    this.setState({
      isSubmitting: false
    });
  };

  // Renders the actions icons in the default view
  renderDefaultIcons = (item, itemIndex) => {
    return (
      <Box display="flex" flexDirection="row" justifyContent="space-evenly" width="100%">
        {this.props.connectTablet && item.has_tablet === 0 && (
          <Fab
            color="primary"
            size="small"
            className="editButton"
            variant="extended"
            onClick={(e) => this.connectTablet(e, itemIndex)}
          >
            <FontAwesomeIcon
              icon={faTabletAlt}
              color={colors.white}
              size="1x"
              className="editIcon"
            />
          </Fab>
        )}
        <Fab
          color="primary"
          size="small"
          className="editButton"
          variant="extended"
          onClick={(e) => this.activateEdit(e, itemIndex)}
        >
          <FontAwesomeIcon icon={faPen} color={colors.white} size="1x" className="editIcon" />
        </Fab>
        <Fab
          color="secondary"
          size="small"
          className="deleteButton"
          variant="extended"
          onClick={(e) => this.showDelete(e, itemIndex)}
        >
          <FontAwesomeIcon icon={faTrash} color={colors.white} size="1x" className="deleteIcon" />
        </Fab>
      </Box>
    );
  };

  // Renders the action icons in editMode
  renderEditIcons = (itemIndex) => {
    if (this.state.editIndex === itemIndex) {
      return this.state.isSubmitting ? (
        <CircularProgress />
      ) : (
        <>
          <Box display="flex" flexDirection="row" justifyContent="space-evenly" width="100%">
            <Fab
              color="primary"
              size="small"
              className="editButton"
              variant="extended"
              onClick={(e) => this.updateValues(e, this.state.editIndex)}
            >
              <FontAwesomeIcon icon={faCheck} color={colors.white} size="1x" className="editIcon" />
            </Fab>
            <Fab
              size="small"
              className="deleteButton"
              variant="extended"
              onClick={this.cancelChanges}
            >
              <FontAwesomeIcon
                icon={faTimes}
                color={colors.white}
                size="1x"
                className="deleteIcon"
              />
            </Fab>
          </Box>
        </>
      );
    }
  };

  // Handles changes to text fields when adding a new bus
  handleNewDriverChange = (event, columnId) => {
    const target = event.target;
    const value = columnId === 'is_active' ? parseInt(target.value) : target.value;
    const id = target.id;

    this.setState(() => {
      let newDriverCopy = this.state.newDriver;
      newDriverCopy[id] = value;
      return newDriverCopy;
    });
  };

  // Validates the new driver being added
  validateNewRow(driver) {
    try {
      // TODO: fix errors, throw error object instead of strings
      if (!driver.driver_name || driver.driver_name == null || driver.driver_name.trim() === '')
        throw this.props.t('no_empty_name');
      if (!driver.driver_employee_number || driver.driver_employee_number == null)
        throw this.props.t('no_empty_employee_num');
      if (!Number.isInteger(driver.is_active) || (driver.is_active !== 0 && driver.is_active !== 1))
        throw this.props.t('incorrect_status');
    } catch (err) {
      this.openSnackbar(err, colors.red);
      throw Error;
    }
  }

  updateDrivers = async () => {
    const response = await authenticatedAxiosInstance.axios.get(`/drivers?searchType=1`);

    this.setState({
      drivers: response.data,
      editMode: false,
      addMode: false,
      deleteModal: false
    });
  };

  // Create a new driver
  onCreateRow = async () => {
    this.validateNewRow(this.state.newDriver);

    this.setState({ isSubmitting: true });

    const reqBody = {
      driver_name: this.state.newDriver.driver_name,
      driver_employee_number: parseInt(this.state.newDriver.driver_employee_number),
      is_active: this.state.newDriver.is_active
    };

    try {
      await authenticatedAxiosInstance.axios.post(`/drivers`, reqBody);
      this.openSnackbar(this.props.t('new_driver_added'), colors.blaiseGreen);

      this.setState({
        newDriver: {
          driver_name: '',
          driver_employee_number: '',
          is_active: 1
        },
        modalOpen: !this.state.modalOpen,
        refreshDrivers: true
      });

      await this.updateDrivers();
      this.setState({ isSubmitting: false, refreshDrivers: false });
    } catch (error) {
      this.openSnackbar(this.props.t('error_creating_driver'), colors.red);
    }
  };

  // Delete a row
  onDeleteRow = async () => {
    try {
      await authenticatedAxiosInstance.axios.delete(`/drivers/${this.state.deleteId}`);
      this.setState({
        deleteModal: false
      });

      await this.updateDrivers();
      this.openSnackbar(this.props.t('driver_removed'), colors.blaiseGreen);
    } catch (err) {
      if (err.response?.data?.error?.message?.errorKey === 'driver_associated_to_shift') {
        this.openSnackbar(this.props.t('driver_associated_to_shift'), colors.red);
      } else {
        this.openSnackbar(this.props.t('problem_removing_driver'), colors.red);
      }
    }

    this.setState({
      isSubmitting: false
    });
  };

  handleEditCellChange = ({ id, field, props }) => {
    let thisField;

    if (field === 'id') {
      thisField = 'driver_employee_number';
    } else {
      thisField = field;
    }

    this.setState(() => {
      let driverCopy = this.state.drivers[id];
      driverCopy[thisField] = props.value;
      return driverCopy;
    });
  };

  handleModalClose = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
      addMode: !this.state.addMode,
      newDriver: {
        driver_name: '',
        driver_employee_number: '',
        is_active: 1
      }
    });
  };

  render() {
    // List of datagrid column titles and their respective styling
    const columnStyles = { flex: 1, align: 'center', headerAlign: 'center', editable: true };

    const columns = [
      {
        field: 'driver_employee_number',
        headerName: this.props.t('employee_number'),
        ...columnStyles
      },
      { field: 'driver_name', headerName: this.props.t('driver_name'), ...columnStyles },
      {
        field: 'button',
        headerName: this.props.t('actions'),
        headerAlign: 'center',
        flex: 0.25,
        renderCell: (params) =>
          !this.state.addMode &&
          (this.state.editMode
            ? this.renderEditIcons(params.id)
            : this.renderDefaultIcons(params.row, params.id)),
        sortable: false,
        disableColumnMenu: true
      }
    ];

    // copy of drivers with id to pass to datagrid
    let driversCopy;
    if (this.state.drivers) {
      driversCopy = this.state.drivers.map((driver, index) => {
        return {
          ...driver,
          id: index
        };
      });
    }

    return (
      <Fragment>
        {this.state.modalOpen && (
          <ModalAdd
            {...this.props}
            modalOpen={this.state.modalOpen}
            modalTitle={this.props.t('add_driver')}
            modalFields={['driver_employee_number', 'driver_name']}
            handleModalAdd={this.onCreateRow}
            handleChange={this.handleNewDriverChange}
            handleModalClose={this.handleModalClose}
          />
        )}
        <div className="pageTitle">{this.props.t('driver_management_title')}</div>
        <Paper>
          <Box display="flex" justifyContent="flex-end" style={{ padding: '16px 0' }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={this.toggleAdd}
              data-testid="add-driver-button"
            >
              {this.props.t('add_driver')}
            </Button>
          </Box>
          {(!this.state.drivers || this.state.refreshDrivers) && (
            <Box display="flex" justifyContent="center" className="emptyBox">
              <CircularProgress />
            </Box>
          )}
          {columns && driversCopy && (
            <DataGridTable
              columns={columns}
              rows={driversCopy}
              editIndex={this.state.editIndex}
              handleEditCellChange={this.handleEditCellChange}
            />
          )}
        </Paper>
        {/* External Components which appear based on changing states */}
        <CustomSnackbar
          message={this.state.snackbar.message}
          open={this.state.snackbar.open}
          onClose={this.closeSnackbar}
          snackbarColor={this.state.snackbar.color}
        />
        <DeleteModal
          open={this.state.deleteModal}
          isSubmitting={this.state.isSubmitting}
          toggleModal={this.cancelChanges}
          deleteId={this.state.deleteId}
          snackbar={this.openSnackbar}
          onDeleteItem={this.onDeleteRow}
          deleteMessage={this.props.t('confirm_delete_driver')}
        />
      </Fragment>
    );
  }
}

export default withTranslation('common')(withAuth0(DriverTable));
