import realAxios from 'axios';
import jwt_decode from 'jwt-decode';
import config from '../config';
import { authStore } from '../store/auth';
import { accessTokenContainer } from '../utils/authUtils';

class AuthenticatedAxiosInstance {
  constructor() {
    // Singleton logic.
    if (AuthenticatedAxiosInstance.instance === null) {
      this.axios;
      AuthenticatedAxiosInstance.instance = this;
    }
    return AuthenticatedAxiosInstance.instance;
  }

  createAuthenticatedAxiosInstance = (accessToken) => {
    this.axios = realAxios.create({
      baseURL: config.apiServer,
      headers: {
        'x-app': 'dashboard',
        Authorization: `Bearer ${accessToken}`
      }
    });

    this.axios.interceptors.request.use(
      async (config) => {
        // jwtExpiration comes back as 10 digit UTC timestamp, so currentTime needs to be converted to a string, sliced and converted back to a number.
        const currentTime = Number(Date.now().toString().slice(0, 10));
        const token = authStore.getState().accessToken;
        const jwtExpiration = jwt_decode(token)?.exp;
        let refresh = true;

        if (currentTime >= jwtExpiration && refresh) {
          // Switch refresh to false, so that it only tries to refresh accessToken once.
          refresh = false;

          // Gets new token.
          const newAccessToken = await accessTokenContainer.getAccessTokenSilently()({
            audience: config.apiAudience
          });

          // Switch authorization header to new token.
          config.headers['Authorization'] = `Bearer ${newAccessToken}`;

          // Set new token in Zustand store.
          authStore.getState().setAccessToken(newAccessToken);
        }

        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
  };
}

let authenticatedAxiosInstance = new AuthenticatedAxiosInstance();

export default authenticatedAxiosInstance;
