import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { withAuth0 } from '@auth0/auth0-react';
import { withTranslation } from 'react-i18next';
import useInterval from '../../higherOrderComponents/useInterval';
import CustomSnackbar from '../../components/Snackbar/CustomSnackbar';
import DataGridTable from '../../components/DataGrid/DataGridTable';
import {
  Box,
  CircularProgress,
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import colors from '../../assets/sass/colors';
import { dateTimeFormat } from '../../utils/MomentTime';
import { DatePicker, LocalizationProvider } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import moment from 'moment';
import LocalStorageKeys from '../../utils/dataGridUtils';
import { RouteStatus } from '../../enums/statusType';
import authenticatedAxiosInstance from '../../axios/axios-authorized';

const DateType = Object.freeze({
  YESTERDAY: 'yesterday',
  TODAY: 'today',
  TOMORROW: 'tomorrow'
});

const FilterType = Object.freeze({
  START: 'start',
  END: 'end'
});

const RouteShiftManagement = ({ i18n, t }) => {
  moment.locale(i18n.language.substr(0, 2));
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [shifts, setShifts] = useState(null);
  const [shiftsCompleted, setShiftsCompleted] = useState([]);
  const [shiftsOngoing, setShiftsOngoing] = useState([]);
  const [shiftsUpcoming, setShiftsUpcoming] = useState([]);
  const [shiftsAll, setShiftsAll] = useState([]);
  const [shiftsUnknown, setShiftsUnknown] = useState([]);
  const [noRouteShifts, setNoRouteShifts] = useState([]);
  const [searchType, setSearchType] = useState(RouteStatus.ALL);
  const [sortModel, setSortModel] = useState([{ field: 'start_time', sort: 'desc' }]);
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [dateFilterSelection, setDateFilterSelection] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: null
  });
  const localEndDateFilter = localStorage.getItem(
    LocalStorageKeys.ROUTE_SHIFT_MANAGEMENT_FILTER_END_DATE
  );
  const localStartDateFilter = localStorage.getItem(
    LocalStorageKeys.ROUTE_SHIFT_MANAGEMENT_FILTER_START_DATE
  );

  useInterval(() => {
    fetchShifts();
  }, 60000);

  useEffect(() => {
    document.title = `${t('route_management_title')} - ${t('trip_source_engine')}`;
    let date = new Date();
    // Load date filters from localStorage.

    localStartDateFilter
      ? setFilterStartDate(localStartDateFilter)
      : setFilterStartDate(date.setDate(date.getDate() - 1));
    localEndDateFilter
      ? setFilterEndDate(localEndDateFilter)
      : setFilterEndDate(date.setDate(date.getDate() + 1));
  }, []);

  useEffect(() => {
    if (filterStartDate && filterEndDate) {
      fetchShifts('mount');
    }
  }, [filterStartDate, filterEndDate]);

  useEffect(() => {
    setDemand(searchType);
  }, [shifts]);

  useEffect(() => {
    let dateToFilter;
    const today = new Date();

    if (dateFilterSelection === DateType.TODAY) {
      dateToFilter = today;
    }
    if (dateFilterSelection === DateType.YESTERDAY) {
      dateToFilter = today.setDate(today.getDate() - 1);
    }

    if (dateFilterSelection === DateType.TOMORROW) {
      dateToFilter = today.setDate(today.getDate() + 1);
    }

    if (dateToFilter) {
      setFilterDate(dateToFilter, FilterType.START);
      setFilterDate(dateToFilter, FilterType.END);
    }
  }, [dateFilterSelection]);

  const fetchShifts = async (type) => {
    try {
      // show spinner on mount load but not refresh loads
      if (type === 'mount') {
        setLoading(true);
      }

      let startDate = filterStartDate ? new Date(filterStartDate) : new Date('1970-01-01T00:00:00');
      startDate.setHours(0, 0, 0, 0);
      let endDate = filterEndDate ? new Date(filterEndDate) : new Date('3000-01-01T00:00:00');
      endDate.setHours(23, 59, 59, 59);

      const response = await authenticatedAxiosInstance.axios.get(`/route/getShiftsWithRoutes`, {
        params: {
          start: startDate.toISOString(),
          end: endDate.toISOString()
        }
      });
      const shiftsData = response?.data;

      const filteredShifts = shiftsData.map((shift) => {
        return {
          ...shift,
          routes: shift.routes.filter(
            // Filter out routes that are completed but do not have a departure time, these are pre-algo reboot routes
            (route) => {
              const isAlgoRebootRoute =
                route.is_completed && !route.departure_time && !route.is_active;
              return !isAlgoRebootRoute;
            }
          )
        };
      });

      let completedShifts = [];
      let ongoingShifts = [];
      let upcomingShifts = [];
      let unknownShifts = [];
      let noRouteShifts = [];

      // shift status determined by status' of all associated routes
      filteredShifts?.forEach((row) => {
        const routes = row.routes;

        const isCompleted = routes.every((route) => {
          return route.is_completed === 1;
        });

        const containsCompleted = routes.some((route) => route.is_completed === 1);
        const containsUpcoming = routes.some(
          (route) =>
            route.is_active === 0 && route.is_completed === 0 && route.current_stop_sequence === 0
        );

        const isActive =
          routes.find((route) => route.is_active === 1) || (containsCompleted && containsUpcoming);

        const isUpcoming = routes.every((route) => {
          return (
            route.is_active === 0 && route.is_completed === 0 && route.current_stop_sequence === 0
          );
        });

        if (isCompleted && routes.length > 0) {
          row.status = RouteStatus.COMPLETED;
          completedShifts.push(row);
        } else if (isActive) {
          row.status = RouteStatus.ONGOING;
          ongoingShifts.push(row);
        } else if (isUpcoming && routes.length > 0) {
          row.status = RouteStatus.UPCOMING;
          upcomingShifts.push(row);
        } else if (routes.length === 0) {
          row.status = RouteStatus.NO_ROUTES;
          noRouteShifts.push(row);
        } else {
          row.status = RouteStatus.UNKNOWN;
          unknownShifts.push(row);
        }
      });

      setShifts(filteredShifts);
      setShiftsAll(filteredShifts);
      setShiftsCompleted(completedShifts);
      setShiftsUpcoming(upcomingShifts);
      setShiftsOngoing(ongoingShifts);
      setShiftsUnknown(unknownShifts);
      setNoRouteShifts(noRouteShifts);
    } catch (err) {
      console.log(err);
      openSnackbar(t('load_driver_shifts_error'), colors.red);
    } finally {
      if (type === 'mount') {
        setLoading(false);
      }
    }
  };

  const setDemand = (type) => {
    setSearchType(type);
    setShifts(
      type === RouteStatus.ALL
        ? shiftsAll
        : type === RouteStatus.ONGOING
        ? shiftsOngoing
        : type === RouteStatus.UPCOMING
        ? shiftsUpcoming
        : type === RouteStatus.UNKNOWN
        ? shiftsUnknown
        : type === RouteStatus.NO_ROUTES
        ? noRouteShifts
        : shiftsCompleted
    );
  };

  const openSnackbar = (snackbarString, color) => {
    setSnackbar({ ...snackbar, open: true, message: snackbarString, color: color });
  };

  const closeSnackbar = (reason) => {
    if (reason !== 'clickaway') {
      setSnackbar({ ...snackbar, open: false });
    }
  };

  const handleSetSortModel = (sortModel) => {
    setSortModel(sortModel);
  };

  const setFilterDate = (val, type) => {
    let date;
    if (val) {
      date = new Date(val);
    }

    if (type === FilterType.START) {
      setFilterStartDate(date ? date : null);
      localStorage.setItem(LocalStorageKeys.ROUTE_SHIFT_MANAGEMENT_FILTER_START_DATE, date);
    }

    if (type === FilterType.END) {
      setFilterEndDate(date ? date : null);
      localStorage.setItem(LocalStorageKeys.ROUTE_SHIFT_MANAGEMENT_FILTER_END_DATE, date);
    }
  };

  const columnStylesSmall = { flex: 0.75, align: 'center', headerAlign: 'center' };
  const columnStyles = { flex: 1, align: 'center', headerAlign: 'center' };

  const columns = [
    { field: 'shift_id', headerName: t('shift_id'), ...columnStyles },
    {
      field: 'driver_name',
      headerName: t('driver_name'),
      valueGetter: (params) => {
        return params.row?.driver?.driver_name;
      },
      ...columnStyles
    },
    {
      field: 'bus_name',
      headerName: t('bus_name'),
      valueGetter: (params) => {
        return params.row?.bus?.bus_name;
      },
      ...columnStylesSmall
    },
    {
      field: 'start_time',
      headerName: t('start_time'),
      type: 'dateTime',
      valueFormatter: (params) => dateTimeFormat(params.value),
      ...columnStyles
    },
    {
      field: 'end_time',
      headerName: t('end_time'),
      type: 'dateTime',
      valueFormatter: (params) => dateTimeFormat(params.value),
      ...columnStyles
    },
    {
      field: 'status',
      headerName: t('route_status'),
      valueFormatter: (params) => t(`history_${params.value}`),
      sortable: false,
      disableColumnMenu: true,
      ...columnStyles
    },
    {
      field: 'button',
      headerName: t('actions'),
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <Box display="flex" flexDirection="row" justifyContent="space-evenly" width="100%">
          <Fab
            color="primary"
            size="small"
            variant="extended"
            onClick={() => {
              const shiftId = params.row?.shift_id;
              history.push(`/routesForShift/${shiftId}`);
            }}
          >
            <FontAwesomeIcon icon={faInfo} color="white" size="1x" />
          </Fab>
        </Box>
      ),
      sortable: false,
      disableColumnMenu: true,
      ...columnStylesSmall
    }
  ];

  let shiftsCopy;
  if (shifts) {
    shiftsCopy = shifts.map((shift, index) => {
      return {
        ...shift,
        id: index
      };
    });
  }

  return (
    <>
      <CustomSnackbar
        message={snackbar.message}
        open={snackbar.open}
        onClose={() => closeSnackbar()}
        snackbarColor={snackbar.color}
      />
      <div className="pageTitle">{t('route_management_title')}</div>
      <>
        <Paper>
          <Box display="flex" style={{ padding: '16px 0', gap: '32px' }}>
            <Box>
              <FormControl>
                <InputLabel>{t('status')}</InputLabel>
                <Select
                  value={searchType}
                  onChange={(e) => setDemand(e.target.value)}
                  style={{ width: '7rem' }}
                >
                  <MenuItem value={RouteStatus.ALL}>{t('history_all')}</MenuItem>
                  <MenuItem value={RouteStatus.UPCOMING}>{t('history_upcoming')}</MenuItem>
                  <MenuItem value={RouteStatus.ONGOING}>{t('history_ongoing')}</MenuItem>
                  <MenuItem value={RouteStatus.COMPLETED}>{t('history_completed')}</MenuItem>
                  <MenuItem value={RouteStatus.NO_ROUTES}>{t('history_no_routes')}</MenuItem>
                  <MenuItem value={RouteStatus.UNKNOWN}>{t('history_unknown')}</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box>
              <LocalizationProvider dateAdapter={MomentUtils}>
                <DatePicker
                  label={t('start')}
                  inputVariant="outlined"
                  value={filterStartDate ? filterStartDate : ' '}
                  onChange={(date) => setFilterDate(date?.format(), FilterType.START)}
                  style={{ width: '100%' }}
                  renderInput={(props) => {
                    return (
                      <TextField {...props} variant="outlined" helperText={null} error={false} />
                    );
                  }}
                />
              </LocalizationProvider>
            </Box>
            <Box style={{ paddingBottom: '0.5rem' }}>
              <LocalizationProvider dateAdapter={MomentUtils}>
                <DatePicker
                  label={t('end')}
                  inputVariant="outlined"
                  value={filterEndDate}
                  onChange={(date) => setFilterDate(date?.format(), FilterType.END)}
                  style={{ width: '100%' }}
                  renderInput={(props) => {
                    return (
                      <TextField {...props} variant="outlined" helperText={null} error={false} />
                    );
                  }}
                />
              </LocalizationProvider>
            </Box>
            <Box
              style={{
                paddingBottom: '0.5rem'
              }}
            >
              <ToggleButtonGroup
                value={dateFilterSelection}
                exclusive
                onChange={(e, newValue) => {
                  setDateFilterSelection(newValue);
                }}
                aria-label="date filter selection"
                style={{ height: '100%' }}
              >
                <ToggleButton
                  value={DateType.YESTERDAY}
                  aria-label={DateType.YESTERDAY}
                  style={{ color: colors.blaiseGray }}
                >
                  {t('yesterday')}
                </ToggleButton>
                <ToggleButton
                  value={DateType.TODAY}
                  aria-label={DateType.TODAY}
                  style={{ color: colors.blaiseGray }}
                >
                  {t('today')}
                </ToggleButton>
                <ToggleButton
                  value={DateType.TOMORROW}
                  aria-label={DateType.TOMORROW}
                  style={{ color: colors.blaiseGray }}
                >
                  {t('tomorrow')}
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>
          {loading && (
            <Box display="flex" justifyContent="center" className="emptyBox">
              <CircularProgress />
            </Box>
          )}
          {!loading && columns && shiftsCopy && (
            <DataGridTable
              columns={columns}
              rows={shiftsCopy}
              sortModel={sortModel}
              handleSetSortModel={handleSetSortModel}
              pageKey={LocalStorageKeys.ROUTE_SHIFT_MANAGEMENT_SORTED_COLUMN}
            />
          )}
        </Paper>
      </>
    </>
  );
};

export default withTranslation('common')(withAuth0(RouteShiftManagement));
