import React from 'react';
import create from 'zustand';
import shallow from 'zustand/shallow';

export const authStore = create((set) => ({
  accessToken: null,
  setAccessToken: (token) => set({ accessToken: token })
}));

export const authHOC = (BaseComponent) => (props) => {
  const store = authStore(
    (state) => ({
      accessToken: state.accessToken,
      setAccessToken: state.setAccessToken
    }),
    shallow
  );

  return <BaseComponent {...props} authStore={store} />;
};
