import React from 'react';
import colors from '../../assets/sass/colors';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const EditRequestWarning = (props) => {
  return (
    <Grid container spacing={2} id="tripModal" justify="center">
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          <FontAwesomeIcon
            icon={faExclamationCircle}
            size="lg"
            style={{ marginRight: '10px', color: colors.blaiseRed }}
          />
          {props.t('phone_request_edit_warning')}
        </Typography>
      </Grid>
      <Grid container item xs={12} justify="center">
        <Button
          style={{
            backgroundColor: colors.blaiseGreen,
            marginTop: '4%',
            color: 'white',
            width: '40%'
          }}
          onClick={() => props.modalSwitch(2)}
        >
          {props.t('continue')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default EditRequestWarning;
