import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import config from '../../config';

const StripeWrapper = ({ stripeAccount, children }) => {
  const [stripeObject, setStripeObject] = useState(null);

  useEffect(() => {
    const fetchStripeObject = async () => {
      const stripeAccountObj = stripeAccount ? { stripeAccount } : {};
      const stripePromise = loadStripe(`${config.stripeKey}`, stripeAccountObj);
      setStripeObject(stripePromise);
    };
    fetchStripeObject();
  }, [stripeAccount]);

  if (!stripeObject) {
    return <></>;
  }

  return <Elements stripe={stripeObject}>{children}</Elements>;
};

export default StripeWrapper;
