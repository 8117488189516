import React from 'react';
import { Alert, AlertTitle, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import ProgressDots from '../../components/UI/ProgressDots';
import colors from '../../assets/sass/colors';
import { DateTimePicker, LocalizationProvider } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import WalkTimeDuration from '../../components/TripRequest/WalkTimeDuration';
import { getArrivalTime, getDepartureTime } from '../../utils/MomentTime';
import { utcToZonedTime } from 'date-fns-tz';
import { addMinutes } from 'date-fns';

function PassengerTripDetailsModal(props) {
  return (
    <Grid container spacing={2} id="tripModal">
      {props.editRequestType !== 'rejected' && (
        <Grid item sm={12} align="right" style={{ margin: '-10px 0' }}>
          <Button onClick={() => props.modalSwitch(1)} style={{ color: '#AAA' }}>
            {props.t('back')}
          </Button>
        </Grid>
      )}
      <Grid item sm={12}>
        <h2>{props.t('trip_details')}</h2>
      </Grid>

      <Grid item sm={12}>
        <TextField
          variant="outlined"
          required
          fullWidth
          value={props.origin.name}
          onFocus={() => {
            props.component.setState({
              openMap: true,
              mapType: 'origin'
            });
          }}
          label={props.t('origin')}
          error={props.errorLocation === 'origin' || props.errorLocation === 'originAndDestination'}
          data-testid="origin"
        />
      </Grid>
      {props.travelTimeData?.finalTravelTime && (
        <WalkTimeDuration
          walkTime={props.travelTimeData?.walkTimeOriginToPickup}
          type="originToPickup"
          maxWalkingDistance={Number(props.transitAgency?.max_walking_distance)}
          stopTypeStrategy={props.travelTimeData?.pickupStopStrategy}
        />
      )}
      <Grid item sm={12}>
        <TextField
          variant="outlined"
          required
          fullWidth
          value={props.destination.name}
          onFocus={() => {
            props.component.setState({
              openMap: true,
              mapType: 'destination'
            });
          }}
          label={props.t('destination')}
          error={
            props.errorLocation === 'destination' || props.errorLocation === 'originAndDestination'
          }
          data-testid="destination"
        />
      </Grid>
      {props.travelTimeData?.finalTravelTime && (
        <WalkTimeDuration
          walkTime={props.travelTimeData?.walkTimeDropoffToDestination}
          type="dropoffToDestination"
          maxWalkingDistance={Number(props.transitAgency?.max_walking_distance)}
          stopTypeStrategy={props.travelTimeData?.dropoffStopStrategy}
        />
      )}
      {/* <Grid item sm={6}>
        <FormControl variant="outlined" fullWidth required >
          <InputLabel htmlFor="outlined-communication-native-simple">{props.t('communication_method')}</InputLabel>
          <Select
            native
            value={props.methodOfCommunication}
            onChange={(e) => {
              props.component.setState({ methodOfCommunication: e.target.value });
              
            }}
            label={props.t('communication_method')}
            inputProps={{
              name: 'communication',
              id: 'outlined-communication-native-simple',
            }}
            disabled={props.isSubmitting}
          >
            <option aria-label="None" value="" />
            <option value={'voice'}>{props.t('voice')}</option>
            <option value={'sms'}>{props.t('sms')}</option>
            <option value={'email'}>{props.t('email')}</option>
          </Select>
        </FormControl>
      </Grid> */}
      <Grid item sm={3}>
        <ToggleButtonGroup
          value={props.departureOrArrival ? props.departureOrArrival : 'departure'}
          exclusive
          onChange={(e, newValue) => {
            props.component.setState({ departureOrArrival: newValue });
          }}
          disabled={props.isSubmitting}
          aria-label="departureOrArrivalPicker"
        >
          <ToggleButton
            value="departure"
            aria-label="departure"
            disabled={props.isTripConfirmed && !props.editRequestMode}
            data-testid="departBtn"
          >
            {props.t('depart')}
          </ToggleButton>
          <ToggleButton
            value="arrival"
            aria-label="arrival"
            disabled={props.isTripConfirmed && !props.editRequestMode}
            data-testid="arriveBtn"
          >
            {props.t('arrive')}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item sm={9}>
        <LocalizationProvider dateAdapter={MomentUtils}>
          <DateTimePicker
            label={props.t('time')}
            inputVariant="outlined"
            value={
              props.time
                ? props.time
                : addMinutes(
                    utcToZonedTime(new Date().toISOString(), props.taTimezone),
                    props.minutesBeforeEarliestDeparture
                  )
            }
            onChange={(date) => {
              props.component.setState({ time: date, errorLocation: null });
            }}
            disabled={
              props.isSubmitting ||
              (props.isTripConfirmed && !props.editRequestMode) ||
              !props.origin ||
              !props.destination
            }
            style={{ width: '100%' }}
            error={props.errorLocation === 'time'}
            minDateTime={addMinutes(
              utcToZonedTime(new Date().toISOString(), props.taTimezone),
              props.minutesBeforeEarliestDeparture
            )}
            maxDateTime={addMinutes(
              utcToZonedTime(new Date().toISOString(), props.taTimezone),
              props.minutesForLatestDeparture
            )}
            renderInput={(props) => {
              return (
                <TextField
                  {...props}
                  variant="outlined"
                  fullWidth
                  helperText={null}
                  error={false}
                  data-testid="timeOfRideStart"
                />
              );
            }}
            data-testid="datePicker"
          />
        </LocalizationProvider>
      </Grid>

      {props.departureOrArrival === 'departure' &&
      props.destination &&
      props.origin &&
      props.travelTimeData ? (
        <Grid container display="flex" alignItems="center" style={{ margin: '5px' }}>
          <Typography
            variant="body2"
            style={{ paddingLeft: 10, size: '1rem', color: colors.blaiseGray }}
          >
            {props.t('passenger_arrival_time')}{' '}
            {getArrivalTime(
              props.travelTimeData.finalTravelTime / 60,
              props.time,
              props.taUtcOffset
            )}
          </Typography>
        </Grid>
      ) : (
        <></>
      )}

      {props.departureOrArrival === 'arrival' &&
      props.destination &&
      props.origin &&
      props.travelTimeData ? (
        <Grid container sm={12} display="flex" alignItems="center" style={{ margin: '5px' }}>
          <Typography
            variant="body2"
            style={{ paddingLeft: 10, size: '1rem', color: colors.blaiseGray }}
          >
            {props.t('passenger_departure_time')}{' '}
            {getDepartureTime(
              props.travelTimeData.finalTravelTime / 60,
              props.time,
              props.taUtcOffset
            )}
          </Typography>
        </Grid>
      ) : (
        <></>
      )}
      {!props.editRequestMode && (
        <Grid item sm={12}>
          <FormControlLabel
            value="returnTrip"
            control={
              <Checkbox
                color="primary"
                checked={props.returnTrip}
                onChange={(e) => {
                  const checked = e.target.checked;
                  if (checked) {
                    props.component.setState({
                      returnTrip: e.target.checked,
                      returnOrigin: props.destination,
                      returnDest: props.origin
                    });
                  } else {
                    props.component.setState({
                      returnTrip: e.target.checked,
                      returnOrigin: null,
                      returnDest: null,
                      returnTime: null
                    });
                  }
                }}
                data-testid="returnInput"
              />
            }
            label={props.t('return_trip')}
          />
        </Grid>
      )}
      {props.returnTrip && (
        <>
          <Grid item sm={3}>
            <ToggleButtonGroup
              value={props.returnDepartureOrArrival ? props.returnDepartureOrArrival : 'departure'}
              exclusive
              onChange={(e, newValue) => {
                props.component.setState({ returnDepartureOrArrival: newValue });
              }}
              disabled={props.isSubmitting}
              aria-label="returnDepartureOrArrivalPicker"
            >
              <ToggleButton
                value="departure"
                aria-label="departure"
                disabled={props.isTripConfirmed}
                data-testid="departBtn2"
              >
                {props.t('depart')}
              </ToggleButton>
              <ToggleButton
                value="arrival"
                aria-label="arrival"
                disabled={props.isTripConfirmed}
                data-testid="arriveBtn2"
              >
                {props.t('arrive')}
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item sm={9}>
            <LocalizationProvider dateAdapter={MomentUtils}>
              <DateTimePicker
                label={props.t('time')}
                inputVariant="outlined"
                value={
                  props.returnTime
                    ? props.returnTime
                    : addMinutes(
                        utcToZonedTime(new Date().toISOString(), props.taTimezone),
                        props.minutesBeforeEarliestDeparture
                      )
                }
                onChange={(date) => {
                  props.component.setState({
                    returnTime: date,
                    errorLocation: null
                  });
                }}
                disabled={
                  props.isSubmitting || props.isTripConfirmed || !props.origin || !props.destination
                }
                style={{ width: '100%' }}
                error={props.errorLocation === 'time'}
                minDateTime={addMinutes(
                  utcToZonedTime(new Date().toISOString(), props.taTimezone),
                  props.minutesBeforeEarliestDeparture
                )}
                maxDateTime={addMinutes(
                  utcToZonedTime(new Date().toISOString(), props.taTimezone),
                  props.minutesForLatestDeparture
                )}
                renderInput={(props) => {
                  return (
                    <TextField
                      {...props}
                      variant="outlined"
                      fullWidth
                      helperText={null}
                      error={false}
                      data-testid="timeOfReturn"
                    />
                  );
                }}
              />
            </LocalizationProvider>
          </Grid>

          {props.returnDepartureOrArrival === 'departure' &&
          props.returnTime &&
          props.destination &&
          props.origin &&
          props.travelTimeData ? (
            <Grid container sm={12} display="flex" alignItems="center" style={{ margin: '5px' }}>
              <Typography
                variant="body2"
                style={{ paddingLeft: 10, size: '1rem', color: colors.blaiseGray }}
              >
                {props.t('passenger_arrival_time')}{' '}
                {getArrivalTime(props.travelTimeData.finalTravelTime / 60, props.returnTime)}
              </Typography>
            </Grid>
          ) : (
            <></>
          )}

          {props.returnDepartureOrArrival === 'arrival' &&
          props.returnTime &&
          props.destination &&
          props.origin &&
          props.travelTimeData ? (
            <Grid container sm={12} display="flex" alignItems="center" style={{ margin: '5px' }}>
              <Typography
                variant="body2"
                style={{ paddingLeft: 10, size: '1rem', color: colors.blaiseGray }}
              >
                {props.t('passenger_departure_time')}{' '}
                {getDepartureTime(props.travelTimeData.finalTravelTime / 60, props.returnTime)}
              </Typography>
            </Grid>
          ) : (
            <></>
          )}
        </>
      )}
      {!props.editRequestMode && (
        <Grid item sm={12}>
          <Alert severity="info" icon={false}>
            <AlertTitle>{props.t('passenger_language_pref')}</AlertTitle>
            {props.selectedPassenger?.language === 'fr' || props.data?.language === 'fr'
              ? props.t('french')
              : props.t('english')}
          </Alert>
        </Grid>
      )}
      {/* <Grid item sm={6} style={{ marginTop: '1.1em' }}>
        <FormControl variant="outlined" fullWidth required>
          <InputLabel htmlFor="outlined-language-native-simple">{props.t('language')}</InputLabel>
          <Select
            native
            value={props.language}
            onChange={(e) => {
              props.component.setState({ language: e.target.value });
            }}
            label={props.t('language')}
            inputProps={{
              name: 'language',
              id: 'outlined-language-native-simple'
            }}
            disabled={props.isSubmitting}
          >
            <option aria-label="None" value="" />
            <option value={'en'}>{props.t('english')}</option>
            <option value={'fr'}>{props.t('french')}</option>
          </Select>
        </FormControl>
      </Grid> */}
      {/* <Grid item sm={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={props.paratransit}
              onChange={(e) => {
                if (!props.editing) {
                  // only change paratransit and let the thing be changed check 
                  props.component.setState({ paratransit: e.target.checked })
                }
              }}
              name="paratransit"
              color="primary"
              id='paratransit'
            />
          }
          label={props.t('reduced_mobility')}
        />
      </Grid> */}
      <Grid item sm={12}>
        <h2>{props.t('notes')}</h2>
      </Grid>
      <Grid item sm={12}>
        <TextField
          placeholder="..."
          multiline
          id="notes"
          variant="outlined"
          required
          fullWidth
          rows={5}
          value={props.notes}
          inputProps={{
            maxLength: 1000
          }}
          onChange={(e) => {
            props.component.setState({ notes: e.target.value });
          }}
          FormHelperTextProps={{
            style: { textAlign: 'right' }
          }}
          helperText={`${props.notes?.length}/1000`}
          disabled={props.isSubmitting}
        />
      </Grid>
      <ProgressDots
        currentStep={props.progress.currentStep}
        totalSteps={props.progress.totalSteps}
      />
      {props.isSubmitting || (props.editRequestMode && !props.travelTimeData) ? (
        <Grid container item xs={12} justify="center">
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <Grid item sm={12} style={{ textAlign: 'center' }}>
            <Button
              color="primary"
              variant="contained"
              onClick={props.validateStepTwo}
              data-testid="toStep3"
            >
              {/* TODO: revisit props.component.logic Mike */}
              {props.t('next')}
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default PassengerTripDetailsModal;
