import React, { Component, Fragment } from 'react';
import { withAuth0 } from '@auth0/auth0-react'; // `this.props.auth0` has all the same properties as the `useAuth0` hook
import { isEqual } from 'lodash';
import { withTranslation } from 'react-i18next';
import { faPen, faTrash, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomSnackbar from '../../components/Snackbar/CustomSnackbar';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import colors from '../../assets/sass/colors';
import './EmployeeTable.scss';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { withKaboom } from '../../higherOrderComponents/withKaboom';
import DataGridTable from '../../components/DataGrid/DataGridTable';
import ModalAdd from '../../components/Modals/ModalAdd';
import { currentUserHOC } from '../../store/user';
import authenticatedAxiosInstance from '../../axios/axios-authorized';

class EmployeeTable extends Component {
  constructor(props) {
    super(props);

    this.blankEmployee = {
      creation_date: '',
      email: '',
      transit_agency_id: null,
      transit_agency_user_id: '',
      transit_agency_user_name: '',
      employee_identifier: ''
    };

    this.editingEmployeeInitialData = {};

    this.state = {
      employees: null,
      addMode: false,
      editMode: false,
      deleteMode: false,
      snackbar: {
        open: false,
        message: ''
      },
      editIndex: null,
      editingEmployee: {},
      employeeToBeDeleted: null,
      isLoading: false,
      modalOpen: false,
      newEmployee: { ...this.blankEmployee }
    };

    this.selectOptions = [
      {
        id: 'transit_agency_admin',
        value: 'transit_agency_admin',
        label: this.props.t('transit_agency_admin')
      },
      {
        id: 'transit_agency_agent',
        value: 'transit_agency_agent',
        label: this.props.t('transit_agency_agent')
      },
      {
        id: 'operator',
        value: 'operator',
        label: this.props.t('operator')
      }
    ];
  }

  componentDidMount = async () => {
    document.title = `${this.props.t('employee_management_title')} - ${this.props.t(
      'trip_source_engine'
    )}`;
    this.fetchEmployees();
  };

  fetchEmployees = async () => {
    try {
      const response = await authenticatedAxiosInstance.axios.get(`/employees`);

      const employees = Object.entries(response.data).map((array) => ({
        //array is composed of [arbitrary id, key/value pairs of the response]
        ...array[1],
        transit_agency_user_id: array[1].transit_agency_user_id,
        roles: array[1].roles && array[1].roles.map((role) => this.props.t(role)).join(', '),
        rolesId: array[1].roles && array[1].roles.map((role) => role).join(', ')
      }));

      this.setState({
        employees
      });
    } catch (err) {
      if (err.response.status === 403) {
        alert('You do not have the permissions required to access this page.');
      }
    }
  };
  toggleAdd = () => {
    this.setState((prevState) => ({
      addMode: !prevState.addMode,
      newEmployee: { ...this.blankEmployee },
      modalOpen: !this.state.modalOpen
    }));
  };

  toggleEdit = (index) => {
    this.setState((prevState) => ({
      editMode: !prevState.editMode,
      editIndex: index,
      editingEmployee: !prevState.editMode
        ? { ...prevState.employees[index] }
        : { ...this.blankEmployee }
    }));
  };

  toggleCancel = () => {
    let tempState = [...this.state.employees];
    this.setState(
      (prevState) => ({
        editMode: !prevState.editMode,
        editIndex: null,
        editingEmployee: { ...this.blankEmployee },
        employees: null
      }),
      () => {
        this.setState({
          employees: tempState
        });
      }
    );
  };

  toggleDelete = (index, employee) => {
    this.setState((prevState) => ({
      deleteMode: !prevState.deleteMode,
      editIndex: index,
      employeeToBeDeleted: employee
    }));
  };

  validateRow = (employee) => {
    let isValid = false;
    try {
      if (
        !employee.first_name?.trim() ||
        !employee.last_name?.trim() ||
        !employee.email?.trim() ||
        !employee.roles ||
        !employee.employee_identifier?.trim()
      )
        throw new Error(this.props.t('required_field'));
      isValid = true;
    } catch (err) {
      this.openSnackbar(err.message, colors.red);
    }
    return isValid;
  };

  createEmployee = async () => {
    const employee = this.state.newEmployee;
    if (!this.validateRow(employee)) return;

    try {
      const reqBody = {
        employee_identifier: employee.employee_identifier,
        first_name: employee.first_name,
        last_name: employee.last_name,
        email: employee.email,
        role: employee.roles
      };

      this.setState({ isLoading: true });

      const result = await authenticatedAxiosInstance.axios.post(`/employees/`, reqBody);

      this.setState(
        (prevState) => {
          const employeesCopy = [...prevState.employees];
          employee['transit_agency_user_id'] = result.data.userId;
          employee['roles'] = this.props.t(employee['roles']);
          employeesCopy.push(employee);

          return {
            employees: employeesCopy,
            modalOpen: !this.state.modalOpen
          };
        },
        () => this.setState({ isLoading: false, addMode: !this.state.addMode })
      );

      this.openSnackbar(this.props.t('employee_create_success'), colors.blaiseGreen);
    } catch (error) {
      this.props.kaboom(error, colors.red);
      this.setState({ isLoading: false });
    }
  };

  updateEmployee = async (event, editIndex) => {
    const { currentUser } = this.props.currentUserStore;
    const employee = this.state.editingEmployee;
    const employeeIndex = editIndex;

    if (!this.validateRow(employee)) return;

    if (isEqual(employee, this.state.employees[employeeIndex])) {
      this.openSnackbar(this.props.t('no_changes'), colors.blaiseGray);
      this.toggleEdit();
      return;
    }

    try {
      const reqBody = {
        transit_agency_user_id: employee.transit_agency_user_id,
        editing_user_id: currentUser.transit_agency_user_id,
        transit_agency_user_name: employee.transit_agency_user_name,
        employee_identifier: employee.employee_identifier,
        first_name: employee.first_name,
        last_name: employee.last_name,
        email: employee.email,
        role: employee.rolesId
      };

      this.setState({ isLoading: true });
      await authenticatedAxiosInstance.axios.put(
        `/employees/${encodeURIComponent(employee.transit_agency_user_id)}`,
        reqBody
      );

      this.setState(
        (prevState) => {
          const employeesCopy = [...prevState.employees];
          employee['roles'] = this.props.t(employee['roles']);
          employeesCopy.splice(employeeIndex, 1, employee);

          return {
            employees: employeesCopy
          };
        },
        () => this.setState({ isLoading: false })
      );

      this.toggleEdit();

      this.openSnackbar(this.props.t('employee_modify_success'), colors.blaiseGreen);
    } catch (error) {
      this.props.kaboom(error, colors.red);
      this.setState({ isLoading: false });
    }
  };

  deleteEmployee = async () => {
    const employeeIndex = this.state.editIndex;

    authenticatedAxiosInstance.axios
      .delete(
        `/employees/${encodeURIComponent(
          this.state.employees[employeeIndex].transit_agency_user_id
        )}`,
        {
          data: {
            transit_agency_user_id: this.state.employeeToBeDeleted.transit_agency_user_id
          }
        }
      )
      .then(() => {
        this.setState((prevState) => {
          const employeesCopy = [...prevState.employees];
          employeesCopy.splice(prevState.editIndex, 1);
          return {
            employees: employeesCopy
          };
        });
        this.toggleDelete();

        this.openSnackbar(this.props.t('employee_delete_success'), colors.blaiseGreen);
      })
      .catch(() => {
        this.openSnackbar(this.props.t('employee_delete_error'), colors.red);
      });
  };

  handleChange = (event, column) => {
    const target = event.target;
    const value = target.value;

    this.setState((prevState) => {
      const employeeCopy = { ...prevState.newEmployee };
      employeeCopy[column] = value;
      return { newEmployee: employeeCopy };
    });
  };

  handleEditCellChange = ({ field, props }) => {
    this.setState((prevState) => {
      const employeeCopy = { ...prevState.editingEmployee };
      employeeCopy[field] = props.value;
      return { editingEmployee: employeeCopy };
    });
  };

  renderDefaultIcons = (employeeIndex, employee) => {
    return (
      <Box display="flex" flexDirection="row" justifyContent="space-evenly" width={1}>
        <Fab
          color="primary"
          size="small"
          className="editButton"
          variant="extended"
          onClick={() => this.toggleEdit(employeeIndex)}
        >
          <FontAwesomeIcon icon={faPen} color={colors.white} size="1x" className="editIcon" />
        </Fab>
        <Fab
          color="secondary"
          size="small"
          className="deleteButton"
          variant="extended"
          onClick={() => this.toggleDelete(employeeIndex, employee)}
        >
          <FontAwesomeIcon icon={faTrash} color={colors.white} size="1x" className="deleteIcon" />
        </Fab>
      </Box>
    );
  };

  // Renders the action icons in editMode
  renderEditIcons = (employeeIndex) => {
    if (this.state.editIndex === employeeIndex) {
      return (
        <Box display="flex" flexDirection="row" justifyContent="space-evenly" width={1}>
          <Fab
            color="primary"
            size="small"
            className="editButton"
            variant="extended"
            onClick={(e) => this.updateEmployee(e, this.state.editIndex)}
          >
            <FontAwesomeIcon icon={faCheck} color={colors.white} size="1x" className="editIcon" />
          </Fab>
          <Fab
            size="small"
            className="deleteButton"
            variant="extended"
            onClick={() => this.toggleCancel()}
          >
            <FontAwesomeIcon icon={faTimes} color={colors.white} size="1x" className="deleteIcon" />
          </Fab>
        </Box>
      );
    }
  };

  openSnackbar = (snackbarString, color) => {
    this.setState({
      snackbar: {
        open: true,
        message: snackbarString,
        color: color
      }
    });
  };

  // Snackbar closes except for when user clicks outside of component
  closeSnackbar = (reason) => {
    if (reason !== 'clickaway') {
      this.setState({ snackbar: { open: false } });
    }
  };

  handleModalClose = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
      addMode: !this.state.addMode
    });
  };

  renderEmployeeRoleOptions = () => {
    const handleEditRoleChange = (event) => {
      const role = event.target.value;

      this.setState((prevState) => {
        const employeeCopy = { ...prevState.editingEmployee };
        employeeCopy['roles'] = role;
        employeeCopy['rolesId'] = role;
        return { editingEmployee: employeeCopy };
      });
    };

    return (
      <TextField
        name="roles"
        id="employee_roles"
        select
        value={this.state.editingEmployee.roles ? this.state.editingEmployee.roles : ' '}
        fullWidth
        onClick={(event) => handleEditRoleChange(event)}
      >
        {this.selectOptions.map((option) => (
          <MenuItem value={option.value} key={`option_${option.id}`}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  render() {
    const columnStyle = { flex: 1, align: 'center', headerAlign: 'center', editable: true };

    const columns = [
      { field: 'employee_identifier', headerName: this.props.t('employee_id'), ...columnStyle },
      { field: 'first_name', headerName: this.props.t('first_name'), ...columnStyle },
      { field: 'last_name', headerName: this.props.t('last_name'), ...columnStyle },
      { field: 'email', headerName: this.props.t('email'), ...columnStyle },
      {
        field: 'roles',
        headerName: this.props.t('employee_roles'),
        ...columnStyle,
        renderEditCell: this.renderEmployeeRoleOptions
      },
      {
        field: 'button',
        headerName: this.props.t('actions'),
        renderCell: (params) =>
          !this.state.addMode &&
          (this.state.editMode
            ? this.renderEditIcons(params.id)
            : this.renderDefaultIcons(params.id, params.row)),
        sortable: false,
        ...columnStyle,
        flex: 0.5,
        disableColumnMenu: true
      }
    ];

    // copy of drivers with id to pass to datagrid
    let employeesCopy;
    if (this.state.employees) {
      employeesCopy = this.state.employees.map((employee, index) => {
        return {
          ...employee,
          id: index
        };
      });
    }

    return (
      <Fragment>
        <div className="pageTitle">{this.props.t('employee_management_title')}</div>
        {this.state.modalOpen && (
          <ModalAdd
            {...this.props}
            modalOpen={this.state.modalOpen}
            modalTitle={this.props.t('add_employee')}
            modalFields={['employee_identifier', 'first_name', 'last_name', 'email', 'roles']}
            modalExtraFields={{ roles: this.selectOptions }}
            handleModalAdd={this.createEmployee}
            handleChange={this.handleChange}
            handleModalClose={this.handleModalClose}
          />
        )}
        <Paper>
          <Box display="flex" justifyContent="flex-end" style={{ padding: '16px 0' }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={this.toggleAdd}
              data-testid="add-employee-button"
            >
              {this.props.t('add_employee')}
            </Button>
          </Box>
          <div>
            {(!this.state.employees || this.state.isLoading) && (
              <Box display="flex" justifyContent="center" className="emptyBox">
                <CircularProgress />
              </Box>
            )}
            {!this.state.isLoading && columns && employeesCopy && (
              <DataGridTable
                columns={columns}
                rows={employeesCopy}
                editIndex={this.state.editIndex}
                handleEditCellChange={this.handleEditCellChange}
              />
            )}
          </div>
        </Paper>
        <CustomSnackbar
          message={this.state.snackbar.message}
          open={this.state.snackbar.open}
          onClose={this.closeSnackbar}
          snackbarColor={this.state.snackbar.color}
        />
        <DeleteModal
          open={this.state.deleteMode}
          toggleModal={this.toggleDelete}
          deleteId={this.state.editIndex}
          snackbar={this.openSnackbar}
          onDeleteItem={this.deleteEmployee}
          deleteMessage={this.props.t('confirm_delete_employee')}
        />
      </Fragment>
    );
  }
}

export default withTranslation('common')(withAuth0(currentUserHOC(withKaboom(EmployeeTable))));
