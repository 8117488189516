import React from 'react';
import { Button, Grid, Typography, Box } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { dateTimeFormatWithOffset } from '../../../utils/MomentTime';
import { useTranslation } from 'react-i18next';
import colors from '../../../assets/sass/colors';

const ConflictsStep = ({ errors, taTimezoneOffset, goToStep }) => {
  const { t } = useTranslation('common');

  return (
    <Grid className="recurrentModal">
      <Grid
        item
        sm={12}
        style={{
          padding: '1rem 1.5rem',
          borderBottom: `1px solid ${colors.gray300}`
        }}
      >
        <Typography
          style={{
            color: colors.gray800,
            fontWeight: 'bold',
            fontSize: '1.25rem'
          }}
        >
          {t('create_recurrent_driver_shift')}
        </Typography>
      </Grid>

      <Grid style={{ overflowY: 'auto' }}>
        <Grid container style={{ padding: '1rem 1.5rem 0 1.5rem', gap: '1rem' }}>
          <Grid item sm={12}>
            <Alert severity="error">
              <AlertTitle>{t('conflicts')}</AlertTitle>
              {t('recurring_shifts_conflicts')}
            </Alert>
          </Grid>

          {/* Bus Overlaps */}
          {errors.length > 0 && errors[0].length > 0 && (
            <>
              <Grid item>
                <Typography style={{ color: colors.gray700, fontWeight: 600, fontSize: '18px' }}>
                  {t('bus_overlaps')}
                </Typography>
              </Grid>

              <Grid item sm={12} style={{ display: 'flex', paddingBottom: '0.2rem' }}>
                <Grid sm={5}>
                  <span className="overlapLabels">{t('start')}</span>
                </Grid>

                <Grid sm={5}>
                  <span className="overlapLabels">{t('end')}</span>
                </Grid>

                <Grid sm={2}>
                  <span className="overlapLabels">{t('bus')}</span>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>

        {errors.length > 0 &&
          errors[0].length > 0 &&
          errors[0][0].extraDetails.overlaps.map((overlap, idx) => (
            <Grid
              key={idx}
              style={{ marginBottom: '1rem', borderBottom: `1px solid ${colors.gray300}` }}
            >
              <Grid sm={12} className="shiftDetailsRow">
                <Grid sm={5}>
                  <span className="overlapText">
                    {dateTimeFormatWithOffset(overlap.new.start_time, taTimezoneOffset)}
                  </span>
                </Grid>

                <Grid sm={5}>
                  <span className="overlapText">
                    {dateTimeFormatWithOffset(overlap.new.end_time, taTimezoneOffset)}
                  </span>
                </Grid>

                <Grid sm={2}>
                  <span className="overlapText">{overlap.new.bus_name || overlap.new.bus_id}</span>
                </Grid>
              </Grid>

              <Grid sm={12} className="shiftDetailsRow">
                <Grid sm={5}>
                  <span className="overlapText">
                    {dateTimeFormatWithOffset(overlap.existing.start_time, taTimezoneOffset)}
                  </span>
                </Grid>

                <Grid sm={5}>
                  <span className="overlapText">
                    {dateTimeFormatWithOffset(overlap.existing.end_time, taTimezoneOffset)}
                  </span>
                </Grid>

                <Grid sm={2}>
                  <span className="overlapText">
                    {overlap.existing.bus.bus_name || overlap.existing.bus_id}
                  </span>
                </Grid>
              </Grid>
            </Grid>
          ))}

        <Grid container style={{ padding: '1rem 1.5rem 0 1.5rem', gap: '1rem' }}>
          {/* Driver Overlaps */}
          {errors.length > 0 && errors[1].length > 0 && (
            <>
              <Grid item>
                <Typography style={{ color: colors.gray700, fontWeight: 600, fontSize: '18px' }}>
                  {t('driver_overlaps')}
                </Typography>
              </Grid>

              <Grid item sm={12} style={{ display: 'flex', paddingBottom: '0.2rem' }}>
                <Grid sm={5}>
                  <span className="overlapLabels">{t('start')}</span>
                </Grid>

                <Grid sm={5}>
                  <span className="overlapLabels">{t('end')}</span>
                </Grid>

                <Grid sm={2}>
                  <span className="overlapLabels">{t('driver')}</span>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>

        {errors.length > 0 &&
          errors[1].length > 0 &&
          errors[1][0].extraDetails.overlaps.map((overlap, idx) => (
            <Grid
              key={idx}
              style={{ marginBottom: '1rem', borderBottom: `1px solid ${colors.gray300}` }}
            >
              <Grid sm={12} className="shiftDetailsRow">
                <Grid sm={5}>
                  <span className="overlapText">
                    {dateTimeFormatWithOffset(overlap.new.start_time, taTimezoneOffset)}
                  </span>
                </Grid>

                <Grid sm={5}>
                  <span className="overlapText">
                    {dateTimeFormatWithOffset(overlap.new.end_time, taTimezoneOffset)}
                  </span>
                </Grid>

                <Grid sm={2}>
                  <span className="overlapText">
                    {overlap.new.driver_name || overlap.new.driver_id}
                  </span>
                </Grid>
              </Grid>

              <Grid sm={12} className="shiftDetailsRow">
                <Grid sm={5}>
                  <span className="overlapText">
                    {dateTimeFormatWithOffset(overlap.existing.start_time, taTimezoneOffset)}
                  </span>
                </Grid>

                <Grid sm={5}>
                  <span className="overlapText">
                    {dateTimeFormatWithOffset(overlap.existing.end_time, taTimezoneOffset)}
                  </span>
                </Grid>

                <Grid sm={2}>
                  <span className="overlapText">
                    {overlap.existing.driver.driver_name || overlap.existing.driver_id}
                  </span>
                </Grid>
              </Grid>
            </Grid>
          ))}
      </Grid>

      <Box
        display="flex"
        justifyContent="flex-end"
        gridGap="1rem"
        style={{
          borderTop: `1px solid ${colors.gray300}`,
          padding: '1rem 1.5rem'
        }}
      >
        <Button onClick={() => goToStep(1)} className="backBtn">
          {t('back')}
        </Button>
      </Box>
    </Grid>
  );
};

export default ConflictsStep;
