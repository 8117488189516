import React from 'react';
import create from 'zustand';
import shallow from 'zustand/shallow';

export const transitAgencyStore = create((set) => ({
  features: null,
  setFeatures: (features) => set({ features }),
  geoJson: null,
  setGeoJson: (geoJson) => set({ geoJson })
}));

export const transitAgencyStoreHOC = (BaseComponent) => (props) => {
  const store = transitAgencyStore(
    (state) => ({
      features: state.features,
      setFeatures: state.setFeatures,
      geoJson: state.geoJson,
      setGeoJson: state.setGeoJson
    }),
    shallow
  );

  return <BaseComponent {...props} transitAgencyStore={store} />;
};
