import authenticatedAxiosInstance from '../axios/axios-authorized';
import moment from 'moment';
import axios from 'axios';
import config from '../config';

/** Get the pickup and dropoff windows of a transit agency
 @param {*} TAId the ID of the transit agency you want to compare the browser time with
 @returns an object containing phone in and non phone in windows for pickup and dropoff times
 */

export const getPickupDropoffWindows = async (TAId) => {
  try {
    const transitAgencyDetails = (
      await authenticatedAxiosInstance.axios.get(`/transitagencies/${TAId}`)
    ).data;

    const windows = {
      phone_in_pickup_window: moment
        .duration(transitAgencyDetails?.phone_in_pickup_window)
        .asMinutes(),
      phone_in_dropoff_window: moment
        .duration(transitAgencyDetails?.phone_in_dropoff_window)
        .asMinutes(),
      pickup_window: moment.duration(transitAgencyDetails?.pickup_window).asMinutes(),
      dropoff_window: moment.duration(transitAgencyDetails?.dropoff_window).asMinutes()
    };

    return windows;
  } catch (err) {
    return err;
  }
};

export const getWalkingDurationDistance = async (coordOrigin, coordDest) => {
  try {
    const walkingTime = (
      await authenticatedAxiosInstance.axios.get(
        `/walkingDetails/${Number(coordOrigin.lon)},${Number(coordOrigin.lat)};${Number(
          coordDest.lon
        )},${Number(coordDest.lat)}`
      )
    ).data;

    const durationInSeconds = Number(walkingTime?.routes[0]?.duration);
    const durationInMinutes = Math.ceil(durationInSeconds / 60); // round up
    const distanceInMeters = Math.round(walkingTime?.routes[0]?.distance);

    return { durationInMinutes, distanceInMeters };
  } catch (error) {
    console.log(`transitAgencyUtils::getWalkingDurationDistance - ${error}`);
  }
};

// Get walking path between two sets of coords
export const getWalkingRoute = async (coordsOrigin, coordsDest) => {
  const routeBody = {
    p1: { lon: Number(coordsOrigin.lon), lat: Number(coordsOrigin.lat) },
    p2: { lon: Number(coordsDest.lon), lat: Number(coordsDest.lat) },
    walking: true
  };
  try {
    const routeResponse = (await authenticatedAxiosInstance.axios.post(`/route`, routeBody)).data;
    return routeResponse;
  } catch (err) {
    console.log(`transitAgencyUtils::getWalkingRoute - ${err}`);
  }
};
