import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faMapMarker, faWalking } from '@fortawesome/free-solid-svg-icons';
import ReactMapboxGl, { GeoJSONLayer, Marker, Layer, Feature } from 'react-mapbox-gl';
import { withAuth0 } from '@auth0/auth0-react';
import authenticatedAxiosInstance from '../../axios/axios-authorized';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  CircularProgress
} from '@material-ui/core';
import colors from '../../assets/sass/colors';
import config from '../../config';
import { lineString, point } from '@turf/turf';
import {
  dateOnlyFormat,
  dateTimeFormat,
  timeOnlyFormat,
  dateTimeFormatWithOffset,
  addSubtractMinutesToTime,
  dateTimeFormatWithTimezone,
  addSubtractMinutesToTimeNoFormatting
} from '../../utils/MomentTime';
import {
  getPickupDropoffWindows,
  getWalkingDurationDistance,
  getWalkingRoute
} from '../../utils/transitAgencyUtils';
import { currentUserStore } from '../../store/user';
import { utcToZonedTime } from 'date-fns-tz';
import './RequestByPhoneDetails.scss';
import { transitAgencyStore } from '../../store/transitAgency';
import { FeaturesType } from '../../enums/featuresType';
import { TripType } from '../../enums/tripType';
import { useParams } from 'react-router-dom';
import { getCenter, getRouteColor } from '../../utils/mapHelpers';
import { displayWindowRange } from '../../utils/dateTimeFormat';
import AdditionalTripDetails from '../../components/AdditionalTripDetails/AdditionalTripDetails';
import { compareBrowserAndAgencyTZ } from '../../utils/timezoneUtils';

const Map = ReactMapboxGl({
  accessToken: config.mapBoxToken
});

const communicationMethod = Object.freeze({
  EMAIL: 'email',
  SMS: 'sms',
  VOICE: 'voice'
});

const BROWSER_LANG = navigator.language.substring(0, 2);

const RequestByPhoneDetails = () => {
  const { tripId } = useParams();
  const [tripDetails, setTripDetails] = useState(null);
  const [routes, setRoutes] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [needsCall, setNeedsCall] = useState(false);
  const [walkingDistanceAndDuration, setWalkingDistanceAndDuration] = useState([]);
  const [timeWindows, setTimeWindows] = useState(null);
  const [taTimezone, setTaTimezone] = useState(''); // Pass into AdditionalTripDetails
  const [taUtcOffset, setTaUtcOffset] = useState(0); // Pass into AdditionalTripDetails
  const [hasTransfersFeature, setHasTransfersFeature] = useState(false);
  const { currentUser } = currentUserStore();
  const { features, geoJson } = transitAgencyStore();
  const { t } = useTranslation('common');

  useEffect(() => {
    getTripDetails();
    getTimezoneDetails();

    if (currentUser.transit_agency_id) {
      getWindows();
    }
  }, []);

  useEffect(() => {
    if (tripDetails) {
      getMapRoutesAndMarkers();
    }

    // Only get walking info if the trip has confirmed details (legs or ride proposal)
    if (tripDetails?.legs?.length > 0 || tripDetails?.ride_proposals?.length > 0) {
      getTripWalkingDurationAndDistance();
    }
  }, [tripDetails]);

  useEffect(() => {
    const transitAgencyHasTransfersFeature = features?.some(
      (feature) => feature.name === FeaturesType.TRANSFERS
    );

    setHasTransfersFeature(transitAgencyHasTransfersFeature);
  }, [features]);

  const getTimezoneDetails = async () => {
    // check if there is timezone mismatch first
    try {
      const timezoneDetails = await compareBrowserAndAgencyTZ(currentUser.transit_agency_id);

      setTaUtcOffset(timezoneDetails.taTimezoneOffset);
      setTaTimezone(timezoneDetails.taTimezone);
    } catch (err) {
      console.log(err);
    }
  };

  const getTripWalkingDurationAndDistance = async () => {
    const { legs, origin_lon, origin_lat, dest_lat, dest_lon, ride_proposals } = tripDetails;
    const walkingDetails = [];

    try {
      // Origin to first pickup walking details for both transfer and non-transfer trips
      const originCoords = { lon: origin_lon, lat: origin_lat };
      const firstPickupCoords = {
        lon: legs[0]?.pickupStopId?.lon || ride_proposals[0].pickup_stop.lon,
        lat: legs[0]?.pickupStopId?.lat || ride_proposals[0].pickup_stop.lat
      };
      const originPickupWalkingDetails = await getWalkingDurationDistance(
        originCoords,
        firstPickupCoords
      );

      walkingDetails.push(originPickupWalkingDetails);

      if (legs.length > 0) {
        // Transfer trips legs and dropoff to destination walking details
        for (let i = 0; i < legs.length; i++) {
          if (legs[i + 1]) {
            // There is another leg, so calculate walking distance between dropoff and next pickup.
            const legDropoffCoords = {
              lon: legs[i].dropoffStopId.lon,
              lat: legs[i].dropoffStopId.lat
            };
            const nextLegPickupCoords = {
              lon: legs[i + 1].pickupStopId.lon,
              lat: legs[i + 1].pickupStopId.lat
            };

            const legDropoffToNextPickupWalkingDetails = await getWalkingDurationDistance(
              legDropoffCoords,
              nextLegPickupCoords
            );

            walkingDetails.push(legDropoffToNextPickupWalkingDetails);
          } else {
            // There are no more legs, so calculate walking distance between dropoff and destination.
            const lastLegDropoffCoords = {
              lon: legs[i].dropoffStopId.lon,
              lat: legs[i].dropoffStopId.lat
            };
            const destinationCoords = {
              lon: dest_lon,
              lat: dest_lat
            };
            const dropoffToDestinationWalkingDetails = await getWalkingDurationDistance(
              lastLegDropoffCoords,
              destinationCoords
            );

            walkingDetails.push(dropoffToDestinationWalkingDetails);
          }
        }
      } else {
        // Non-transfer trips dropoff to destination
        const lastLegDropoffCoords = {
          lon: ride_proposals[0].dropoff_stop.lon,
          lat: ride_proposals[0].dropoff_stop.lat
        };
        const destinationCoords = {
          lon: dest_lon,
          lat: dest_lat
        };
        const dropoffToDestinationWalkingDetails = await getWalkingDurationDistance(
          lastLegDropoffCoords,
          destinationCoords
        );

        walkingDetails.push(dropoffToDestinationWalkingDetails);
      }

      setWalkingDistanceAndDuration(walkingDetails);
    } catch (error) {
      console.log('RequestByPhoneDetails::getTripWalkingDurationAndDistance', error);
    }
  };

  const getTripDetails = async () => {
    try {
      const tripDetailsResponse = (
        await authenticatedAxiosInstance.axios.get(`${config.apiServer}/trip/${tripId}`)
      ).data.trip;
      console.log('tripDetailsResponse==>', tripDetailsResponse);
      setTripDetails(tripDetailsResponse);
    } catch (error) {
      console.log('RequestByPhoneDetails::getTripDetails', error);
    }
  };

  const getMapRoutesAndMarkers = async () => {
    try {
      // For backwards compatibility
      const pickupInfoExists = tripDetails.legs?.[0]
        ? tripDetails.legs[0].pickup_time_original
        : tripDetails?.ride_proposals[0]?.pickup_time;

      if (!pickupInfoExists) {
        formatTripNoConfirmedDetails(tripDetails);
      } else if (tripDetails.legs?.[0]) {
        formatTripWithLegs(tripDetails);
      } else if (!tripDetails.legs?.[0]) {
        formatTripNoLegs(tripDetails);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatTripNoConfirmedDetails = async (trip) => {
    const originCoords = { lon: trip.origin_lon, lat: trip.origin_lat };
    const destCoords = {
      lon: trip.dest_lon,
      lat: trip.dest_lat
    };

    let originDestinationRoute = [
      [Number(originCoords.lon), Number(originCoords.lat)],
      [Number(destCoords.lon), Number(destCoords.lat)]
    ];

    setRoutes([{ route: originDestinationRoute, type: TripType.ON_FOOT }]);
  };

  const formatTripWithLegs = async (tripObj) => {
    const { legs, origin_name, origin_lon, origin_lat, dest_lon, dest_lat, dest_name } = tripObj;
    let routes = [];
    let markers = [];

    const originCoords = { lon: origin_lon, lat: origin_lat };
    const firstPickupCoords = {
      lon: legs[0].pickupStopId.lon,
      lat: legs[0].pickupStopId.lat
    };

    const originPickupRoute = await getWalkingRoute(originCoords, firstPickupCoords);

    routes.push({ route: originPickupRoute, type: TripType.ON_FOOT });

    const originPoint = {
      serviceType: 'origin',
      lon: Number(origin_lon),
      lat: Number(origin_lat),
      name: origin_name
    };

    markers.push({ ...originPoint });

    for (let i = 0; i < legs.length; i++) {
      const dropoffCoords = { lon: legs[i].dropoffStopId.lon, lat: legs[i].dropoffStopId.lat };

      // Straight route from pickup to dropoff in upcoming trips
      const legPickupToDropoffRoute = [
        [Number(legs[i].pickupStopId.lon), Number(legs[i].pickupStopId.lat)],
        [Number(legs[i].dropoffStopId.lon), Number(legs[i].dropoffStopId.lat)]
      ];

      routes.push({ route: legPickupToDropoffRoute, type: legs[i].type });

      const pickupPoint = {
        serviceType: legs[i].type,
        lon: Number(legs[i].pickupStopId.lon),
        lat: Number(legs[i].pickupStopId.lat),
        name: legs[i].pickupStopId.name
      };

      markers.push({ ...pickupPoint });

      const lastLeg = legs[legs.length - 1];

      const finalDropoffCoords = { lon: lastLeg.dropoffStopId.lon, lat: lastLeg.dropoffStopId.lat };
      const destCoords = { lon: dest_lon, lat: dest_lat };

      // If there is another leg after, we need the walking route from dropoff to the next pickup
      if (legs[i + 1]) {
        const nextPickupCoords = {
          lon: legs[i + 1].pickupStopId.lon,
          lat: legs[i + 1].pickupStopId.lat
        };
        const dropoffToNextPickupRoute = await getWalkingRoute(dropoffCoords, nextPickupCoords);

        routes.push({ route: dropoffToNextPickupRoute, type: TripType.ON_FOOT });
      } else {
        const dropoffToDestRoute = await getWalkingRoute(finalDropoffCoords, destCoords);
        routes.push({ route: dropoffToDestRoute, type: TripType.ON_FOOT });
      }

      const dropoffPoint = {
        serviceType: legs[i].type,
        lon: Number(legs[i].dropoffStopId.lon),
        lat: Number(legs[i].dropoffStopId.lat),
        name: legs[i].dropoffStopId.name
      };

      markers.push({ ...dropoffPoint });
    }

    const destPoint = {
      serviceType: 'destination',
      lon: Number(dest_lon),
      lat: Number(dest_lat),
      name: dest_name
    };

    markers.push({ ...destPoint });

    if (routes.length > 0) {
      const routesForMap = routes.map((path) => {
        const justRoute = Object.fromEntries(
          Object.entries(path).filter(([key]) => !key.includes('type'))
        );

        const convertedPath = Object.values(justRoute).flat();
        return { path: lineString(convertedPath), type: path.type || null };
      });

      setRoutes(routesForMap);
    }

    if (markers.length > 0) {
      const onlyPickupDropoffStops = markers.filter(
        (point) => point.serviceType !== 'origin' && point.serviceType !== 'destination'
      );

      // Markers
      const stopsArray = onlyPickupDropoffStops.map((stop) => {
        const coords = point([Number(stop.lon), Number(stop.lat)]);
        return { type: stop.serviceType, mapCoords: coords };
      });

      setMarkers(stopsArray);
    }
  };

  const formatTripNoLegs = async (tripObj) => {
    let originPickupRoute = null;
    // A straight line from pickup stop to dropoff stop
    let pickupDropoffRoute = [
      [
        Number(tripObj.ride_proposals[0].pickup_stop.lon),
        Number(tripObj.ride_proposals[0].pickup_stop.lat)
      ],
      [
        Number(tripObj.ride_proposals[0].dropoff_stop.lon),
        Number(tripObj.ride_proposals[0].dropoff_stop.lat)
      ]
    ];
    let dropoffDestRoute = null;

    const originCoords = { lon: tripObj.origin_lon, lat: tripObj.origin_lat };
    const pickupCoords = {
      lon: tripObj.ride_proposals[0].pickup_stop.lon,
      lat: tripObj.ride_proposals[0].pickup_stop.lat
    };
    const dropoffCoords = {
      lon: tripObj.ride_proposals[0].dropoff_stop.lon,
      lat: tripObj.ride_proposals[0].dropoff_stop.lat
    };
    const destCoords = {
      lon: tripObj.dest_lon,
      lat: tripObj.dest_lat
    };

    originPickupRoute = await getWalkingRoute(originCoords, pickupCoords);
    dropoffDestRoute = await getWalkingRoute(dropoffCoords, destCoords);

    const pickupPoint = {
      type: TripType.ON_DEMAND,
      lon: tripObj.ride_proposals[0].pickup_stop.lon,
      lat: tripObj.ride_proposals[0].pickup_stop.lat,
      name: tripObj.ride_proposals[0].pickup_stop.name
    };

    const dropoffPoint = {
      type: TripType.ON_DEMAND,
      lon: tripObj.ride_proposals[0].dropoff_stop.lon,
      lat: tripObj.ride_proposals[0].dropoff_stop.lat,
      name: tripObj.ride_proposals[0].dropoff_stop.name
    };

    setMarkers([{ ...pickupPoint }, { ...dropoffPoint }]);
    if (originPickupRoute && pickupDropoffRoute && dropoffDestRoute) {
      setRoutes([
        { route: originPickupRoute, type: TripType.ON_FOOT },
        { route: pickupDropoffRoute, type: TripType.ON_DEMAND },
        { route: dropoffDestRoute, type: TripType.ON_FOOT }
      ]);
    }
  };

  const getWindows = async () => {
    const windows = await getPickupDropoffWindows(currentUser.transit_agency_id);
    setTimeWindows({
      phone_in_pickup_window: windows?.phone_in_pickup_window || 0,
      phone_in_dropoff_window: windows?.phone_in_dropoff_window || 0
    });
  };

  const changePhoneStatus = async (status) => {
    try {
      await authenticatedAxiosInstance.axios.post(
        `/rides/${tripDetails.ride_proposal_id}/updateCall`,
        {
          status: status ? 0 : 1
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getPickupDropoffTimes = (step) => {
    // HALP - Make DST changes.
    // For trips that have ride proposals (transfer and non transfer trips)
    if (
      tripDetails.ride_proposals[0].arrival_time ||
      tripDetails.ride_proposals[0].departure_time
    ) {
      return displayWindowRange(
        step,
        step === 'pickup'
          ? tripDetails.ride_proposals[0].departure_time
          : tripDetails.ride_proposals[0].arrival_time
      );
      // For trips that don't have a ride proposal yet (the ride is confirmed, but it's too far in the future still)
    } else if (tripDetails?.earliest_departure || tripDetails?.latest_arrival) {
      return displayWindowRange(
        step,
        step === 'pickup' ? tripDetails?.earliest_departure : tripDetails?.latest_arrival
      );
    } else {
      return t('trip_status_pending');
    }
  };

  const getLabel = () => {
    if (tripDetails?.communication === communicationMethod.EMAIL) {
      return t(`${communicationMethod.EMAIL}`);
    } else if (tripDetails?.communication === communicationMethod.SMS) {
      return t(`${communicationMethod.SMS}`);
    } else {
      return t(`${communicationMethod.VOICE}`);
    }
  };

  const getTranslatedAccommodations = (passenger) => {
    // Object with possible accommodation values and their translations.
    const accommodations = {
      wheelchair_demand: t('wheelchair'),
      bike_demand: t('bike'),
      motorized_aid_demand: t('motorized_aid'),
      stroller_demand: t('stroller')
    };

    let translatedAccommodations = [];
    let translatedAccommodationsString = '';
    // Convert passenger object into array to loop over it.
    Object.entries(passenger).forEach(([key, value]) => {
      // If the key of the accommodations array includes the passenger key and it's value is 1 (accommodation was selected for that passenger).
      if (Object.keys(accommodations).includes(key) && value === 1) {
        // Add the translation to the translatedAccommodation array.
        translatedAccommodations.push(accommodations[key]);
        // Convert array to string and then add spaces between items.
        translatedAccommodationsString = translatedAccommodations.toString().replace(',', ', ');
      }
    });
    return translatedAccommodationsString.length ? translatedAccommodationsString : ' - ';
  };

  const getPaymentMethodTranslation = (paymentType) => {
    const paymentTranslations = {
      card: t('card'),
      tickets: t('tickets'),
      pay_on_board: t('pay_on_board'),
      smart_card: t('smart_card'),
      pass: t('pass')
    };

    return paymentTranslations[paymentType];
  };

  const polygonPaint = (ReactMapboxGl.FillPaint = {
    'fill-color': ['coalesce', ['get', 'color'], colors.blaiseGreen],
    'fill-opacity': 0.3
  });

  return (
    <>
      {isLoading ? (
        <Grid
          container
          item
          xs={12}
          justify="center"
          alignItems="center"
          style={{ height: '90vh' }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h2>
                {t('trip_details')} - {tripDetails?.passenger?.first_name}{' '}
                {tripDetails?.passenger?.last_name}
              </h2>
            </Grid>
            <Map /* eslint-disable-next-line */
              style="mapbox://styles/mapbox/light-v10"
              className="bordered"
              center={getCenter(
                tripDetails.dest_lat,
                tripDetails.dest_lon,
                tripDetails.origin_lat,
                tripDetails.origin_lon
              )}
              containerStyle={{ width: '100%', height: '200px', borderRadius: 10 }}
              zoom={[10]}
            >
              {/* Transit operating zone */}
              <GeoJSONLayer data={geoJson} fillPaint={polygonPaint} />

              {/* Origin marker */}
              <Marker
                coordinates={[tripDetails.origin_lon, tripDetails.origin_lat]}
                anchor="left"
                style={{ color: colors.black, paddingBefore: 10 }}
              >
                {tripDetails.origin_lon && (
                  <>
                    <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" color={colors.blaisePurple} />
                  </>
                )}
              </Marker>

              {/* Routes */}
              {routes.map((route, index) => (
                <Layer
                  key={`route-${index}`}
                  id={`route-${index}`}
                  type="line"
                  paint={{
                    'line-width': 1.5,
                    'line-dasharray': route.type === TripType.ON_FOOT ? [2, 1] : [1, 0],
                    'line-color': getRouteColor(route.type)
                  }}
                >
                  <Feature coordinates={route?.path?.geometry?.coordinates || route.route} />
                </Layer>
              ))}

              {/* Pickup & dropoff markers */}
              {markers.map((marker, index) => (
                <Layer
                  key={`marker-${index}`}
                  type="circle"
                  id={`marker-${index}`}
                  paint={{
                    'circle-color': colors.white,
                    'circle-stroke-width': 2,
                    'circle-stroke-color':
                      marker.type === 'on_demand' ? colors.blaiseGreen : colors.fixedRouteOrange,
                    'circle-stroke-opacity': 1,
                    'circle-radius': 4
                  }}
                >
                  <Feature
                    coordinates={[
                      // Backwards compatibility. Transfers || Non-transfers.
                      marker?.mapCoords?.geometry?.coordinates[0] || marker.lon,
                      marker?.mapCoords?.geometry?.coordinates[1] || marker.lat
                    ]}
                  />
                </Layer>
              ))}

              {/* Destination marker */}
              <Marker
                coordinates={[tripDetails.dest_lon, tripDetails.dest_lat]}
                anchor="left"
                style={{ color: colors.black, paddingBefore: 10 }}
              >
                {tripDetails.dest_lon && (
                  <>
                    <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" color={colors.blaiseRed} />
                  </>
                )}
              </Marker>
            </Map>
            {hasTransfersFeature && (
              <Box style={{ marginTop: '16px' }}>
                <Typography className="map-key-container">
                  <Box
                    className="map-key-circle"
                    style={{
                      backgroundColor: colors.blaiseGreen
                    }}
                  />
                  {t('on_demand_zone')}
                </Typography>
                <Typography className="map-key-container">
                  <Box
                    className="map-key-circle"
                    style={{
                      backgroundColor: colors.fixedRouteOrange
                    }}
                  />
                  {t('fixed_route_zone')}
                </Typography>
              </Box>
            )}
            <Box className="details-container">
              {/* Dashed line */}
              <Box className="dashed-line-container">
                <Box className="dashed-line">
                  <Box className="origin-dot"></Box>
                  <FontAwesomeIcon
                    className="destination-dot"
                    icon={faMapMarker}
                    size="lg"
                    color={colors.blaiseRed}
                  />
                </Box>
              </Box>

              {/* Trip details */}
              <Box className="inputs-container">
                {/* Origin */}
                <Box className="origin-container">
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <TextField
                        name="origin"
                        value={tripDetails.origin_name || t('trip_status_pending')}
                        variant="outlined"
                        inputProps={{ readOnly: true }}
                        fullWidth
                        label={t('origin')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        name="departureTime"
                        value={getPickupDropoffTimes('pickup')}
                        variant="outlined"
                        inputProps={{ readOnly: true }}
                        fullWidth
                        label={t('departure_time')}
                      />
                    </Grid>
                  </Grid>
                  <Box className="trip-details-icon-container">
                    {/* Only show if there are confirmed trip details */}
                    {(tripDetails.legs.length > 0 || tripDetails.ride_proposals.length > 0) && (
                      <>
                        <FontAwesomeIcon
                          icon={faWalking}
                          size="lg"
                          color={colors.black}
                          style={{ marginRight: '8px' }} // 8px margin added so that the text Walk and Vehicle are in line.
                        />
                        <Box>
                          <Typography style={{ fontSize: '14px' }}>{t('walk')}</Typography>
                          <Typography style={{ fontSize: '10px' }}>
                            {t('about')} {walkingDistanceAndDuration[0]?.durationInMinutes}{' '}
                            {walkingDistanceAndDuration[0]?.durationInMinutes === 1
                              ? 'min'
                              : 'mins'}
                            , {walkingDistanceAndDuration[0]?.distanceInMeters}m
                          </Typography>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>

                {/* Trip Details */}
                <AdditionalTripDetails
                  legs={tripDetails?.legs[0] ? tripDetails.legs : tripDetails.ride_proposals}
                  walkingDistanceAndDuration={walkingDistanceAndDuration}
                />

                {/* Destination */}
                <Box className="destination-container">
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <TextField
                        name="dropoffStopName"
                        value={tripDetails.dest_name || t('trip_status_pending')}
                        variant="outlined"
                        inputProps={{ readOnly: true }}
                        fullWidth
                        label={t('destination')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        name="dropoffTime"
                        value={getPickupDropoffTimes('dropoff')}
                        variant="outlined"
                        inputProps={{ readOnly: true }}
                        fullWidth
                        label={t('arrival_time')}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
            <Typography style={{ fontWeight: '600', margin: '32px 0' }} variant="subtitle1">
              {t('additional_information')}
            </Typography>
            <Box style={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }}>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <TextField
                    name="numberOfPassengers"
                    value={tripDetails.trip_request_passenger_type_links.length}
                    variant="outlined"
                    inputProps={{ readOnly: true }}
                    fullWidth
                    label={t('num_passengers')}
                  />
                </Grid>
              </Grid>
              {tripDetails.trip_request_passenger_type_links.map((passenger) => (
                <Grid key={passenger.id} container spacing={2}>
                  <Grid item sm={6}>
                    <TextField
                      name="passengerType"
                      value={
                        BROWSER_LANG == 'en'
                          ? passenger.transit_agency_passenger_type.translationType.english
                          : passenger.transit_agency_passenger_type.translationType.french
                      }
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                      fullWidth
                      label={t('passenger_type')}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      name="accommodation"
                      value={getTranslatedAccommodations(passenger)}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                      fullWidth
                      label={t('accommodations')}
                    />
                  </Grid>
                </Grid>
              ))}
            </Box>
            <Typography style={{ fontWeight: '600', margin: '32px 0' }} variant="subtitle1">
              {t('payment')}
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={3}>
                <TextField
                  name="paymentMethod"
                  value={getPaymentMethodTranslation(tripDetails.payment_type)}
                  variant="outlined"
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label={t('payment_method')}
                />
              </Grid>
              <Grid item sm={3}>
                <TextField
                  name="price"
                  // Backwards compatibility.
                  value={
                    '$' +
                    (
                      tripDetails.trip_price ||
                      tripDetails.legs.reduce((acc, curr) => acc + curr.leg_price, 0) / 100
                    ).toFixed(2)
                  }
                  variant="outlined"
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label={t('total')}
                />
              </Grid>
            </Grid>
            <Typography style={{ fontSize: '14px', color: colors.red, marginTop: '8px' }}>
              {t('not_all_charges_warning')}
            </Typography>
            <Grid container spacing={2} style={{ marginTop: '32px' }}>
              <Grid item sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!needsCall}
                      onChange={(e) => {
                        changePhoneStatus(e.target.checked);
                        setNeedsCall(!e.target.checked);
                      }}
                      name="phonestatus"
                      color="primary"
                      id="phonestatus"
                    />
                  }
                  label={t('passenger_called_details')}
                />
              </Grid>
              <Grid item sm={3}>
                <TextField
                  name="preferredCommunication"
                  value={
                    tripDetails.passenger.preferred_communication === communicationMethod.EMAIL
                      ? tripDetails.passenger.email
                      : tripDetails.passenger.phone_number
                  }
                  variant="outlined"
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label={getLabel()}
                />
              </Grid>
              <Grid item sm={3}>
                <TextField
                  name="secretCode"
                  value={tripDetails.secret_code}
                  variant="outlined"
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label={t('secret_code')}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default withAuth0(RequestByPhoneDetails);
