import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomSnackbar from '../Snackbar/CustomSnackbar';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

class MyProfile extends Component {
  render() {
    return (
      <div>
        <CustomSnackbar
          message={this.props.snackbar.message}
          open={this.props.snackbar.open}
          onClose={this.props.closeSnackbar}
          snackbarColor={this.props.snackbar.color}
        />
        <Box>
          <Paper>
            <Box p={2}>
              <Grid container spacing={3}>
                {/* Profile settings */}
                <Grid item xs={6}>
                  <Box mb={1} display="flex" flexDirection="row" justifyContent="space-between">
                    <div className="profileTitle">{this.props.t('profile_settings')}</div>
                  </Box>
                  <Box mb={1} flexDirection="column" display="flex">
                    {Object.keys(this.props.profileFields).map((field) => (
                      <TextField
                        inputProps={{
                          'data-testid': this.props.profileFields[field].id
                        }}
                        id={this.props.profileFields[field].id}
                        label={this.props.profileFields[field].label}
                        type="text"
                        value={this.props.profileFields[field].value}
                        disabled={!this.props.profileFields[field].editable}
                        key={this.props.profileFields[field].id}
                        onChange={(e) => {
                          this.props.updateField(field, e);
                        }}
                      />
                    ))}
                  </Box>
                </Grid>
                {/* Change password */}
                <Grid item xs={6}>
                  <Box mb={1} display="flex" flexDirection="row" justifyContent="space-between">
                    <div className="profileTitle">{this.props.t('change_password')}</div>
                  </Box>
                  <Box mb={1} flexDirection="column" display="flex">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.props.sendPasswordChangeEmail}
                      disabled={this.props.passwordEmailSent}
                    >
                      {this.props.t('change_password')}
                    </Button>
                    {this.props.passwordEmailSent && (
                      <p>{this.props.t('password_reset_link_sent')}</p>
                    )}
                  </Box>
                </Grid>
              </Grid>
              {/* Buttons */}
              <Box display="flex" justifyContent="flex-end" style={{ gap: '8px' }}>
                <Button
                  variant="outlined"
                  onClick={() => this.props.resetFields()}
                  className="button"
                  data-testid="reset_profile"
                >
                  {this.props.t('reset')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.props.saveChanges()}
                >
                  {this.props.loading ? (
                    <CircularProgress color="secondary" size={12} />
                  ) : (
                    this.props.t('save')
                  )}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Box>
      </div>
    );
  }
}

const profileFieldsShape = PropTypes.shape({
  editable: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  modified: PropTypes.bool,
  value: PropTypes.string
});

MyProfile.propTypes = {
  snackbar: PropTypes.shape({
    open: PropTypes.bool,
    message: PropTypes.string
  }),
  closeSnackbar: PropTypes.func,
  profileFields: PropTypes.shape({
    creationDate: profileFieldsShape,
    email: profileFieldsShape,
    first_name: profileFieldsShape,
    last_name: profileFieldsShape,
    transportSociety: profileFieldsShape
  }),
  updateField: PropTypes.func,
  passwordEmailSent: PropTypes.bool,
  sendPasswordChangeEmail: PropTypes.func,
  resetFields: PropTypes.func,
  saveChanges: PropTypes.func,
  loading: PropTypes.bool
};

export default withTranslation('common')(MyProfile);
