import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';
import config from './config';

// Multi-project setup:
// routing-algo: For live updates of route details by the algo. It was part of NS demo.
// passenger-app-natuve: This is where the real time bus location are stored and displayed on the passenger app.

const routingAlgo = initializeApp(
  {
    apiKey: config.routingAlgoFbApiKey,
    authDomain: config.routingAlgoFbAuthDomain,
    projectId: config.routingAlgoFbProjectId,
    storageBucket: config.routingAlgoFbStorageBucket,
    messagingSenderId: config.routingAlgoFbMessagingSenderId,
    appId: config.routingAlgoFbAppId
  },
  'routing-algo'
);

const passengerAppNative = initializeApp(
  {
    apiKey: config.passengerAppNativeFbApiKey,
    authDomain: config.passengerAppNativeFbAuthDomain,
    databaseURL: config.passengerAppNativeFbDatabaseUrl,
    projectId: config.passengerAppNativeFbProjectId,
    storageBucket: config.passengerAppNativeFbStorageBucket,
    messagingSenderId: config.passengerAppNativeFbMessagingSenderId,
    appId: config.passengerAppNativeFbAppId
  },
  'passenger-app-native'
);

// Add all Firebase services below
export const routingAlgoFirestore = getFirestore(routingAlgo);
export const passengerAppNativeRealtime = getDatabase(passengerAppNative);
