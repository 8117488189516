import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles, withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Rating from '@material-ui/lab/Rating';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../assets/sass/colors';

import blaiseLogo from '../../assets/images/blaiseLogo.png';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  }
}));

const labels = {
  1: 'Very Poor',
  2: 'Poor',
  3: 'Good',
  4: 'Very Good',
  5: 'Excellent'
};

const StyledRating = withStyles({
  iconFilled: {
    color: colors.blaiseGreen
  },
  iconHover: {
    color: colors.blaiseGreen
  }
})(Rating);

const ReviewModal = (props) => {
  const classes = useStyles();
  const modalOpen = props.modalOpen;
  const modalFields = props.modalFields;
  const modalTitle = props.modalTitle;
  const handleSubmit = props.handleReviewSubmit;
  const extraFields = props.modalExtraFields;

  const [ratingLabel, setRatingLabel] = useState(-1);
  const [reviewSubmitDisabled, setReviewSubmitDisabled] = useState(true);

  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        props.handleModalClose();
      }}
      className={classes.modal}
    >
      <Box m={4} style={{ overflow: 'auto', maxHeight: '85%', width: '25%' }}>
        <Paper elevation={0} style={{ padding: '32px' }}>
          <Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              height={300}
            >
              <img src={blaiseLogo} className="logoNavbar" alt="logo" />
              <h2>{modalTitle}</h2>
              <StyledRating
                name="tripReview"
                value={props.reviewRating}
                onChangeActive={(event, newLabel) => {
                  setRatingLabel(newLabel);
                }}
                onChange={(event, newRating) => {
                  props.setReviewRating(newRating);
                  setReviewSubmitDisabled(false);
                }}
                precision={1}
                icon={<FontAwesomeIcon icon={faStar} style={{ padding: 2 }} />}
                size="large"
              />
              <Box
                style={{
                  fontWeight: 'bold',
                  height: '32px',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {props.reviewRating !== null && (
                  <>{labels[ratingLabel !== -1 ? ratingLabel : props.reviewRating]}</>
                )}
              </Box>
            </Box>
            <Grid container spacing={3}>
              {modalFields &&
                modalFields.map((field) => {
                  return (
                    <Grid item xs={12} key={field}>
                      <TextField
                        name={field}
                        fullWidth
                        id={field}
                        select={extraFields?.[field] ? true : false}
                        variant="outlined"
                        multiline
                        label={props.t(field)}
                        onChange={(e) => props.handleChange(e)}
                      >
                        {extraFields &&
                          extraFields[field]?.map((option) => {
                            return (
                              <MenuItem value={option.value} key={option.id}>
                                {option.label}
                              </MenuItem>
                            );
                          })}
                      </TextField>
                    </Grid>
                  );
                })}
            </Grid>
            <Box pt={4} display="flex" justifyContent="center" style={{ width: '100%' }}>
              <Button
                disabled={reviewSubmitDisabled}
                color="primary"
                variant="contained"
                onClick={() => handleSubmit()}
              >
                {props.t('submit')}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default ReviewModal;
