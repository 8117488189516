import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { withAuth0 } from '@auth0/auth0-react';
import { currentUserStore } from '../../store/user';
import { withTranslation } from 'react-i18next';
import Fuse from 'fuse.js';
import useInterval from '../../higherOrderComponents/useInterval';
import DataGridTablePagination from '../../components/DataGrid/DataGridTablePagination';
import ReviewModal from '../../components/Modals/ReviewModal';
import CustomSnackbar from '../../components/Snackbar/CustomSnackbar';
import colors from '../../assets/sass/colors';
import './RideManagement.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faStar, faCog, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import {
  Box,
  Button,
  CircularProgress,
  Fab,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  TextField
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { DatePicker, LocalizationProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import { dateTimeFormat, dateTimeFormatWithOffset } from '../../utils/MomentTime';
import LocalStorageKeys from '../../utils/dataGridUtils';
import authenticatedAxiosInstance from '../../axios/axios-authorized';
import { compareBrowserAndAgencyTZ } from '../../utils/timezoneUtils';
import { debounce } from 'lodash';

const DateType = Object.freeze({
  YESTERDAY: 'yesterday',
  TODAY: 'today',
  TOMORROW: 'tomorrow'
});

const FilterType = Object.freeze({
  START: 'start',
  END: 'end'
});

const requestSourceTypes = Object.freeze({
  ENGINE: 'trip_source_engine',
  ADHOC: 'trip_source_adhoc',
  PASSENGER_WEB: 'trip_source_web',
  PASSENGER_IOS: 'trip_source_ios',
  PASSENGER_ANDROID: 'trip_source_android'
});

const nonDefaultColumns = Object.freeze([
  'shift_id',
  'passenger_comment',
  'passenger_rating',
  'cancellation_time',
  'has_boarded',
  'phone_number',
  'stripe_receipt_url',
  'wheelchair_demand',
  'bike_demand',
  'nondisabled_demand',
  'dest_name',
  'origin_name',
  'payment_type',
  'request_source',
  'request_date',
  'trip_price'
]);

const RideManagement = (props) => {
  moment.locale(props.i18n.language.substr(0, 2));
  const history = useHistory();
  const { currentUser } = currentUserStore();

  const [loading, setLoading] = useState(false);
  const [trips, setTrips] = useState(null);
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [dateFilterSelection, setDateFilterSelection] = useState(null);
  const [statusFilter, setStatusFilter] = useState('all');
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [reviewRideInfo, setReviewRideInfo] = useState(null);
  const [reviewRating, setReviewRating] = useState(0);
  const [reviewComment, setReviewComment] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [fieldSearchInfo, setFieldSearchInfo] = useState(null);
  const [runDebouncer, setRunDebouncer] = useState(false);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: null
  });

  const localSortedColumn = JSON.parse(
    localStorage.getItem(LocalStorageKeys.RIDE_MANAGEMENT_SORTED_COLUMN)
  );
  const localHiddenColumns = JSON.parse(
    localStorage.getItem(LocalStorageKeys.RIDE_MANAGEMENT_HIDDEN_COLUMNS)
  );
  const localStartDateFilter = localStorage.getItem(
    LocalStorageKeys.RIDE_MANAGEMENT_FILTER_START_DATE
  );

  const localEndDateFilter = localStorage.getItem(LocalStorageKeys.RIDE_MANAGEMENT_FILTER_END_DATE);
  const localStatusFilter = localStorage.getItem(LocalStorageKeys.RIDE_MANAGEMENT_FILTER_STATUS);
  const [columns, setColumns] = useState([]);
  const [sortModel, setSortModel] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const timezoneDetails = useRef({});

  const [tablePageParameters, setTablePageParameters] = useState({
    isLoading: false,
    rowCount: 0,
    pageNumber: 0,
    pageSize: 25
  });

  const isSettingsMenuOpen = Boolean(anchorEl);

  const nonSearchableColumns = [
    'pickup_stop',
    'dropoff_stop',
    'bus_name',
    'payment_type',
    'stripe_receipt_url',
    'has_boarded',
    'cancellation_time',
    'passenger_rating',
    'passenger_comment',
    'button',
    'status'
  ];

  useInterval(() => {
    // don't want to refresh trips if user is searching
    if (fieldSearchInfo?.query === '') {
      fetchAllTripsWithFields('refresh', columns);
    }
  }, 60000);

  useEffect(() => {
    document.title = `${props.t('ride_management_title')} - ${props.t('trip_source_engine')}`;
    let date = new Date();
    let hiddenColumns;

    // Load hidden columns from localStorage or if there aren't any, then hide all columns that are not part of the default view
    if (localHiddenColumns) {
      setHiddenColumns(localHiddenColumns);
      hiddenColumns = localHiddenColumns;
    } else {
      setHiddenColumns(nonDefaultColumns);
      hiddenColumns = nonDefaultColumns;
    }

    localSortedColumn && localSortedColumn.length > 0 // Check if the user has a local sorted column
      ? hiddenColumns // If the user has a local sorted column, check if there are any hidden columns
        ? !hiddenColumns.includes(localSortedColumn[0].field) // If there are hidden columns, make sure that the sorted column is not part of them
          ? setSortModel(localSortedColumn)
          : setSortModel([])
        : setSortModel(localSortedColumn)
      : setSortModel([]);
    // Load filters from localStorage.
    localStartDateFilter
      ? setFilterStartDate(localStartDateFilter)
      : setFilterStartDate(date.setDate(date.getDate() - 1));
    localEndDateFilter
      ? setFilterEndDate(localEndDateFilter)
      : setFilterEndDate(date.setDate(date.getDate() + 1));
    localStatusFilter ? setStatusFilter(localStatusFilter) : setStatusFilter('all');
  }, []);

  useEffect(() => {
    // Filter out hidden columns
    setColumns(
      initialColumns.map((column) => {
        if (hiddenColumns.includes(column.field)) {
          column.hide = true;
        } else {
          column.hide = false;
        }
        return column;
      })
    );
    fetchAllTripsWithFields('refresh', columns, sortModel, fieldSearchInfo, statusFilter);
  }, [hiddenColumns, tablePageParameters?.pageNumber, tablePageParameters?.pageSize]);

  useEffect(() => {
    changeStatusFilter(statusFilter);
  }, [trips]);

  useEffect(() => {
    changeStatusFilter(localStatusFilter);
  }, [statusFilter]);

  // Fetch all Trips within the start-end dates when the start or end filter changes
  useEffect(() => {
    if (filterStartDate && filterEndDate) {
      fetchAllTripsWithFields('mount', columns);
    }
  }, [filterStartDate, filterEndDate]);

  useEffect(() => {
    let dateToFilter;
    const today = new Date();

    if (dateFilterSelection === DateType.TODAY) {
      dateToFilter = today;
    }
    if (dateFilterSelection === DateType.YESTERDAY) {
      dateToFilter = today.setDate(today.getDate() - 1);
    }

    if (dateFilterSelection === DateType.TOMORROW) {
      dateToFilter = today.setDate(today.getDate() + 1);
    }

    if (dateToFilter) {
      setFilterDate(dateToFilter, FilterType.START);
      setFilterDate(dateToFilter, FilterType.END);
    }
  }, [dateFilterSelection]);

  useEffect(() => {
    fetchAllTripsWithFields('refresh', columns, sortModel, fieldSearchInfo, statusFilter);
  }, [sortModel, statusFilter]);

  useEffect(() => {
    if (runDebouncer) {
      setRunDebouncer(false);
      fetchAllTripsWithFields('refresh', columns, sortModel, fieldSearchInfo, statusFilter);
    }
  }, [runDebouncer]);

  const verifyTimezoneDetails = async (taid) => {
    const details = await compareBrowserAndAgencyTZ(taid);
    timezoneDetails.current = details;
    if (timezoneDetails.current.timezoneMismatch) {
      openSnackbar(
        `${props.t('timezone_mismatch_message')} (${timezoneDetails.taTimezone}). ${props.t(
          'timezone_no_actions_required'
        )}`,
        colors.red
      );
    }
  };

  const resetHiddenColumns = () => {
    if (localHiddenColumns) {
      localStorage.removeItem(LocalStorageKeys.RIDE_MANAGEMENT_HIDDEN_COLUMNS);
    }

    setAnchorEl(null);

    localStorage.setItem(
      LocalStorageKeys.RIDE_MANAGEMENT_HIDDEN_COLUMNS,
      JSON.stringify(nonDefaultColumns)
    );
    // filter out all columns except the ones listed below
    setHiddenColumns(nonDefaultColumns);
  };

  const resetSortedColumn = () => {
    if (localSortedColumn) {
      localStorage.removeItem(LocalStorageKeys.RIDE_MANAGEMENT_SORTED_COLUMN);
    }
    setAnchorEl(null);
    setSortModel([]);
  };

  const fetchAllTripsWithFields = async (
    type,
    fields,
    sortModel,
    fieldSearchInfo,
    statusFilter
  ) => {
    try {
      fields = fields.filter((column) => !hiddenColumns.includes(column.field));
      fields = fields.map((field) => {
        return field.field;
      });
      // show spinner on mount load but not refresh loads
      if (type === 'mount') {
        setLoading(true);
        await verifyTimezoneDetails(currentUser.transit_agency_id);
      }

      let startDate = filterStartDate ? new Date(filterStartDate) : new Date();
      startDate.setHours(0, 0, 0, 0);
      let endDate = filterEndDate ? new Date(filterEndDate) : new Date();
      endDate.setHours(23, 59, 59, 59);

      setTablePageParameters((oldParameters) => ({ ...oldParameters, isLoading: true }));

      // Send the start and end dates as query params to the backend (will query TripRequest table within the date range)
      const response = await authenticatedAxiosInstance.axios.get(`/tripsallwithfields`, {
        params: {
          start: startDate.toISOString(),
          end: endDate.toISOString(),
          fields: fields.length > 0 ? fields : ['trip_request_id'],
          page: tablePageParameters.pageNumber,
          limit: tablePageParameters.pageSize,
          sortModel: sortModel?.[0],
          fieldSearchInfo: fieldSearchInfo?.query ? fieldSearchInfo : null,
          status: statusFilter
        }
      });

      setTablePageParameters((oldParameters) => ({
        ...oldParameters,
        isLoading: false,
        rowCount: response?.data?.trips?.count
      }));
      const justTrips = response?.data?.trips?.rows;
      // search keys for all fields:
      const rowsWithTimes = justTrips.map((trip) => {
        if (trip.ride_proposals.length !== 0) {
          if (fields.includes('pickup_time') && trip.ride_proposals[0].pickup_time) {
            //Pickup time that matches TA (due to offset)
            trip.ride_proposals[0].pickup_time = dateTimeFormatWithOffset(
              trip.ride_proposals?.[0]?.pickup_time,
              timezoneDetails.current.taTimezoneOffset,
              true,
              'YYYY-MM-DD HH:mm:ss'
            );
          }
          if (fields.includes('dropoff_time') && trip.ride_proposals[0].dropoff_time) {
            // Dropoff time that matches TA (due to offset)
            trip.ride_proposals[0].dropoff_time = dateTimeFormatWithOffset(
              trip.ride_proposals?.[0]?.dropoff_time,
              timezoneDetails.current.taTimezoneOffset,
              true,
              'YYYY-MM-DD HH:mm:ss'
            );
          }

          if (fields.includes('departure_time')) {
            // Departure time that matches TA (due to offset)
            trip.ride_proposals[0].departure_time = dateTimeFormatWithOffset(
              trip.ride_proposals?.[0]?.departure_time,
              timezoneDetails.current.taTimezoneOffset,
              true,
              'YYYY-MM-DD HH:mm:ss'
            );
          }

          if (fields.includes('arrival_time')) {
            // Arrival time that matches TA (due to offset)
            trip.ride_proposals[0].arrival_time = dateTimeFormatWithOffset(
              trip.ride_proposals?.[0]?.arrival_time,
              timezoneDetails.current.taTimezoneOffset,
              true,
              'YYYY-MM-DD HH:mm:ss'
            );
          }

          if (fields.includes('cancellation_time') && trip.ride_proposals[0].cancellation_time) {
            // Cancellation time that matches TA (due to offset)
            trip.ride_proposals[0].cancellation_time = dateTimeFormatWithOffset(
              trip.ride_proposals?.[0]?.cancellation_time,
              timezoneDetails.current.taTimezoneOffset,
              true,
              'YYYY-MM-DD HH:mm:ss'
            );
          }
        }

        if (fields.includes('request_date')) {
          // Request time that matches TA (due to offset)
          trip.request_date = dateTimeFormatWithOffset(
            trip.request_date,
            timezoneDetails.current.taTimezoneOffset,
            true,
            'YYYY-MM-DD HH:mm:ss'
          );
        }
        return trip;
      });

      setTrips(rowsWithTimes);
    } catch (err) {
      console.log(err, 'err');
      openSnackbar(props.t('error_fetching_trips'));
    } finally {
      if (type === 'mount') {
        setLoading(false);
      }
    }
  };

  const changeStatusFilter = (type) => {
    setStatusFilter(type);
    if (type) {
      localStorage.setItem(LocalStorageKeys.RIDE_MANAGEMENT_FILTER_STATUS, type);
    }
  };

  const handleReviewRating = (rating) => {
    setReviewRating(rating);
  };

  const handleReviewCommentChange = (event) => {
    setReviewComment(event.target.value);
  };

  const handleReviewSubmit = async () => {
    const rideId = reviewRideInfo.ride_proposals[0].ride_proposal_id;
    // add the rating and comment attributes to the corresponding ride in ride_actual table
    const reqBodyReview = {
      rating: reviewRating,
      comment: reviewComment
    };

    try {
      await authenticatedAxiosInstance.axios.post(`/rides/${rideId}/actualRideFromRPID`);
      await authenticatedAxiosInstance.axios.put(`/rides/${rideId}/actualRide`, reqBodyReview);

      openSnackbar(props.t('review_submit_success'), colors.blaiseGreen);
      setReviewModalOpen(!reviewModalOpen);
      fetchAllTripsWithFields('mount', columns);
    } catch (err) {
      console.log(err, 'err');
      openSnackbar(props.t('errors:review_submit_error'), colors.red);
    }
  };

  const openSnackbar = (snackbarString, color) => {
    setSnackbar({ ...snackbar, open: true, message: snackbarString, color: color });
  };

  const closeSnackbar = (reason) => {
    if (reason !== 'clickaway') {
      setSnackbar({ ...snackbar, open: false });
    }
  };

  const debouncedSearch = useCallback(
    debounce(() => {
      setRunDebouncer(true);
    }, 500),
    []
  );

  const handleSearchColumnChange = (event) => {
    const value = event.target.value;
    setFieldSearchInfo((prevState) => ({
      ...prevState,
      column: value
    }));
    setShowSearchBar(true);
    if (fieldSearchInfo?.query) {
      debouncedSearch();
    }
  };

  const handleSearchQueryChange = (event) => {
    const value = event.target.value;
    if (value.length === 0) {
      setFieldSearchInfo((prevState) => ({
        ...prevState,
        query: null
      }));
    } else {
      setFieldSearchInfo((prevState) => ({
        ...prevState,
        query: value
      }));
    }
    debouncedSearch();
  };

  const setFilterDate = (val, type) => {
    let date;
    if (val) {
      date = new Date(val);
    }

    if (type === FilterType.START) {
      setFilterStartDate(date ? date : null);
      localStorage.setItem(LocalStorageKeys.RIDE_MANAGEMENT_FILTER_START_DATE, date);
    }

    if (type === FilterType.END) {
      setFilterEndDate(date ? date : null);
      localStorage.setItem(LocalStorageKeys.RIDE_MANAGEMENT_FILTER_END_DATE, date);
    }
  };

  // make copies of trips with id to pass to datagrid
  let tripsCopy;
  if (trips) {
    tripsCopy = trips.map((trip, index) => {
      return {
        ...trip,
        id: index
      };
    });
  }

  const centerStyles = { width: 150, align: 'center', headerAlign: 'center' };
  const centerStylesWide = { width: 200, align: 'center', headerAlign: 'center' };

  const initialColumns = [
    {
      field: 'trip_request_id',
      headerName: props.t('id'),
      ...centerStyles,
      valueGetter: (params) => {
        return params.row.item ? params.row.item.trip_request_id : params.row.trip_request_id;
      }
    },
    {
      field: 'status',
      headerName: props.t('status'),
      ...centerStylesWide,
      valueGetter: (params) => {
        let type = params?.row?.status;
        if (!params.row?.status) {
          return 'Old request';
        } else if (params?.row?.status === 'accepted' || params?.row?.status === 'confirmed') {
          type = 'upcoming';
        } else if (params.row?.status === 'processing') {
          type = 'pending';
        }
        return props.t(`history_${type}`);
      },
      sortable: false
    },
    {
      field: 'first_name',
      headerName: props.t('first_name'),
      ...centerStyles,
      valueGetter: (params) => {
        return params.row.item
          ? params.row.item.passenger?.first_name
          : params.row.passenger?.first_name
          ? params.row.passenger.first_name
          : ' - ';
      }
    },
    {
      field: 'last_name',
      headerName: props.t('last_name'),
      ...centerStyles,
      valueGetter: (params) => {
        return params.row.item
          ? params.row.item.passenger?.last_name
          : params.row.passenger?.last_name
          ? params.row.passenger.last_name
          : ' - ';
      }
    },
    {
      field: 'email',
      headerName: props.t('passenger_email'),
      ...centerStylesWide,
      valueGetter: (params) => {
        if (params.row.item) {
          return params.row.item ? params.row.item.passenger?.email : params.row.passenger?.email;
        } else {
          return params.row.passenger?.email;
        }
      }
    },
    {
      field: 'bus_name',
      headerName: props.t('bus_name'),
      ...centerStylesWide,
      valueGetter: (params) => {
        if (params.row.item) {
          const busName = params.row.item.ride_proposals[0]?.route?.driver_shift?.bus?.bus_name;
          return busName;
        } else {
          const busName = params.row.ride_proposals[0]?.route?.driver_shift?.bus?.bus_name;
          return busName;
        }
      },
      valueFormatter: (params) => {
        if (
          params.row.item &&
          params.row.item.ride_proposals[0]?.route?.driver_shift?.bus?.bus_name
        ) {
          return params.row.item.ride_proposals[0].route.driver_shift?.bus.bus_name;
        } else if (
          params.row.ride_proposals &&
          params.row.ride_proposals[0]?.route?.driver_shift?.bus?.bus_name
        ) {
          return params.row.ride_proposals[0].driver_shift?.route.bus.bus_name;
        } else {
          return ' - ';
        }
      }
    },
    {
      field: 'departure_time',
      headerName: props.t('departure'),
      ...centerStylesWide,
      type: 'dateTime',
      valueGetter: (params) => {
        if (params.row.item && params.row.item.ride_proposals[0]?.departure_time) {
          const newValue = dateTimeFormat(params.row.item.ride_proposals?.[0]?.departure_time);
          return newValue;
        } else if (params.row.ride_proposals && params.row.ride_proposals[0]?.departure_time) {
          const newValue = dateTimeFormat(params.row.ride_proposals?.[0]?.departure_time);
          return newValue;
        } else {
          return '-';
        }
      }
    },
    {
      field: 'pickup_stop',
      headerName: props.t('pickup_stop'),
      ...centerStylesWide,
      valueGetter: (params) => {
        if (params.row.item) {
          return params.row.item.ride_proposals[0]?.pickup_stop?.name || '-';
        } else {
          return params.row.ride_proposals[0]?.pickup_stop?.name || '-';
        }
      }
    },
    {
      field: 'pickup_time',
      headerName: props.t('pickup_time'),
      ...centerStylesWide,
      type: 'dateTime',
      valueGetter: (params) => {
        if (params.row.item && params.row.item.ride_proposals[0]?.pickup_time) {
          const newValue = new Date(params.row.item.ride_proposals[0]?.pickup_time);
          return newValue;
        } else if (params.row.ride_proposals && params.row.ride_proposals[0]?.pickup_time) {
          const newValue = new Date(params.row.ride_proposals[0]?.pickup_time);
          return newValue;
        } else {
          return 0;
        }
      },
      valueFormatter: (params) => {
        if (params.row.item && params.row.item.ride_proposals[0]?.pickup_time) {
          const newFormat = dateTimeFormat(params.row.item.ride_proposals[0]?.pickup_time);
          return newFormat;
        } else if (params.row.ride_proposals && params.row.ride_proposals[0]?.pickup_time) {
          const newFormat = dateTimeFormat(params.row.ride_proposals[0]?.pickup_time);
          return newFormat;
        } else {
          return ' - ';
        }
      }
    },
    {
      field: 'dropoff_stop',
      headerName: props.t('dropoff_stop'),
      ...centerStylesWide,
      valueGetter: (params) => {
        if (params.row.item) {
          return params.row.item.ride_proposals[0]?.dropoff_stop?.name || '-';
        } else {
          return params.row.ride_proposals[0]?.dropoff_stop?.name || '-';
        }
      }
    },
    {
      field: 'dropoff_time',
      headerName: props.t('dropoff_time'),
      ...centerStylesWide,
      type: 'dateTime',
      valueGetter: (params) => {
        if (params.row.item && params.row.item.ride_proposals[0]?.dropoff_time) {
          const newValue = new Date(params.row.item.ride_proposals[0]?.dropoff_time);
          return newValue;
        } else if (params.row.ride_proposals && params.row.ride_proposals[0]?.dropoff_time) {
          const newValue = new Date(params.row.ride_proposals[0]?.dropoff_time);
          return newValue;
        } else {
          return 0;
        }
      },
      valueFormatter: (params) => {
        if (params.row.item && params.row.item.ride_proposals[0]?.dropoff_time) {
          const newFormat = params.row.item.ride_proposals[0]?.dropoff_time;
          return dateTimeFormat(newFormat);
        } else if (params.row.ride_proposals && params.row.ride_proposals[0]?.dropoff_time) {
          const newFormat = params.row.ride_proposals[0]?.dropoff_time;
          return dateTimeFormat(newFormat);
        } else {
          return ' - ';
        }
      }
    },
    {
      field: 'arrival_time',
      headerName: props.t('arrival'),
      ...centerStylesWide,
      type: 'dateTime',
      valueGetter: (params) => {
        if (params.row.item && params.row.item.ride_proposals[0]?.arrival_time) {
          const newValue = new Date(params.row.item.ride_proposals[0]?.arrival_time);
          return newValue;
        } else if (params.row.ride_proposals && params.row.ride_proposals[0]?.arrival_time) {
          const newValue = new Date(params.row.ride_proposals[0]?.arrival_time);
          return newValue;
        } else {
          return 0;
        }
      },
      valueFormatter: (params) => {
        if (params.row.item && params.row.item.ride_proposals[0]?.arrival_time) {
          const newFormat = dateTimeFormat(params.row.item.ride_proposals[0]?.arrival_time);
          return newFormat;
        } else if (params.row.ride_proposals && params.row.ride_proposals[0]?.arrival_time) {
          const newFormat = dateTimeFormat(params.row.ride_proposals[0]?.arrival_time);
          return newFormat;
        } else {
          return ' - ';
        }
      }
    },
    {
      field: 'lateness',
      headerName: props.t('lateness'),
      ...centerStyles,
      sortable: false,
      renderCell: (params) => {
        let pickupTimeOriginal;
        let actualArrivalTime;

        if (params.row?.item) {
          pickupTimeOriginal =
            new Date(params.row.item?.ride_proposals?.[0]?.pickup_time_original).getTime() / 1000;
          actualArrivalTime =
            new Date(
              params.row.item?.ride_proposals?.[0]?.route?.route_stops?.[0]?.actual_arrival_time
            ).getTime() / 1000;
        } else {
          pickupTimeOriginal =
            new Date(params.row.ride_proposals?.[0]?.pickup_time_original).getTime() / 1000;
          actualArrivalTime =
            new Date(
              params.row.ride_proposals?.[0]?.route?.route_stops?.[0]?.actual_arrival_time
            ).getTime() / 1000;
        }

        let lateness;
        if (pickupTimeOriginal && actualArrivalTime) {
          lateness = ((actualArrivalTime - pickupTimeOriginal) / 60).toFixed(0);
        }

        return (
          <div style={{ color: lateness > 0 ? colors.blaiseRed : colors.blaiseGreen }}>
            {lateness > 0 ? `+${lateness}` : lateness < 0 ? lateness : '-'}
          </div>
        );
      }
    },
    {
      field: 'trip_price',
      headerName: props.t('trip_price'),
      ...centerStylesWide,
      valueGetter: (params) => {
        if (params.row.item) {
          return params.row.item.trip_price ? (params.row.item.trip_price / 100).toFixed(2) : '-';
        } else {
          return params.row.trip_price ? (params.row.trip_price / 100).toFixed(2) : '-';
        }
      }
    },
    {
      field: 'request_date',
      headerName: props.t('request_date'),
      ...centerStylesWide,
      type: 'dateTime',
      valueGetter: (params) => {
        if (params.row.item && params.row.item.request_date) {
          const newValue = new Date(params.row.item.request_date);
          return newValue;
        } else if (params.row.request_date) {
          const newValue = new Date(params.row.request_date);
          return newValue;
        } else {
          return 0;
        }
      },
      valueFormatter: (params) => {
        if (params.row.item && params.row.item.request_date) {
          const newFormat = dateTimeFormat(params.row.item.request_date);
          return newFormat;
        } else if (params.row.request_date) {
          const newFormat = dateTimeFormat(params.row.request_date);
          return newFormat;
        } else {
          return ' - ';
        }
      }
    },
    {
      field: 'request_source',
      headerName: props.t('request_source'),
      ...centerStylesWide,
      valueGetter: (params) => {
        if (params.row.item) {
          return props.t(requestSourceTypes[params.row.item.request_source]) || '-';
        } else {
          return props.t(requestSourceTypes[params.row.request_source]) || '-';
        }
      }
    },
    {
      field: 'payment_type',
      headerName: props.t('payment_type'),
      ...centerStylesWide,
      valueGetter: (params) => {
        if (params.row.item) {
          return props.t(params.row.item.payment_type) || '-';
        } else {
          return props.t(params.row.payment_type) || '-';
        }
      }
    },
    {
      field: 'origin_name',
      headerName: props.t('origin'),
      ...centerStylesWide,
      valueGetter: (params) => {
        if (params.row.item) {
          return params.row.item.origin_name || '-';
        } else {
          return params.row.origin_name || '-';
        }
      }
    },
    {
      field: 'dest_name',
      headerName: props.t('destination'),
      ...centerStylesWide,
      valueGetter: (params) => {
        if (params.row.item) {
          return params.row.item.dest_name || '-';
        } else {
          return params.row.dest_name || '-';
        }
      }
    },
    {
      field: 'nondisabled_demand',
      headerName: props.t('nondisabled_demand'),
      ...centerStylesWide,
      valueGetter: (params) => {
        if (params.row.item) {
          return params.row?.item.nondisabled_demand || '-';
        } else {
          return params.row?.nondisabled_demand || '-';
        }
      }
    },
    {
      field: 'wheelchair_demand',
      headerName: props.t('wheelchair_demand'),
      ...centerStylesWide,
      valueGetter: (params) => {
        if (params.row.item) {
          return params.row?.item.wheelchair_demand || '-';
        } else {
          return params.row?.wheelchair_demand || '-';
        }
      }
    },
    {
      field: 'bike_demand',
      headerName: props.t('bike_demand'),
      ...centerStylesWide,
      valueGetter: (params) => {
        if (params.row.item) {
          return params.row?.item.bike_demand || '-';
        } else {
          return params.row?.bike_demand || '-';
        }
      }
    },
    {
      field: 'stripe_receipt_url',
      headerName: props.t('stripe_receipt_url'),
      ...centerStyles,
      renderCell: (params) => {
        return (
          <>
            {params.row?.item?.stripe_receipt_url ||
              (params.row?.stripe_receipt_url && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={params.row?.item?.stripe_receipt_url || params.row?.stripe_receipt_url}
                  className="viewReceipt"
                >
                  {props.t('stripe_receipt_url')}
                </a>
              ))}
          </>
        );
      },
      sortable: false
    },
    {
      field: 'phone_number',
      headerName: props.t('phone_number'),
      ...centerStylesWide,
      valueGetter: (params) => {
        if (params.row.item) {
          return params.row.item.passenger.phone_number || '-';
        } else {
          return params.row.passenger.phone_number || '-';
        }
      }
    },
    {
      field: 'has_boarded',
      headerName: props.t('has_boarded'),
      ...centerStylesWide,
      valueGetter: (params) => {
        if (params.row.item) {
          switch (params.row.item?.ride_proposals[0]?.has_boarded) {
            case null:
              return 'No';
            case 0:
              return 'No-show';
            case 1:
              return 'Yes';
            default:
              return '-';
          }
        } else {
          switch (params.row?.ride_proposals[0]?.has_boarded) {
            case null:
              return 'No';
            case 0:
              return 'No-show';
            case 1:
              return 'Yes';
            default:
              return '-';
          }
        }
      }
    },
    {
      field: 'cancellation_time',
      headerName: props.t('cancellation_time'),
      ...centerStylesWide,
      type: 'dateTime',
      valueGetter: (params) => {
        let cancellationTime;
        if (params.row.item) {
          cancellationTime =
            params.row?.item?.ride_proposals[0]?.cancellation_time ||
            params.row?.item?.ride_proposals[1]?.cancellation_time ||
            '-';
        } else {
          cancellationTime =
            params.row?.ride_proposals[0]?.cancellation_time ||
            params.row?.ride_proposals[1]?.cancellation_time ||
            '-';
        }
        if (cancellationTime !== '-') {
          return dateTimeFormat(cancellationTime);
        }
        return cancellationTime;
      }
    },
    {
      field: 'passenger_rating',
      headerName: props.t('passenger_rating'),
      ...centerStylesWide,
      valueGetter: (params) => {
        if (params.row.item) {
          return params.row.item.ride_proposals[0]?.ride_actual?.passenger_rating || '-';
        } else {
          return params.row.ride_proposals[0]?.ride_actual?.passenger_rating || '-';
        }
      }
    },
    {
      field: 'passenger_comment',
      headerName: props.t('passenger_comment'),
      ...centerStylesWide,
      valueGetter: (params) => {
        if (params.row.item) {
          return params.row.item.ride_proposals[0]?.ride_actual?.passenger_comment || '-';
        } else {
          return params.row.ride_proposals[0]?.ride_actual?.passenger_comment || '-';
        }
      }
    },
    {
      field: 'shift_id',
      headerName: props.t('route_id'),
      ...centerStyles,
      renderCell: (params) => {
        return (
          <>
            {params.row?.item?.ride_proposals[0]?.route?.shift_id && (
              <Link
                to={{
                  pathname: `/routesForShift/${params.row?.item?.ride_proposals[0]?.route?.shift_id}`,
                  hash: `${params.row?.item?.ride_proposals[0]?.route?.route_id}`
                }}
                style={{ color: colors.black, textDecoration: 'none' }}
              >
                <h3>{params.row?.item?.ride_proposals[0]?.route?.route_id}</h3>
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  color={colors.blaiseGreen}
                  size="lg"
                  style={{ marginLeft: '10px' }}
                />
              </Link>
            )}
            {params.row?.ride_proposals[0]?.route?.shift_id && (
              <Link
                to={{
                  pathname: `/routesForShift/${params.row?.ride_proposals[0]?.route?.shift_id}`,
                  hash: `${params.row?.ride_proposals[0]?.route?.route_id}`
                }}
                style={{ color: colors.black, textDecoration: 'none' }}
              >
                {params.row?.ride_proposals[0]?.route?.route_id}
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  color={colors.blaiseGreen}
                  size="lg"
                  style={{ marginLeft: '10px' }}
                />
              </Link>
            )}
          </>
        );
      },
      sortable: false
    },
    {
      field: 'button',
      headerName: props.t('actions'),
      ...centerStyles,
      renderCell: (params) => {
        const tripRideActual =
          params.row?.ride_proposals?.[0]?.ride_actual?.ride_actual_id ||
          params.row?.item?.ride_proposals?.[0]?.ride_actual?.ride_actual_id;
        const enableReviewTripStatus = params.row?.status === 'completed';
        return (
          <>
            {enableReviewTripStatus && !tripRideActual && (
              <div style={{ padding: 5 }}>
                <Fab
                  color="primary"
                  size="small"
                  variant="extended"
                  onClick={() => {
                    let tripRequestInfo = params.row.item || params.row;
                    setReviewRideInfo(tripRequestInfo);
                    setReviewModalOpen(true);
                  }}
                >
                  <FontAwesomeIcon icon={faStar} color="white" size="1x" />
                </Fab>
              </div>
            )}
            <div style={{ padding: 5 }}>
              <Fab
                color="primary"
                size="small"
                variant="extended"
                onClick={() => {
                  let tripRequestId =
                    params.row.trip_request_id || params.row.item?.trip_request_id;
                  history.push(`/history/${tripRequestId}`);
                }}
              >
                <FontAwesomeIcon icon={faInfo} color="white" size="1x" />
              </Fab>
            </div>
          </>
        );
      },
      sortable: false
    }
  ];

  return (
    <>
      {reviewModalOpen && (
        <ReviewModal
          {...props}
          modalOpen={reviewModalOpen}
          modalTitle={props.t('review_for_trip_engine') + reviewRideInfo.trip_request_id}
          modalFields={['add_a_comment']}
          reviewRating={reviewRating}
          setReviewRating={handleReviewRating}
          handleChange={handleReviewCommentChange}
          handleReviewSubmit={handleReviewSubmit}
          handleModalClose={() => setReviewModalOpen(!reviewModalOpen)}
        />
      )}
      <CustomSnackbar
        message={snackbar.message}
        open={snackbar.open}
        onClose={() => closeSnackbar()}
        snackbarColor={snackbar.color}
      />
      <div className="pageTitle">{props.t('ride_management_title')}</div>
      {loading ? (
        <div className="blaise-progress-top">
          <CircularProgress />
        </div>
      ) : (
        <Paper>
          <Box
            className="titleSearch"
            display="flex"
            justifyContent="space-between"
            style={{ padding: '16px 0' }}
          >
            <Box display="flex" style={{ gap: '32px' }}>
              <Box display="flex" style={{ gap: '32px' }}>
                <FormControl>
                  <InputLabel>{props.t('search_label')}</InputLabel>
                  <Select
                    value={fieldSearchInfo?.column}
                    onChange={(event) => {
                      event.preventDefault();
                      handleSearchColumnChange(event);
                    }}
                    style={{ width: '7rem' }}
                  >
                    {columns.map((column) => {
                      if (!column.hide && !nonSearchableColumns.includes(column.field)) {
                        return (
                          <MenuItem value={column.field}>{props.t(`${column.field}`)}</MenuItem>
                        );
                      }
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box
                className="tripsSearch"
                display="flex"
                justifyContent="flex-start"
                marginTop="2px"
              >
                {showSearchBar ? (
                  <TextField
                    label={props.t('search')}
                    value={fieldSearchInfo?.query}
                    onChange={handleSearchQueryChange}
                  />
                ) : null}
              </Box>
              <Box>
                <FormControl>
                  <InputLabel>{props.t('status')}</InputLabel>
                  <Select
                    value={statusFilter}
                    onChange={(e) => {
                      e.preventDefault();
                      changeStatusFilter(e.target.value);
                    }}
                    style={{ width: '7rem' }}
                  >
                    <MenuItem value="all">{props.t('history_all')}</MenuItem>
                    <MenuItem value="processing">{props.t('history_pending')}</MenuItem>
                    <MenuItem value="accepted_or_confirmed">{props.t('history_upcoming')}</MenuItem>
                    <MenuItem value="cancelled">{props.t('history_cancelled')}</MenuItem>
                    <MenuItem value="rejected">{props.t('history_rejected')}</MenuItem>
                    <MenuItem value="completed">{props.t('history_completed')}</MenuItem>
                    <MenuItem value="no_show">{props.t('history_no_show')}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <LocalizationProvider dateAdapter={MomentUtils}>
                  <DatePicker
                    label={props.t('start')}
                    inputVariant="outlined"
                    value={filterStartDate ? filterStartDate : ' '}
                    onChange={(date) => setFilterDate(date?.format(), FilterType.START)}
                    style={{ width: '100%' }}
                    renderInput={(props) => {
                      return (
                        <TextField {...props} variant="outlined" helperText={null} error={false} />
                      );
                    }}
                  />
                </LocalizationProvider>
              </Box>
              <Box>
                <LocalizationProvider dateAdapter={MomentUtils}>
                  <DatePicker
                    label={props.t('end')}
                    inputVariant="outlined"
                    value={filterEndDate}
                    onChange={(date) => setFilterDate(date?.format(), FilterType.END)}
                    style={{ width: '100%' }}
                    renderInput={(props) => {
                      return (
                        <TextField {...props} variant="outlined" helperText={null} error={false} />
                      );
                    }}
                  />
                </LocalizationProvider>
              </Box>
              <Box>
                <ToggleButtonGroup
                  value={dateFilterSelection}
                  exclusive
                  onChange={(e, newValue) => {
                    setDateFilterSelection(newValue);
                  }}
                  aria-label="date filter selection"
                  style={{ height: '100%' }}
                >
                  <ToggleButton
                    value={DateType.YESTERDAY}
                    aria-label={DateType.YESTERDAY}
                    style={{ color: colors.blaiseGray }}
                  >
                    {props.t('yesterday')}
                  </ToggleButton>
                  <ToggleButton
                    value={DateType.TODAY}
                    aria-label={DateType.TODAY}
                    style={{ color: colors.blaiseGray }}
                  >
                    {props.t('today')}
                  </ToggleButton>
                  <ToggleButton
                    value={DateType.TOMORROW}
                    aria-label={DateType.TOMORROW}
                    style={{ color: colors.blaiseGray }}
                  >
                    {props.t('tomorrow')}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Box>
            <Box style={{ paddingLeft: '2rem', display: 'flex' }}>
              <Button
                id="basic-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                aria-expanded={isSettingsMenuOpen ? 'true' : undefined}
                onClick={(event) => setAnchorEl(event.currentTarget)}
              >
                <FontAwesomeIcon icon={faCog} color={colors.blaiseGray} size="2x" />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={isSettingsMenuOpen}
                getContentAnchorEl={null}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
              >
                <MenuItem onClick={resetHiddenColumns}>{props.t('reset_hidden_columns')}</MenuItem>
                <MenuItem onClick={resetSortedColumn}>{props.t('reset_sorted_column')}</MenuItem>
              </Menu>
            </Box>
          </Box>
          {tripsCopy && (
            <DataGridTablePagination
              rows={tripsCopy}
              rowCount={tablePageParameters?.rowCount}
              columns={columns}
              checkboxSelection
              disableSelectionOnClick
              sortModel={sortModel}
              handleSortModelChange={setSortModel}
              hiddenColumns={hiddenColumns}
              handleSetHiddenColumns={setHiddenColumns}
              pageKey={LocalStorageKeys.RIDE_MANAGEMENT_SORTED_COLUMN}
              tablePageParameters={tablePageParameters}
              setPageSize={setTablePageParameters}
              setPageNumber={setTablePageParameters}
            />
          )}
        </Paper>
      )}
    </>
  );
};

export default withTranslation('common')(withAuth0(RideManagement));
