import React, { useEffect, useState } from 'react';
import authenticatedAxiosInstance from '../../axios/axios-authorized';
import ConfirmationDialog from '../Modals/ConfirmationDialog';
import CustomSnackbar from '../../components/Snackbar/CustomSnackbar';
import DataGridTable from '../../components/DataGrid/DataGridTable';
import PassengersModal from '../PassengersModal/PassengersModal';
import RouteManifestModal from './RouteManifestModal';

import { Box, Button, CircularProgress, Fab, Grid, TextField, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faCheck,
  faTimes,
  faArrowDown,
  faArrowUp
} from '@fortawesome/free-solid-svg-icons';
import colors from '../../assets/sass/colors';

import { checkPermissions } from '../../utils/authUtils';
import { RouteStatus } from '../../enums/statusType';
import { timeOnlyFormat } from '../../utils/MomentTime';

const RouteDetails = ({ auth0, t, route, setTablesLoaded }) => {
  const [loading, setLoading] = useState(false);
  const [routeStops, setRouteStops] = useState(null);
  const [passengersModalVisible, setPassengersModalVisible] = useState(false);
  const [stopForModal, setStopForModal] = useState(null);
  const [routeStatus, setRouteStatus] = useState(null);
  const [openManifest, setOpenManifest] = useState(false);
  const [stopActionsConfirmation, setStopActionsConfirmation] = useState({
    open: false,
    type: ''
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: null
  });

  const hasSAdminPermissions = checkPermissions(auth0, 'execute:switchCurrentTA');

  useEffect(() => {
    const routeId = route.route_id;
    fetchRouteStops(routeId);
    fetchRouteStatus(route);
  }, [route]);

  const fetchRouteStops = async (routeId) => {
    if (!stopActionsConfirmation.open) {
      setLoading(true);
    }

    try {
      const response = await authenticatedAxiosInstance.axios.get(`/allRoutestops`, {
        params: {
          idArray: [routeId]
        }
      });

      if (response?.data) {
        setRouteStops(response.data);
      }
    } catch (err) {
      console.log(err);
      openSnackbar(t('error_fetching_routes'), colors.red);
    } finally {
      if (!stopActionsConfirmation.open) {
        setLoading(false);
      }
      setTablesLoaded((prevState) => [...prevState, true]);
    }
  };

  const fetchRouteStatus = (route) => {
    if (route.is_completed === 1) {
      setRouteStatus(RouteStatus.COMPLETED);
    } else if (route.is_active === 1) {
      setRouteStatus(RouteStatus.ONGOING);
    } else if (
      route.is_completed === 0 &&
      route.is_active === 0 &&
      route.current_stop_sequence === 0
    ) {
      setRouteStatus(RouteStatus.UPCOMING);
    } else {
      setRouteStatus(RouteStatus.UNKNOWN);
    }
  };

  const calculateLateness = (stop) => {
    const arrivedTimeInSeconds = new Date(stop.actual_arrival_time).getTime() / 1000;
    let pickupDropoffOriginalTimes = [];

    if (stop.bus_stop.pickup_stop.length > 0) {
      const pickups = stop.bus_stop.pickup_stop;
      pickups.map((pickup) => {
        const timeInSeconds = new Date(pickup.pickup_time_original).getTime() / 1000;
        if (timeInSeconds) {
          pickupDropoffOriginalTimes.push(timeInSeconds);
        }
      });
    }

    if (stop.bus_stop.dropoff_stop.length > 0) {
      const dropoffs = stop.bus_stop.dropoff_stop;
      dropoffs.map((dropoff) => {
        const timeInSeconds = new Date(dropoff.dropoff_time_original).getTime() / 1000;
        if (timeInSeconds) {
          pickupDropoffOriginalTimes.push(timeInSeconds);
        }
      });
    }

    // If no pickup or dropoff (eg. from cancellation):
    if (pickupDropoffOriginalTimes.length === 0) {
      return;
    }

    const earliestRideTime = Math.min(...pickupDropoffOriginalTimes);
    const earliestTimeAtStop = new Date(earliestRideTime * 1000);

    let lateness = ((arrivedTimeInSeconds - earliestRideTime) / 60).toFixed(0);
    if (lateness === '-0') {
      // for example: if the lateness returned is -0.15, it will be rounded to -0 but we want 0 in the column
      lateness = 0;
    }

    return { earliestTimeAtStop, lateness };
  };

  const getArrivalDepartTime = (actualTime, predictedTime) => {
    let arriveDepartTime;
    let usePredictedTime = false;

    if (actualTime) {
      arriveDepartTime = timeOnlyFormat(actualTime);
    } else if (predictedTime) {
      arriveDepartTime = timeOnlyFormat(predictedTime);
      usePredictedTime = true;
    } else {
      arriveDepartTime = ' - ';
    }

    return { arriveDepartTime, usePredictedTime };
  };

  const openSnackbar = (snackbarString, color) => {
    setSnackbar({ ...snackbar, open: true, message: snackbarString, color: color });
  };

  const closeSnackbar = (reason) => {
    if (reason !== 'clickaway') {
      setSnackbar({ ...snackbar, open: false });
    }
  };

  const handlePassengerModal = (stop) => {
    setStopForModal(stop);
    setPassengersModalVisible(true);
  };

  const handleForceStopArrival = async () => {
    try {
      const arrivalTime = Math.floor(Date.now() / 1000);

      await authenticatedAxiosInstance.axios.put(`/route/${route.route_id}/stoparrival`, {
        arrivalTime
      });

      const start = new Date().getTime();
      // Refresh at 5sec and 10sec after forcing arrival (algo can take up to 10sec to insert a new stop)
      const interval = setInterval(() => {
        if (new Date().getTime() - start > 11000) {
          clearInterval(interval);
          openSnackbar(t('force_arrival_success'), colors.blaiseGreen);
          setStopActionsConfirmation({ open: false, type: '' });
          return;
        }

        fetchRouteStops(route.route_id);
      }, 5000);
    } catch (err) {
      console.log(err);
      setStopActionsConfirmation({ open: false, type: '' });
      openSnackbar(t('error_generic'), colors.red);
    }
  };

  const handleForceStopDeparture = async () => {
    try {
      const departureTime = Math.floor(Date.now() / 1000);

      await authenticatedAxiosInstance.axios.post(`/route/${route.route_id}/stopdeparture`, {
        departureTime
      });

      fetchRouteStops(route.route_id);
      openSnackbar(t('force_departure_success'), colors.blaiseGreen);
    } catch (err) {
      console.log(err);
      openSnackbar(t('error_generic'), colors.red);
    } finally {
      setStopActionsConfirmation({ open: false, type: '' });
    }
  };

  const columnStylesSmall = {
    flex: 0.75,
    align: 'center',
    headerAlign: 'center'
  };

  const columnStyles = { flex: 1, align: 'center', headerAlign: 'center' };

  const columns = [
    {
      field: 'stop_sequence',
      headerName: '#',
      ...columnStylesSmall,
      sortable: false,
      disableColumnMenu: true,
      flex: 0.5
    },
    {
      field: 'bus_stop',
      headerName: t('bus_stop'),
      ...columnStyles,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.bus_stop.name}>
            <Box>{params.row.bus_stop.name}</Box>
          </Tooltip>
        );
      }
    },
    {
      field: 'arrival_time',
      headerName: t('arrival_time'),
      renderCell: (params) => {
        const arrivalResponse = getArrivalDepartTime(
          params.row.actual_arrival_time,
          params.row.arrival_time
        );
        const arrivalTime = arrivalResponse?.arriveDepartTime;
        const useArrivalPrediction = arrivalResponse?.usePredictedTime;

        return (
          <div>
            {arrivalTime}
            {useArrivalPrediction && <span style={{ fontSize: '0.85em' }}>{t('predicted')}</span>}
          </div>
        );
      },
      ...columnStylesSmall
    },
    {
      field: 'has_arrived',
      headerName: t('has_arrived'),
      renderCell: (params) => {
        return (
          <>
            {params.id === 0 ? (
              ' - '
            ) : params.row.has_arrived ? (
              <FontAwesomeIcon icon={faCheck} color={colors.blaiseGreen} size="1x" />
            ) : (
              <FontAwesomeIcon icon={faTimes} color={colors.blaiseGray} size="1x" />
            )}
          </>
        );
      },
      ...columnStylesSmall,
      sortable: false
    },
    {
      field: 'departure_time',
      headerName: t('departure_time'),
      renderCell: (params) => {
        const departureResponse = getArrivalDepartTime(
          params.row.actual_departure_time,
          params.row.departure_time
        );

        let departureTime = departureResponse?.arriveDepartTime;
        let useDeparturePrediction = departureResponse?.usePredictedTime;

        if (params.id === routeStops.length - 1) {
          departureTime = ' - ';
          useDeparturePrediction = false;
        }

        return (
          <div>
            {departureTime}
            {useDeparturePrediction && <span style={{ fontSize: '0.85em' }}>{t('predicted')}</span>}
          </div>
        );
      },
      ...columnStylesSmall
    },
    {
      field: 'has_departed',
      headerName: t('has_departed'),
      renderCell: (params) => {
        return (
          <>
            {params.id === routeStops.length - 1 ? (
              ' - '
            ) : params.row.has_departed ? (
              <FontAwesomeIcon icon={faCheck} color={colors.blaiseGreen} size="1x" />
            ) : (
              <FontAwesomeIcon icon={faTimes} color={colors.blaiseGray} size="1x" />
            )}
          </>
        );
      },
      ...columnStylesSmall,
      sortable: false
    },
    {
      field: 'earliestPromisedTime',
      headerName: t('earliest_promised_time'),
      ...columnStyles
    },
    {
      field: 'lateness',
      headerName: t('lateness'),
      renderCell: (params) => {
        return (
          <div
            style={{
              fontWeight: 'bold',
              color: params.row.late > 0 ? colors.blaiseRed : colors.blaiseGreen
            }}
          >
            {params.row.late > 0 ? `+${params.row.late}` : params.row.late}
          </div>
        );
      },
      ...columnStylesSmall
    },
    {
      field: 'button',
      headerName: t('actions'),
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-evenly"
            alignItems="center"
            width="100%"
          >
            <Tooltip title={t('view_passengers')}>
              <Fab
                color="primary"
                size="small"
                variant="extended"
                onClick={() => {
                  handlePassengerModal(params.row);
                }}
              >
                <FontAwesomeIcon icon={faUser} color="white" size="1x" />
              </Fab>
            </Tooltip>
            {hasSAdminPermissions && (
              <>
                <Tooltip title={t('force_arrive')}>
                  <Fab
                    color="primary"
                    size="small"
                    variant="extended"
                    onClick={() => {
                      setStopActionsConfirmation({ open: true, type: 'arrival' });
                    }}
                    disabled={params.row?.has_arrived || params.id === 0 ? true : false}
                  >
                    <FontAwesomeIcon icon={faArrowDown} color="white" size="1x" />
                  </Fab>
                </Tooltip>
                <Tooltip title={t('force_depart')}>
                  <Fab
                    color="primary"
                    size="small"
                    variant="extended"
                    onClick={() => {
                      setStopActionsConfirmation({ open: true, type: 'departure' });
                    }}
                    disabled={params.row?.has_departed ? true : false}
                    style={params.row?.has_departed ? { pointerEvents: 'none' } : {}}
                  >
                    <FontAwesomeIcon icon={faArrowUp} color="white" size="1x" />
                  </Fab>
                </Tooltip>
              </>
            )}
          </Box>
        );
      },
      sortable: false,
      disableColumnMenu: true,
      ...columnStylesSmall,
      ...(!hasSAdminPermissions && { flex: 0.5 })
    }
  ];

  let routeStopsCopy;
  if (routeStops) {
    routeStopsCopy = routeStops.map((stop, index) => {
      // Get earliest promised time and lateness for each stop
      let isLate;
      if (
        stop.actual_arrival_time &&
        (stop.bus_stop.pickup_stop.length > 0 || stop.bus_stop.dropoff_stop.length > 0)
      ) {
        isLate = calculateLateness(stop);
      }

      return {
        ...stop,
        id: index,
        earliestPromisedTime: isLate ? timeOnlyFormat(isLate.earliestTimeAtStop) : t('history_na'),
        late: isLate ? isLate.lateness : ' - '
      };
    });
  }

  return (
    <>
      <CustomSnackbar
        message={snackbar.message}
        open={snackbar.open}
        onClose={() => closeSnackbar()}
        snackbarColor={snackbar.color}
      />
      {stopActionsConfirmation.open && (
        <ConfirmationDialog
          open={stopActionsConfirmation.open}
          onClose={() => setStopActionsConfirmation({ open: false, type: '' })}
          confirmAction={
            stopActionsConfirmation.type === 'arrival'
              ? handleForceStopArrival
              : handleForceStopDeparture
          }
          text={t('force_stop_action_warning')}
        />
      )}
      {route && openManifest && (
        <RouteManifestModal
          auth0={auth0}
          t={t}
          modalOpen={openManifest}
          setModalOpen={setOpenManifest}
          routeToShow={route}
        />
      )}
      {stopForModal && passengersModalVisible && (
        <PassengersModal
          auth0={auth0}
          t={t}
          stop={stopForModal}
          openModal={passengersModalVisible}
          setModalVisible={setPassengersModalVisible}
        />
      )}
      <Box my={8}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              name="id"
              value={route.route_id}
              variant="outlined"
              fullWidth
              id="id"
              label={t('route_id')}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              name="departure"
              value={route.departure_time ? timeOnlyFormat(route.departure_time) : ' - '}
              variant="outlined"
              fullWidth
              id="day"
              label={t('departure_time')}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              name="status"
              value={routeStatus ? t(`history_${routeStatus}`) : ' - '}
              variant="outlined"
              fullWidth
              id="status"
              label={t('status')}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              fullWidth
              variant="contained"
              onClick={() => setOpenManifest(!openManifest)}
              color="primary"
              id="openManifest"
              style={{ height: '95%' }}
            >
              {t('view_extended_manifest')}
            </Button>
          </Grid>
          {loading && !routeStopsCopy && (
            <Grid container item xs={12} justify="center">
              <CircularProgress />
            </Grid>
          )}
          {routeStopsCopy && (
            <Grid item xs={12}>
              <DataGridTable columns={columns} rows={routeStopsCopy} />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default RouteDetails;
