import React, { Component } from 'react';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Dropzone.scss';
import { withTranslation } from 'react-i18next';

/**
 * Dropzone component to allow drag and drop files. Child component of Upload.
 *
 * TODO: Translation
 *
 * Props:
 * disabled {Boolean} Disables the dropzone interaction
 * onFilesAdded {Function} A function from the parent that processes the files added
 * multiple {Boolean} Allows the user to select multiple files. Only for <input>, not for drag and drop function
 * allowedFileTypes {Array} Optional. Default: []. An array of file extensions (with the dot). Eg: [.jpg, .jpeg]. An empty array allows any file type to be uploaded
 */
class Dropzone extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();

    this.state = {
      hightlight: false
    };
  }

  /**
   * Opens the file dialog when the dropzone is clicked.
   */
  openFileDialog = () => {
    if (this.props.disabled) return;

    this.fileInputRef.current.click();
  };

  /**
   * Called when a file has been selected or dropped.
   * @param {*} evt
   */
  onFilesAdded = (evt) => {
    if (this.props.disabled) return;

    const files = evt.target.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
  };

  /**
   * Helper method that converts the file list into an array.
   * @param {*} list File list
   */
  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  /**
   * Called when a file is dragged over the dropzone. Highlights the dropzone box.
   * @param {*} evt
   */
  onDragOver = (evt) => {
    evt.preventDefault();

    if (this.props.disabled) return;

    this.setState({ hightlight: true });
  };

  /**
   * When done dragging. Resets highlight.
   */
  onDragLeave = () => {
    this.setState({ hightlight: false });
  };

  /**
   * When the file is dropped into the dropzone
   * @param {*} evt
   */
  onDrop = (evt) => {
    evt.preventDefault();

    if (this.props.disabled) return;

    const files = evt.dataTransfer.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
    this.setState({ hightlight: false });
  };

  render() {
    return (
      <div
        className={`Dropzone ${this.state.hightlight ? 'Highlight' : ''}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        style={{ cursor: this.props.disabled ? 'default' : 'pointer' }}
      >
        <FontAwesomeIcon icon={faUpload} />
        <input
          ref={this.fileInputRef}
          className="FileInput"
          type="file"
          multiple={this.props.multiple}
          onChange={this.onFilesAdded}
          accept={this.props.allowedFileTypes.toString()}
        />
        <span>{this.props.t('upload_file_instructions')}</span>
        <span>
          {this.props.t('allowed_file_types')}:{' '}
          {this.props.allowedFileTypes.join(', ') || this.props.t('geojson')}
        </span>
      </div>
    );
  }
}

export default withTranslation('common')(Dropzone);
