import React from 'react';
import { Box, Button, CircularProgress, Dialog, DialogContent } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
const DisconnectTabletModal = ({ open, onClose, isSubmitting, text, t, handleDisconnectTable }) => {
  return (
    <Dialog open={open} onClose={onClose} className="deleteDialog">
      <DialogContent>
        <Box display="flex" flexDirection="row" justifyContent="space-between" mb={2}>
          {text}
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="center" mt={2}>
          {isSubmitting ? (
            <CircularProgress />
          ) : (
            <>
              <Button variant="outlined" onClick={onClose} color="primary" id="cancelButton">
                {t('cancel')}
              </Button>
              <Button
                variant="contained"
                onClick={handleDisconnectTable}
                color="secondary"
                id="confirmButton"
              >
                {t('deregister')}
              </Button>
            </>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default withTranslation('common')(DisconnectTabletModal);
