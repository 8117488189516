import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withTranslation } from 'react-i18next';
import './DeleteModal.scss';

class DeleteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteModal: this.props.open,
      isSubmitting: this.props.isSubmitting
    };
  }

  closeDeleteModal = () => {
    this.props.toggleModal();
  };

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const newState = {};
    if (nextProps.open !== prevState.showDeleteModal) {
      newState.showDeleteModal = nextProps.open;
    }

    if (nextProps.isSubmitting !== prevState.isSubmitting) {
      newState.isSubmitting = nextProps.isSubmitting;
    }

    return newState;
  };

  render() {
    return (
      <Dialog
        open={this.state.showDeleteModal}
        onClose={this.closeDeleteModal}
        className="deleteDialog"
      >
        <DialogContent>
          <Box display="flex" flexDirection="row" justifyContent="space-between" mb={2}>
            {this.props.deleteMessage}
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            mt={2}
            style={{ gap: '32px' }}
          >
            {this.state.isSubmitting ? (
              <CircularProgress />
            ) : (
              <>
                <Button variant="outlined" onClick={this.closeDeleteModal} color="secondary">
                  {this.props.t('cancel')}
                </Button>
                <Button
                  variant="contained"
                  onClick={(e) => this.props.onDeleteItem(e)}
                  color="secondary"
                >
                  {this.props.t('delete')}
                </Button>
              </>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withTranslation('common')(DeleteModal);
