import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
  Tab,
  Tabs,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Checkbox
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import './TicketBundles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDollarSign,
  faHourglassHalf,
  faUser,
  faSpinner
} from '@fortawesome/free-solid-svg-icons';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import colors from '../../assets/sass/colors';
import authenticatedAxiosInstance from '../../axios/axios-authorized';
import TicketBundleItem from '../../components/TicketBundleItem/TicketBundleItem';
import { creditCardDateFormat } from '../../utils/dateTimeFormat';
import { PaymentType } from '../../enums/PaymentType';

const cardOptions = {
  style: {
    base: {
      iconColor: colors.blaiseGreen,
      color: colors.black,
      fontSize: '18px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883'
      },
      '::placeholder': {
        color: colors.blaiseGray
      }
    },
    invalid: {
      iconColor: colors.red,
      color: colors.red
    }
  }
};

const TicketBundles = ({
  addCardToCustomersStripeAccount,
  savedCards,
  openSnackbar,
  passenger,
  passengerBalance,
  getPassengerBalance
}) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const [selectedBundle, setSelectedBundle] = useState(null);
  const [availableTicketBundles, setAvailableTicketBundles] = useState([]);
  const [ticketBundlePaymentMethod, setTicketBundlePaymentMethod] = useState(null);
  const [ticketToRedeem, setTicketToRedeem] = useState('');
  const [invalidRedemption, setInvalidRedemption] = useState(false);
  const [isPurchasingTicket, setIsPurchasingTicket] = useState(false);

  const [saveCardForLaterInitialCheckbox, setSaveCardForLaterInitialCheckbox] = useState(false);
  const [saveCardForLaterConsentCheckbox, setSaveCardForLaterConsentCheckbox] = useState(false);
  const [cardHasConsent, setCardHasConsent] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation('common');

  useEffect(() => {
    getTicketBundles();
  }, []);

  const switchTabPanel = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const getTicketBundles = async () => {
    try {
      const ticketBundles = (
        await authenticatedAxiosInstance.axios.get(
          `/transitagencies/${passenger.transit_agency_id}/${passenger.type}/bundles`
        )
      ).data;

      setAvailableTicketBundles(ticketBundles);
    } catch (error) {
      console.log('TicketBundles::getTicketBundles', error);
    }
  };

  const purchaseTicketBundle = async () => {
    try {
      setIsPurchasingTicket(true);
      let paymentId;
      if (ticketBundlePaymentMethod === PaymentType.PAY_WITH_NEW_CARD) {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement)
        });

        if (!error) {
          paymentId = paymentMethod.id;

          if (cardHasConsent) {
            try {
              await addCardToCustomersStripeAccount(paymentMethod.id);
            } catch (err) {
              console.log('TicketBundles::purchaseTicketBundle', error);
            }
          }
        }
      }

      const reqBody = {
        passenger_id: passenger.passenger_id,
        product_stripe_id: selectedBundle.product_stripe_id,
        payment_method_id:
          ticketBundlePaymentMethod === PaymentType.PAY_WITH_NEW_CARD
            ? paymentId
            : ticketBundlePaymentMethod,
        transit_agency_id: passenger.transit_agency_id,
        // if card has consent then passenger does not want to detach card after purchase (not a one time payment)
        is_one_time: ticketBundlePaymentMethod === PaymentType.PAY_WITH_NEW_CARD && !cardHasConsent
      };

      await authenticatedAxiosInstance.axios.post(`/bundle/purchase`, reqBody);

      getPassengerBalance();
      setSelectedBundle(null);
      setTicketBundlePaymentMethod(null);
      openSnackbar(t('ticket_balance_updated'), colors.blaiseGreen);
    } catch (error) {
      console.log('TicketBundles::purchaseTicketBundle', error);
    } finally {
      setIsPurchasingTicket(false);
    }
  };

  const isCardExpiryDateValid = (cardMonth, cardYear) => {
    // Months with Moment are 0 indexed, so +1 for corrent month.
    const currentMonth = moment().month() + 1;
    const currentYear = moment().year();

    if (currentYear >= cardYear && currentMonth > cardMonth) {
      return false;
    }

    return true;
  };

  const toggleSaveCardForLaterInitial = (checked) => {
    setSaveCardForLaterInitialCheckbox(checked);
    setSaveCardForLaterConsentCheckbox(false);
    setCardHasConsent(false);
  };

  const cancelSavedCardConsent = () => {
    setSaveCardForLaterInitialCheckbox(false);
    setSaveCardForLaterConsentCheckbox(false);
  };

  const validateAndRedeemTicket = async () => {
    try {
      setIsPurchasingTicket(true);
      const reqBody = { ticket_number: ticketToRedeem, taId: passenger.transit_agency_id };

      const ticketValidation = await authenticatedAxiosInstance.axios.post(
        `/ticket/validate`,
        reqBody
      );

      if (ticketValidation.data === 'INVALID') {
        setInvalidRedemption(true);
        return;
      } else {
        const reqBody = {
          passenger_id: passenger.passenger_id,
          ticket_number: ticketToRedeem,
          taId: passenger.transit_agency_id
        };
        const redeemTicket = authenticatedAxiosInstance.axios.put(`/ticket/use`, reqBody);
        const updateBalance = authenticatedAxiosInstance.axios.put(
          `/passengers/${passenger.passenger_id}/updatePassengerBalanceWTicket/${passenger.transit_agency_id}`,
          { ticket_number: ticketToRedeem }
        );
        await Promise.all([redeemTicket, updateBalance]);

        setTicketToRedeem('');
        getPassengerBalance();
        openSnackbar(t('ticket_balance_updated'), colors.blaiseGreen);
      }
    } catch (error) {
      console.log('TicketBundles::validateAndRedeemTicket', error);
    } finally {
      setIsPurchasingTicket(false);
    }
  };

  const creditAmount = selectedBundle?.product_metadata
    ? JSON.parse(selectedBundle.product_metadata)?.bundleCreditAmount
    : null;

  return (
    <Grid
      item
      sm={12}
      spacing={3}
      className="ticket-bundle-container"
      style={{
        marginTop: '16px'
      }}
    >
      <Typography
        variant="h5"
        className={
          passengerBalance === 0 && !selectedBundle ? 'insufficient-balance' : 'sufficient-balance'
        }
      >
        {t('blaise_balance')}: ${(passengerBalance / 100).toFixed(2)}
      </Typography>
      {passengerBalance === 0 && !selectedBundle && (
        <Typography variant="body2" style={{ color: colors.blaiseRed, marginTop: 5 }}>
          {t('insufficient_funds_warning')}
        </Typography>
      )}

      {/* Value proposition */}
      <Box>
        <Typography variant="h6" className="ticket-title">
          {t('tickets')}
        </Typography>
        <Box display="flex">
          <Box className="icon-container">
            <FontAwesomeIcon icon={faDollarSign} color={colors.black} size="lg" />
          </Box>
          <Typography variant="subtitle2">{t('add_funds')}</Typography>
        </Box>
        <Box display="flex">
          <Box className="icon-container">
            <FontAwesomeIcon icon={faHourglassHalf} color={colors.black} size="lg" />
          </Box>
          <Typography variant="subtitle2">{t('funds_dont_expire')}</Typography>
        </Box>
        <Box display="flex">
          <Box className="icon-container">
            <FontAwesomeIcon icon={faUser} color={colors.black} size="lg" />
          </Box>
          <Typography variant="subtitle2">{t('no_refunds')}</Typography>
        </Box>
      </Box>

      {/* Tabs */}
      <Box>
        <Box>
          <Tabs
            value={selectedTab}
            indicatorColor="primary"
            onChange={switchTabPanel}
            aria-label={t('aria_label_ticket_tabs')}
            className="tabs-container"
          >
            <Tab
              style={{ textTransform: 'initial', flexGrow: 1, maxWidth: '277px' }}
              label="Plans"
            />
            <Tab
              style={{ textTransform: 'initial', flexGrow: 1, maxWidth: '277px' }}
              label={t('redeem')}
            />
          </Tabs>
        </Box>
        <TabPanel selectedTab={selectedTab} index={0}>
          {/* Plans tab */}
          {availableTicketBundles.length > 0 && (
            <Box className="ticket-bundle-plans-container">
              {availableTicketBundles.map((ticketBundle) => {
                return (
                  <TicketBundleItem
                    key={ticketBundle?.name}
                    ticketBundle={ticketBundle}
                    setSelectedBundle={setSelectedBundle}
                    selectedBundle={selectedBundle}
                  />
                );
              })}
            </Box>
          )}

          {/* Ticket bundle payment options */}
          {selectedBundle && creditAmount && (
            <>
              <Box className="blaise-balance-purchase-summary">
                <Typography variant="h6">
                  {t('blaise_balance_will_be')} $
                  {((passengerBalance + Number(creditAmount)) / 100).toFixed(2)}
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6" className="ticket-title">
                  {t('payment_type')}
                </Typography>
                {ticketBundlePaymentMethod &&
                  ticketBundlePaymentMethod !== PaymentType.PAY_WITH_NEW_CARD && (
                    <Alert severity="warning" style={{ marginTop: '16px' }}>
                      {t('confirm_last4')}
                    </Alert>
                  )}
                <RadioGroup
                  aria-label="card options"
                  name="cardOptions"
                  onChange={(e) => {
                    setTicketBundlePaymentMethod(e.target.value);
                    toggleSaveCardForLaterInitial(false);
                  }}
                  value={ticketBundlePaymentMethod}
                  style={{ margin: '16px 0' }}
                >
                  {savedCards?.length > 0 &&
                    savedCards?.map((card) => {
                      return (
                        <Box
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '16px'
                          }}
                          key={card.card.fingerprint}
                        >
                          <FormControlLabel
                            value={card.id}
                            control={<Radio color="primary" />}
                            label={
                              <>
                                <Box style={{ textTransform: 'capitalize' }}>
                                  {card.card.brand} ****{card.card.last4}
                                </Box>
                                {isCardExpiryDateValid(card.card.exp_month, card.card.exp_year) ? (
                                  <Box style={{ color: colors.blaiseGray, fontSize: '14px' }}>
                                    {t('expires_on_x', {
                                      expiryDate: creditCardDateFormat(
                                        card.card.exp_month,
                                        card.card.exp_year
                                      )
                                    })}
                                  </Box>
                                ) : (
                                  <Box style={{ color: colors.blaiseRed, fontSize: '14px' }}>
                                    {t('expired_on_x', {
                                      expiryDate: creditCardDateFormat(
                                        card.card.exp_month,
                                        card.card.exp_year
                                      )
                                    })}
                                  </Box>
                                )}
                              </>
                            }
                            labelPlacement="end"
                          />
                        </Box>
                      );
                    })}
                  <FormControlLabel
                    value={PaymentType.PAY_WITH_NEW_CARD}
                    control={<Radio color="primary" />}
                    label={t('new_card')}
                    labelPlacement="end"
                  />
                </RadioGroup>
                {ticketBundlePaymentMethod === PaymentType.PAY_WITH_NEW_CARD && (
                  <>
                    <form
                      style={{
                        paddingTop: '2%',
                        marginBottom: '32px',
                        padding: '2%',
                        backgroundColor: colors.blaiseLightGreen,
                        boxShadow: `0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 ${colors.blaiseLightGreen}`,
                        borderRadius: '4px'
                      }}
                      data-testid="passDataInput"
                    >
                      <CardElement options={cardOptions} />
                    </form>

                    <Box>
                      <FormControlLabel
                        value="saveCardForLaterInitial"
                        control={
                          <Checkbox
                            color="primary"
                            checked={saveCardForLaterInitialCheckbox}
                            onChange={(e) => toggleSaveCardForLaterInitial(e.target.checked)}
                          />
                        }
                        label={t('save_card_for_later')}
                        labelPlacement="end"
                        style={{ marginBottom: '16px' }}
                      />
                      {saveCardForLaterInitialCheckbox && !cardHasConsent && (
                        <Box className="consent-from-passenger-container">
                          <Box
                            style={{
                              color: colors.blaiseGray,
                              fontSize: '16px',
                              fontWeight: '700',
                              marginBottom: '16px'
                            }}
                          >
                            {t('consent_from_passenger')}
                          </Box>
                          <Box
                            style={{
                              color: colors.buttonGreyText,
                              fontSize: '16px',
                              fontWeight: '700',
                              marginBottom: '16px'
                            }}
                          >
                            {t('consent_for_future_payments')}
                          </Box>
                          <FormControlLabel
                            value="saveCardForLaterConsent"
                            style={{ marginBottom: '16px' }}
                            control={
                              <Checkbox
                                color="primary"
                                checked={saveCardForLaterConsentCheckbox}
                                onChange={(e) =>
                                  setSaveCardForLaterConsentCheckbox(e.target.checked)
                                }
                              />
                            }
                            label={t('consent_requested')}
                            labelPlacement="end"
                          />
                          <Box display="flex" gridGap={24} justifyContent="center">
                            <Button
                              color="secondary"
                              variant="outlined"
                              onClick={cancelSavedCardConsent}
                            >
                              {t('cancel')}
                            </Button>
                            <Button
                              color="primary"
                              variant="contained"
                              disabled={!saveCardForLaterConsentCheckbox}
                              onClick={() => setCardHasConsent(true)}
                            >
                              {t('authorize')}
                            </Button>
                          </Box>
                        </Box>
                      )}
                      {cardHasConsent && (
                        <Box className="consent-from-passenger-container">
                          {t('consent_future_payments_authorized')}
                        </Box>
                      )}
                    </Box>
                  </>
                )}
                <Box display="flex" justifyContent="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={purchaseTicketBundle}
                    disabled={!ticketBundlePaymentMethod}
                    data-testid="ticketBundlePaymentMethod"
                  >
                    {isPurchasingTicket ? (
                      <FontAwesomeIcon icon={faSpinner} spin color={colors.white} size="lg" />
                    ) : (
                      t('purchase')
                    )}
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </TabPanel>
        <TabPanel selectedTab={selectedTab} index={1}>
          {/* Redeem tab */}
          <Box style={{ marginTop: '16px', marginBottom: '24px' }}>
            <TextField
              fullWidth
              name="cardNumber"
              value={ticketToRedeem}
              onChange={(e) => {
                setTicketToRedeem(e.target.value);
                setInvalidRedemption(false);
              }}
              variant="outlined"
              label={t('enter_ticket_number')}
            />
            {invalidRedemption && (
              <Typography variant="subtitle2" style={{ color: colors.blaiseRed, marginTop: '5px' }}>
                {t('recheck_tickets')}
              </Typography>
            )}
          </Box>
          <Grid
            item
            sm={12}
            style={{ minHeight: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={validateAndRedeemTicket}
              disabled={!ticketToRedeem}
              data-testid="ticketBundlePaymentMethod"
            >
              {isPurchasingTicket ? (
                <FontAwesomeIcon icon={faSpinner} spin color={colors.white} size="lg" />
              ) : (
                <>{t('redeem')}</>
              )}
            </Button>
          </Grid>
        </TabPanel>
      </Box>
    </Grid>
  );
};

export default TicketBundles;

const TabPanel = ({ children, selectedTab, index, ...other }) => {
  return (
    <Box
      role="tabpanel"
      hidden={selectedTab !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {selectedTab === index && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          style={{ width: '100%' }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};
