import React, { useEffect, useState } from 'react';
import config from '../../config';
import { withAuth0 } from '@auth0/auth0-react';
import { withTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, Grid, TextField, Typography } from '@material-ui/core';

import ReactMapboxGl, { Marker, Layer, Feature, GeoJSONLayer } from 'react-mapbox-gl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faMapMarker, faWalking } from '@fortawesome/free-solid-svg-icons';

import CustomSnackbar from '../../components/Snackbar/CustomSnackbar';
import colors from '../../assets/sass/colors';
import authenticatedAxiosInstance from '../../axios/axios-authorized';
import { dateTimeFormat, dateTimeFormatWithTimezone } from '../../utils/MomentTime';
import NotFound from '../Error/NotFound';
import { currentUserStore } from '../../store/user';
import {
  getPickupDropoffWindows,
  getWalkingDurationDistance,
  getWalkingRoute
} from '../../utils/transitAgencyUtils';
import { lineString, point } from '@turf/turf';
import { compareBrowserAndAgencyTZ } from '../../utils/timezoneUtils';
import './TripDetails.scss';
import { transitAgencyStore } from '../../store/transitAgency';
import { TripType } from '../../enums/tripType';
import { getCenter, getRouteColor } from '../../utils/mapHelpers';
import { FeaturesType } from '../../enums/featuresType';
import { displayWindowRange } from '../../utils/dateTimeFormat';
import AdditionalTripDetails from '../AdditionalTripDetails/AdditionalTripDetails';

const Map = ReactMapboxGl({
  accessToken: config.mapBoxToken
});

const requestSourceTypes = Object.freeze({
  ENGINE: 'trip_source_engine',
  ADHOC: 'trip_source_adhoc',
  PASSENGER_WEB: 'trip_source_web',
  PASSENGER_IOS: 'trip_source_ios',
  PASSENGER_ANDROID: 'trip_source_android'
});

const BROWSER_LANG = navigator.language.substring(0, 2);

const TripDetails = ({ tripId, t }) => {
  const [tripDetails, setTripDetails] = useState(null);
  const [routes, setRoutes] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [walkingDistanceAndDuration, setWalkingDistanceAndDuration] = useState([]);
  const [tripNotFound, setTripNotFound] = useState(false);
  const [tripStatus, setTripStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [timeWindows, setTimeWindows] = useState(null);
  const [timezoneDetails, setTimezoneDetails] = useState({});
  const [hasTransfersFeature, setHasTransfersFeature] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: null
  });
  const { currentUser } = currentUserStore((state) => ({ currentUser: state.currentUser }));
  const { geoJson, features } = transitAgencyStore();
  const rideProposal = tripDetails?.ride_proposals?.[0];
  const rideProposalId = rideProposal?.ride_proposal_id;
  const edt =
    timezoneDetails.timezoneMismatch && timezoneDetails?.taTimezone
      ? dateTimeFormatWithTimezone(
          tripDetails?.earliest_departure,
          timezoneDetails.taTimezone,
          'YYYY-MM-DD HH:mm:ss'
        )
      : tripDetails?.earliest_departure;
  const lat =
    timezoneDetails.timezoneMismatch && timezoneDetails?.taTimezone
      ? dateTimeFormatWithTimezone(
          tripDetails?.latest_arrival,
          timezoneDetails.taTimezone,
          'YYYY-MM-DD HH:mm:ss'
        )
      : tripDetails?.latest_arrival;
  const requested = timezoneDetails.timezoneMismatch
    ? dateTimeFormatWithTimezone(tripDetails?.request_date, timezoneDetails?.taTimezone)
    : dateTimeFormat(tripDetails?.request_date);
  const cancellationTime = rideProposal?.cancellation_time
    ? timezoneDetails.timezoneMismatch
      ? dateTimeFormatWithTimezone(rideProposal?.cancellation_time, timezoneDetails?.taTimezone)
      : dateTimeFormat(rideProposal?.cancellation_time)
    : null;
  const source = requestSourceTypes[tripDetails?.request_source];

  useEffect(() => {
    verifyTimezoneDetails(currentUser.transit_agency_id);
    getTripDetails();
  }, []);

  useEffect(() => {
    const transitAgencyHasTransfersFeature = features?.some(
      (feature) => feature.name === FeaturesType.TRANSFERS
    );

    setHasTransfersFeature(transitAgencyHasTransfersFeature);
  }, [features]);

  useEffect(() => {
    if (currentUser.transit_agency_id) {
      getWindows();
    }
  }, [timezoneDetails]);

  useEffect(() => {
    if (tripDetails) {
      getMapRoutesAndMarkers();
    }

    // Only get walking info if the trip has confirmed details (legs or ride proposal)
    if (tripDetails?.legs?.length > 0 || tripDetails?.ride_proposals?.length > 0) {
      getTripWalkingDurationAndDistance();
    }
  }, [tripDetails]);

  const getTripDetails = async () => {
    try {
      const tripDetailsResponse = (
        await authenticatedAxiosInstance.axios.get(`${config.apiServer}/trip/${tripId}`)
      ).data.trip;

      if (!tripDetailsResponse) {
        setTripNotFound(true);
      }

      const responseStatus = await authenticatedAxiosInstance.axios.post(`/rides/status`, {
        tripRequestId: [tripId]
      });
      // console.log({ responseStatus });
      let status = responseStatus.data[tripId];

      if (['accepted', 'confirmed_no_details', 'confirmed_details'].includes(status)) {
        status = 'upcoming';
      }

      if (status === 'processing') {
        status = 'pending';
      }

      setTripStatus(status);
      setTripDetails(tripDetailsResponse);
      console.log('tripDetailsResponse==>', tripDetailsResponse);
    } catch (error) {
      console.log('RequestByPhoneDetails::getTripDetails', error);
    }
  };

  const getTripWalkingDurationAndDistance = async () => {
    const { legs, origin_lon, origin_lat, dest_lat, dest_lon, ride_proposals } = tripDetails;
    const walkingDetails = [];

    try {
      // Origin to first pickup walking details for both transfer and non-transfer trips
      const originCoords = { lon: origin_lon, lat: origin_lat };
      const firstPickupCoords = {
        lon: legs[0]?.pickupStopId?.lon || ride_proposals[0].pickup_stop.lon,
        lat: legs[0]?.pickupStopId?.lat || ride_proposals[0].pickup_stop.lat
      };
      const originPickupWalkingDetails = await getWalkingDurationDistance(
        originCoords,
        firstPickupCoords
      );

      walkingDetails.push(originPickupWalkingDetails);

      if (legs.length > 0) {
        // Transfer trips legs and dropoff to destination walking details
        for (let i = 0; i < legs.length; i++) {
          if (legs[i + 1]) {
            // There is another leg, so calculate walking distance between dropoff and next pickup.
            const legDropoffCoords = {
              lon: legs[i].dropoffStopId.lon,
              lat: legs[i].dropoffStopId.lat
            };
            const nextLegPickupCoords = {
              lon: legs[i + 1].pickupStopId.lon,
              lat: legs[i + 1].pickupStopId.lat
            };

            const legDropoffToNextPickupWalkingDetails = await getWalkingDurationDistance(
              legDropoffCoords,
              nextLegPickupCoords
            );

            walkingDetails.push(legDropoffToNextPickupWalkingDetails);
          } else {
            // There are no more legs, so calculate walking distance between dropoff and destination.
            const lastLegDropoffCoords = {
              lon: legs[i].dropoffStopId.lon,
              lat: legs[i].dropoffStopId.lat
            };
            const destinationCoords = {
              lon: dest_lon,
              lat: dest_lat
            };
            const dropoffToDestinationWalkingDetails = await getWalkingDurationDistance(
              lastLegDropoffCoords,
              destinationCoords
            );

            walkingDetails.push(dropoffToDestinationWalkingDetails);
          }
        }
      } else {
        // Non-transfer trips dropoff to destination
        const lastLegDropoffCoords = {
          lon: ride_proposals[0].dropoff_stop.lon,
          lat: ride_proposals[0].dropoff_stop.lat
        };
        const destinationCoords = {
          lon: dest_lon,
          lat: dest_lat
        };
        const dropoffToDestinationWalkingDetails = await getWalkingDurationDistance(
          lastLegDropoffCoords,
          destinationCoords
        );

        walkingDetails.push(dropoffToDestinationWalkingDetails);
      }

      setWalkingDistanceAndDuration(walkingDetails);
    } catch (error) {
      console.log('RequestByPhoneDetails::getTripWalkingDurationAndDistance', error);
    }
  };

  const getMapRoutesAndMarkers = async () => {
    try {
      setIsLoading(true);

      // For backwards compatibility
      const pickupInfoExists = tripDetails.legs?.[0]
        ? tripDetails.legs[0].pickup_time_original
        : tripDetails?.ride_proposals[0]?.pickup_time;

      if (!pickupInfoExists) {
        console.log('formatTripNoConfirmedDetails');
        formatTripNoConfirmedDetails(tripDetails);
      } else if (tripDetails.legs?.[0]) {
        console.log('formatTripWithLegs');
        formatTripWithLegs(tripDetails);
      } else if (!tripDetails.legs?.[0]) {
        console.log('formatTripNoLegs');
        formatTripNoLegs(tripDetails);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatTripNoConfirmedDetails = async (trip) => {
    const originCoords = { lon: trip.origin_lon, lat: trip.origin_lat };
    const destCoords = {
      lon: trip.dest_lon,
      lat: trip.dest_lat
    };

    let originDestinationRoute = [
      [Number(originCoords.lon), Number(originCoords.lat)],
      [Number(destCoords.lon), Number(destCoords.lat)]
    ];

    setRoutes([{ route: originDestinationRoute, type: TripType.ON_FOOT }]);
  };

  const formatTripWithLegs = async (tripObj) => {
    const { legs, origin_name, origin_lon, origin_lat, dest_lon, dest_lat, dest_name } = tripObj;
    let routes = [];
    let markers = [];

    const originCoords = { lon: origin_lon, lat: origin_lat };
    const firstPickupCoords = {
      lon: legs[0].pickupStopId.lon,
      lat: legs[0].pickupStopId.lat
    };

    const originPickupRoute = await getWalkingRoute(originCoords, firstPickupCoords);

    routes.push({ route: originPickupRoute, type: TripType.ON_FOOT });

    const originPoint = {
      serviceType: 'origin',
      lon: Number(origin_lon),
      lat: Number(origin_lat),
      name: origin_name
    };

    markers.push({ ...originPoint });

    for (let i = 0; i < legs.length; i++) {
      const dropoffCoords = { lon: legs[i].dropoffStopId.lon, lat: legs[i].dropoffStopId.lat };

      // Straight route from pickup to dropoff in upcoming trips
      const legPickupToDropoffRoute = [
        [Number(legs[i].pickupStopId.lon), Number(legs[i].pickupStopId.lat)],
        [Number(legs[i].dropoffStopId.lon), Number(legs[i].dropoffStopId.lat)]
      ];

      routes.push({ route: legPickupToDropoffRoute, type: legs[i].type });

      const pickupPoint = {
        serviceType: legs[i].type,
        lon: Number(legs[i].pickupStopId.lon),
        lat: Number(legs[i].pickupStopId.lat),
        name: legs[i].pickupStopId.name
      };

      markers.push({ ...pickupPoint });

      const lastLeg = legs[legs.length - 1];

      const finalDropoffCoords = { lon: lastLeg.dropoffStopId.lon, lat: lastLeg.dropoffStopId.lat };
      const destCoords = { lon: dest_lon, lat: dest_lat };

      // If there is another leg after, we need the walking route from dropoff to the next pickup
      if (legs[i + 1]) {
        const nextPickupCoords = {
          lon: legs[i + 1].pickupStopId.lon,
          lat: legs[i + 1].pickupStopId.lat
        };
        const dropoffToNextPickupRoute = await getWalkingRoute(dropoffCoords, nextPickupCoords);

        routes.push({ route: dropoffToNextPickupRoute, type: TripType.ON_FOOT });
      } else {
        const dropoffToDestRoute = await getWalkingRoute(finalDropoffCoords, destCoords);
        routes.push({ route: dropoffToDestRoute, type: TripType.ON_FOOT });
      }

      const dropoffPoint = {
        serviceType: legs[i].type,
        lon: Number(legs[i].dropoffStopId.lon),
        lat: Number(legs[i].dropoffStopId.lat),
        name: legs[i].dropoffStopId.name
      };

      markers.push({ ...dropoffPoint });
    }

    const destPoint = {
      serviceType: 'destination',
      lon: Number(dest_lon),
      lat: Number(dest_lat),
      name: dest_name
    };

    markers.push({ ...destPoint });

    if (routes.length > 0) {
      const routesForMap = routes.map((path) => {
        const justRoute = Object.fromEntries(
          Object.entries(path).filter(([key]) => !key.includes('type'))
        );

        const convertedPath = Object.values(justRoute).flat();
        return { path: lineString(convertedPath), type: path.type || null };
      });

      setRoutes(routesForMap);
    }

    if (markers.length > 0) {
      const onlyPickupDropoffStops = markers.filter(
        (point) => point.serviceType !== 'origin' && point.serviceType !== 'destination'
      );

      // Markers
      const stopsArray = onlyPickupDropoffStops.map((stop) => {
        const coords = point([Number(stop.lon), Number(stop.lat)]);
        return { type: stop.serviceType, mapCoords: coords };
      });

      setMarkers(stopsArray);
    }
  };

  const formatTripNoLegs = async (tripObj) => {
    let originPickupRoute = null;
    // A straight line from pickup stop to dropoff stop
    let pickupDropoffRoute = [
      [
        Number(tripObj.ride_proposals[0].pickup_stop.lon),
        Number(tripObj.ride_proposals[0].pickup_stop.lat)
      ],
      [
        Number(tripObj.ride_proposals[0].dropoff_stop.lon),
        Number(tripObj.ride_proposals[0].dropoff_stop.lat)
      ]
    ];
    let dropoffDestRoute = null;

    const originCoords = { lon: tripObj.origin_lon, lat: tripObj.origin_lat };
    const pickupCoords = {
      lon: tripObj.ride_proposals[0].pickup_stop.lon,
      lat: tripObj.ride_proposals[0].pickup_stop.lat
    };
    const dropoffCoords = {
      lon: tripObj.ride_proposals[0].dropoff_stop.lon,
      lat: tripObj.ride_proposals[0].dropoff_stop.lat
    };
    const destCoords = {
      lon: tripObj.dest_lon,
      lat: tripObj.dest_lat
    };

    originPickupRoute = await getWalkingRoute(originCoords, pickupCoords);
    dropoffDestRoute = await getWalkingRoute(dropoffCoords, destCoords);

    const pickupPoint = {
      type: TripType.ON_DEMAND,
      lon: tripObj.ride_proposals[0].pickup_stop.lon,
      lat: tripObj.ride_proposals[0].pickup_stop.lat,
      name: tripObj.ride_proposals[0].pickup_stop.name
    };

    const dropoffPoint = {
      type: TripType.ON_DEMAND,
      lon: tripObj.ride_proposals[0].dropoff_stop.lon,
      lat: tripObj.ride_proposals[0].dropoff_stop.lat,
      name: tripObj.ride_proposals[0].dropoff_stop.name
    };

    setMarkers([{ ...pickupPoint }, { ...dropoffPoint }]);
    if (originPickupRoute && pickupDropoffRoute && dropoffDestRoute) {
      setRoutes([
        { route: originPickupRoute, type: TripType.ON_FOOT },
        { route: pickupDropoffRoute, type: TripType.ON_DEMAND },
        { route: dropoffDestRoute, type: TripType.ON_FOOT }
      ]);
    }
  };

  const getWindows = async () => {
    const windows = await getPickupDropoffWindows(currentUser.transit_agency_id);
    setTimeWindows(windows);
  };

  const openSnackbar = (snackbarString, color) => {
    setSnackbar({ ...snackbar, open: true, message: snackbarString, color: color });
  };

  const closeSnackbar = (reason) => {
    if (reason !== 'clickaway') {
      setSnackbar({ ...snackbar, open: false });
    }
  };

  const verifyTimezoneDetails = async (taid) => {
    const timezoneDetails = await compareBrowserAndAgencyTZ(taid);
    setTimezoneDetails(timezoneDetails);
    if (timezoneDetails.timezoneMismatch) {
      openSnackbar(
        `${t('timezone_mismatch_message')} (${timezoneDetails.taTimezone}). ${t(
          'timezone_no_actions_required'
        )}`,
        colors.red
      );
    }
  };

  // Keep button disabled for some statuses
  let cancelButtonDisable;
  if (tripStatus === 'upcoming') {
    cancelButtonDisable = false;
  } else {
    cancelButtonDisable = true;
  }

  let preferenceField;
  if (tripDetails?.preference && lat) {
    preferenceField = `${t('arrive_on')} ${dateTimeFormat(lat)}`;
  } else if (!tripDetails?.preference && edt) {
    preferenceField = `${t('depart_on')} ${dateTimeFormat(edt)}`;
  } else {
    preferenceField = ' - ';
  }

  const handleCancelTrip = async (rideProposalId) => {
    try {
      await authenticatedAxiosInstance.axios.put(
        `/phoneRequest/${tripDetails?.passenger?.passenger_id}/rides/${rideProposalId}/cancellation`
      );

      openSnackbar(t('confirmation_of_trip_cancellation'), colors.blaiseGreen);
      getTripDetails();
    } catch (err) {
      console.log(err, 'err');
      openSnackbar(t('phone_request_delete_error'), colors.red);
    }
  };

  const getTranslatedAccommodations = (passenger) => {
    // Object with possible accommodation values and their translations.
    const accommodations = {
      wheelchair_demand: t('wheelchair'),
      bike_demand: t('bike'),
      motorized_aid_demand: t('motorized_aid'),
      stroller_demand: t('stroller')
    };

    let translatedAccommodations = [];
    let translatedAccommodationsString = '';
    // Convert passenger object into array to loop over it.
    Object.entries(passenger).forEach(([key, value]) => {
      // If the key of the accommodations array includes the passenger key and it's value is 1 (accommodation was selected for that passenger).
      if (Object.keys(accommodations).includes(key) && value === 1) {
        // Add the translation to the translatedAccommodation array.
        translatedAccommodations.push(accommodations[key]);
        // Convert array to string and then add spaces between items.
        translatedAccommodationsString = translatedAccommodations.toString().replace(',', ', ');
      }
    });
    return translatedAccommodationsString.length ? translatedAccommodationsString : ' - ';
  };

  const getPickupDropoffTimes = (step) => {
    // HALP - Make DST changes.
    // For trips that have ride proposals (transfer and non transfer trips)
    if (
      tripDetails.ride_proposals[0].arrival_time ||
      tripDetails.ride_proposals[0].departure_time
    ) {
      return displayWindowRange(
        step,
        step === 'pickup'
          ? tripDetails.ride_proposals[0].departure_time
          : tripDetails.ride_proposals[0].arrival_time
      );
      // For trips that don't have a ride proposal yet (the ride is confirmed, but it's too far in the future still)
    } else if (tripDetails?.earliest_departure || tripDetails?.latest_arrival) {
      return displayWindowRange(
        step,
        step === 'pickup' ? tripDetails?.earliest_departure : tripDetails?.latest_arrival
      );
    } else {
      return t('trip_status_pending');
    }
  };

  const polygonPaint = (ReactMapboxGl.FillPaint = {
    'fill-color': ['coalesce', ['get', 'color'], colors.blaiseGreen],
    'fill-opacity': 0.3
  });

  return (
    <>
      <CustomSnackbar
        message={snackbar.message}
        open={snackbar.open}
        onClose={() => closeSnackbar()}
        snackbarColor={snackbar.color}
      />
      {tripNotFound && !isLoading && <NotFound title={'404'} message={t('page_not_found')} />}
      {isLoading && (
        <Grid container style={{ marginTop: '20px' }} justify="center">
          <CircularProgress />
        </Grid>
      )}
      {tripDetails && !isLoading && (
        <>
          <Box mb={4}>
            <Box display="flex" justifyContent="space-between" style={{ width: '100%' }}>
              <h2>
                {t('trip_details')} - {tripDetails?.passenger?.first_name}{' '}
                {tripDetails?.passenger?.last_name}
              </h2>
              <Box display="flex" alignItems="center">
                <Button
                  variant="outlined"
                  color="secondary"
                  disabled={cancelButtonDisable}
                  onClick={() => handleCancelTrip(rideProposalId)}
                >
                  {t('cancel_trip')}
                </Button>
              </Box>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  name="trip_request_id"
                  value={tripId}
                  variant="outlined"
                  fullWidth
                  id="trip_request_id"
                  label={t('id')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="status"
                  value={t(`history_${tripStatus}`) || t('history_na')}
                  variant="outlined"
                  fullWidth
                  id="status"
                  label={t('status')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="first_name"
                  value={tripDetails?.passenger?.first_name || ' - '}
                  variant="outlined"
                  fullWidth
                  id="first_name"
                  label={t('first_name')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="last_name"
                  value={tripDetails?.passenger?.last_name || ' - '}
                  variant="outlined"
                  fullWidth
                  id="last_name"
                  label={t('last_name')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="passenger_email"
                  value={tripDetails?.passenger?.email || ' - '}
                  variant="outlined"
                  fullWidth
                  id="passenger_email"
                  label={t('email')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="passenger_phone"
                  value={tripDetails?.passenger?.phone_number || ' - '}
                  variant="outlined"
                  fullWidth
                  id="passenger_phone"
                  label={t('phone_number')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="origin"
                  value={tripDetails?.origin_name || ' - '}
                  variant="outlined"
                  fullWidth
                  id="origin"
                  label={t('origin')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="destination"
                  value={tripDetails?.dest_name || ' - '}
                  variant="outlined"
                  fullWidth
                  id="destination"
                  label={t('destination')}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name="requested"
                  value={requested}
                  variant="outlined"
                  fullWidth
                  id="requested"
                  label={t('request_date')}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name="preference"
                  value={preferenceField}
                  variant="outlined"
                  fullWidth
                  id="preference"
                  label={t('time')}
                />
              </Grid>
              {/* HALP - Add route ID */}
              {/* <Grid item xs={3}>
                <TextField
                  name="route_id"
                  value={routeId || ' - '}
                  variant="outlined"
                  fullWidth
                  id="route_id"
                  label={t('route_id')}
                  InputProps={
                    routeId && {
                      endAdornment: (
                        <Link
                          to={{
                            pathname: `/routesForShift/${shiftId}`,
                            hash: `${routeId}`
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faExternalLinkAlt}
                            color={colors.blaiseGreen}
                            size="lg"
                          />
                        </Link>
                      )
                    }
                  }
                />
              </Grid>
              // HALP - Add bus name
              <Grid item xs={3}>
                <TextField
                  name="bus_name"
                  value={busName || ' - '}
                  variant="outlined"
                  fullWidth
                  id="bus_name"
                  label={t('bus_name')}
                />
              </Grid> */}
              <Grid item xs={3}>
                <TextField
                  name="number of passengers"
                  value={
                    tripDetails?.nondisabled_demand +
                      tripDetails?.wheelchair_demand +
                      tripDetails?.bike_demand || ' - '
                  }
                  variant="outlined"
                  fullWidth
                  id="demand"
                  label={t('num_passengers')}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name="booking_method"
                  value={t(source) || ' - '}
                  variant="outlined"
                  fullWidth
                  id="comment"
                  label={t('request_source')}
                />
              </Grid>
              {/* // HALP - Add has boarded */}
              {/* <Grid item xs={3}>
                <TextField
                  name="has_boarded"
                  value={hasBoarded ? t('yes') : t('no')}
                  variant="outlined"
                  fullWidth
                  id="has_boarded"
                  label={t('has_boarded')}
                />
              </Grid> */}
              <Grid item xs={3}>
                <TextField
                  name="price"
                  // Backwards compatibility.
                  value={
                    '$' +
                    (
                      tripDetails.trip_price ||
                      tripDetails.legs.reduce((acc, curr) => acc + curr.leg_price, 0) / 100
                    ).toFixed(2)
                  }
                  variant="outlined"
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label={t('total')}
                />
              </Grid>
              {/* // HALP - Add payment method */}
              {/* <Grid item xs={3}>
                <TextField
                  name="payment type"
                  value={
                    (paymentType && tripPrice) || paymentType === PaymentMethodType.PASS
                      ? t(paymentType)
                      : ' - '
                  }
                  variant="outlined"
                  fullWidth
                  id="paymentType"
                  label={t('payment_type')}
                />
              </Grid> */}
              {/* Passengers */}
              {tripDetails.trip_request_passenger_type_links.map((passenger) => (
                <>
                  <Grid item sm={3} key={`passenger-type-${passenger.id}`}>
                    <TextField
                      name="passengerType"
                      value={
                        BROWSER_LANG == 'en'
                          ? passenger.transit_agency_passenger_type.translationType.english
                          : passenger.transit_agency_passenger_type.translationType.french
                      }
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                      fullWidth
                      label={t('passenger_type')}
                    />
                  </Grid>
                  <Grid item sm={3} key={`accommodation-${passenger.id}`}>
                    <TextField
                      name="accommodation"
                      value={getTranslatedAccommodations(passenger)}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                      fullWidth
                      label={t('accommodations')}
                    />
                  </Grid>
                </>
              ))}
              {tripStatus === 'completed' && (
                <>
                  <Grid item xs={6}>
                    <TextField
                      name="rating"
                      value={rideProposal?.ride_actual?.passenger_rating || ' - '}
                      variant="outlined"
                      fullWidth
                      id="rating"
                      label={t('passenger_rating')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="comment"
                      value={rideProposal?.ride_actual?.passenger_comment || ' - '}
                      variant="outlined"
                      fullWidth
                      id="comment"
                      label={t('passenger_comment')}
                    />
                  </Grid>
                </>
              )}
              {tripDetails?.stripe_receipt_url && (
                <Grid item xs={3}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={tripDetails?.stripe_receipt_url}
                    className="viewReceipt"
                  >
                    {t('stripe_receipt_url')}
                  </a>
                </Grid>
              )}
              {cancellationTime && (
                <Grid item xs={3}>
                  <TextField
                    name="cancel_time"
                    value={cancellationTime}
                    variant="outlined"
                    fullWidth
                    id="cancel_time"
                    label={t('cancellation_time')}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
          {/* MAP */}
          <Map /* eslint-disable-next-line */
            style="mapbox://styles/mapbox/light-v10"
            className="bordered"
            center={getCenter(
              tripDetails.dest_lat,
              tripDetails.dest_lon,
              tripDetails.origin_lat,
              tripDetails.origin_lon
            )}
            containerStyle={{ width: '100%', height: '200px', borderRadius: 10 }}
            zoom={[10]}
          >
            {/* Transit operating zone */}
            <GeoJSONLayer data={geoJson} fillPaint={polygonPaint} />

            {/* Origin marker */}
            <Marker
              coordinates={[tripDetails.origin_lon, tripDetails.origin_lat]}
              anchor="left"
              style={{ color: colors.black, paddingBefore: 10 }}
            >
              {tripDetails.origin_lon && (
                <>
                  <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" color={colors.blaisePurple} />
                </>
              )}
            </Marker>

            {/* Routes */}
            {routes.map((route, index) => (
              <Layer
                key={`route-${index}`}
                id={`route-${index}`}
                type="line"
                paint={{
                  'line-width': 1.5,
                  'line-dasharray': route.type === TripType.ON_FOOT ? [2, 1] : [1, 0],
                  'line-color': getRouteColor(route.type)
                }}
              >
                <Feature coordinates={route?.path?.geometry?.coordinates || route.route} />
              </Layer>
            ))}

            {/* Pickup & dropoff markers */}
            {markers.map((marker, index) => (
              <Layer
                key={`marker-${index}`}
                type="circle"
                id={`marker-${index}`}
                paint={{
                  'circle-color': colors.white,
                  'circle-stroke-width': 2,
                  'circle-stroke-color':
                    marker.type === 'on_demand' ? colors.blaiseGreen : colors.fixedRouteOrange,
                  'circle-stroke-opacity': 1,
                  'circle-radius': 4
                }}
              >
                <Feature
                  coordinates={[
                    // Backwards compatibility. Transfers || Non-transfers.
                    marker?.mapCoords?.geometry?.coordinates[0] || marker.lon,
                    marker?.mapCoords?.geometry?.coordinates[1] || marker.lat
                  ]}
                />
              </Layer>
            ))}

            {/* Destination marker */}
            <Marker
              coordinates={[tripDetails.dest_lon, tripDetails.dest_lat]}
              anchor="left"
              style={{ color: colors.black, paddingBefore: 10 }}
            >
              {tripDetails.dest_lon && (
                <>
                  <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" color={colors.blaiseRed} />
                </>
              )}
            </Marker>
          </Map>
          {hasTransfersFeature && (
            <Box style={{ marginTop: '16px' }}>
              <Typography className="map-key-container">
                <Box
                  className="map-key-circle"
                  style={{
                    backgroundColor: colors.blaiseGreen
                  }}
                />
                {t('on_demand_zone')}
              </Typography>
              <Typography className="map-key-container">
                <Box
                  className="map-key-circle"
                  style={{
                    backgroundColor: colors.fixedRouteOrange
                  }}
                />
                {t('fixed_route_zone')}
              </Typography>
            </Box>
          )}
          {/* TRIP DETAILS */}
          <Box className="details-container">
            {/* Dashed line */}
            <Box className="dashed-line-container">
              <Box className="dashed-line">
                <Box className="origin-dot"></Box>
                <FontAwesomeIcon
                  className="destination-dot"
                  icon={faMapMarker}
                  size="lg"
                  color={colors.blaiseRed}
                />
              </Box>
            </Box>

            {/* Trip details */}
            <Box className="inputs-container">
              {/* Origin */}
              <Box className="origin-container">
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      name="origin"
                      value={tripDetails.origin_name || t('trip_status_pending')}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                      fullWidth
                      label={t('origin')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="departureTime"
                      value={getPickupDropoffTimes('pickup')}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                      fullWidth
                      label={t('departure_time')}
                      x
                    />
                  </Grid>
                </Grid>
                <Box className="trip-details-icon-container">
                  {/* Only show if there are confirmed trip details */}
                  {tripStatus !== 'rejected' && (
                    <>
                      <FontAwesomeIcon
                        icon={faWalking}
                        size="lg"
                        color={colors.black}
                        style={{ marginRight: '8px' }} // 8px margin added so that the text Walk and Vehicle are in line.
                      />
                      <Box>
                        <Typography style={{ fontSize: '14px' }}>{t('walk')}</Typography>
                        <Typography style={{ fontSize: '10px' }}>
                          {t('about')} {walkingDistanceAndDuration[0]?.durationInMinutes}{' '}
                          {walkingDistanceAndDuration[0]?.durationInMinutes === 1 ? 'min' : 'mins'},{' '}
                          {walkingDistanceAndDuration[0]?.distanceInMeters}m
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>

              {/* Trip Details */}
              <AdditionalTripDetails
                legs={tripDetails?.legs[0] ? tripDetails.legs : tripDetails.ride_proposals}
                walkingDistanceAndDuration={walkingDistanceAndDuration}
              />

              {/* Destination */}
              <Box className="destination-container">
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      name="dropoffStopName"
                      value={tripDetails.dest_name || t('trip_status_pending')}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                      fullWidth
                      label={t('destination')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="dropoffTime"
                      value={getPickupDropoffTimes('dropoff')}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                      fullWidth
                      label={t('arrival_time')}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default withTranslation('common')(withAuth0(TripDetails));
