/* eslint-disable no-undef */
const nameSplit = (name) => {
  if (!name) {
    return {
      first_name: '',
      last_name: ''
    };
  }
  const nameArray = name.split(' ');

  // if nameArray length is 1, then we only have a first name.
  if (nameArray.length === 1) {
    return {
      first_name: nameArray[0],
      last_name: ''
    };
  } else if (nameArray.length > 2) {
    let first_name = '';
    first_name = nameArray.slice(0, nameArray.length - 1).join(' ');
    return {
      first_name: first_name,
      last_name: nameArray[nameArray.length - 1]
    };
  } else {
    return {
      first_name: nameArray[0],
      last_name: nameArray[1]
    };
  }
};

module.exports = nameSplit;
