import React, { Component } from 'react';
import BusTable from '../../components/BusTable/BusTable';
import { withTranslation } from 'react-i18next';

class BusManagement extends Component {
  componentDidMount = () => {
    document.title = `${this.props.t('vehicle_management_title')} - ${this.props.t(
      'trip_source_engine'
    )}`;
  };

  render() {
    return (
      <div>
        <div className="pageTitle">{this.props.t('vehicle_management_title')}</div>
        <BusTable />
      </div>
    );
  }
}

export default withTranslation('common')(BusManagement);
