import React, { Component, Fragment } from 'react';
import 'react-big-scheduler/lib/css/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faInfo, faPhone, faTrash, faPen } from '@fortawesome/free-solid-svg-icons';
import { withAuth0 } from '@auth0/auth0-react';
import PhoneRequestModal from './PhoneRequestModal';
import colors from '../../assets/sass/colors';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import CustomSnackbar from '../../components/Snackbar/CustomSnackbar';
import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import 'moment/locale/fr';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import './RequestByPhone.scss';
import DataGridTable from '../../components/DataGrid/DataGridTable';
import { dateTimeFormat, dateTimeFormatWithTimezone } from '../../utils/MomentTime';
import { currentUserHOC } from '../../store/user';
import authenticatedAxiosInstance from '../../axios/axios-authorized';
import { compareBrowserAndAgencyTZ } from '../../utils/timezoneUtils';
import { withRouter } from 'react-router-dom';

//https://davidmathlogic.com/colorblind/#%2300CA92-%232A2AC1-%23FF8700-%23E770FF-%23000000
const status = {
  confirmed: '#00CDAD',
  accepted: '#07a7cb',
  rejected: '#F94346',
  pending: '#f29339',
  none: '#000000'
};

class RequestByPhone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      editRequestMode: false,
      editRequestType: 'accepted',
      isSubmitting: false,
      isViewingInfo: false,
      snackbar: {
        open: false,
        message: ''
      },
      rows: [],
      transitAgency: {},
      isConfirmModalOpen: false,
      isLateCancellation: null,
      runCalledInRequestData: true,
      listOfPassengers: [],
      taUtcOffset: 0,
      timezoneMismatch: false,
      taTimezone: ''
    };
  }

  componentDidMount = async () => {
    document.title = `${this.props.t('called_in_requests')} - ${this.props.t(
      'trip_source_engine'
    )}`;
    moment.locale(i18next.language.substr(0, 2));

    await this.getListOfPassengers();
    await this.getTimezoneDetails();

    // This keeps a timeout that triggers the location autosearch at the end of waiting. Timer gets refreshed every time the user types.
    this.searchTriggerTimeout = null;

    //Pulling trip request status
    this.tick = setInterval(this.getCalledInRequestData, 10000);
    this.getTransitAgency();
  };

  getTimezoneDetails = async () => {
    const { currentUser } = this.props.currentUserStore;

    // check if there is timezone mismatch first
    try {
      const timezoneDetails = await compareBrowserAndAgencyTZ(currentUser.transit_agency_id);

      this.setState({
        taUtcOffset: timezoneDetails.taTimezoneOffset,
        timezoneMismatch: timezoneDetails.timezoneMismatch,
        taTimezone: timezoneDetails.taTimezone
      });

      if (timezoneDetails.timezoneMismatch) {
        this.openSnackbar(
          `${this.props.t('timezone_mismatch_message')} (${
            timezoneDetails.taTimezone
          }). ${this.props.t('timezone_no_actions_required')}`,
          colors.red
        );
      }

      // After we have the timezone offset, load the called in request data
      this.getCalledInRequestData();
    } catch (err) {
      console.log(err);
    }
  };

  getTransitAgency = async () => {
    const { currentUser } = this.props.currentUserStore;
    const transitAgency = (
      await authenticatedAxiosInstance.axios.get(
        `/transitagencies/${currentUser.transit_agency_id}`
      )
    ).data;

    this.setState({
      transitAgency
    });
  };

  getListOfPassengers = async () => {
    const response = await authenticatedAxiosInstance.axios.get(`/passengers`);

    this.setState({ listOfPassengers: response.data });
  };

  componentWillUnmount() {
    clearTimeout(this.tick);
  }

  getCalledInRequestData = async () => {
    if (this.state.runCalledInRequestData) {
      try {
        const response = (await authenticatedAxiosInstance.axios.get(`/allPhoneRequests`)).data;

        const rows = [];

        const filter = response.filter(
          (entry) =>
            entry.status !== 'unknown' &&
            entry.status !== 'completed' &&
            entry.status !== 'no_show' &&
            entry.status !== 'cancelled'
        );

        filter.forEach((trip) => {
          const rideProposal = Array.isArray(trip?.ride_proposals)
            ? trip?.ride_proposals?.at(-1) // Get the last ride proposal if multiple (But it should never return more than 1 ride proposals unless we are testing stuff)
            : undefined;
          const status = trip?.status;
          // convert times to TA timezone
          const departureTime = rideProposal?.departure_time
            ? dateTimeFormatWithTimezone(
                rideProposal.departure_time,
                this.state.taTimezone,
                'YYYY-MM-DD HH:mm:ss'
              )
            : dateTimeFormatWithTimezone(
                trip.earliest_departure,
                this.state.taTimezone,
                'YYYY-MM-DD HH:mm:ss'
              );
          const arrivalTime = rideProposal?.arrival_time
            ? dateTimeFormatWithTimezone(
                rideProposal.arrival_time,
                this.state.taTimezone,
                'YYYY-MM-DD HH:mm:ss'
              )
            : dateTimeFormatWithTimezone(
                trip.latest_arrival,
                this.state.taTimezone,
                'YYYY-MM-DD HH:mm:ss'
              );
          rows.push({
            rowId: trip?.trip_request_id,
            firstName: trip?.passenger?.first_name,
            lastName: trip?.passenger?.last_name,
            passengerId: trip?.passenger?.passenger_id,
            passengerStripeId: trip?.passenger?.stripe_customer_id,
            earliestDeparture: departureTime,
            latestArrival: arrivalTime,
            status,
            phoneNumber: trip?.passenger?.phone_number,
            busName:
              rideProposal?.route?.driver_shift?.bus?.bus_name ||
              trip?.legs[0]?.driver_shift?.bus?.bus_name,
            needDetailsCall: rideProposal?.need_details_call,
            pickupStop: rideProposal?.pickup_stop,
            dropoffStop: rideProposal?.dropoff_stop,
            origin: {
              name: trip.origin_name || '',
              lon: parseFloat(trip.origin_lon),
              lat: parseFloat(trip.origin_lat)
            },
            destination: {
              name: trip.dest_name || '',
              lon: parseFloat(trip.dest_lon),
              lat: parseFloat(trip.dest_lat)
            },
            paymentType: trip?.payment_type,
            rideProposal,
            departureOrArrival: trip?.preference ? 'arrival' : 'departure',
            tripPrice: trip?.trip_price
          });
        });

        // Sort rows by requestDate
        rows.sort((a, b) => {
          return new Date(b.requestDate) - new Date(a.requestDate);
        });

        this.setState({ rows });
      } catch (err) {
        if (err.response && err.response.status === 403) {
          alert(this.props.t('no_permission'));
        } else {
          console.log(err);
          this.openSnackbar(this.props.t('phone_request_get_error'), colors.red);
        }
      }
    }
  };

  handleCloseModal = () => {
    this.setState({
      editingId: -1,
      editingIndex: -1,
      editRequestMode: false,
      editRequestType: 'accepted',
      modalIsOpen: false,
      runCalledInRequestData: true
    });

    this.getCalledInRequestData();
  };

  openNewModal = () => {
    this.setState({
      editingId: -1,
      editingIndex: -1,
      editRequestMode: false,
      modalIsOpen: true,
      runCalledInRequestData: false
    });
  };

  openEditModal = (row, index, type) => {
    this.setState({
      editingId: row.passengerId,
      editingIndex: index,
      editRequestMode: true,
      editRequestType: type,
      modalIsOpen: true,
      runCalledInRequestData: false
    });
  };

  openTripDetailsPage = (row, index) => {
    this.props.history.push({
      pathname: `/requestByPhoneDetails/${this.state.rows[index].rowId}`
    });
  };

  // Snackbar message and color are passed as props to indicate what to display
  openSnackbar = (snackbarString, color) => {
    this.setState({
      snackbar: {
        open: true,
        message: snackbarString,
        color: color
      }
    });
  };

  // Snackbar closes except for when user clicks outside of component
  closeSnackbar = (reason) => {
    if (reason !== 'clickaway') {
      this.setState({ snackbar: { open: false } });
    }
  };

  getColor = (statusString) => {
    let stateStatus;
    switch (statusString) {
      case 'processing':
        stateStatus = status.pending;
        break;
      case 'rejected':
        stateStatus = status.rejected;
        break;
      case 'confirmed_no_details':
      case 'accepted':
        stateStatus = status.accepted;
        break;
      case 'confirmed_details':
        stateStatus = status.confirmed;
        break;
      default:
        stateStatus = status.none;
    }
    return stateStatus;
  };

  cancelTrip = async (row) => {
    const now = new Date().toUTCString();
    if (row.rideProposal != null && moment(row.rideProposal.departure_time).isBefore(now)) {
      this.openSnackbar(this.props.t('phone_request_past_departure_error'), colors.red);
    }

    this.setState({
      isSubmitting: true
    });

    const passengerId = row.passengerId;

    try {
      await authenticatedAxiosInstance.axios.put(
        `/phoneRequest/${passengerId}/rides/${row.rideProposal.ride_proposal_id}/cancellation`
      );

      this.openSnackbar(this.props.t('phone_request_delete_success'), colors.blaiseGreen);
      this.getCalledInRequestData();
    } catch (err) {
      this.openSnackbar(this.props.t('phone_request_delete_error'), colors.red);
    }
    this.setState({ isSubmitting: false });
  };

  renderIcons = (row, index) => {
    let disableDelete = true;
    if (row.status === 'confirmed_details' || row.status === 'confirmed_no_details') {
      disableDelete = false;
    }

    let disableEdit = true;
    if (
      row.status === 'confirmed_details' ||
      row.status === 'confirmed_no_details' ||
      row.status === 'rejected'
    ) {
      disableEdit = false;
    }

    let editTripType = 'accepted';
    if (row.status === 'rejected') {
      editTripType = 'rejected';
    }

    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-evenly"
        alignItems="center"
        width="100%"
      >
        <Fab
          size="small"
          color="secondary"
          variant="extended"
          onClick={() => {
            this.handleDeleteIcon(row, index);
          }}
          disabled={disableDelete}
        >
          <FontAwesomeIcon icon={faTrash} size="1x" color={colors.white} />
        </Fab>
        <Fab
          size="small"
          color="primary"
          variant="extended"
          onClick={() => this.openTripDetailsPage(row, index)}
          disabled={row.status !== 'confirmed_details'}
        >
          <FontAwesomeIcon icon={faInfo} size="1x" color={colors.white} />
        </Fab>
        <Fab
          size="small"
          color="primary"
          variant="extended"
          onClick={() => this.openEditModal(row, index, editTripType)}
          disabled={disableEdit}
        >
          <FontAwesomeIcon icon={faPen} size="1x" color={colors.white} />
        </Fab>
      </Box>
    );
  };

  handleDeleteIcon = async (rowToCancel, indexToCancel) => {
    this.checkLateCancellation(rowToCancel, indexToCancel);

    this.setState({
      isConfirmModalOpen: true,
      rowToCancel,
      indexToCancel
    });
  };

  handleCancelTrip = async () => {
    try {
      await this.cancelTrip(this.state.rowToCancel, this.state.indexToCancel);
      this.setState({ isConfirmModalOpen: false });
    } catch (err) {
      this.setState({ isConfirmModalOpen: false });
    }
  };

  checkLateCancellation = async (row) => {
    const isLate = await authenticatedAxiosInstance.axios.get(
      `/rides/${row.rideProposal.ride_proposal_id}/verifyLateCancellation/`
    );

    this.setState({ isLateCancellation: isLate.data });
  };

  render() {
    const { classes } = this.props;

    const columnStyle = { width: 150, align: 'center', headerAlign: 'center' };
    const columnStyleWide = { width: 225, align: 'center', headerAlign: 'center' };
    const columns = [
      {
        field: 'rowId',
        headerName: this.props.t('id'),
        ...columnStyle
      },
      {
        field: 'bus_name',
        headerName: this.props.t('bus_name'),
        valueGetter: (params) => {
          const busName = params.row?.busName;
          return busName ? busName : ' - ';
        },
        ...columnStyle
      },
      {
        field: 'firstName',
        headerName: this.props.t('first_name'),
        ...columnStyle
      },
      {
        field: 'lastName',
        headerName: this.props.t('last_name'),
        ...columnStyle
      },
      {
        field: 'departure_time',
        headerName: this.props.t('departure'),
        valueGetter: (params) => {
          if (params.row?.earliestDeparture) {
            return new Date(params.row?.earliestDeparture);
          }
        },
        valueFormatter: (params) => {
          if (params.row?.earliestDeparture) {
            const newFormat = params.row?.earliestDeparture;
            return dateTimeFormat(newFormat);
          } else {
            return ' - ';
          }
        },
        ...columnStyleWide
      },
      {
        field: 'arrival_time',
        headerName: this.props.t('arrival'),
        valueGetter: (params) => {
          if (params.row?.latestArrival) {
            return new Date(params.row?.latestArrival);
          }
        },
        valueFormatter: (params) => {
          if (params.row?.latestArrival) {
            const newFormat = params.row?.latestArrival;
            return dateTimeFormat(newFormat);
          } else {
            return ' - ';
          }
        },
        ...columnStyleWide
      },
      {
        field: 'status',
        headerName: this.props.t('status'),
        ...columnStyle,
        renderCell: (params) => (
          <FontAwesomeIcon
            icon={faCircle}
            color={this.getColor(params.row.status)}
            size="2x"
            className="editIcon"
          />
        )
      },
      {
        field: 'contact_passenger',
        headerName: this.props.t('contact_passenger'),
        sortable: false,
        ...columnStyle,
        width: 200,
        renderCell: (params) => (
          <FontAwesomeIcon
            icon={faPhone}
            color={params.row?.needDetailsCall ? colors.blaiseGreen : colors.blaiseLightGray}
            size="2x"
          />
        )
      },
      {
        field: 'button',
        headerName: this.props.t('actions'),
        renderCell: (params) => this.renderIcons(params.row, params.id),
        sortable: false,
        ...columnStyleWide
      }
    ];

    // copy of rows with id to pass to datagrid
    let rowsCopy;
    if (this.state.rows) {
      rowsCopy = this.state.rows.map((row, index) => {
        return {
          ...row,
          id: index
        };
      });
    }

    const { currentUser } = this.props.currentUserStore;

    return (
      <Fragment>
        <Modal
          open={this.state.isConfirmModalOpen}
          onClose={() => this.setState({ isConfirmModalOpen: false })}
        >
          <Grid container spacing={2} id="trip_cancellation_confirmation_modal">
            <Grid item sm={12}>
              <h2>{this.props.t('confirmation_of_trip_cancellation')}</h2>
            </Grid>
            {this.state.isLateCancellation && (
              <Grid item sm={12}>
                <p>{this.props.t('late_cancellation_penalty')}</p>
              </Grid>
            )}
            {!this.state.isLateCancellation && (
              <Grid item sm={12}>
                <p>{this.props.t('not_late_cancellation')}</p>
              </Grid>
            )}
            <Grid item sm={6}>
              <Button
                style={{ backgroundColor: colors.blaiseLightGray, color: 'black', width: '100%' }}
                onClick={() => this.setState({ isConfirmModalOpen: false })}
              >
                {this.props.t('exit')}
              </Button>
            </Grid>
            <Grid item sm={6}>
              <Button
                form="systemParamForm"
                type="submit"
                style={{ backgroundColor: colors.red, color: 'white', width: '100%' }}
                onClick={this.handleCancelTrip}
              >
                {this.props.t('cancel_trip')}
              </Button>
            </Grid>
          </Grid>
        </Modal>
        <Modal open={this.state.modalIsOpen} onClose={this.handleCloseModal}>
          <PhoneRequestModal
            editRequestMode={this.state.editRequestMode}
            editRequestType={this.state.editRequestType}
            data={this.state.rows[this.state.editingIndex] || {}}
            editingId={this.state.editingId}
            openSnackbar={this.openSnackbar}
            handleCloseModal={this.handleCloseModal}
            transitAgencyId={currentUser?.transit_agency_id}
            transitAgency={this.state.transitAgency}
            listOfPassengers={this.state.listOfPassengers}
            taUtcOffset={this.state.taUtcOffset}
            taTimezone={this.state.taTimezone}
          />
        </Modal>

        <div className="pageTitle">{this.props.t('called_in_requests')}</div>

        <div className="legendChart">
          <Table style={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                <TableCell align="center">{this.props.t('trip_status_rejected')}</TableCell>
                <TableCell align="center">{this.props.t('trip_status_pending')}</TableCell>
                <TableCell align="center">{this.props.t('trip_status_accepted')}</TableCell>
                <TableCell align="center">{this.props.t('trip_status_confirmed')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  <FontAwesomeIcon icon={faCircle} color={status.rejected} size="2x" />
                </TableCell>
                <TableCell align="center">
                  <FontAwesomeIcon icon={faCircle} color={status.pending} size="2x" />
                </TableCell>
                <TableCell align="center">
                  <FontAwesomeIcon icon={faCircle} color={status.accepted} size="2x" />
                </TableCell>
                <TableCell align="center">
                  <FontAwesomeIcon icon={faCircle} color={status.confirmed} size="2x" />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>

        <Paper className={classes.wrapper}>
          <Box display="flex" justifyContent="flex-end" style={{ padding: '16px 0' }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={this.openNewModal}
              data-testid="createNewRide"
            >
              {this.props.t('new')}
            </Button>
          </Box>
          {rowsCopy && (
            <div>
              <DataGridTable
                rows={rowsCopy}
                columns={columns}
                pageSize={5}
                checkboxSelection
                disableSelectionOnClick
              />
            </div>
          )}
        </Paper>

        <CustomSnackbar
          message={this.state.snackbar.message}
          open={this.state.snackbar.open}
          onClose={this.closeSnackbar}
          snackbarColor={this.state.snackbar.color}
        />
      </Fragment>
    );
  }
}

const styles = () => ({
  wrapper: {
    borderRadius: '4px'
  },
  topCells: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'left'
  },
  exit: {
    fontSize: '12px',
    position: 'absolute',
    top: '29px',
    right: '22px',
    color: '#8C8C8C'
  },
  topCellInput: {
    width: '100%',
    borderRadius: '3px',
    border: '1px solid #404040',
    height: '30px',
    paddingLeft: '10px'
  },
  searchBox: {
    '& label': {
      marginTop: '-5px',
      fontSize: '14px'
    },
    '& .Mui-focused': {
      marginTop: '0px',
      fontSize: '16px'
    },
    '& .MuiFormLabel-filled': {
      marginTop: '0px',
      fontSize: '16px'
    }
  },
  exportBtn: {
    paddingTop: '3px',
    paddingBottom: '4px'
  }
});

export default withStyles(styles, { withTheme: true })(
  withTranslation('common')(withRouter(withAuth0(currentUserHOC(RequestByPhone))))
);
