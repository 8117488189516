import React, { useState } from 'react';
import { Modal } from '@material-ui/core';
import moment from 'moment';
import authenticatedAxiosInstance from '../../../axios/axios-authorized';
import './RecurrentShiftsModal.scss';
import ConfirmationStep from './Confirmation';
import StepWizard from 'react-step-wizard';
import ConflictsStep from './Conflicts';
import FormStep from './Form';

const RecurrentShiftsModal = ({
  isOpen,
  closeModal,
  drivers,
  buses,
  depots,
  openSnackbar,
  loadDriverShifts,
  kaboom,
  taUtcOffset
}) => {
  const WEEKDAYS = moment.weekdaysShort();

  const [shiftsToBeInserted, setShiftsToBeInserted] = useState();
  const [errors, setErrors] = useState([]);
  const [shiftFormData, setShiftFormData] = useState();

  // Used in Form and Confirmation Steps
  // dryRun = true, returns the shifts to be inserted
  // dryRun = false, inserts shifts to DB
  const postRecurrenceData = async (dryRun = true) => {
    let shiftDuration = moment(shiftFormData.endTime).diff(shiftFormData.startTime, 'seconds');

    // handles the case if end date is the next day
    if (shiftDuration < 0) {
      shiftDuration += 60 * 60 * 24; // seconds in a day
    }

    const reqBody = {
      shiftDurationSeconds: shiftDuration,
      shiftStartTime: moment(shiftFormData.startTime).format('HH:mm'),
      recurrenceStartDate: moment(shiftFormData.recurrStart).format('YYYY-MM-DD'),
      recurrenceEndDate: moment(shiftFormData.recurrEnd).format('YYYY-MM-DD'),
      applicableDayOfWeek: {
        0: shiftFormData.recurringDays.includes(WEEKDAYS[0]),
        1: shiftFormData.recurringDays.includes(WEEKDAYS[1]),
        2: shiftFormData.recurringDays.includes(WEEKDAYS[2]),
        3: shiftFormData.recurringDays.includes(WEEKDAYS[3]),
        4: shiftFormData.recurringDays.includes(WEEKDAYS[4]),
        5: shiftFormData.recurringDays.includes(WEEKDAYS[5]),
        6: shiftFormData.recurringDays.includes(WEEKDAYS[6])
      },
      driverId: shiftFormData.driverId,
      busId: shiftFormData.busId,
      startDepot: shiftFormData.startDepot,
      endDepot: shiftFormData.endDepot,
      deadheading: shiftFormData.deadheading,
      dryRun
    };

    return await authenticatedAxiosInstance.axios.post(`/drivershift/bulk`, reqBody);
  };

  return (
    <Modal open={isOpen} onClose={closeModal} disableEnforceFocus={true}>
      <StepWizard transitions={{}} className="driverShiftsModal">
        <FormStep
          drivers={drivers}
          buses={buses}
          depots={depots}
          closeModal={closeModal}
          openSnackbar={openSnackbar}
          setShiftsToBeInserted={setShiftsToBeInserted}
          setErrors={setErrors}
          setShiftFormData={setShiftFormData}
          postRecurrenceData={postRecurrenceData}
        />
        <ConflictsStep errors={errors} taTimezoneOffset={taUtcOffset} />
        <ConfirmationStep
          shiftsToBeInserted={shiftsToBeInserted ? shiftsToBeInserted : []}
          taTimezoneOffset={taUtcOffset}
          kaboom={kaboom}
          loadDriverShifts={loadDriverShifts}
          postRecurrenceData={postRecurrenceData}
          closeModal={closeModal}
          openSnackbar={openSnackbar}
        />
      </StepWizard>
    </Modal>
  );
};

export default RecurrentShiftsModal;
