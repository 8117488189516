import React, { Component, Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import { withTranslation } from 'react-i18next';
import { withAuth0 } from '@auth0/auth0-react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import colors from '../../assets/sass/colors';
import Fuse from 'fuse.js';
import { currentUserHOC } from '../../store/user';
import authenticatedAxiosInstance from '../../axios/axios-authorized';

class PassengerSearchAndLinkModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
      searchResults: [],
      passengers: [],
      selectedPassenger: null,
      keys: ['passenger_id', 'first_name', 'last_name', 'phone_number', 'email'],
      confirmLoading: false,
      dataIsLoading: false
    };
  }

  async componentDidMount() {
    try {
      const response = await authenticatedAxiosInstance.axios.get(`/passengers?searchType=2`);

      this.setState({ passengers: response.data }, () => this.loadFuse());
    } catch (err) {
      console.log(`An error has occrued: ${err}`);
    }
  }

  handleSearchChange = (e) => {
    e.persist();
    const { value } = e.target;
    this.setState({ searchValue: value }, () => {
      if (value.length === 0) {
        this.setState({
          searchResults: null
        });
      } else if (e.target.value.length > 3) {
        const newList = this.state.fuse.search(e.target.value);
        this.setState({
          searchResults: newList
        });
      }
    });
  };

  loadFuse = () => {
    const options = {
      includeScore: true,
      minMatchCharLength: 3,
      threshold: 0.4,
      keys: this.state.keys
    };

    let fuse;
    if (this.state.passengers) {
      fuse = new Fuse(this.state.passengers, options);
    } else {
      fuse = new Fuse([], options);
    }
    this.setState({ fuse });
  };

  handleConfirmPassengerSelection = async () => {
    const { currentUser } = this.props.currentUserStore;

    try {
      const linkPassengerToTA = authenticatedAxiosInstance.axios.put(
        `/passengers/${this.state.selectedPassenger?.passenger_id}/transitagency/${currentUser.transit_agency_id}/linkPassengerToTA`,
        {}
      );

      const linkPassengerBalanceToTA = authenticatedAxiosInstance.axios.put(
        `/passengers/${this.state.selectedPassenger?.passenger_id}/linkPBalanceToTA/${currentUser.transit_agency_id}`,
        {}
      );

      await Promise.all([linkPassengerToTA, linkPassengerBalanceToTA]);
      this.props.handleModal(false, true);
    } catch (err) {
      console.log(`An error occurred ${err}`);
    }
  };

  render() {
    const styles = {
      selectedPassengerFalse: {
        border: `1px solid ${colors.blaiseGray}`,
        borderRadius: '5px',
        padding: '15px',
        margin: '7px',
        cursor: 'pointer'
      },
      selectedPassengerTrue: {
        border: `2px solid ${colors.blaiseGreen}`,
        borderRadius: '5px',
        padding: '15px',
        margin: '7px',
        cursor: 'pointer'
      }
    };
    return (
      <Fragment>
        <Grid container spacing={2} id="tripModal">
          <Grid item xs={12}>
            <h2>{this.props.t('search_passenger')}</h2>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="searchValue"
              variant="outlined"
              value={this.state.searchValue}
              fullWidth
              label={this.props.t('passenger_name_email_phone')}
              onChange={(e) => this.handleSearchChange(e)}
              data-testid="searchForUserToLink"
            />
          </Grid>
          {this.state.searchResults ? (
            this.state.searchResults.map((item, i) => {
              return (
                <Grid
                  container
                  style={
                    this.state.selectedPassenger
                      ? item.item.passenger_id === this.state.selectedPassenger.passenger_id
                        ? styles.selectedPassengerTrue
                        : styles.selectedPassengerFalse
                      : styles.selectedPassengerFalse
                  }
                  onClick={() => this.setState({ selectedPassenger: item.item })}
                  key={item.item.passenger_id}
                  data-testid={`result-${i}`}
                >
                  <Grid item xs={6}>
                    <h5>{this.props.t('name')}</h5>
                    <p>{item.item.first_name + ' ' + item.item.last_name}</p>
                  </Grid>
                  <Grid item xs={6} data-testid={`email-${i}`}>
                    <h5>{this.props.t('passenger_email')}</h5>
                    <p>{item.item.email}</p>
                  </Grid>
                  <Grid item xs={6}>
                    <h5>{this.props.t('passenger_id')}</h5>
                    <p>{item.item.passenger_id}</p>
                  </Grid>
                  <Grid item xs={6}>
                    <h5>{this.props.t('phone_number')}</h5>
                    <p>{item.item.phone_number}</p>
                  </Grid>
                </Grid>
              );
            })
          ) : (
            <></>
          )}
          <Button
            disabled={this.state.selectedPassenger ? false : true}
            style={{
              backgroundColor: this.state.selectedPassenger
                ? colors.blaiseGreen
                : colors.blaiseGray,
              marginTop: '2%',
              color: 'white',
              width: '40%',
              left: '30%'
            }}
            onClick={this.handleConfirmPassengerSelection}
            data-testid="confirm"
          >
            {this.props.t('confirm')}
          </Button>
        </Grid>
      </Fragment>
    );
  }
}

export default withTranslation('common')(withAuth0(currentUserHOC(PassengerSearchAndLinkModal)));
