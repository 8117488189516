import moment from 'moment-timezone';

const lang = navigator.language.substring(0, 2);

export const dateTimeFormat = (value) => {
  // Dont format the string with AM/PM when using french
  if (lang == 'fr') {
    return moment(value).locale(lang).format('LL, H:mm');
  } else {
    return moment(value).locale(lang).format('LL, h:mma');
  }
};

// Used for when we want to show dates in the timezone of the TA
export const dateTimeFormatWithOffset = (value, offset, valueIsUTC = true, format = '') => {
  if (format) {
    if (valueIsUTC) {
      return moment(value).locale(lang).utc().add(offset, 'h').format(format);
    }
    return moment(value).locale(lang).add(offset, 'h').format(format);
  }
  const defaultFormat = lang == 'fr' ? 'LL, H:mm' : 'LL, h:mma';
  if (valueIsUTC) {
    return moment(value).locale(lang).utc().add(offset, 'h').format(defaultFormat);
  }
  return moment(value).locale(lang).add(offset, 'h').format(defaultFormat);
};

export const timeDateFormat = (value) => {
  return moment(value).locale(lang).format('h:mma, MMM. Do YYYY');
};

// Format: September 4 1986
export const dateOnlyFormat = (value, offset = '') => {
  if (offset) {
    return moment(value).utc().add(offset, 'h').locale(lang).format('LL');
  }
  return moment(value).locale(lang).format('LL');
};

// Format: Apr 27th 2022
// Takes 10 digit Unix timestamp. If you have a 13 digit, divide value by 10 when calling function.
export const dateOnlyFormatShortMonth = (value) => {
  return lang === 'fr'
    ? moment(value * 1000).format('D MMM YYYY')
    : moment(value * 1000).format('MMM Do YYYY');
};

export const timeOnlyFormat = (value, offset = '') => {
  // Dont format the string with AM/PM when using french
  if (lang === 'fr') {
    if (offset) {
      return moment(value).utc().add(offset, 'h').format('H:mm');
    }
    return moment(value).format('H:mm');
  } else {
    if (offset) {
      return moment(value).utc().add(offset, 'h').format('h:mma');
    }
    return moment(value).format('h:mma');
  }
};

export const getArrivalTime = (travelTime, value, offset = '') => {
  // This function is used to take the user selected time and add the length of the trip to get an estimate of when they will arrive
  if (lang === 'fr') {
    if (offset) {
      return moment(value).utc().add(offset, 'h').add(travelTime, 'minutes').format('H:mm');
    }
    return moment(value).add(travelTime, 'minutes').format('H:mm');
  } else {
    if (offset) {
      return moment(value).utc().add(offset, 'h').add(travelTime, 'minutes').format('LT');
    }
    return moment(value).add(travelTime, 'minutes').format('LT');
  }
};

// Returns number, example: 18
// Takes 10 digit Unix timestamp. If you have a 13 digit, divide value by 1000 when calling function.
export const calculateDaysRemaining = (startDate, endDate) => {
  const start = moment(startDate * 1000);
  const end = moment(endDate * 1000);

  return end.diff(start, 'days');
};

export const getDepartureTime = (travelTime, value, offset = '') => {
  // This function is used to take the user selected time and subtract the length of the trip to get an estimate of when they will depart

  if (lang === 'fr') {
    if (offset) {
      return moment(value).utc().add(offset, 'h').subtract(travelTime, 'minutes').format('H:mm');
    }
    return moment(value).subtract(travelTime, 'minutes').format('H:mm');
  } else {
    if (offset) {
      return moment(value).utc().add(offset, 'h').subtract(travelTime, 'minutes').format('LT');
    }
    return moment(value).subtract(travelTime, 'minutes').format('LT');
  }
};

export const fileTimeFormat = (value) => {
  return moment(value).format('MMM_Do_YYYY');
};

export const timeDiff = (arrival, departure) => {
  return Math.trunc(moment.duration(new Date(arrival) - new Date(departure)).asMinutes()) + ' min';
};

export const addSubtractMinutesToTime = (type, time, minutes) => {
  let unformattedTime;
  if (type === 'add') {
    unformattedTime = moment(time).add(minutes, 'minutes');
  } else {
    unformattedTime = moment(time).subtract(minutes, 'minutes');
  }

  if (lang == 'fr') {
    return moment(unformattedTime).locale(lang).format('H:mm');
  } else {
    return moment(unformattedTime).locale(lang).format('h:mma');
  }
};

export const addSubtractMinutesToTimeUTC = (type, time, minutes) => {
  let unformattedTime;
  if (type === 'add') {
    unformattedTime = moment(time).utc().add(minutes, 'minutes');
  } else {
    unformattedTime = moment(time).utc().subtract(minutes, 'minutes');
  }
  return unformattedTime;
};

export const convertTimeToMinutes = (time) => {
  time = time.split(':');
  return +time[0] * 60 + +time[1];
};

export const convertTimeToHours = (time) => {
  time = time.split(':');
  return (+time[0] + +time[1] / 60).toFixed(2);
};

export const convertMinutesToTime = (minutes) => {
  let h = Math.floor(minutes / 60);
  let m = minutes - h * 60;
  return `${h}:${m}:00`;
};

export const convertHoursToTime = (hours) => {
  let h = Math.floor(hours);
  let m = (hours - h) * 60;
  return `${h}:${m}:00`;
};

// format moment with timezone (removes need for offset)
export const dateTimeFormatWithTimezone = (value, timezone, format = '') => {
  if (format) {
    return moment(value).locale(lang).utc().tz(timezone).format(format);
  }
  const defaultFormat = lang == 'fr' ? 'LL, H:mm' : 'LL, h:mma';
  return moment(value).locale(lang).utc().tz(timezone).format(defaultFormat);
};

export const timeOnlyFormatWithTimezone = (value, timezone = 'UTC') => {
  // Dont format the string with AM/PM when using french
  if (lang === 'fr') {
    return moment(value).tz(timezone).format('H:mm');
  } else {
    return moment(value).tz(timezone).format('h:mma');
  }
};
