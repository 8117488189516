import React, { Component, Fragment } from 'react';
import { Box, Button, CircularProgress, Fab, Modal, Paper, Tooltip } from '@material-ui/core';
import { faPen, faTrash, faCheck, faTimes, faTabletAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../assets/sass/colors';
import CustomSnackbar from '../Snackbar/CustomSnackbar';
import DeleteModal from '../DeleteModal/DeleteModal';
import DisconnectTableModal from './DisconnectTabletModal';
import { withAuth0 } from '@auth0/auth0-react';
import { withTranslation } from 'react-i18next';
import '../Fleet/FleetTable/FleetTable.scss';
import DataGridTable from '../DataGrid/DataGridTable';
import ModalAdd from '../Modals/ModalAdd';
import '../../assets/sass/global.scss';
import authenticatedAxiosInstance from '../../axios/axios-authorized';

class BusTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      buses: null, // stores the buses data from the api call
      refreshBuses: false, // state determines refresh rate of table
      deleteModal: false, // show/hide delete modal
      editMode: false, // turns on editMode for a specific row
      addMode: this.props.add, // renders AddRow component
      snackbar: {
        // open/close snackbar element, message is the string that is passed to the component
        open: false,
        message: ''
      },
      editIndex: null, // default index for row in editMode
      deleteId: null, // default bus id in deleteModal,
      newBus: {
        // stores the data when adding a new bus
        bus_fleet_id: '',
        bus_name: ''
      },
      isSubmitting: false,
      fleetIds: null,
      openConnectTablet: false,
      connectBus: null,
      modalOpen: false,
      disconnectModalOpen: false,
      disconnectBusInfo: null
    };

    this.initialBuses = null;
  }

  async componentDidMount() {
    //api call to get busfleets to prepare the list of select options
    try {
      const { data } = await authenticatedAxiosInstance.axios.get(`/busfleets?searchType=1`);

      const listOfSelectOptions = data.map(({ bus_fleet_id, bus_type }) => {
        return {
          id: bus_fleet_id.toString(),
          value: bus_fleet_id.toString(),
          label: bus_fleet_id.toString() + ' - ' + bus_type.toString()
        };
      });

      this.setState({ fleetIds: listOfSelectOptions });
    } catch (err) {
      console.log(err);
    }

    this.getAllBuses();
  }

  async componentDidUpdate() {
    // This is called when add, edit, and delete are false
    // refreshBus state is used so that the table is not endlessly refreshing
    if (
      !this.state.addMode &&
      !this.state.editMode &&
      !this.state.deleteModal &&
      this.state.refreshBuses
    ) {
      try {
        const response = await authenticatedAxiosInstance.axios.get(`/buses?searchType=1`);

        response.data.forEach((bus) => {
          bus['bus_type'] = bus.bus_fleet.bus_type;
        });

        this.setState({
          buses: response.data,
          refreshBuses: false
        });

        this.initialBuses = []; // creates an array for the initial bus fleet to compare differences for edits
        this.state.buses.forEach((bus) => {
          this.initialBuses.push({
            bus_id: bus.bus_id,
            bus_fleet_id: bus.bus_fleet_id,
            bus_number: bus.bus_number,
            bus_name: bus.bus_name,
            is_active: bus.is_active,
            has_tablet: bus.has_tablet
          });
        });
      } catch (error) {
        // in the case that the database returns no buses, an empty array is set as bus state
        if (error.statusCode === 404) {
          const buses = [];
          this.setState({
            buses,
            refreshBuses: false
          });
        } else {
          this.openSnackbar(this.props.t('problem_loading_buses'));
        }
      }
    }
  }

  getAllBuses = async () => {
    try {
      const response = await authenticatedAxiosInstance.axios.get(`/buses?searchType=1`);

      response.data.forEach((bus) => {
        bus['bus_type'] = bus.bus_fleet.bus_type;
      });

      this.setState({
        buses: response.data,
        refreshBuses: false
      });

      this.initialBuses = [];
      this.state.buses.forEach((bus) => {
        this.initialBuses.push({
          bus_id: bus.bus_id,
          bus_fleet_id: bus.bus_fleet_id,
          bus_number: bus.bus_number,
          bus_name: bus.bus_name,
          is_active: bus.is_active,
          has_tablet: bus.has_tablet
        });
      });
    } catch (err) {
      if (err.response && err.response.status === 403) {
        alert(this.props.t('no_permission'));
      } else {
        this.openSnackbar(this.props.t('problem_loading_buses'));
      }
    }
  };

  // Snackbar message and color are passed as props to indicate what to display
  openSnackbar = (snackbarString, color) => {
    this.setState({
      snackbar: {
        open: true,
        message: snackbarString,
        color: color
      }
    });
  };

  // Snackbar closes except for when user clicks outside of component
  closeSnackbar = (reason) => {
    if (reason !== 'clickaway') {
      this.setState({ snackbar: { open: false } });
    }
  };

  handleEditCellChange = ({ id, field, props }) => {
    this.setState(() => {
      let busesCopy = this.state.buses[id];
      busesCopy[field] = props.value;
      return busesCopy;
    });
  };

  // Checks if any changes have been made for a specific bus fleet
  isDifferent = (originalBuses, updatedBuses, index) => {
    return !(
      originalBuses[index].bus_id === updatedBuses[index].bus_id &&
      originalBuses[index].bus_fleet_id === updatedBuses[index].bus_fleet_id &&
      originalBuses[index].bus_name === updatedBuses[index].bus_name
    );
  };

  // Activate the editing mode
  activateEdit = (e, busIndex) => {
    this.setState({
      editMode: true,
      editIndex: busIndex
    });
  };

  // Open the delete confirmation modal
  showDelete = (e, busIndex) => {
    this.setState({
      deleteModal: true,
      deleteId: this.state.buses[busIndex].bus_id
    });
  };

  // Toggle add mode
  toggleAdd = () => {
    this.setState({
      addMode: !this.state.addMode,
      //refreshBuses: true,
      modalOpen: !this.state.modalOpen
    });
  };

  // Cancel any changes that are in progress on the text fields
  cancelChanges = () => {
    this.setState({
      addMode: false,
      editMode: false,
      deleteModal: false,
      buses: this.initialBuses,
      deleteId: null,
      editIndex: null,
      refreshBuses: true
    });
  };

  // Updates the values in the database based on new values entered
  updateValues = async (event, index) => {
    this.validateNewRow(this.state.buses[index]);
    try {
      if (!this.isDifferent(this.initialBuses, this.state.buses, index))
        throw new Error(this.props.t('no_changes'));
    } catch (err) {
      this.openSnackbar(err.message, colors.blaiseGray);
      this.setState({ editMode: false, refreshFleet: true });
      return;
    }

    this.setState({
      isSubmitting: true
    });

    const reqBody = {
      bus_fleet_id: this.state.buses[index].bus_fleet_id,
      bus_name: this.state.buses[index].bus_name
    };

    try {
      await authenticatedAxiosInstance.axios.put(`/bus/${this.state.buses[index].bus_id}`, reqBody);

      this.initialBuses[index] = {
        ...this.initialBuses[index],
        bus_name: reqBody.bus_name
      };
      this.openSnackbar(this.props.t('bus_modified'), colors.blaiseGreen);
      this.setState({
        editMode: false,
        editIndex: null,
        refreshFleet: true
      });
    } catch (error) {
      this.openSnackbar(this.props.t('bus_changing_error'), colors.red);
    }

    this.setState({
      isSubmitting: false
    });
  };

  // Renders the actions icons in the default view
  renderDefaultIcons = (bus, busIndex) => {
    return (
      <Box display="flex" flexDirection="row" justify="flex-end" style={{ gap: '8px' }}>
        {bus.has_tablet === 1 && (
          <Tooltip title={this.props.t('deregister')}>
            <Fab
              color="secondary"
              backgroundColor={colors.blaiseGray}
              size="small"
              className="editButton"
              variant="extended"
              onClick={() =>
                this.setState({
                  disconnectModalOpen: true,
                  disconnectBusInfo: { id: bus.bus_id, name: bus.bus_name }
                })
              }
            >
              <FontAwesomeIcon
                icon={faTabletAlt}
                color={colors.white}
                backgroundColor={colors.blaiseGray}
                size="1x"
                className="editIcon"
              />
            </Fab>
          </Tooltip>
        )}
        {bus.has_tablet === 0 && (
          <Tooltip title={this.props.t('register')}>
            <Fab
              color="primary"
              size="small"
              className="editButton"
              variant="extended"
              onClick={(e) => this.connectTablet(e, busIndex)}
            >
              <FontAwesomeIcon
                icon={faTabletAlt}
                color={colors.white}
                size="1x"
                className="editIcon"
              />
            </Fab>
          </Tooltip>
        )}
        <Tooltip title={this.props.t('edit')}>
          <Fab
            color="primary"
            size="small"
            className="editButton"
            variant="extended"
            onClick={(e) => this.activateEdit(e, busIndex)}
          >
            <FontAwesomeIcon icon={faPen} color={colors.white} size="1x" className="editIcon" />
          </Fab>
        </Tooltip>
        <Tooltip title={this.props.t('remove')}>
          <Fab
            color="secondary"
            size="small"
            className="deleteButton"
            variant="extended"
            onClick={(e) => this.showDelete(e, busIndex)}
          >
            <FontAwesomeIcon icon={faTrash} color={colors.white} size="1x" className="deleteIcon" />
          </Fab>
        </Tooltip>
      </Box>
    );
  };

  connectTablet = async (e, index) => {
    const busId = this.state.buses[index].bus_id;
    const busName = this.state.buses[index].bus_name;
    const busNum = this.state.buses[index].bus_number;

    const code = await authenticatedAxiosInstance.axios.get(
      `/createCode/${this.state.buses[index].bus_id}`
    );

    this.setState({
      connectBus: {
        code: code.data,
        busId,
        busName,
        busNum
      },
      openConnectTablet: true
    });
  };

  disconnectTablet = async () => {
    try {
      await authenticatedAxiosInstance.axios.put(
        `/deregisterTabletOnEngine/${this.state.disconnectBusInfo.id}`
      );

      this.openSnackbar(this.props.t('deregister_success'), colors.blaiseGreen);
      this.getAllBuses();
      this.setState({ disconnectModalOpen: false, disconnectBusInfo: null });
    } catch (err) {
      console.log(err);
      this.openSnackbar(this.props.t('deregister_error'), colors.blaiseRed);
    }
  };

  // Renders the action icons in editMode
  renderEditIcons = (busIndex) => {
    if (this.state.editIndex === busIndex) {
      return this.state.isSubmitting ? (
        <CircularProgress />
      ) : (
        <>
          <Box display="flex" flexDirection="row" justifyContent="space-evenly" width="100%">
            <Fab
              color="primary"
              size="small"
              className="editButton"
              variant="extended"
              onClick={(e) => this.updateValues(e, this.state.editIndex)}
            >
              <FontAwesomeIcon icon={faCheck} color={colors.white} size="1x" className="editIcon" />
            </Fab>
            <Fab
              size="small"
              className="deleteButton"
              variant="extended"
              onClick={this.cancelChanges}
            >
              <FontAwesomeIcon
                icon={faTimes}
                color={colors.white}
                size="1x"
                className="deleteIcon"
              />
            </Fab>
          </Box>
        </>
      );
    }
  };

  // Handles changes to text fields when adding a new bus
  handleNewBusChange = (event, index) => {
    const target = event.target;
    const value = target.name === 'bus_fleet_id' ? parseInt(target.value) : target.value;

    this.setState((prevState) => {
      let newBusCopy = { ...prevState.newBus };

      newBusCopy[index] = value;

      return {
        newBus: newBusCopy
      };
    });
  };

  // Validates the new bus being added
  validateNewRow(bus) {
    try {
      if (!Number(bus.bus_fleet_id) || bus.bus_name.length <= 0)
        throw new Error(this.props.t('required_field'));
    } catch (err) {
      this.openSnackbar(err.message, colors.red);
      throw Error;
    }
  }

  // Create a new bus
  onCreateRow = async () => {
    this.validateNewRow(this.state.newBus);

    try {
      const reqBody = {
        bus_name: this.state.newBus.bus_name
      };

      await authenticatedAxiosInstance.axios.post(
        `/busfleets/${this.state.newBus.bus_fleet_id}/bus`,
        reqBody
      );

      this.openSnackbar(this.props.t('new_bus_added'), colors.blaiseGreen);
      this.setState({
        addMode: false,
        newBus: {
          bus_fleet_id: null,
          bus_name: null
        },
        refreshBuses: true,
        modalOpen: !this.state.modalOpen
      });
    } catch (error) {
      console.log(error);
      this.openSnackbar(this.props.t('create_bus_error'), colors.red);
    }
  };

  // Delete a row
  onDeleteRow = async () => {
    try {
      this.setState({ isSubmitting: true });
      await authenticatedAxiosInstance.axios.delete(`/bus/${this.state.deleteId}`);

      this.cancelChanges();
      this.openSnackbar(this.props.t('bus_now_inactive'), colors.blaiseGreen);
    } catch (err) {
      if (err.response?.data?.error?.message?.errorKey === 'bus_associated_to_shift') {
        this.openSnackbar(this.props.t('bus_associated_to_shift'), colors.red);
      } else {
        this.openSnackbar(this.props.t('bus_delete_error'), colors.red);
      }
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  handleModalClose = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
      addMode: !this.state.addMode,
      refreshBuses: true,
      newBus: {
        bus_fleet_id: '',
        bus_name: ''
      }
    });
  };

  render() {
    const columnStyles = { flex: 1, align: 'center', headerAlign: 'center' };

    const columns = [
      {
        field: 'bus_fleet_id',
        headerName: this.props.t('fleet_id'),
        ...columnStyles,
        editable: false
      },
      { field: 'bus_type', headerName: this.props.t('fleet'), ...columnStyles, editable: false },
      {
        field: 'bus_name',
        headerName: this.props.t('bus_name'),
        valueGetter: (params) => {
          return params.row?.bus_name || params.row.bus_number?.toString();
        },
        ...columnStyles,
        editable: true
      },
      {
        field: 'button',
        headerName: this.props.t('actions'),
        headerAlign: 'center',
        flex: 0.5,
        renderCell: (params) =>
          !this.state.addMode &&
          (this.state.editMode
            ? this.renderEditIcons(params.id)
            : this.renderDefaultIcons(params.row, params.id)),
        sortable: false,
        align: 'right',
        disableColumnMenu: true
      }
    ];

    let busesCopy;
    if (this.state.buses) {
      busesCopy = this.state.buses.map((bus, index) => {
        return {
          ...bus,
          id: index
        };
      });
    }

    return (
      <Fragment>
        {this.state.modalOpen && (
          <ModalAdd
            {...this.props}
            modalOpen={this.state.modalOpen}
            modalTitle={this.props.t('add_bus_button_title')}
            modalFields={['bus_fleet_id', 'bus_name']}
            modalExtraFields={{ bus_fleet_id: this.state.fleetIds }}
            handleModalAdd={this.onCreateRow}
            handleChange={this.handleNewBusChange}
            handleModalClose={this.handleModalClose}
          />
        )}
        <Paper>
          <Box display="flex" justifyContent="flex-end" style={{ padding: '16px 0' }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={this.state.fleetIds != null && this.state.fleetIds.length === 0}
              onClick={this.toggleAdd}
              data-testid="add-bus-shift-button"
            >
              {this.props.t('add_bus_button_title')}
            </Button>
          </Box>
          {(!this.state.buses || this.state.refreshBuses) && (
            <Box display="flex" justifyContent="center" className="emptyBox">
              <CircularProgress />
            </Box>
          )}
          {columns && busesCopy && (
            <DataGridTable
              columns={columns}
              rows={busesCopy}
              editIndex={this.state.editIndex}
              handleEditCellChange={this.handleEditCellChange}
            />
          )}
        </Paper>
        {/* External Components which appear based on changing states */}
        <CustomSnackbar
          message={this.state.snackbar.message}
          open={this.state.snackbar.open}
          onClose={this.closeSnackbar}
          snackbarColor={this.state.snackbar.color}
        />
        <DeleteModal
          open={this.state.deleteModal}
          isSubmitting={this.state.isSubmitting}
          toggleModal={this.cancelChanges}
          deleteId={this.state.deleteId}
          snackbar={this.openSnackbar}
          onDeleteItem={this.onDeleteRow}
          deleteMessage={this.props.t('confirm_delete_bus')}
        />
        {this.state.disconnectModalOpen && (
          <DisconnectTableModal
            open={this.state.disconnectModalOpen}
            onClose={() => this.setState({ disconnectModalOpen: false })}
            isSubmitting={this.state.isSubmitting}
            text={this.props.t('deregister_tablet_warning', {
              busName: this.state.disconnectBusInfo.name
            })}
            t={this.props.t}
            handleDisconnectTable={this.disconnectTablet}
          />
        )}
        {this.state.connectBus && (
          <Modal
            open={this.state.openConnectTablet}
            onClose={() => {
              this.setState({ openConnectTablet: false });
            }}
            className="modal"
          >
            <Box display="flex" align="center" m={4}>
              <Paper>
                <h2>{this.props.t('setup_blaise_drive')}</h2>
                <Box align="center" m={4} style={{ fontSize: 20 }}>
                  {this.props.t('tablet_setup_1')}{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {this.state.connectBus.busName
                      ? this.state.connectBus.busName
                      : this.state.connectBus.busNum}{' '}
                  </span>
                  {this.props.t('tablet_setup_2')}:
                </Box>
                <Box
                  align="center"
                  m={4}
                  style={{ color: colors.blaiseGreen, fontWeight: 'bold', fontSize: 45 }}
                >
                  {this.state.connectBus.code}
                </Box>
                <Box align="center" m={4}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => this.setState({ openConnectTablet: false })}
                  >
                    OK
                  </Button>
                </Box>
              </Paper>
            </Box>
          </Modal>
        )}
      </Fragment>
    );
  }
}

export default withTranslation('common')(withAuth0(BusTable));
