import React, { Component, Fragment } from 'react';

import { withTranslation } from 'react-i18next';
import { withAuth0 } from '@auth0/auth0-react';
import { checkPermissions } from '../../utils/authUtils';
import axios from 'axios';

import Box from '@material-ui/core/Box';

import config from '../../config';
import './Profile.scss';
import colors from '../../assets/sass/colors';

import MyProfile from '../../components/MyProfile/MyProfile';
import SwitchAgency from '../../components/MyProfile/SwitchAgency';
import nameSplit from '../../utils/nameSplit';
import { currentUserHOC } from '../../store/user';
import authorizedAxiosInstance from '../../axios/axios-authorized';
import { authHOC } from '../../store/auth';

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profileFields: {},
      loading: false,
      snackbar: {
        open: false,
        message: ''
      },
      passwordEmailSent: false,
      name: null, //The name displayed on top of the page. Separate from fields,
      profileFieldsCompare: null
    };
  }

  componentDidMount = async () => {
    document.title = `${this.props.t('profile_title')} - ${this.props.t('trip_source_engine')}`;
    await this.fetchUserData();
  };

  /**
   * Fetches user data from database
   * Used by componentDidMount and after sumitting changes
   */
  fetchUserData = async () => {
    try {
      const userInfo = (await authorizedAxiosInstance.axios.get(`/currentUser`)).data;

      let firstName = '';
      let lastName = '';

      // hanlding for possible previously null value of first_name since data changed only recently
      if (!userInfo.first_name) {
        const name = nameSplit(userInfo.transit_agency_user_name);
        firstName = name['first_name'];
        lastName = name['last_name'];
      } else {
        firstName = userInfo.first_name;
        lastName = userInfo.last_name;
      }

      const profileFields = {
        first_name: {
          id: 'first_name',
          label: this.props.t('first_name'),
          value: firstName,
          editable: true,
          modified: false
        },
        last_name: {
          id: 'last_name',
          label: this.props.t('last_name'),
          value: lastName,
          editable: true,
          modified: false
        },
        email: {
          id: 'email',
          label: this.props.t('email'),
          value: userInfo.email,
          editable: true,
          modified: false
        },
        transportSociety: {
          id: 'transit_agency_name',
          label: this.props.t('transit_agency'),
          value: userInfo.transit_agency.transit_agency_name,
          editable: false,
          modified: false
        },
        creationDate: {
          id: 'creation_date',
          label: this.props.t('creation_date'),
          value: userInfo.creation_date,
          editable: false,
          modified: false
        }
      };
      this.setState({ profileFields, name: userInfo.transit_agency_user_name });
      this.setState({ profileFieldsCompare: JSON.parse(JSON.stringify(profileFields)) }); //Deep copy
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * Trigger by TextField onChange
   * @param {*} field field name
   * @param {*} e event
   */
  updateField = (field, e) => {
    let profileFieldsStateCopy = this.state.profileFields;
    const newValue = e.target.value;

    profileFieldsStateCopy[field].value = newValue;
    profileFieldsStateCopy[field].modified =
      newValue.trim() !== this.state.profileFieldsCompare[field].value;

    this.setState({
      profileFields: profileFieldsStateCopy
    });
  };

  /**
   * Submit changes to the backend
   */
  saveChanges = async () => {
    try {
      this.setState({ loading: true });
      let editObject = {};
      for (const key in this.state.profileFields) {
        const field = this.state.profileFields[key];
        if (field.modified || field.id === 'first_name' || field.id === 'last_name') {
          editObject[key] = field.value;
        }
      }
      if (Object.keys(editObject).length === 0) return;

      await authorizedAxiosInstance.axios.put(`/currentUser`, editObject);
      this.openSnackbar(this.props.t('changes_saved'), colors.blaiseGreen);
    } catch (e) {
      this.openSnackbar(this.props.t('error_generic'), colors.red);
    } finally {
      this.setState({ loading: false });
      this.fetchUserData();
    }
  };

  /**
   * This seems to be the best way to change password.
   */
  sendPasswordChangeEmail = async () => {
    const { currentUser } = this.props.currentUserStore;
    try {
      await axios.post(
        `https://${config.auth0Domain}/dbconnections/change_password`,
        {
          client_id: `${config.auth0ClientId}`,
          email: currentUser.email,
          connection: 'Username-Password-Authentication'
        },
        {
          'content-type': 'application/json'
        }
      );
      this.setState({
        passwordEmailSent: true
      });
    } catch (e) {
      this.openSnackbar(this.props.t('error_generic'), colors.red);
    }
  };

  //Snackbar

  // Snackbar message and color are passed as props to indicate what to display
  openSnackbar = (snackbarString, color) => {
    this.setState({
      snackbar: {
        open: true,
        message: snackbarString,
        color: color
      }
    });
  };

  // Snackbar closes except for when user clicks outside of component
  closeSnackbar = (reason) => {
    if (reason !== 'clickaway') {
      this.setState({ snackbar: { open: false } });
    }
  };

  resetFields = () => {
    const resetObject = {};
    Object.keys(this.state.profileFields).forEach((field) => {
      resetObject[field] = { ...this.state.profileFieldsCompare[field] };
    });
    this.setState({ profileFields: resetObject });
  };

  render() {
    const { currentUser } = this.props.currentUserStore;

    return (
      <Fragment>
        <Box mb={4} flexDirection="row" display="flex">
          <img
            src={
              this.props.auth0 && this.props.auth0.user.picture
                ? this.props.auth0.user.picture
                : null
            }
            id="profilePic"
            alt="profilePicture"
          />
          {this.state.profileFields &&
            this.state.profileFields.first_name &&
            this.state.profileFields.last_name &&
            this.state.profileFields.transportSociety && (
              <Box flexDirection="column" justifyContent="center" display="flex" mx={3}>
                <div className="profileName">
                  {this.state.profileFields.first_name.value}{' '}
                  {this.state.profileFields.last_name.value}
                </div>
                <div className="agencySubtitle">
                  {this.state.profileFields.transportSociety.value}
                </div>
              </Box>
            )}
        </Box>

        {this.props.auth0 &&
          checkPermissions(this.props.auth0, 'execute:switchCurrentTA') &&
          currentUser && (
            <SwitchAgency
              auth0={this.props.auth0}
              currentUser={currentUser}
              t={this.props.t}
              snackbar={this.state.snackbar}
              closeSnackbar={this.closeSnackbar}
              openSnackbar={this.openSnackbar}
            />
          )}

        <MyProfile
          snackbar={this.state.snackbar}
          closeSnackbar={this.closeSnackbar}
          profileFields={this.state.profileFields}
          updateField={this.updateField}
          passwordEmailSent={this.state.passwordEmailSent}
          sendPasswordChangeEmail={this.sendPasswordChangeEmail}
          resetFields={this.resetFields}
          saveChanges={this.saveChanges}
          loading={this.state.loading}
        />
      </Fragment>
    );
  }
}

export default withTranslation('common')(withAuth0(authHOC(currentUserHOC(Profile))));
