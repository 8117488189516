import React from 'react';
import colors from '../../assets/sass/colors';
import { TripType } from '../../enums/tripType';
import { displayWindowRange } from '../../utils/dateTimeFormat';
import { Box, Grid, TextField, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWalking, faBus } from '@fortawesome/free-solid-svg-icons';
import './AdditionalTripDetails.scss';
import { useTranslation } from 'react-i18next';

const AdditionalTripDetails = ({ legs, walkingDistanceAndDuration }) => {
  const { t } = useTranslation('common');

  return (
    <>
      {legs.map((leg, index) => (
        <Box key={leg.leg_id}>
          <Box className="pickup-dropoff-container">
            {/* Line and dots */}
            <Box
              className="dot"
              style={{
                top: '20px',
                borderColor: `${
                  leg.type === TripType.ON_DEMAND ? colors.blaiseGreen : colors.fixedRouteOrange
                }`
              }}
            />
            <Box
              className="solid-line"
              style={{
                borderColor: `${
                  leg.type === TripType.ON_DEMAND ? colors.blaiseGreen : colors.fixedRouteOrange
                }`
              }}
            />
            <Box
              className="dot"
              style={{
                bottom: '20px',
                borderColor: `${
                  leg.type === TripType.ON_DEMAND ? colors.blaiseGreen : colors.fixedRouteOrange
                }`
              }}
            />

            {/* Pickup info */}
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  name="pickupStopName"
                  value={
                    leg?.pickupStopId?.name || leg?.pickup_stop?.name || t('trip_status_pending')
                  }
                  variant="outlined"
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label={t('pickup_stop')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="pickupTime"
                  value={
                    leg.pickup_time_window_lo || leg.pickup_time
                      ? displayWindowRange('pickup', leg.pickup_time_window_lo || leg.pickup_time)
                      : t('trip_status_pending')
                  }
                  // HALP - Fix.
                  // value={
                  //   tripDetails?.pickupTime
                  //     ? displayWindowRange(
                  //         'pickup',
                  //         tripDetails?.pickupTime,
                  //         tripDetails?.dropoffTime,
                  //         taTimezone,
                  //         {
                  //           pickup_window: timeWindows?.phone_in_pickup_window,
                  //           dropoff_window: timeWindows?.phone_in_dropoff_window
                  //         },
                  //         true
                  //       )
                  //     : t('trip_status_pending')
                  // }
                  variant="outlined"
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label={t('pickup_time')}
                />
              </Grid>
            </Grid>
            {/* Bus icon and info */}
            <Box className="trip-details-icon-container">
              <FontAwesomeIcon icon={faBus} size="lg" color={colors.black} />
              <Box>
                <Typography style={{ fontSize: '14px' }}>{t('bus')}</Typography>
              </Box>
              <TextField
                name="busName"
                // On-demand bus name, or on-demand transfers bus name, or external bus name.
                value={
                  leg.route?.bus?.bus?.bus_name ||
                  leg.route?.driver_shift?.bus?.bus_name ||
                  leg.external_line_id ||
                  ' - '
                }
                style={{ width: 'max-content' }}
                variant="outlined"
                inputProps={{ readOnly: true }}
                fullWidth
                label={t('bus_name')}
              />
            </Box>

            {/* Dropoff info */}
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  name="dropoffStopName"
                  value={
                    leg?.dropoffStopId?.name || leg?.dropoff_stop?.name || t('trip_status_pending')
                  }
                  variant="outlined"
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label={t('dropoff_stop')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="dropoffTime"
                  value={
                    leg.dropoff_time_window_lo || leg.dropoff_time
                      ? displayWindowRange(
                          'dropoff',
                          leg.dropoff_time_window_hi || leg.dropoff_time,
                          leg.dropoff_time_window_lo && leg.dropoff_time_window_hi
                        )
                      : t('trip_status_pending')
                  }
                  // HALP - Fix
                  // value={
                  //   tripDetails?.dropoffTime
                  //     ? displayWindowRange(
                  //         'dropoff',
                  //         tripDetails?.dropoffTime,
                  //         tripDetails?.pickupTime,
                  //         taTimezone,
                  //         {
                  //           pickup_window: timeWindows?.phone_in_pickup_window,
                  //           dropoff_window: timeWindows?.phone_in_dropoff_window
                  //         },
                  //         true
                  //       )
                  //     : t('trip_status_pending')
                  // }
                  variant="outlined"
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label={t('dropoff_time')}
                />
              </Grid>
            </Grid>
          </Box>
          <Box className="trip-details-icon-container">
            <FontAwesomeIcon
              icon={faWalking}
              size="lg"
              color={colors.black}
              style={{ marginRight: '8px' }} // 8px margin added so that the text Walk and Vehicle are in line.
            />
            <Box>
              <Typography style={{ fontSize: '14px' }}>{t('walk')}</Typography>
              <Typography style={{ fontSize: '10px' }}>
                {t('about')} {walkingDistanceAndDuration[index + 1]?.durationInMinutes}{' '}
                {walkingDistanceAndDuration[index + 1]?.durationInMinutes === 1 ? 'min' : 'mins'},{' '}
                {walkingDistanceAndDuration[index + 1]?.distanceInMeters}m
              </Typography>
            </Box>
          </Box>
        </Box>
      ))}
    </>
  );
};

export default AdditionalTripDetails;
