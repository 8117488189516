import React from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/styles';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const ModalAdd = (props) => {
  const classes = useStyles();
  const modalOpen = props.modalOpen;
  const modalFields = props.modalFields;
  const modalTitle = props.modalTitle;
  const handleAddNew = props.handleModalAdd;
  const extraFields = props.modalExtraFields;

  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        props.handleModalClose();
      }}
      className={classes.modal}
    >
      <Box m={4} style={{ overflow: 'auto', maxHeight: '85%', width: '40%' }}>
        <Paper elevation={0} style={{ padding: '32px' }}>
          <Box>
            <h2>{modalTitle}</h2>
            <Grid container spacing={3}>
              {modalFields &&
                modalFields.map((field) => {
                  return (
                    <Grid item xs={12} key={field}>
                      <TextField
                        name={field}
                        required
                        fullWidth
                        id={field}
                        // type="number"
                        select={extraFields?.[field] ? true : false}
                        variant="outlined"
                        label={props.t(field)}
                        onChange={(e) => props.handleChange(e, field)}
                      >
                        {extraFields &&
                          extraFields[field]?.map((option) => {
                            return (
                              <MenuItem value={option.value} key={option.id}>
                                {option.label}
                              </MenuItem>
                            );
                          })}
                      </TextField>
                    </Grid>
                  );
                })}
            </Grid>
            <Box pt={4} display="flex" justifyContent="center" style={{ width: '100%' }}>
              <Button color="primary" variant="contained" onClick={() => handleAddNew()}>
                {props.t('create')}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default ModalAdd;
