import React from 'react';
import Grid from '@material-ui/core/Grid';
import './multistep.scss';

function ProgressDots({ currentStep, totalSteps }) {
  const nTimes = parseInt(totalSteps);
  const stepsArray = [];
  for (let i = 0; i < nTimes; i++) {
    stepsArray.push(1);
  }

  return (
    <Grid item sm={12} style={{ textAlign: 'center', marginTop: '15px', marginBottom: '15px' }}>
      {stepsArray.map((item, index) => {
        if (index + 1 === currentStep) {
          return <span className="darkdot" key={`progressdot_${index}`}></span>;
        } else {
          return <span className="dot" key={`progressdot_${index}`}></span>;
        }
      })}
    </Grid>
  );
}

export default ProgressDots;
