import React, { Component } from 'react';
import colors from '../../../assets/sass/colors';
import { withAuth0 } from '@auth0/auth0-react';
import { withTranslation } from 'react-i18next';
import ModalAdd from '../../Modals/ModalAdd';
import authenticatedAxiosInstance from '../../../axios/axios-authorized';

class AddFleet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddRow: this.props.open,
      newBusFleet: {
        bus_type: '',
        wheelchair_capacity: '',
        bike_capacity: '',
        nondisabled_capacity: '',
        operating_cost: '',
        min_passengers: ''
      }
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.open !== prevState.showAddRow) {
      return { showDeleteModal: nextProps.open };
    }

    return null;
  }

  closeRow = () => {
    this.setState({ showAddRow: false });
    this.props.toggleAdd();
  };

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const id = target.id;

    this.setState(() => {
      let busFleetCopy = this.state.newBusFleet;
      busFleetCopy[id] = id === 'bus_type' ? value : Number(value);
      return busFleetCopy;
    });
  };

  validateValues = () => {
    const {
      bus_type,
      wheelchair_capacity,
      bike_capacity,
      nondisabled_capacity,
      operating_cost,
      min_passengers
    } = this.state.newBusFleet;

    try {
      if (parseInt(wheelchair_capacity) < 0)
        throw new Error(this.props.t('no_negative_wheelchair_capacity'));
      if (parseInt(bike_capacity) < 0) throw new Error(this.props.t('no_negative_bike_capacity'));
      if (parseInt(nondisabled_capacity) < 0)
        throw new Error(this.props.t('no_negative_nondisabled_capacity'));
      if (parseInt(operating_cost) < 0) throw new Error(this.props.t('no_negative_operating_cost'));
      if (parseInt(min_passengers) < 0) throw new Error(this.props.t('no_negative_num_passengers'));
      if (parseInt(min_passengers) > parseInt(nondisabled_capacity))
        throw new Error(this.props.t('min_cannot_be_greater_than_max'));
      if (
        bus_type.length === '' ||
        bike_capacity === '' ||
        wheelchair_capacity === '' ||
        nondisabled_capacity === '' ||
        operating_cost === '' ||
        min_passengers === ''
      )
        throw new Error(this.props.t('all_fields_necessary'));
      this.createFleet();
    } catch (err) {
      this.props.snackbar(err.message, colors.red);
    }
  };

  createFleet = async () => {
    try {
      const reqBody = {
        bus_type: this.state.newBusFleet.bus_type,
        wheelchair_capacity: this.state.newBusFleet.wheelchair_capacity,
        nondisabled_capacity: this.state.newBusFleet.nondisabled_capacity,
        bike_capacity: this.state.newBusFleet.bike_capacity,
        operating_cost: this.state.newBusFleet.operating_cost,
        min_passengers: this.state.newBusFleet.min_passengers
      };

      authenticatedAxiosInstance.axios
        .post(`/busfleets/`, reqBody)
        .then(() => {
          this.initialBusFleet = reqBody;
          this.props.snackbar(this.props.t('new_fleet_added'), colors.blaiseGreen);
          this.setState({ addMode: false });
          this.props.toggleAdd();
        })
        .catch(() => {
          throw Error;
        });
    } catch {
      this.props.snackbar(this.props.t('request_not_completed'), colors.red);
    }
  };

  render() {
    return (
      <ModalAdd
        {...this.props}
        modalOpen={this.props.open}
        modalTitle={this.props.t('add_fleet_button_title')}
        modalFields={[
          'bus_type',
          'min_passengers',
          'wheelchair_capacity',
          'nondisabled_capacity',
          'bike_capacity',
          'operating_cost'
        ]}
        handleModalAdd={this.validateValues}
        handleChange={this.handleChange}
        handleModalClose={this.props.toggleAdd}
      />
    );
  }
}

export default withTranslation('common')(withAuth0(AddFleet));
