import React, { useState, useEffect } from 'react';
import {
  Paper,
  Button,
  Grid,
  Modal,
  CircularProgress,
  Box,
  Popover,
  TextField,
  InputAdornment,
  Typography
} from '@material-ui/core';
import colors from '../../../assets/sass/colors';
import './SetupForm.scss';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomSnackbar from '../../Snackbar/CustomSnackbar';
import authenticatedAxiosInstance from '../../../axios/axios-authorized';
import { useTranslation } from 'react-i18next';
import {
  convertTimeToHours,
  convertTimeToMinutes,
  convertMinutesToTime,
  convertHoursToTime
} from '../../../utils/MomentTime';
import Joi from 'joi';

const SetupForm = ({ transitAgencyId }) => {
  const [transitAgency, setTransitAgency] = useState(null);
  const [initialTransitAgency, setInitialTransitAgency] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: null
  });
  const [infoPopover, setInfoPopover] = useState({
    open: false,
    message: '',
    anchor: null
  });

  const { t } = useTranslation('common');

  useEffect(() => {
    getTransitAgencyDetails();
  }, []);

  const getTransitAgencyDetails = async () => {
    const transitAgency = (
      await authenticatedAxiosInstance.axios.get(`/transitagencies/${transitAgencyId}`)
    ).data;

    const formattedTransitAgency = {
      ...transitAgency,
      request_window_high: convertTimeToHours(transitAgency.request_window_high),
      request_window_low: convertTimeToHours(transitAgency.request_window_low),
      trip_window: convertTimeToMinutes(transitAgency.trip_window),
      driver_departure_buffer: convertTimeToMinutes(transitAgency.driver_departure_buffer),
      trip_decision_buffer: convertTimeToMinutes(transitAgency.trip_decision_buffer),
      trip_details_buffer: convertTimeToMinutes(transitAgency.trip_details_buffer),
      driver_break_frequency_start: convertTimeToMinutes(
        transitAgency.driver_break_frequency_start
      ),
      driver_break_frequency_end: convertTimeToMinutes(transitAgency.driver_break_frequency_end)
    };

    setTransitAgency(formattedTransitAgency);
    setInitialTransitAgency(formattedTransitAgency);
  };

  // Handling Popover prompts for the textfield info icons
  const handlePopoverOpen = (event, popoverString) => {
    event.preventDefault();
    setInfoPopover({ open: true, message: popoverString, anchor: event.currentTarget });
  };

  const handlePopoverClose = () => {
    setInfoPopover({ open: false, anchor: null });
  };

  const openSnackbar = (snackbarString, color) => {
    setSnackbar({
      open: true,
      message: snackbarString,
      color
    });
  };

  const closeSnackbar = (reason) => {
    if (reason !== 'clickaway') {
      setSnackbar({ ...snackbar, open: false });
    }
  };

  // handles changing inputs in the TextFields
  const handleChange = (event) => {
    const updatedAgency = {
      ...transitAgency,
      [event.target.id]: event.target.value
    };

    setTransitAgency(updatedAgency);
  };

  const handleSaveChanges = async () => {
    try {
      const reqBody = {
        ...transitAgency,
        request_window_high: convertHoursToTime(transitAgency.request_window_high),
        request_window_low: convertHoursToTime(transitAgency.request_window_low),
        trip_window: convertMinutesToTime(transitAgency.trip_window),
        driver_departure_buffer: convertMinutesToTime(transitAgency.driver_departure_buffer),
        trip_decision_buffer: convertMinutesToTime(transitAgency.trip_decision_buffer),
        trip_details_buffer: convertMinutesToTime(transitAgency.trip_details_buffer),
        driver_break_frequency_start: convertMinutesToTime(
          transitAgency.driver_break_frequency_start
        ),
        driver_break_frequency_end: convertMinutesToTime(transitAgency.driver_break_frequency_end)
      };

      await authenticatedAxiosInstance.axios.put(`/transitagencies/${transitAgencyId}`, reqBody);

      setInitialTransitAgency(transitAgency);
      openSnackbar(t('changes_saved'), colors.blaiseGreen);

      setIsModalOpen(false);
    } catch (err) {
      if (err.response && err.response.status === 403) {
        alert(t('no_permission'));
      } else {
        openSnackbar(t('setup_update_error'), colors.red);
      }
    }
  };

  const validateData = async (e) => {
    e.preventDefault();

    try {
      if (transitAgency === initialTransitAgency) {
        throw t('no_changes');
      }

      const taParameters = {
        trip_window: transitAgency.trip_window,
        max_walking_distance: transitAgency.max_walking_distance,
        trip_decision_buffer: transitAgency.trip_decision_buffer,
        trip_details_buffer: transitAgency.trip_details_buffer,
        driver_departure_buffer: transitAgency.driver_departure_buffer,
        request_window_low: transitAgency.request_window_low,
        request_window_high: transitAgency.request_window_high,
        driver_break_frequency_start: transitAgency.driver_break_frequency_start,
        driver_break_frequency_end: transitAgency.driver_break_frequency_end,
        operating_hours_start: convertTimeToMinutes(transitAgency.operating_hours_start),
        operating_hours_end: convertTimeToMinutes(transitAgency.operating_hours_end)
      };

      // System Parameter Validation
      const schema = Joi.object({
        trip_window: Joi.number()
          .min(5)
          .max(30)
          .error(() => {
            throw t('time_window_validation', { min: 5, max: 30 });
          }),
        max_walking_distance: Joi.number()
          .min(5)
          .max(20)
          .error(() => {
            throw t('max_walking_time_validation', { min: 5, max: 20 });
          }),
        trip_decision_buffer: Joi.number()
          .min(1)
          .max(5)
          .error(() => {
            throw t('trip_decision_buffer_validation', { min: 1, max: 5 });
          }),
        trip_details_buffer: Joi.number()
          .min(5)
          .max(60)
          .error(() => {
            throw t('trip_details_buffer_validation', { min: 5, max: 60 });
          }),
        driver_departure_buffer: Joi.number()
          .min(1)
          .max(60)
          .error(() => {
            throw t('driver_departure_buffer_validation', { min: 1, max: 60 });
          }),
        request_window_low: Joi.number()
          .min(0)
          .error(() => {
            throw t('request_window_low_validation');
          }),
        request_window_high: Joi.number()
          .min(Number(taParameters.request_window_low))
          .error(() => {
            throw t('request_window_high_validation');
          }),
        driver_break_frequency_start: Joi.number()
          .min(15)
          .error(() => {
            throw t('driver_break_frequency_start_validation', { min: 15 });
          }),
        driver_break_frequency_end: Joi.number()
          .min(Number(taParameters.driver_break_frequency_start) + 10)
          .error(() => {
            throw t('driver_break_frequency_end_validation');
          }),
        operating_hours_start: Joi.number()
          .min(0)
          .max(1440)
          .error(() => {
            throw t('operating_hours_start_validation', { min: '12:00 AM', max: '11:59:59 PM' });
          }),
        operating_hours_end: Joi.number()
          .min(Number(taParameters.operating_hours_start))
          .max(1440)
          .error(() => {
            throw t('operating_hours_end_validation');
          })
      });

      await schema.validateAsync(taParameters);
      await handleSaveChanges();
    } catch (err) {
      openSnackbar(err, colors.red);
    }
  };

  const setupFields = [
    {
      //0
      id: 'trip_window',
      label: t('pickup_or_dropoff_time_window'),
      endAdornment: t('min'),
      placeholder: t('time_30_ex'),
      description: t('pickup_or_dropoff_time_window_description')
    },
    {
      //1
      id: 'max_walking_distance',
      label: t('max_walk_time'),
      endAdornment: t('min'),
      placeholder: t('time_7_ex'),
      description: t('max_walking_description')
    },
    {
      //2
      id: 'trip_decision_buffer',
      label: t('trip_decision_buffer'),
      endAdornment: t('min'),
      placeholder: t('time_30_ex'),
      description: t('trip_decision_buffer_description')
    },
    {
      //3
      id: 'trip_details_buffer',
      label: t('trip_details_delay'),
      endAdornment: t('min'),
      placeholder: t('time_30_ex'),
      description: t('trip_details_delay_description')
    },
    {
      //4
      id: 'driver_departure_buffer',
      label: t('driver_departure_buffer'),
      endAdornment: t('min'),
      placeholder: t('time_30_ex'),
      description: t('driver_departure_buffer_description')
    },
    {
      //5
      id: 'request_window_low',
      label: t('time_before_request_range'),
      description: t('min_delay_description')
    },
    {
      //6
      id: 'request_window_high',
      label: ' ',
      placeholder: t('time_45_ex'),
      description: t('request_window_description')
    },
    {
      //7
      id: 'driver_break_frequency_start',
      label: t('driver_break_frequency')
    },
    {
      //8
      id: 'driver_break_frequency_end',
      label: ' ',
      endAdornment: t('to'),
      description: t('driver_break_frequency_description')
    },

    {
      //9
      id: 'operating_hours_start',
      label: t('operating_hours'),
      endAdornment: t('to')
    },
    {
      //10
      id: 'operating_hours_end',
      label: ' ',
      description: t('operating_hours_description')
    }
  ];

  return (
    <div>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className="system-parameters-form-container"
      >
        <Grid container spacing={2} id="setupModal">
          <Grid item sm={12}>
            <p>{t('system_params_info_notice')}</p>
          </Grid>
          <Grid item sm={6}>
            <Button
              style={{ backgroundColor: 'white', color: 'black', width: '100%' }}
              onClick={() => setIsModalOpen(false)}
            >
              {t('cancel')}
            </Button>
          </Grid>
          <Grid item sm={6}>
            <Button
              form="systemParamForm"
              type="submit"
              style={{ backgroundColor: '#52479C', color: 'white', width: '100%' }}
              onClick={validateData}
            >
              {t('save')}
            </Button>
          </Grid>
        </Grid>
      </Modal>

      <Paper className="formPaper">
        <Box
          className="formBox"
          pl={1}
          pr={2}
          pt={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          {transitAgency === null && (
            <div className="blaise-progress-top">
              <CircularProgress color="primary" />
            </div>
          )}
          {transitAgency && (
            <form
              className="formContainer"
              autoComplete="off"
              onSubmit={(e) => validateData(e)}
              id="systemParamForm"
            >
              <Box>
                {setupFields.slice(0, 5).map((setupField, i) => {
                  return (
                    <Grid container key={i}>
                      <Grid item xs={11}>
                        <TextField
                          fullWidth
                          required
                          margin="dense"
                          id={setupField.id}
                          label={setupField.label}
                          placeholder={setupField.placeholder}
                          type="number"
                          key={setupField.id}
                          value={transitAgency[setupField.id]}
                          onChange={handleChange}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{
                            style: { paddingLeft: 10, paddingBottom: 4, paddingTop: 15 }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {setupField.endAdornment}
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <FontAwesomeIcon
                          icon={faQuestionCircle}
                          key={setupField.id + 'Icon'}
                          color={colors.blaiseGray}
                          size="sm"
                          className="formIcons"
                          onClick={(e) => handlePopoverOpen(e, setupField.description)}
                        />
                      </Grid>
                    </Grid>
                  );
                })}

                {/* Request window */}
                <div>
                  <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                    {setupFields[5].label}*
                  </Typography>
                  <Grid container alignItems="center">
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        required
                        margin="dense"
                        InputLabelProps={{ shrink: true }}
                        id={setupFields[5].id}
                        type="number"
                        value={transitAgency[setupFields[5].id]}
                        onChange={handleChange}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">h</InputAdornment>
                        }}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Typography align="center">{t('to')}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        required
                        margin="dense"
                        InputLabelProps={{ shrink: true, required: false }}
                        id={setupFields[6].id}
                        type="number"
                        value={transitAgency[setupFields[6].id]}
                        onChange={handleChange}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">h</InputAdornment>
                        }}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <FontAwesomeIcon
                        icon={faQuestionCircle}
                        color={colors.blaiseGray}
                        size="sm"
                        className="formIcons"
                        onClick={(e) => handlePopoverOpen(e, setupFields[6].description)}
                      />
                    </Grid>
                  </Grid>
                </div>

                {/* Driver break frequency */}
                <div>
                  <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                    {setupFields[7].label}*
                  </Typography>
                  <Grid container alignItems="center">
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        required
                        margin="dense"
                        InputLabelProps={{ shrink: true }}
                        id={setupFields[7].id}
                        type="number"
                        value={transitAgency[setupFields[7].id]}
                        onChange={handleChange}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">min</InputAdornment>
                        }}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Typography align="center">{t('to')}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        required
                        margin="dense"
                        InputLabelProps={{ shrink: true, required: false }}
                        id={setupFields[8].id}
                        type="number"
                        value={transitAgency[setupFields[8].id]}
                        onChange={handleChange}
                        error={transitAgency[setupFields[8].id] < 0}
                        inputProps={setupFields[8].inputProps}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">min</InputAdornment>
                        }}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <FontAwesomeIcon
                        icon={faQuestionCircle}
                        color={colors.blaiseGray}
                        size="sm"
                        className="formIcons"
                        onClick={(e) => handlePopoverOpen(e, setupFields[8].description)}
                      />
                    </Grid>
                  </Grid>
                </div>

                {/* Operating hours */}
                <div>
                  <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                    {setupFields[9].label}*
                  </Typography>
                  <Grid container alignItems="center">
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        required
                        margin="dense"
                        InputLabelProps={{ shrink: true }}
                        id={setupFields[9].id}
                        type="time"
                        value={transitAgency[setupFields[9].id]}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Typography align="center">{t('to')}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        required
                        margin="dense"
                        InputLabelProps={{ shrink: true, required: false }}
                        id={setupFields[10].id}
                        type="time"
                        value={transitAgency[setupFields[10].id]}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <FontAwesomeIcon
                        icon={faQuestionCircle}
                        color={colors.blaiseGray}
                        size="sm"
                        className="formIcons"
                        onClick={(e) => handlePopoverOpen(e, setupFields[10].description)}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Box>
              <Box
                p={0}
                m={0}
                display="flex"
                justifyContent="center"
                style={{ margin: '20px auto' }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsModalOpen(true);
                  }}
                >
                  {t('submit')}
                </Button>
              </Box>
            </form>
          )}
        </Box>
      </Paper>
      <Popover
        open={infoPopover.open}
        anchorEl={infoPopover.anchor}
        onClose={handlePopoverClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClick={handlePopoverClose}
        className="tooltip"
      >
        {' '}
        {infoPopover.message}{' '}
      </Popover>
      <CustomSnackbar
        message={snackbar.message}
        open={snackbar.open}
        onClose={closeSnackbar}
        snackbarColor={snackbar.color}
      />
    </div>
  );
};

export default SetupForm;
