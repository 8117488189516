import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  ListItemIcon,
  CircularProgress
} from '@material-ui/core';
import colors from '../../assets/sass/colors';
import authenticatedAxiosInstance from '../../axios/axios-authorized';
import { useTranslation } from 'react-i18next';

export default function SwitchAgency({ currentUser, openSnackbar }) {
  const [TAList, setTAList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('common');

  useEffect(() => {
    fetchMembershipTA();
  }, []);

  // Fetches the list of TA the user is part of. Using the "ta:<name>" permission on Auth0.
  const fetchMembershipTA = async () => {
    try {
      const response = (await authenticatedAxiosInstance.axios.get(`/transitagencies/membership`))
        .data;
      setTAList(response);
    } catch (err) {
      openSnackbar(`Fetching all transit agencies: ${err.message}`, colors.red);
      console.log(err);
    }
  };
  const handleChange = async (e) => {
    try {
      setIsLoading(true);
      const selectedTA = e.target.value;
      await authenticatedAxiosInstance.axios.post(`/transitagencies/switchCurrent`, { selectedTA });
      window.location.reload();
    } catch (err) {
      console.log(err);
      openSnackbar(`Changing TA: ${err.message}`, colors.red);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {TAList && TAList.length > 0 && (
        <FormControl style={{ margin: 10, width: '30%' }}>
          <InputLabel>{t('switch_agency')}</InputLabel>
          <Select value={currentUser?.transit_agency_id} label="Agency" onChange={handleChange}>
            {TAList.length > 0 &&
              TAList.map((ta) => (
                <MenuItem
                  key={ta.transit_agency_id}
                  value={ta.transit_agency_id}
                  style={{
                    color: ta.is_active === 1 ? 'green' : 'gray',
                    fontWeight: ta.is_active === 1 ? 'bold' : 'normal'
                  }}
                >
                  {ta.transit_agency_name}
                  {isLoading && (
                    <ListItemIcon>
                      <CircularProgress size="0.5rem" style={{ marginLeft: 5 }} />
                    </ListItemIcon>
                  )}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
      {TAList && TAList.length === 0 && <Typography>{t('switch_ta_no_ta')}</Typography>}
    </>
  );
}
