import React, { useState } from 'react';
import { Button, Grid, Box, Typography, CircularProgress } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { dateTimeFormatWithOffset } from '../../../utils/MomentTime';
import { useTranslation } from 'react-i18next';
import colors from '../../../assets/sass/colors';

const ConfirmationStep = ({
  shiftsToBeInserted,
  taTimezoneOffset,
  kaboom,
  loadDriverShifts,
  postRecurrenceData,
  closeModal,
  openSnackbar,
  goToStep
}) => {
  const { t } = useTranslation('common');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Submits data to DB
  const handleUploadShiftData = async () => {
    setIsSubmitting(true);

    try {
      await postRecurrenceData(false);
      await loadDriverShifts();

      closeModal();
      openSnackbar(t('create_driver_shift_success'), colors.blaiseGreen);
    } catch (err) {
      kaboom(err, colors.red);
    }
  };

  return (
    <Grid className="recurrentModal">
      <Grid
        item
        sm={12}
        style={{
          padding: '1rem 1.5rem',
          borderBottom: `1px solid ${colors.gray300}`
        }}
      >
        <Typography
          style={{
            color: colors.gray800,
            fontWeight: 'bold',
            fontSize: '1.25rem'
          }}
        >
          {t('create_recurrent_driver_shift')}
        </Typography>
      </Grid>

      <Grid style={{ overflowY: 'auto', overflowX: 'hidden' }}>
        <Grid container style={{ padding: '1rem 1.5rem 0 1.5rem', gap: '1rem' }}>
          <Grid item sm={12}>
            <Alert severity="success" icon={false}>
              <AlertTitle>{t('confirmation')}</AlertTitle>
              {t('recurring_shifts_confirmation')}
            </Alert>
          </Grid>

          <Grid
            item
            sm={12}
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingBottom: '0.2rem'
            }}
          >
            <Grid sm={5}>
              <span className="overlapLabels">{t('start')}</span>
            </Grid>

            <Grid sm={5}>
              <span className="overlapLabels">{t('end')}</span>
            </Grid>

            <Grid sm={2}>
              <span className="overlapLabels">{t('bus')}</span>
            </Grid>
          </Grid>
        </Grid>

        {shiftsToBeInserted.map((shift, idx) => (
          <Grid key={idx}>
            <Grid sm={12} className="shiftDetailsRow" style={{ padding: '1rem 1.5rem' }}>
              <Grid sm={5}>
                <span className="overlapText">
                  {dateTimeFormatWithOffset(shift.start_time, taTimezoneOffset, false)}
                </span>
              </Grid>

              <Grid sm={5}>
                <span className="overlapText">
                  {dateTimeFormatWithOffset(shift.end_time, taTimezoneOffset, false)}
                </span>
              </Grid>

              <Grid sm={2}>
                <span className="overlapText">{shift.bus_name || shift.bus_id}</span>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>

      <Box
        display="flex"
        justifyContent="flex-end"
        gridGap="1rem"
        style={{
          borderTop: `1px solid ${colors.gray300}`,
          padding: '1rem 1.5rem'
        }}
      >
        {isSubmitting ? (
          <Grid container item xs={12} justify="center">
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Button onClick={() => goToStep(1)} className="backBtn">
              {t('back')}
            </Button>
            <Button onClick={() => handleUploadShiftData()} variant="contained" color="primary">
              {t('create')}
            </Button>
          </>
        )}
      </Box>
    </Grid>
  );
};

export default ConfirmationStep;
