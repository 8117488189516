import {
  addSubtractMinutesToTimeUTC,
  dateOnlyFormat,
  timeOnlyFormatWithTimezone
} from './MomentTime';

/**
 * Converts a time string of format hh:mm or hh:mm:ss to an integer representing the time in minutes
 * The seconds part is ignored in the calculation
 * @param {*} time Time string hh:mm or hh:mm:ss
 */
export const convertTimeToMinutes = (time) => {
  time = time.split(':');
  return +time[0] * 60 + +time[1];
};

export const convertMilisecondsToDDMMYYYY_HHMM = (time) => {
  let convertedDateString;
  const d = new Date(time);
  const dayOfTheMonth = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
  const monthOfTheYear = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
  const year = d.getFullYear();
  const minutes = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();
  if (!navigator.language.includes('en')) {
    const hour = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours();
    convertedDateString = `${dayOfTheMonth}/${monthOfTheYear}/${year} ${hour}:${minutes}`;
  } else {
    let hour = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours();
    const partOfAday = hour < 12 ? 'AM' : 'PM';
    hour = hour % 12 < 10 ? `0${hour % 12}` : hour % 12;
    convertedDateString = `${monthOfTheYear}/${dayOfTheMonth}/${year} ${hour}:${minutes} ${partOfAday}`;
  }
  return convertedDateString;
};

export const creditCardDateFormat = (month, year) => {
  const formattedMonth =
    month.toString().length === 2 ? month.toString() : month.toString().padStart(2, '0');
  const formattedYear = year.toString().slice(-2);

  return `${formattedMonth}/${formattedYear}`;
};

export const displayWindowRange = (
  type,
  time,
  timeForComparison = null,
  timezone = 'UTC',
  timeWindows,
  displayDateWithTime = false
) => {
  if (!timeWindows) {
    return timeOnlyFormatWithTimezone(time, timezone);
  }

  let pickupDropoffWindow =
    type === 'pickup' ? timeWindows.pickup_window : timeWindows.dropoff_window;

  // if service time is 0, range will be hh:mm - hh:mm of the same time so return only one
  if (!pickupDropoffWindow) {
    return timeOnlyFormatWithTimezone(time, timezone);
  }

  let rangeStartEnd = addSubtractMinutesToTimeUTC(
    type === 'pickup' ? 'add' : 'subtract',
    time,
    pickupDropoffWindow
  );

  let rangeConverted = rangeStartEnd?.toISOString();

  // If the dropoff lower end is earlier than the pickup time (eg. when a transit agency has a very wide window allowance)
  // we replace the lower end with the pickup time
  if (
    type === 'dropoff' &&
    timeForComparison &&
    new Date(rangeConverted) < new Date(timeForComparison)
  ) {
    rangeConverted = timeForComparison;
  }
  // if pickup upper range ends after dropoff time, we use dropoff time
  if (
    type === 'pickup' &&
    timeForComparison &&
    new Date(rangeConverted) > new Date(timeForComparison)
  ) {
    rangeConverted = timeForComparison;
  }

  const rangeText = timeOnlyFormatWithTimezone(rangeConverted, timezone) || '';
  const timeText = timeOnlyFormatWithTimezone(time, timezone) || '';
  const dateText = displayDateWithTime ? `${dateOnlyFormat(time)}, ` : '';

  const displayString =
    type === 'pickup'
      ? `${dateText}${timeText} - ${rangeText}`
      : `${dateText}${rangeText} - ${timeText}`;

  return displayString;
};
