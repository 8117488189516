import React, { Component, Fragment } from 'react';
import Scheduler, { SchedulerData, ViewTypes, CellUnits } from 'react-big-scheduler';
import 'react-big-scheduler/lib/css/style.css';
import withDragDropContext from './withDnDContext';
import { format } from 'date-fns';
import { withAuth0 } from '@auth0/auth0-react';
import { withTranslation } from 'react-i18next';
import DeleteModal from '../DeleteModal/DeleteModal';
import colors from '../../assets/sass/colors';
import { Button, CircularProgress, Grid, Modal, Paper } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import moment from 'moment-timezone';
import './DriverShiftTable.scss';
import { withKaboom } from '../../higherOrderComponents/withKaboom';
import { mailToTypes } from '../../models/MailToModel';
import { currentUserHOC } from '../../store/user';
import { driverShiftHOC } from '../../store/driverShift';
import authenticatedAxiosInstance from '../../axios/axios-authorized';
import RecurrentShiftsModal from './RecurrentShifts/RecurrentShiftsModal';
import SingleShiftModal from './SingleShiftModal';
import { compareBrowserAndAgencyTZ } from '../../utils/timezoneUtils';
import { hasFutureDriverShifts } from '../../utils/driverShift';
import { dateOnlyFormat } from '../../utils/MomentTime';

const NON_WORKING_TIME_START = 17; // Start of non operating hours (from this point onwards the transit agency is inactive)
const NON_WORKING_TIME_END = 5; // End of non operating hours (from this point onwards the transit agency is active)
const SHIFT_CUTOFF_ERROR = 'shift_create_error_shift_before_cutoff_time';
const BROWSER_LANG = navigator.language.substring(0, 2);

const shiftUpdateFields = Object.freeze({
  CURRENT: 'current',
  NONE: 'none',
  ALL: 'all'
});

let schedulerData = null;

class DriverShiftTable extends Component {
  constructor(props) {
    super(props);

    schedulerData = new SchedulerData(
      format(new Date(), 'yyyy-MM-dd'),
      ViewTypes.Week,
      false,
      false,
      {
        checkConflict: true, // disables the ability for two overlapping events to be created
        crossResourceMove: false, // disables the ability to move events across rows
        startResizable: false, // disables the ability to resize the beginning of an event
        endResizable: false, // disables the ability to resize the end of an event
        movable: false, // disables the ability to move shifts by dragging on the chart
        creatable: false, // disables the ability to create shifts by dragging on the chart
        resourceName: this.props.t('driver_name'),
        views: [
          {
            viewName: this.props.t('day'),
            viewType: ViewTypes.Day,
            showAgenda: false,
            isEventPerspective: false
          },
          {
            viewName: this.props.t('week'),
            viewType: ViewTypes.Week,
            showAgenda: false,
            isEventPerspective: false
          },
          {
            viewName: this.props.t('month'),
            viewType: ViewTypes.Month,
            showAgenda: false,
            isEventPerspective: false
          },
          {
            viewName: this.props.t('quarter'),
            viewType: ViewTypes.Quarter,
            showAgenda: false,
            isEventPerspective: false
          },
          {
            viewName: this.props.t('year'),
            viewType: ViewTypes.Year,
            showAgenda: false,
            isEventPerspective: false
          }
        ]
      },
      {
        isNonWorkingTimeFunc: this.isNonWorkingTime
      },
      moment
    );

    schedulerData.localeMoment.locale(BROWSER_LANG);
    schedulerData.config.schedulerWidth = window.innerWidth - 310; // TODO - make this responsive if necessary

    this.state = {
      viewModel: schedulerData,
      resources: [], // row titles (drivers)
      events: [], // row content (driver shifts)
      editId: null,
      editDriverId: '',
      editBusId: '',
      editStartTime: null,
      editEndTime: null,
      startDepotId: '',
      endDepotId: '',
      editDeadheading: '',
      drivers: [],
      buses: [],
      depots: [],
      isLoading: true,
      isSubmitting: false,
      singleModalOpen: false,
      recurrentModalOpen: false,
      editModalOpen: false,
      deleteModalOpen: false,
      requestShiftChanges: false,
      showRequestShift: false,
      shiftStatus: '',
      taUtcOffset: 0,
      taTimezoneName: 'UTC',
      hasPermissionLastMinuteShiftChange: false
    };
    this.editing = false;
  }

  componentDidMount() {
    document.title = `${this.props.t('driver_shifts_title')} - ${this.props.t(
      'trip_source_engine'
    )}`;
    moment.locale(BROWSER_LANG);
    this.loadShifts();
  }

  componentDidUpdate = async (prevProps, prevState) => {
    // If user changes any input fields, don't show request button.
    if (
      this.state.editDriverId !== prevState.editDriverId ||
      this.state.editBusId !== prevState.editBusId ||
      this.state.editStartTime !== prevState.editStartTime ||
      this.state.editEndTime !== prevState.editEndTime ||
      this.state.startDepotId !== prevState.startDepotId ||
      this.state.endDepotId !== prevState.endDepotId ||
      this.state.editDeadheading !== prevState.editDeadheading
    ) {
      this.setState({ showRequestShift: false });

      const shiftsCopy = [...this.state.events];
      const foundShift = shiftsCopy.find((shift) => shift.id === this.state.editId);

      // Only request shift changes if the user has changed certain values from the origin shift data (not just prevState)
      if (
        this.state.shiftStatus === shiftUpdateFields.CURRENT &&
        foundShift &&
        (moment(this.state.editStartTime).set('second', 0).toISOString() !== foundShift.start ||
          moment(this.state.editEndTime).set('second', 0).toISOString() !== foundShift.end ||
          Number(this.state.startDepotId) !== Number(foundShift.startDepotId) ||
          Number(this.state.endDepotId) !== Number(foundShift.endDepotId) ||
          this.state.editDeadheading !== foundShift.deadheading)
      ) {
        this.setState({ requestShiftChanges: true });
      } else {
        this.setState({ requestShiftChanges: false });
      }
    }
  };

  loadShifts = async () => {
    const { currentUser } = this.props.currentUserStore;

    // check if there is timezone mismatch first
    try {
      const timezoneDetails = await compareBrowserAndAgencyTZ(currentUser.transit_agency_id);
      const timezone = timezoneDetails.taTimezone;

      this.setState({
        taUtcOffset: timezoneDetails.taTimezoneOffset,
        taTimezoneName: timezone
      });

      if (timezoneDetails.timezoneMismatch) {
        this.props.openSnackbar(
          `${this.props.t('timezone_mismatch_message')} (${timezone}). ${this.props.t(
            'timezone_no_actions_required'
          )}`,
          colors.red
        );
      }

      // After we have the timezone offset, load the shifts
      this.loadAddNewShiftInfo();
    } catch (err) {
      console.log(err);
    }
  };

  loadAddNewShiftInfo = async () => {
    const { currentUser } = this.props.currentUserStore;

    try {
      this.loadDriverShifts();

      // Get all drivers and buses to be able to add them to the dropdown in the edit modal
      const driversAndBuses = await authenticatedAxiosInstance.axios.get(
        `/drivershift/driversAndBuses`
      );

      const taDepots = (
        await authenticatedAxiosInstance.axios.get(
          `/busstops/${currentUser?.transit_agency_id}/depots`
        )
      ).data;

      this.setState({
        drivers: driversAndBuses.data.drivers,
        buses: driversAndBuses.data.buses,
        depots: taDepots
      });
    } catch (error) {
      this.props.kaboom(error, colors.red);
    }
  };

  // Load the driver shifts from the database
  loadDriverShifts = async () => {
    // Add all drivers and shifts to the resources and events arrays to then add them to the chart
    try {
      const driverShifts = await authenticatedAxiosInstance.axios.get(
        `/drivershift/currentAndUpcoming`
      );
      const driversAndShifts = driverShifts.data;
      hasFutureDriverShifts(driversAndShifts);
      const resources = [];
      const events = [];

      driversAndShifts.forEach((item) => {
        resources.push({
          id: item.driver_id,
          name: item.driver_name
        });
        if (item.driver_shifts) {
          const shifts = item.driver_shifts;
          shifts.forEach((shift) => {
            events.push({
              id: shift.shift_id,
              start: moment(shift.start_time)
                .utc()
                .tz(this.state.taTimezoneName)
                .format('YYYY-MM-DD HH:mm:ss'),
              end: moment(shift.end_time)
                .utc()
                .tz(this.state.taTimezoneName)
                .format('YYYY-MM-DD HH:mm:ss'),
              busId: shift.bus_id,
              title: shift.bus.bus_name ? shift.bus.bus_name : `Bus ${shift.bus.bus_number}`,
              resourceId: item.driver_id,
              startDepotId: shift.start_depot,
              endDepotId: shift.end_depot,
              deadheading: shift.deadheading
            });
          });
        }
      });

      this.state.viewModel.setResources(resources); // add resources (drivers) to the chart
      this.state.viewModel.setEvents(events); // add events (driver shifts) to the chart
      this.setState({
        resources: resources,
        events: events,
        isLoading: false
      });
    } catch (error) {
      this.props.kaboom(error, colors.red);

      this.setState({
        isLoading: false
      });
    }
  };

  // Toggle the modal when deleting a driver shift
  toggleDeleteModal = (schedulerData, event) => {
    // If the modal is being opened, set the state of the shift that is being opened
    let deleteId = null;
    if (!this.state.deleteModalOpen) {
      deleteId = event.id;
    }

    this.setState((prevState) => ({
      deleteModalOpen: !prevState.deleteModalOpen,
      editId: deleteId
    }));
  };

  // Handle the opening of the edit modal
  handleOpenEditModal = async (
    editId,
    editDriverId,
    editBusId,
    editStartTime,
    editEndTime,
    startDepotId,
    endDepotId,
    editDeadheading
  ) => {
    this.setState({
      editId,
      editDriverId,
      editBusId,
      editStartTime,
      editEndTime,
      startDepotId,
      endDepotId,
      editDeadheading
    });

    const canUpdate = await authenticatedAxiosInstance.axios.get(
      `/drivershift/${editId}/canUpdateFields`
    );

    this.setState({
      hasPermissionLastMinuteShiftChange: canUpdate?.data?.has_permission_last_minute_shift_change
    });

    if (canUpdate.data.result === 'none') {
      this.setState({ shiftStatus: shiftUpdateFields.NONE });
      this.props.openSnackbar(this.props.t('past_driver_shift'), colors.red);
    } else if (canUpdate.data.result === 'everything') {
      this.setState({ shiftStatus: shiftUpdateFields.ALL });
    } else if (canUpdate.data.result === 'current') {
      this.setState({ shiftStatus: shiftUpdateFields.CURRENT });
    }
    this.editing = true;
    this.setState({ editModalOpen: true });
  };

  // Handle the closing of the create/edit modal
  handleCloseShiftModals = () => {
    this.setState({
      singleModalOpen: false,
      editModalOpen: false,
      recurrentModalOpen: false,
      editDriverId: '',
      editBusId: '',
      editStartTime: null,
      editEndTime: null,
      startDepotId: '',
      endDepotId: '',
      requestShiftChanges: false,
      editDeadheading: '',
      shiftStatus: ''
    });
  };

  // Ensure all values are not empty
  validateEditDriverShiftValues = () => {
    return (
      this.state.editBusId &&
      this.state.editDriverId &&
      this.state.editEndTime &&
      this.state.editStartTime &&
      this.state.startDepotId.toString() &&
      this.state.endDepotId.toString() &&
      this.state.editDeadheading.toString()
    );
  };

  handleSubmitEditShift = async () => {
    if (!this.validateEditDriverShiftValues()) {
      return this.props.openSnackbar(this.props.t('create_driver_shift_error'), colors.red);
    }

    // Check if dates are in the past
    const currentDateOfTA = moment().utc().add(this.state.taUtcOffset, 'h');
    if (
      (this.state.editStartTime && moment(this.state.editStartTime).diff(currentDateOfTA) < 0) ||
      (this.state.editEndTime && moment(this.state.editEndTime).diff(currentDateOfTA) < 0)
    ) {
      return this.props.openSnackbar(this.props.t('driver_shift_time_past_error'), colors.red);
    }

    this.setState({ isSubmitting: true });

    const reqBody = {
      driverId: this.state.editDriverId,
      busId: this.state.editBusId,
      startTime: moment(this.state.editStartTime).format('YYYY-MM-DD HH:mm:ss'),
      endTime: moment(this.state.editEndTime).format('YYYY-MM-DD HH:mm:ss'),
      startDepot: this.state.startDepotId,
      endDepot: this.state.endDepotId,
      requestShiftChanges: this.state.requestShiftChanges,
      deadheading: this.state.editDeadheading,
      timeIsLocal: true
    };

    try {
      await authenticatedAxiosInstance.axios.put(`/drivershift/${this.state.editId}`, reqBody);

      if (this.state.shiftStatus === 'current' && this.state.hasPermissionLastMinuteShiftChange) {
        const { currentUser } = this.props.currentUserStore;

        const reqBody = {
          mailTo: mailToTypes.DRIVER_SHIFT_LAST_MINUTE_CHANGE,
          transitAgencyName: currentUser.transit_agency.transit_agency_name,
          transitAgencyNumber: currentUser.transit_agency_id,
          firstName: currentUser.first_name,
          driverShift: {
            shiftId: this.state.requestShiftChanges ? this.state.editId : '',
            driverId: this.state.editDriverId,
            busId: this.state.editBusId,
            startTime: moment(this.state.editStartTime).format('YYYY-MM-DD HH:mm:ss'),
            endTime: moment(this.state.editEndTime).format('YYYY-MM-DD HH:mm:ss'),
            startDepot: Number(this.state.startDepotId),
            endDepot: Number(this.state.endDepotId),
            deadheading: this.state.editDeadheading
          }
        };
        await authenticatedAxiosInstance.axios.post(`/specialRequest`, reqBody);
      }

      this.loadDriverShifts();
      this.handleCloseShiftModals();
      this.props.openSnackbar(this.props.t('update_driver_shift_success'), colors.blaiseGreen);
    } catch (err) {
      this.props.kaboom(err, colors.red);

      if (err.response.data.error.errorKey === SHIFT_CUTOFF_ERROR) {
        this.setState({ showRequestShift: true });
      }
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  handleSubmitCreateShift = async () => {
    if (!this.validateEditDriverShiftValues()) {
      return this.props.openSnackbar(this.props.t('create_driver_shift_error'), colors.red);
    }

    // Check if dates are in the past
    const currentDateOfTA = moment().utc().add(this.state.taUtcOffset, 'h');
    if (
      (this.state.editStartTime && moment(this.state.editStartTime).diff(currentDateOfTA) < 0) ||
      (this.state.editEndTime && moment(this.state.editEndTime).diff(currentDateOfTA) < 0)
    ) {
      return this.props.openSnackbar(this.props.t('driver_shift_time_past_error'), colors.red);
    }

    this.setState({ isSubmitting: true });

    let shiftDuration = moment(this.state.editEndTime).diff(this.state.editStartTime, 'seconds');

    // handles the case if end date is the next day
    if (shiftDuration < 0) {
      shiftDuration += 60 * 60 * 24; // seconds in a day
    }

    const reqBody = {
      shiftDurationSeconds: shiftDuration,
      shiftStartTime: moment(this.state.editStartTime).format('HH:mm'),
      recurrenceStartDate: moment(this.state.editStartTime).format('YYYY-MM-DD'),
      recurrenceEndDate: moment(this.state.editStartTime).format('YYYY-MM-DD'),
      applicableDayOfWeek: { 0: true, 1: true, 2: true, 3: true, 4: true, 5: true, 6: true },
      driverId: this.state.editDriverId,
      busId: this.state.editBusId,
      startDepot: this.state.startDepotId,
      endDepot: this.state.endDepotId,
      deadheading: this.state.editDeadheading,
      dryRun: false
    };

    try {
      await authenticatedAxiosInstance.axios.post('/drivershift/bulk', reqBody);

      this.loadDriverShifts();
      this.handleCloseShiftModals();
      this.props.openSnackbar(this.props.t('create_driver_shift_success'), colors.blaiseGreen);
    } catch (err) {
      const errors = err.response.data.errors;
      const busOverlap = errors[0].length > 0;
      const driverOverlap = errors[1].length > 0;

      if (busOverlap) {
        this.props.openSnackbar(this.props.t('bus_already_booked', { ns: 'errors' }), colors.red);
      } else if (driverOverlap) {
        this.props.openSnackbar(
          this.props.t('driver_already_booked', { ns: 'errors' }),
          colors.red
        );
      }
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  // Request a driver shift via email
  handleRequestDriverShift = async () => {
    const { currentUser } = this.props.currentUserStore;

    const reqBody = {
      mailTo: this.state.requestShiftChanges
        ? mailToTypes.DRIVER_SHIFT_UPDATE_REQUEST
        : mailToTypes.DRIVER_SHIFT_REQUEST,
      transitAgencyName: currentUser.transit_agency.transit_agency_name,
      transitAgencyNumber: currentUser.transit_agency_id,
      firstName: currentUser.first_name,
      driverShift: {
        shiftId: this.state.requestShiftChanges ? this.state.editId : '',
        driverId: this.state.editDriverId,
        busId: this.state.editBusId,
        startTime: moment(this.state.editStartTime).format('YYYY-MM-DD HH:mm:ss'),
        endTime: moment(this.state.editEndTime).format('YYYY-MM-DD HH:mm:ss'),
        startDepot: Number(this.state.startDepotId),
        endDepot: Number(this.state.endDepotId),
        deadheading: this.state.editDeadheading
      }
    };

    try {
      await authenticatedAxiosInstance.axios.post(`/specialRequest`, reqBody);

      this.handleCloseShiftModals();

      this.props.openSnackbar(
        this.props.t(`${'request_driver_shift_confirmation'}`),
        colors.blaiseGreen
      );
    } catch (err) {
      this.props.kaboom(err, colors.red);
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  // Delete a driver shift
  onDeleteItem = async () => {
    this.setState({
      isSubmitting: true
    });

    try {
      await authenticatedAxiosInstance.axios.delete(`/drivershift/${this.state.editId}`);

      // remove the shift from the events
      schedulerData.removeEventById(this.state.editId);
      this.props.openSnackbar(this.props.t('deleted_driver_shift'), colors.blaiseGreen);

      this.setState({
        deleteModalOpen: false,
        isSubmitting: false
      });

      this.loadDriverShifts();
    } catch (error) {
      this.props.kaboom(error, colors.red);

      this.setState({
        deleteModalOpen: false,
        isSubmitting: false
      });
    }
  };

  render() {
    const { viewModel } = this.state;

    const rightCustomHeader = (
      <Grid>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            this.setState({ recurrentModalOpen: true });
            this.editing = false;
          }}
          style={{ height: '36px', marginRight: '16px' }}
        >
          {this.props.t('new_recurrent')}
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            this.setState({ singleModalOpen: true });
            this.editing = false;
          }}
          style={{ height: '36px' }}
        >
          {this.props.t('new_single')}
        </Button>
      </Grid>
    );

    return (
      <Fragment>
        {this.state.drivers &&
        this.state.buses &&
        this.state.drivers.length > 0 &&
        this.state.buses.length > 0 ? (
          <>
            <SingleShiftModal
              isOpen={this.state.singleModalOpen || this.state.editModalOpen}
              closeModal={this.handleCloseShiftModals}
              drivers={this.state.drivers}
              buses={this.state.buses}
              depots={this.state.depots}
              isEditing={this.editing}
              isSubmitting={this.state.isSubmitting}
              shiftInPast={this.state.shiftStatus === shiftUpdateFields.NONE}
              shiftWithinCutoffPeriod={this.state.shiftStatus === shiftUpdateFields.CURRENT}
              driverId={this.state.editDriverId}
              busId={this.state.editBusId}
              startDepotId={this.state.startDepotId}
              endDepotId={this.state.endDepotId}
              startTime={this.state.editStartTime}
              endTime={this.state.editEndTime}
              deadheading={this.state.editDeadheading}
              handleSubmitEditShift={this.handleSubmitEditShift}
              handleSubmitCreateShift={this.handleSubmitCreateShift}
              requestShiftChanges={this.state.requestShiftChanges}
              showRequestShift={this.state.showRequestShift}
              handleRequestDriverShift={this.handleRequestDriverShift}
              parent={this}
              hasPermissionLastMinuteShiftChange={this.state.hasPermissionLastMinuteShiftChange}
            />
            <RecurrentShiftsModal
              isOpen={this.state.recurrentModalOpen}
              closeModal={this.handleCloseShiftModals}
              drivers={this.state.drivers}
              buses={this.state.buses}
              depots={this.state.depots}
              openSnackbar={this.props.openSnackbar}
              loadDriverShifts={this.loadDriverShifts}
              kaboom={this.props.kaboom}
              taUtcOffset={this.state.taUtcOffset}
            />
          </>
        ) : (
          <Modal open={this.state.editModalOpen} onClose={this.handleCloseShiftModals}>
            <div>
              <Grid container spacing={2} id="editDriverShiftModal">
                <Grid item sm={12}>
                  <h2>{this.props.t('add_bus_and_driver_first_suggestion_title')}</h2>
                </Grid>
                <Grid item sm={12}>
                  <p>{this.props.t('add_bus_and_driver_first_suggestion_body')}</p>
                </Grid>
              </Grid>
            </div>
          </Modal>
        )}
        <div className="pageTitle">{this.props.t('driver_shifts_title')}</div>
        {this.props.driverShiftStore?.driverShiftsAreEndingSoon && (
          <Alert severity="warning">
            <AlertTitle>
              {this.props.t('driver_shifts_ending_soon_title', {
                date: dateOnlyFormat(this.props.driverShiftStore?.noShiftsAfterDate)
              })}
            </AlertTitle>
            {this.props.t('driver_shifts_ending_soon_description')}
          </Alert>
        )}
        <Paper>
          {this.state.isLoading ? (
            <div className="blaise-progress-top centered">
              <CircularProgress />
            </div>
          ) : (
            <>
              <Scheduler
                schedulerData={viewModel}
                prevClick={this.prevClick}
                nextClick={this.nextClick}
                onSelectDate={this.onSelectDate}
                onViewChange={this.onViewChange}
                eventItemClick={() => {}}
                viewEventText={this.props.t('update')}
                viewEventClick={(_, e) => {
                  this.handleOpenEditModal(
                    e.id,
                    e.resourceId,
                    e.busId,
                    e.start,
                    e.end,
                    e.startDepotId,
                    e.endDepotId,
                    e.deadheading
                  );
                }}
                viewEvent2Text={this.props.t('delete')}
                viewEvent2Click={this.toggleDeleteModal}
                updateEventStart={this.updateEventStart}
                updateEventEnd={this.updateEventEnd}
                moveEvent={this.moveEvent}
                newEvent={this.newEvent}
                conflictOccurred={this.conflictOccurred}
                toggleExpandFunc={this.toggleExpandFunc}
                rightCustomHeader={rightCustomHeader}
              />
            </>
          )}

          <DeleteModal
            open={this.state.deleteModalOpen}
            isSubmitting={this.state.isSubmitting}
            toggleModal={this.toggleDeleteModal}
            deleteId={this.state.editId}
            snackbar={this.props.openSnackbar}
            onDeleteItem={this.onDeleteItem}
            deleteMessage={this.props.t('confirm_delete_shift')}
          />
        </Paper>
      </Fragment>
    );
  }

  // Determines which area of the chart will be red (disabled)
  isNonWorkingTime = (schedulerData, time) => {
    // Setting an hour or day of the week to true will make that area on the chart red
    const { localeMoment } = schedulerData;
    if (schedulerData.cellUnit === CellUnits.Hour) {
      let hour = localeMoment(time).hour();
      if (hour < NON_WORKING_TIME_END || hour > NON_WORKING_TIME_START) return true;
    } else {
      let dayOfWeek = localeMoment(time).weekday();
      if (dayOfWeek === 0 || dayOfWeek === 6) return true;
    }

    return false;
  };

  prevClick = (schedulerData) => {
    schedulerData.prev();
    schedulerData.setEvents(this.state.events);
    this.setState({
      viewModel: schedulerData
    });
  };

  nextClick = (schedulerData) => {
    schedulerData.next();
    schedulerData.setEvents(this.state.events);
    this.setState({
      viewModel: schedulerData
    });
  };

  onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
    schedulerData.setEvents(this.state.events);
    this.setState({
      viewModel: schedulerData
    });
  };

  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    schedulerData.setEvents(this.state.events);
    this.setState({
      viewModel: schedulerData
    });
  };

  newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    if (
      window.confirm(
        `Voulez-vous créer un nouvel événement? {slotId: ${slotId}, slotName: ${slotName}, start: ${start}, end: ${end}, type: ${type}, item: ${item}}`
      )
    ) {
      let newFreshId = 0;
      schedulerData.events.forEach((item) => {
        if (item.id >= newFreshId) newFreshId = item.id + 1;
      });

      let newEvent = {
        id: newFreshId,
        title: 'Nouvel événement que vous avez crée',
        start: start,
        end: end,
        resourceId: slotId,
        bgColor: 'purple'
      };
      schedulerData.addEvent(newEvent);
      this.setState({
        viewModel: schedulerData
      });
    }
  };

  updateEventStart = (schedulerData, event, newStart) => {
    if (
      window.confirm(
        `Est-ce que vous voulez ajuster le début de l'événement? {eventId: ${event.id}, eventTitle: ${event.title}, newStart: ${newStart}}`
      )
    ) {
      schedulerData.updateEventStart(event, newStart);
    }
    this.setState({
      viewModel: schedulerData
    });
  };

  updateEventEnd = (schedulerData, event, newEnd) => {
    if (
      window.confirm(
        `Est-ce que vous voulez ajuster la fin de l'événement? {eventId: ${event.id}, eventTitle: ${event.title}, newEnd: ${newEnd}}`
      )
    ) {
      schedulerData.updateEventEnd(event, newEnd);
    }
    this.setState({
      viewModel: schedulerData
    });
  };

  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    if (
      window.confirm(
        `Est-ce que vous voulez bouger l'événement? {eventId: ${event.id}, eventTitle: ${event.title}, newSlotId: ${slotId}, newSlotName: ${slotName}, newStart: ${start}, newEnd: ${end}`
      )
    ) {
      schedulerData.moveEvent(event, slotId, slotName, start, end);
      this.setState({
        viewModel: schedulerData
      });
    }
  };

  conflictOccurred = (action, event) => {
    alert(`Il y a eu un conflit. {action: ${action}, event: ${event}`);
  };

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({
      viewModel: schedulerData
    });
  };
}

export default withTranslation('common')(
  withAuth0(currentUserHOC(driverShiftHOC(withDragDropContext(withKaboom(DriverShiftTable)))))
);
