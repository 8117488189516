import { differenceInDays, isAfter } from 'date-fns';
import authenticatedAxiosInstance from '../axios/axios-authorized';
import { driverShiftStore } from '../store/driverShift';

/**
 * Checks if there are driver shifts in the future and sets the driverShiftsAreEndingSoon flag in the store.
 *
 * @param {Array} currentAndUpcomingDriverShiftData Optional. The response from /drivershift/currentAndUpcoming.
 *                                                  If set, the function won't make a request to the server.
 *
 * @returns {Boolean} True if there are driver shifts in the future, false otherwise.
 */
export const hasFutureDriverShifts = async (currentAndUpcomingDriverShiftData = null) => {
  try {
    const driverShiftInfo =
      currentAndUpcomingDriverShiftData ||
      (await authenticatedAxiosInstance.axios.get(`/drivershift/currentAndUpcoming`)).data;

    const WARNING_DAYS_THRESHOLD = 45; // Shifts must exist 45 days from now. Otherwise, warn the user.

    // TODO: Consider gaps and outliers.
    // eg: The shifts normally end on Oct 1, 2022 but there is a random shift on Jan 3, 2042.
    let farthestShift = new Date();
    for (const driver of driverShiftInfo) {
      const driverShifts = driver.driver_shifts;
      for (const shift of driverShifts) {
        const shiftStartTime = new Date(shift.start_time);
        if (isAfter(shiftStartTime, farthestShift)) {
          farthestShift = shiftStartTime;
        }
      }
    }

    const hasShiftWithinRange =
      differenceInDays(farthestShift, new Date()) > WARNING_DAYS_THRESHOLD;

    driverShiftStore.setState({ driverShiftsAreEndingSoon: !hasShiftWithinRange });
    driverShiftStore.setState({ noShiftsAfterDate: farthestShift });

    return hasShiftWithinRange;
  } catch (err) {
    console.log('Unable to determine future driver shifts', err);
    driverShiftStore.setState({ driverShiftsAreEndingSoon: false }); // Don't show the notification on error
  }
};
