import React, { Component, Fragment } from 'react';
import { withAuth0 } from '@auth0/auth0-react';
import Joi from 'joi';
import produce from 'immer';
import Fuse from 'fuse.js';
import { faTimesCircle, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import CustomSnackbar from '../../components/Snackbar/CustomSnackbar';
import PassengerSearchAndLinkModal from './PassengerSearchAndLinkModal';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Modal from '@material-ui/core/Modal';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Fab from '@material-ui/core/Fab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { withKaboom } from '../../higherOrderComponents/withKaboom';
import colors from '../../assets/sass/colors';
import './PassengerManagement.scss';
import config from '../../config';
import DataGridTable from '../../components/DataGrid/DataGridTable';
import { currentUserHOC } from '../../store/user';
import authenticatedAxiosInstance from '../../axios/axios-authorized';

const BROWSER_LANG = navigator.language.substring(0, 2);

const createPassengerInitialState = {
  email: null,
  first_name: null,
  last_name: null,
  phone: null,
  type: null,
  hasDisability: false,
  usesWheelchair: false,
  language: 'en'
};
class PassengerManagement extends Component {
  constructor(props) {
    super(props);

    this.timeout = 0;

    this.state = {
      modalIsOpen: false,
      isEdit: false,
      passengers: null,
      loadingPassengers: true,
      keys: ['first_name', 'last_name', 'phone', 'email', 'date'],
      snackbar: {
        open: false,
        message: '',
        color: null
      },
      searchQuery: '',
      searchResults: null,
      profileToShow: null,
      isSubmitting: false,
      isLoading: true,
      searchFocus: false,
      startTime: null,
      endTime: null,
      openCreateModal: false,
      createPassengerProfile: createPassengerInitialState,
      taExtraDetails: null,
      taExtraDetailsIniitalState: null, // Stores a copy so the form can be reset. This state should not be changed
      passengerTypes: null,
      openLinkPassengerModal: false
    };
  }

  async componentDidMount() {
    document.title = `${this.props.t('passenger_management')} - ${this.props.t(
      'trip_source_engine'
    )}`;
    const now = new Date();
    const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const timeToSubtract = 60 * 60 * 24 * 1000 * 120;

    const oneTwentyDaysEarlier = startOfToday - timeToSubtract;
    const startingTime = new Date(oneTwentyDaysEarlier);
    const { currentUser } = this.props.currentUserStore;
    await this.fetchActiveUsers();

    const response = await authenticatedAxiosInstance.axios.get(
      `/transitagencies/${currentUser.transit_agency_id}/extraDetails`
    );

    const passengerTypes = await authenticatedAxiosInstance.axios.get(
      `/transitagencies/${currentUser.transit_agency_id}/passengerTypes`
    );

    this.setState(
      {
        startTime: new Date(startingTime).getTime(),
        endTime: new Date(now).getTime(),
        taExtraDetails: response.data.preferences,
        taExtraDetailsIniitalState: response.data.preferences,
        passengerTypes: passengerTypes.data,
        isLoading: false
      },
      () => {
        this.state.taExtraDetails.forEach((pref) => {
          if (pref.has_options) {
            pref.optionIdValue = null;
          } else {
            pref.textValue = null;
          }
        });
      }
    );
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.startTime !== this.state.startTime || prevState.endTime !== this.state.endTime) {
      this.fetchActiveUsers();
    }
  }

  validateSubmit = async () => {
    const schema = Joi.object({
      first_name: Joi.string()
        .required()
        .max(100)
        .messages({
          'string.base': this.props.t('validation_passenger_name_cannot_be_empty'),
          'any.required': this.props.t('validation_passenger_name_cannot_be_empty'),
          'string.max': this.props.t('validation_passenger_name_cannot_exceed_num_chars')
        }),
      last_name: Joi.string()
        .required()
        .max(100)
        .messages({
          'string.base': this.props.t('validation_passenger_name_cannot_be_empty'),
          'any.required': this.props.t('validation_passenger_name_cannot_be_empty'),
          'string.max': this.props.t('validation_passenger_name_cannot_exceed_num_chars')
        }),
      phone: Joi.string()
        .required()
        .messages({
          'string.base': this.props.t('validation_phone_required'),
          'any.required': this.props.t('validation_phone_required')
        }),
      email: Joi.string()
        .email({ tlds: false })
        .messages({
          'string.base': this.props.t('validation_email_required'),
          'string.email': this.props.t('validation_email_required')
        })
    });

    let { first_name, last_name, phone, email } = this.state.profileToShow;
    const { error } = schema.validate({
      first_name,
      last_name,
      phone,
      email
    });

    if (error) {
      const errorMsg = error.details[0].message;
      this.openSnackbar(errorMsg, colors.red);
      this.setState({
        isEdit: false
      });
      return;
    }

    this.handleUpdateProfile();
  };

  fetchActiveUsers = async () => {
    try {
      this.setState({ loadingPassengers: true });
      const response = (
        await authenticatedAxiosInstance.axios.get(
          `/passengersByTA?searchType=1&startTime=null&endTime=null`
        )
      ).data;

      let passengerList = [];

      const filteredPassengers = response.reduce((accumulator, current) => {
        const alreadyExists = accumulator.find(
          (item) => item.passenger_id === current.passenger_id
        );

        return alreadyExists ? accumulator : [...accumulator, current];
      }, []);

      filteredPassengers?.forEach(
        ({
          passenger_id,
          passenger_name,
          email,
          phone_number,
          creation_date,
          first_name,
          last_name,
          has_disability,
          uses_wheelchair,
          language,
          passenger_acknowledgement_id
        }) => {
          passengerList.push({
            id: passenger_id,
            name: passenger_name,
            first_name: first_name,
            last_name: last_name,
            email: email,
            phone: phone_number,
            date: creation_date,
            hasDisability: has_disability,
            usesWheelchair: uses_wheelchair,
            language: language,
            status: passenger_acknowledgement_id ? 'active' : 'inactive'
          });
        }
      );

      this.setState(
        {
          passengers: passengerList,
          loadingPassengers: false
        },
        () => this.loadFuse()
      );
    } catch (err) {
      this.openSnackbar(this.props.t('error_fetching_passengers'));
    }
  };

  loadFuse = () => {
    const options = {
      includeScore: true,
      minMatchCharLength: 3,
      threshold: 0.4,
      keys: this.state.keys
    };

    let fuse;
    if (this.state.passengers) {
      fuse = new Fuse(this.state.passengers, options);
    } else {
      fuse = new Fuse([], options);
    }
    this.setState({ fuse });
  };

  openSnackbar = (snackbarString, color) => {
    this.setState({
      snackbar: {
        open: true,
        message: snackbarString,
        color: color
      }
    });
  };

  // snackbar closes except for when user clicks outside of component
  closeSnackbar = (reason) => {
    if (reason !== 'clickaway') {
      this.setState({ snackbar: { open: false } });
    }
  };

  handleOpenModal = async (row) => {
    const { currentUser } = this.props.currentUserStore;
    let rowCopy = row;

    try {
      const selectedPassengerId = row.id;
      const extraDetailsCall = authenticatedAxiosInstance.axios.get(
        `/passengers/${selectedPassengerId}/taSettings/${currentUser.transit_agency_id}`
      );

      const passengerTypeCall = authenticatedAxiosInstance.axios.get(
        `/passengers/${selectedPassengerId}/transitagency/${currentUser.transit_agency_id}/passengerType`
      );

      const responses = await Promise.all([extraDetailsCall, passengerTypeCall]);
      const [extraDetails, passengerType] = [responses[0].data, responses[1].data];

      // We need to pass in the id rather than the type
      const passengerTypeObject = this.state.passengerTypes.find(
        (entry) => entry.type === passengerType.type
      );
      rowCopy = { ...row, type: passengerTypeObject.id || this.state.passengerTypes[0]?.id };

      //Populate data
      for (const param of extraDetails) {
        const userPreferenceId = param.preference_id;
        const userPreferenceOptionId = param.preference_option_id; // For dropdown choices
        const userValue = param.value; // For user input values

        // Find index of target preference id in taExtraDetails
        const preferenceIdIndex = this.state.taExtraDetails?.findIndex(
          (ops) => ops.preference_id === userPreferenceId
        );

        // Assign the value to it
        if (Number.isInteger(preferenceIdIndex) && preferenceIdIndex >= 0) {
          const taExtraDetailsObject = this.state.taExtraDetails[preferenceIdIndex];

          // Is a dropdown
          if (
            Object.prototype.hasOwnProperty.call(taExtraDetailsObject, 'optionIdValue') &&
            Number.isInteger(userPreferenceOptionId)
          ) {
            // Search for the actual string value of the option
            const optionIndex = taExtraDetailsObject?.preference_options?.findIndex(
              (ops) => ops.preference_option_id === userPreferenceOptionId
            );
            const optionIdValueString =
              Number.isInteger(optionIndex) && optionIndex >= 0
                ? taExtraDetailsObject.preference_options[optionIndex].value
                : null;

            this.setState(
              produce((draft) => {
                draft.taExtraDetails[preferenceIdIndex].optionIdValue = optionIdValueString;
              })
            );
          }

          // Is textbox
          if (Object.prototype.hasOwnProperty.call(taExtraDetailsObject, 'textValue')) {
            this.setState(
              produce((draft) => {
                draft.taExtraDetails[preferenceIdIndex].textValue = userValue;
              })
            );
          }
        }
      }
    } catch (err) {
      console.log(`Cannot retrieve passenger extra info: ${err}`);
    }
    this.setState({
      modalIsOpen: true,
      isEdit: true,
      profileToShow: rowCopy
    });
  };

  handleCloseModal = () => {
    this.setState({
      modalIsOpen: false,
      taExtraDetails: this.state.taExtraDetailsIniitalState
    });
    this.setState({
      isEdit: false
    });
  };

  clearSearchQuery = () => {
    this.setState({ searchQuery: '', searchResults: null });
  };

  handleLinkPassengerModal = (state, shouldRefreshPassengerList = false) => {
    this.setState({
      openLinkPassengerModal: state
    });
    if (shouldRefreshPassengerList) {
      this.fetchActiveUsers();
    }
  };

  handleEditProfile = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => {
      return {
        ...prevState,
        profileToShow: {
          ...prevState.profileToShow,
          [name]: value
        }
      };
    });
  };

  handleEditProfileCheckbox = (key) => {
    if (key === 'hasDisability') {
      this.setState(
        produce((draft) => {
          draft.profileToShow['hasDisability'] = !this.state.profileToShow.hasDisability;
          draft.profileToShow['usesWheelchair'] = false;
        })
      );
    } else if (key === 'usesWheelchair') {
      this.setState(
        produce((draft) => {
          draft.profileToShow['usesWheelchair'] = !this.state.profileToShow.usesWheelchair;
        })
      );
    }
  };

  handleDeleteRequest = async () => {
    const { currentUser } = this.props.currentUserStore;
    this.setState({
      isSubmitting: true
    });

    try {
      await authenticatedAxiosInstance.axios.delete(
        `/passengers/${this.state.profileToShow.id}/transitagency/${currentUser.transit_agency_id}/linkPassengerToTA`
      );

      this.setState({ modalIsOpen: false }, () => this.setState({ isSubmitting: false }));
      this.fetchActiveUsers();
      this.openSnackbar(this.props.t('deleted_passenger_success'), colors.blaiseGreen);
    } catch (err) {
      this.openSnackbar(this.props.t('deleted_passenger_error'), colors.red);
    }
  };

  handleUpdateProfile = async () => {
    const { currentUser } = this.props.currentUserStore;
    this.setState({
      isSubmitting: true
    });

    const reqBody = {
      name: this.state.profileToShow.name,
      first_name: this.state.profileToShow.first_name,
      last_name: this.state.profileToShow.last_name,
      email: this.state.profileToShow.email,
      phone: this.state.profileToShow.phone,
      hasDisability: this.state.profileToShow.hasDisability,
      usesWheelchair: this.state.profileToShow.usesWheelchair,
      language: this.state.profileToShow.language
    };

    const reqBodyExtraDetails = {
      taExtraDetails: {
        ...this.state.taExtraDetails
      }
    };

    const reqBodyPassengerType = {
      passenger_type_id: this.state.profileToShow.type
    };

    try {
      const updatePassengerBlaiseDetails = authenticatedAxiosInstance.axios.put(
        `/passengers/${this.state.profileToShow.id}`,
        reqBody
      );

      const updatePassengerTAExtraDetails = authenticatedAxiosInstance.axios.put(
        `/passengers/${this.state.profileToShow.id}/taSettings/${currentUser.transit_agency_id}`,
        reqBodyExtraDetails
      );

      const updatePassengerType = authenticatedAxiosInstance.axios.put(
        `/passengers/${this.state.profileToShow.id}/transitagency/${currentUser.transit_agency_id}/passengerType`,
        reqBodyPassengerType,
        {
          headers: {
            Authorization: `Bearer ${await this.props.auth0.getAccessTokenSilently({
              audience: config.apiAudience
            })}`,
            'x-app': 'dashboard'
          }
        }
      );

      await Promise.all([
        updatePassengerBlaiseDetails,
        updatePassengerTAExtraDetails,
        updatePassengerType
      ]);

      const index = this.state.passengers.findIndex(
        (item) => item.id === this.state.profileToShow.id
      );

      this.setState((prevState) => {
        return {
          passengers: prevState.passengers.map((obj, i) => {
            if (i === index) {
              return {
                ...obj,
                ...reqBody
              };
            } else {
              return obj;
            }
          })
        };
      });

      // if searchQuery not null or ''
      if (this.state.searchQuery !== null && this.state.searchQuery !== '') {
        const searchIndex = this.state.searchResults.findIndex(
          (passenger) => passenger.item.id === this.state.profileToShow.id
        );

        this.setState((prevState) => {
          return {
            searchResults: prevState.searchResults.map((obj, i) => {
              if (i === searchIndex) {
                return {
                  ...obj,
                  item: {
                    ...obj.item,
                    ...reqBody
                  }
                };
              } else {
                return {
                  ...obj
                };
              }
            })
          };
        });
      }

      this.fetchActiveUsers();
      this.handleCloseModal();
      this.openSnackbar(this.props.t('passenger_edit_success'), colors.blaiseGreen);
    } catch (err) {
      this.openSnackbar(this.props.t('passenger_edit_error'), colors.red);
    } finally {
      this.setState({
        isSubmitting: false
      });
    }
  };

  handleSearchChange = (e) => {
    const { value } = e.target;
    this.processSearchChange(value);
  };

  processSearchChange = (value) => {
    if (value.length === 0) {
      this.setState({
        searchResults: null,
        searchQuery: ''
      });
    } else {
      this.setState({
        searchQuery: value
      });
    }

    if (value.length < 3) {
      return;
    }

    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      let searchResults = this.state.fuse.search(value);

      this.setState({ searchResults });
    }, 350);
  };

  handleTimeChange = (period, time) => {
    if (period === 'start') {
      this.setState({
        startTime: new Date(time).getTime()
      });
    } else if (period === 'end') {
      this.setState({
        endTime: new Date(time).getTime()
      });
    }
  };

  activateCreate = () => {
    this.setState({
      createPassengerProfile: {
        type: this.state.passengerTypes[0].id
      },
      openCreateModal: true
    });
  };

  handleCreateCloseModal = () => {
    this.setState({
      openCreateModal: false,
      createPassengerProfile: {
        email: null,
        first_name: null,
        last_name: null,
        phone: null,
        type: this.state.passengerTypes[0].id,
        hasDisability: false,
        usesWheelchair: false,
        language: 'en'
      },
      taExtraDetails: this.state.taExtraDetailsIniitalState
    });
    this.fetchActiveUsers();
  };

  updateOptionIdValue = (value, index) => {
    this.setState(
      produce((draft) => {
        draft.taExtraDetails[index].optionIdValue = value;
      })
    );
  };

  updateTextValue = (value, index) => {
    this.setState(
      produce((draft) => {
        draft.taExtraDetails[index].textValue = value;
      })
    );
  };

  handleCreateChange = (e, key) => {
    e.persist();
    if (key === 'hasDisability') {
      this.setState(
        produce((draft) => {
          draft.createPassengerProfile['hasDisability'] =
            !this.state.createPassengerProfile.hasDisability;
          draft.createPassengerProfile['usesWheelchair'] = false;
        })
      );
    } else if (key === 'usesWheelchair') {
      this.setState(
        produce((draft) => {
          draft.createPassengerProfile['usesWheelchair'] =
            !this.state.createPassengerProfile.usesWheelchair;
        })
      );
    } else {
      this.setState(
        produce((draft) => {
          draft.createPassengerProfile[key] = e.target.value;
        })
      );
    }
  };

  createPassenger = async () => {
    const { firstName, lastName, email, phone, type, hasDisability, usesWheelchair, language } =
      this.state.createPassengerProfile;

    const createPassengerSchema = Joi.object({
      firstName: Joi.string()
        .required()
        .max(100)
        .messages({
          'string.max': this.props.t('max_length_passenger_management'),
          'any.required': this.props.t('required_field')
        }),
      lastName: Joi.string()
        .required()
        .max(100)
        .messages({
          'string.max': this.props.t('max_length_passenger_management'),
          'any.required': this.props.t('required_field')
        }),
      email: Joi.string()
        .required()
        .max(100)
        .messages({
          'string.max': this.props.t('max_length_passenger_management'),
          'any.required': this.props.t('required_field')
        }),
      phone: Joi.string()
        .required()
        .max(100)
        .messages({
          'string.max': this.props.t('max_length_passenger_management'),
          'any.required': this.props.t('required_field')
        })
    });

    const { error } = createPassengerSchema.validate({
      firstName,
      lastName,
      email,
      phone
    });

    if (error) {
      const errorMsg = error.details[0].message;
      this.props.openSnackbar(errorMsg, colors.red);
      return;
    }

    const reqBody = {
      passengerDetails: {
        firstName,
        lastName,
        email,
        phone,
        type,
        hasDisability,
        usesWheelchair,
        language: language || BROWSER_LANG
      },
      taExtraDetails: {
        ...this.state.taExtraDetails
      }
    };

    try {
      this.setState({ isSubmitting: true });

      await authenticatedAxiosInstance.axios.post(`/passengersByTa`, reqBody);

      this.handleCreateCloseModal();
      this.openSnackbar(this.props.t('passenger_account_create_success'), colors.blaiseGreen);
    } catch (err) {
      this.props.kaboom(err, colors.red);
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  renderCreateModal = () => {
    return (
      <Modal
        open={this.state.openCreateModal}
        onClose={this.handleCreateCloseModal}
        className="modal"
      >
        <Box m={4} style={{ overflow: 'auto', maxHeight: '90%' }}>
          <Paper elevation={0} style={{ padding: '32px' }}>
            <Box>
              <h2>{this.props.t('new_passenger')}</h2>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    name="firstName"
                    value={this.state.createPassengerProfile.firstName || ''}
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    label={this.props.t('first_name')}
                    disabled={this.state.isSubmitting}
                    onChange={(e) => this.handleCreateChange(e, 'firstName')}
                    data-testid="firstName"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="lastName"
                    value={this.state.createPassengerProfile.lastName || ''}
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    label={this.props.t('last_name')}
                    disabled={this.state.isSubmitting}
                    onChange={(e) => this.handleCreateChange(e, 'lastName')}
                    data-testid="lastName"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="email"
                    value={this.state.createPassengerProfile.email || ''}
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label={this.props.t('email')}
                    disabled={this.state.isSubmitting}
                    onChange={(e) => this.handleCreateChange(e, 'email')}
                    data-testid="email"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="phone"
                    value={this.state.createPassengerProfile.phone || ''}
                    variant="outlined"
                    required
                    fullWidth
                    id="phone"
                    label={this.props.t('phone_number')}
                    disabled={this.state.isSubmitting}
                    onChange={(e) => this.handleCreateChange(e, 'phone')}
                    data-testid="phone"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel htmlFor="outlined-language-native-simple">
                      {this.props.t('passenger_type')}
                    </InputLabel>
                    <Select
                      native
                      value={this.state.createPassengerProfile.type || ''}
                      onChange={(e) => this.handleCreateChange(e, 'type')}
                      label={this.props.t('passenger_type')}
                      inputProps={{
                        name: 'type',
                        id: 'outlined-language-native-simple'
                      }}
                      disabled={this.state.isSubmitting}
                      data-testid="passengerType"
                    >
                      {this.state.passengerTypes &&
                        this.state.passengerTypes.map((element) => {
                          return (
                            <option key={element.id} value={element.id}>
                              {BROWSER_LANG == 'en' ? element.english : element.french}
                            </option>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel htmlFor="outlined-language-native-simple">
                      {this.props.t('language')}
                    </InputLabel>
                    <Select
                      native
                      value={this.state.createPassengerProfile.language}
                      defaultValue={BROWSER_LANG}
                      onChange={(e) => this.handleCreateChange(e, 'language')}
                      label={this.props.t('language')}
                      inputProps={{
                        name: 'language',
                        id: 'outlined-language-native-simple'
                      }}
                      disabled={this.state.isSubmitting}
                    >
                      <option value={'en'}>{this.props.t('english')}</option>
                      <option value={'fr'}>{this.props.t('french')}</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        disabled={this.state.isSubmitting}
                        checked={this.state.createPassengerProfile.hasDisability}
                        onChange={(e) => this.handleCreateChange(e, 'hasDisability')}
                      />
                    }
                    label={this.props.t('reduced_mobility')}
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        disabled={
                          this.state.isSubmitting ||
                          !this.state.createPassengerProfile.hasDisability
                        }
                        checked={this.state.createPassengerProfile.usesWheelchair}
                        onChange={(e) => this.handleCreateChange(e, 'usesWheelchair')}
                      />
                    }
                    label={this.props.t('uses_wheelchair')}
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item xs={12}>
                  {this.state.taExtraDetails && this.state.taExtraDetails.length > 0 && (
                    <Fragment>
                      <Grid item xs={12}>
                        <h2>{this.props.t('extra_details')} </h2>
                      </Grid>
                      <Grid container spacing={2}>
                        {this.state.taExtraDetails.map((pref, index) => {
                          // if this is a select options input
                          if (pref.has_options) {
                            return (
                              <Grid item sm={12} key={`pref-${pref.preference_id}-${index}`}>
                                <FormControl
                                  variant="outlined"
                                  fullWidth
                                  required={pref.is_required}
                                >
                                  <InputLabel htmlFor={this.props.t(`${pref.title_key}`)}>
                                    {BROWSER_LANG == 'en'
                                      ? pref.translationTitle.english
                                      : pref.translationTitle.french}
                                  </InputLabel>
                                  <Select
                                    native
                                    value={pref.optionIdValue ? pref.optionIdValue : ''}
                                    onChange={(e) => {
                                      this.updateOptionIdValue(e.target.value, index);
                                    }}
                                    label={
                                      BROWSER_LANG == 'en'
                                        ? pref.translationTitle.english
                                        : pref.translationTitle.french
                                    }
                                    inputProps={{
                                      name: this.props.t(`${pref.title_key}`),
                                      id: this.props.t(`${pref.title_key}`)
                                    }}
                                    disabled={this.props.isSubmitting}
                                  >
                                    <option aria-label="None" value="" />
                                    {pref.preference_options.map((option) => {
                                      return (
                                        <option
                                          value={this.props.t(option.value)}
                                          key={`option-${option.preference_option_id}`}
                                        >
                                          {BROWSER_LANG == 'en'
                                            ? option.translationValue.english
                                            : option.translationValue.french}
                                        </option>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Grid>
                            );
                          } else {
                            return (
                              <Grid item sm={12} key={`pref-${pref.preference_id}-${index}`}>
                                <TextField
                                  name={
                                    BROWSER_LANG == 'en'
                                      ? pref.translationTitle.english
                                      : pref.translationTitle.french
                                  }
                                  value={pref.textValue ? pref.textValue : ''}
                                  variant="outlined"
                                  required={pref.is_required}
                                  fullWidth
                                  id={this.props.t(`${pref.title_key}`)}
                                  label={
                                    BROWSER_LANG == 'en'
                                      ? pref.translationTitle.english
                                      : pref.translationTitle.french
                                  }
                                  onChange={(e) => {
                                    this.updateTextValue(e.target.value, index);
                                  }}
                                  disabled={this.props.isSubmitting}
                                />
                              </Grid>
                            );
                          }
                        })}
                      </Grid>
                    </Fragment>
                  )}
                </Grid>

                {this.state.isSubmitting ? (
                  <Grid container item xs={12} justify="center">
                    <CircularProgress />
                  </Grid>
                ) : (
                  <>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      mt={2}
                      style={{ gap: '32px', width: '100%' }}
                    >
                      <Button color="secondary" variant="outlined">
                        {this.props.t('cancel')}
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={this.createPassenger}
                        data-testid="create"
                      >
                        {this.props.t('create')}
                      </Button>
                    </Box>
                  </>
                )}
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Modal>
    );
  };

  render() {
    const { classes } = this.props;

    let searchResultsArrayCopy;
    if (this.state.searchResults) {
      searchResultsArrayCopy = this.state.searchResults.map((searchResult, index) => {
        return {
          ...searchResult,
          id: index
        };
      });
    }

    const columnStyle = { flex: 0.5, align: 'center', headerAlign: 'center' };
    const columnStyleWide = { flex: 1, align: 'center', headerAlign: 'center' };

    const columns = [
      {
        field: 'first_name',
        headerName: this.props.t('first_name'),
        ...columnStyle,
        valueGetter: (params) => {
          return params.row.item ? params.row.item.first_name : params.row.first_name;
        }
      },
      {
        field: 'last_name',
        headerName: this.props.t('last_name'),
        ...columnStyle,
        valueGetter: (params) => {
          return params.row.item ? params.row.item.last_name : params.row.last_name;
        }
      },
      {
        field: 'email',
        headerName: this.props.t('email'),
        ...columnStyleWide,
        valueGetter: (params) => {
          return params.row.item
            ? params.row.item.email
            : params.row.email
            ? params.row.email
            : '-';
        }
      },
      {
        field: 'phone',
        headerName: this.props.t('phone_number'),
        ...columnStyle,
        align: 'center',
        valueGetter: (params) => {
          return params.row.item
            ? params.row.item.phone
            : params.row.phone
            ? params.row.phone
            : '-';
        }
      },
      {
        field: 'date',
        headerName: this.props.t(`creation_date`),
        ...columnStyle,
        valueGetter: (params) => {
          return params.row.item ? params.row.item.date : params.row.date;
        }
      },
      {
        field: 'status',
        headerName: this.props.t(`status`),
        ...columnStyle,
        valueGetter: (params) => {
          return params.row.item
            ? this.props.t(`${params.row.item.status}`)
            : this.props.t(`${params.row.status}`);
        }
      },
      {
        field: 'button',
        headerName: this.props.t('actions'),
        ...columnStyle,
        renderCell: (params) => (
          <Fab
            color="primary"
            size="small"
            className="editButton"
            variant="extended"
            onClick={() => this.handleOpenModal(params.row.item ? params.row.item : params.row)}
          >
            <FontAwesomeIcon icon={faPen} color={colors.white} size="1x" className="editIcon" />
          </Fab>
          // <FontAwesomeIcon icon={faPen} color={colors.black} size="1x" onClick={ () => this.handleOpenModal(params.row.item ? params.row.item : params.row) }  style={{fontSize: '10px'}}/>
        ),
        sortable: false,
        disableColumnMenu: true
      }
    ];

    return (
      <Fragment>
        {this.state.openCreateModal && this.renderCreateModal()}
        {this.state.openLinkPassengerModal && (
          <Modal
            open={this.state.openLinkPassengerModal}
            onClose={() => this.handleLinkPassengerModal(false)}
            className="modal"
          >
            <PassengerSearchAndLinkModal handleModal={this.handleLinkPassengerModal} />
          </Modal>
        )}

        {this.state.profileToShow && (
          <Modal open={this.state.modalIsOpen} onClose={this.handleCloseModal} className="modal">
            <Box m={4} style={{ overflow: 'auto', maxHeight: '90%' }}>
              <Paper elevation={0}>
                <Box m={4}>
                  <h2>
                    {this.props.t('passenger_profile')}{' '}
                    <span style={{ fontSize: 16, color: colors.blaiseGray, fontWeight: 'normal' }}>
                      ({this.state.profileToShow.id})
                    </span>
                  </h2>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        name="first_name"
                        value={this.state.profileToShow.first_name || ''}
                        variant="outlined"
                        required
                        fullWidth
                        id="first_name"
                        label={this.props.t('first_name')}
                        disabled={this.state.isSubmitting}
                        onChange={(e) => this.handleEditProfile(e)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        name="last_name"
                        value={this.state.profileToShow.last_name || ''}
                        variant="outlined"
                        required
                        fullWidth
                        id="last_name"
                        label={this.props.t('last_name')}
                        disabled={this.state.isSubmitting}
                        onChange={(e) => this.handleEditProfile(e)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        name="email"
                        value={this.state.profileToShow.email || ''}
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label={this.props.t('email')}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        name="phone"
                        value={this.state.profileToShow.phone || ''}
                        variant="outlined"
                        required
                        fullWidth
                        id="phone"
                        label={this.props.t('phone_number')}
                        disabled={this.state.isSubmitting}
                        onChange={(e) => this.handleEditProfile(e)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl variant="outlined" fullWidth required>
                        <InputLabel htmlFor="outlined-language-native-simple">
                          {this.props.t('passenger_type')}
                        </InputLabel>
                        <Select
                          native
                          value={this.state.profileToShow.type}
                          onChange={(e) => this.handleEditProfile(e)}
                          label={this.props.t('passenger_type')}
                          inputProps={{
                            name: 'type',
                            id: 'outlined-language-native-simple'
                          }}
                          disabled={this.state.isSubmitting}
                        >
                          {this.state.passengerTypes &&
                            this.state.passengerTypes.map((element) => {
                              return (
                                <option key={element.id} value={element.id}>
                                  {BROWSER_LANG == 'en' ? element.english : element.french}
                                </option>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl variant="outlined" fullWidth required>
                        <InputLabel htmlFor="outlined-language-native-simple">
                          {this.props.t('language')}
                        </InputLabel>
                        <Select
                          native
                          value={this.state.profileToShow.language || BROWSER_LANG}
                          onChange={(e) => this.handleEditProfile(e)}
                          label={this.props.t('language')}
                          inputProps={{
                            name: 'language',
                            id: 'outlined-language-native-simple'
                          }}
                          disabled={this.state.isSubmitting}
                        >
                          <option aria-label="None" value="" />
                          <option value={'en'}>{this.props.t('english')}</option>
                          <option value={'fr'}>{this.props.t('french')}</option>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            disabled={this.state.isSubmitting}
                            checked={this.state.profileToShow.hasDisability ? true : false}
                            onChange={() => this.handleEditProfileCheckbox('hasDisability')}
                          />
                        }
                        label={this.props.t('reduced_mobility')}
                        labelPlacement="start"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            disabled={
                              this.state.isSubmitting || !this.state.profileToShow.hasDisability
                            }
                            checked={this.state.profileToShow.usesWheelchair ? true : false}
                            onChange={() => this.handleEditProfileCheckbox('usesWheelchair')}
                          />
                        }
                        label={this.props.t('uses_wheelchair')}
                        labelPlacement="start"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {this.state.taExtraDetails && this.state.taExtraDetails.length > 0 && (
                        <Fragment>
                          <Grid item xs={12}>
                            <h2>{this.props.t('extra_details')} </h2>
                          </Grid>
                          <Grid container spacing={2}>
                            {this.state.taExtraDetails.map((pref, index) => {
                              // if this is a select options input
                              if (pref.has_options) {
                                return (
                                  <Grid item sm={12} key={`pref-${pref.preference_id}-${index}`}>
                                    <FormControl
                                      variant="outlined"
                                      fullWidth
                                      required={pref.is_required}
                                    >
                                      <InputLabel htmlFor={this.props.t(`${pref.title_key}`)}>
                                        {BROWSER_LANG == 'en'
                                          ? pref.translationTitle.english
                                          : pref.translationTitle.french}
                                      </InputLabel>
                                      <Select
                                        native
                                        value={pref.optionIdValue ? pref.optionIdValue : ''}
                                        onChange={(e) => {
                                          this.updateOptionIdValue(e.target.value, index);
                                        }}
                                        label={
                                          BROWSER_LANG == 'en'
                                            ? pref.translationTitle.english
                                            : pref.translationTitle.french
                                        }
                                        inputProps={{
                                          name: this.props.t(`${pref.title_key}`),
                                          id: this.props.t(`${pref.title_key}`)
                                        }}
                                        disabled={this.props.isSubmitting}
                                      >
                                        <option aria-label="None" value="" />
                                        {pref.preference_options.map((option) => {
                                          return (
                                            <option
                                              value={this.props.t(option.value)}
                                              key={`option-${option.preference_option_id}`}
                                            >
                                              {BROWSER_LANG == 'en'
                                                ? option.translationValue.english
                                                : option.translationValue.french}
                                            </option>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                );
                              } else {
                                return (
                                  <Grid item sm={12} key={`pref-${pref.preference_id}-${index}`}>
                                    <TextField
                                      name={this.props.t(`${pref.title_key}`)}
                                      value={pref.textValue ? pref.textValue : ''}
                                      variant="outlined"
                                      required={pref.is_required}
                                      fullWidth
                                      id={this.props.t(`${pref.title_key}`)}
                                      label={
                                        BROWSER_LANG == 'en'
                                          ? pref.translationTitle.english
                                          : pref.translationTitle.french
                                      }
                                      onChange={(e) => {
                                        this.updateTextValue(e.target.value, index);
                                      }}
                                      disabled={this.props.isSubmitting}
                                    />
                                  </Grid>
                                );
                              }
                            })}
                          </Grid>
                        </Fragment>
                      )}
                    </Grid>
                    {this.state.isSubmitting ? (
                      <Grid container item xs={12} justify="center">
                        <CircularProgress />
                      </Grid>
                    ) : (
                      <>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="center"
                          mt={2}
                          style={{ gap: '32px', width: '100%' }}
                        >
                          <Button
                            color="secondary"
                            variant="outlined"
                            onClick={this.handleDeleteRequest}
                          >
                            {this.props.t('delete')}
                          </Button>
                          <Button color="primary" variant="contained" onClick={this.validateSubmit}>
                            {this.props.t('update')}
                          </Button>
                        </Box>
                      </>
                    )}
                  </Grid>
                </Box>
              </Paper>
            </Box>
          </Modal>
        )}
        <CustomSnackbar
          message={this.state.snackbar.message}
          open={this.state.snackbar.open}
          onClose={this.closeSnackbar}
          snackbarColor={this.state.snackbar.color}
        />
        <div className="pageTitle">{this.props.t('passenger_management')}</div>
        {!this.state.passengers ? (
          <div className="blaise-progress-top">
            <CircularProgress />
          </div>
        ) : (
          <Paper className={classes.wrapper}>
            <Grid container justify="space-between" style={{ padding: '16px 0' }}>
              {/* <Box display="flex" justifyContent="flex-start" className="tableBorder">
                {this.props.t("list_of_passengers")}
              </Box> */}
              <Grid item style={{ position: 'relative' }}>
                <TextField
                  label={this.props.t('search')}
                  value={this.state.searchQuery}
                  onChange={(e) => this.handleSearchChange(e)}
                  style={{ width: '200px' }}
                />
                {this.state.searchQuery && this.state.searchQuery.length > 0 && (
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    color={colors.blaiseGray}
                    size="1x"
                    className="exit"
                    style={{ float: 'right', marginTop: '25px' }}
                    onClick={() => this.clearSearchQuery()}
                  />
                )}
              </Grid>
              <Grid item style={{ display: 'flex', alignItems: 'flex-end' }}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.activateCreate}
                    data-testid="addNewPassenger"
                    disabled={this.state.isLoading}
                  >
                    {this.props.t('new_passenger')}
                  </Button>
                </Grid>
                <Grid item style={{ marginLeft: '16px' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.handleLinkPassengerModal(true)}
                    data-testid="linkPassenger"
                  >
                    {this.props.t('link_existing_account')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {this.state.passengers && (
              <div>
                <DataGridTable
                  rows={searchResultsArrayCopy ? searchResultsArrayCopy : this.state.passengers}
                  columns={columns}
                  checkboxSelection
                  disableSelectionOnClick
                />
              </div>
            )}
          </Paper>
        )}
      </Fragment>
    );
  }
}

const styles = () => ({
  wrapper: {
    borderRadius: '4px'
  },
  topCells: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'left',
    width: '100%'
  },
  exit: {
    fontSize: '12px',
    position: 'absolute',
    top: '29px',
    right: '22px',
    color: '#8C8C8C'
  },
  topCellInput: {
    width: '100%',
    borderRadius: '3px',
    border: '1px solid #404040',
    height: '30px',
    paddingLeft: '10px'
  },
  searchBox: {
    '& label': {
      marginTop: '-5px',
      fontSize: '14px'
    },
    '& .Mui-focused': {
      marginTop: '0px',
      fontSize: '16px'
    },
    '& .MuiFormLabel-filled': {
      marginTop: '0px',
      fontSize: '16px'
    }
  }
});

export default withStyles(styles, { withTheme: true })(
  withTranslation('common')(withAuth0(currentUserHOC(withKaboom(PassengerManagement))))
);
