import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import { Typography } from '@material-ui/core';
import colors from '../../assets/sass/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCog,
  faBus,
  faBusAlt,
  faMapSigns,
  faUser,
  faUserTie,
  faSignOutAlt,
  faClock,
  faPhone,
  faHistory,
  faUsers,
  faRoute,
  faCommentDots,
  faChartBar
} from '@fortawesome/free-solid-svg-icons';
import blaiseLogo from '../../assets/images/blaiseLogo.png';
import './NavBar.scss';
import { useHistory } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import config from '../../config';
import { currentUserStore } from '../../store/user';
import { analyticsStore } from '../../store/analytics';
import { driverShiftStore } from '../../store/driverShift';

const NavBar = (props) => {
  const {
    location: { pathname }
  } = props;
  let history = useHistory();
  const { currentUser, setCurrentUser } = currentUserStore();
  const { analyticsUrl } = analyticsStore();
  const { driverShiftsAreEndingSoon } = driverShiftStore();
  const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();

  const checkPermissions = (permissionString) => {
    if (user) {
      const permissions = user['http://blaisetransitagency.com/permissions'];
      if (permissions.includes(permissionString)) {
        return true;
      }
    }
    return false;
  };

  const checkEmployeeManagementPermissions = () => checkPermissions('read:agents');
  const checkFleetManagementPermissions = () => checkPermissions('manage:fleets');
  const checkBusManagementPermissions = () => checkPermissions('manage:buses');
  const checkBusStopManagementPermissions = () => checkPermissions('manage:busStops');
  const checkDriverManagementPermissions = () => checkPermissions('manage:drivers');
  const checkDriverShiftManagementPermissions = () => checkPermissions('manage:driverShifts');
  const checkCalledInManagementPermissions = () => checkPermissions('manage:calledInRequests');
  // const checkParatransitManagementPermissions = () => checkPermissions("manage:paratransit");
  const checkPassengerManagementPermissions = () => checkPermissions('manage:passengers');
  const checkSystemParametersManagementPermissions = () =>
    checkPermissions('manage:systemParameters');
  const checkMyProfilePermissions = () => checkPermissions('manage:myProfile');
  const checkHistoryManagementPermissions = () => checkPermissions('manage:history');
  const checkRouteManagmentPermissions = () => checkPermissions('manage:routes');

  return (
    <Drawer className="drawer" variant="permanent" anchor="left">
      <Box display="flex" flexDirection="column" className="navBox navbar-right-border">
        <Box>
          <img src={blaiseLogo} className="logoNavbar" alt="logo" />
          {analyticsUrl && (
            <MenuItem
              className={pathname === '/analytics' ? 'selectedNavbarItem' : ''}
              button
              onClick={() => history.push('/analytics')}
              selected={pathname === '/analytics'}
            >
              <FontAwesomeIcon icon={faChartBar} fixedWidth />
              &ensp; {props.t('analytics_title')}
            </MenuItem>
          )}

          {/* <MenuItem className={pathname === '/realtime' ? 'selectedNavbarItem'} button onClick={() => history.push("/realtime")}
                selected={pathname === '/realtime'}>
                <FontAwesomeIcon icon={faMapMarkedAlt} fixedWidth />
                &ensp; Interactive map
              </MenuItem> */}

          {checkFleetManagementPermissions() && (
            <MenuItem
              className={pathname === '/fleets' ? 'selectedNavbarItem' : ''}
              button
              onClick={() => history.push('/fleets')}
              selected={pathname === '/fleets'}
            >
              <FontAwesomeIcon icon={faBus} fixedWidth />
              &ensp; {props.t('fleet_management_title')}
            </MenuItem>
          )}
          {checkBusManagementPermissions() && (
            <MenuItem
              className={pathname === '/vehicles' ? 'selectedNavbarItem' : ''}
              button
              onClick={() => history.push('/vehicles')}
              selected={pathname === '/vehicles'}
            >
              <FontAwesomeIcon icon={faBusAlt} fixedWidth />
              &ensp; {props.t('vehicle_management_title')}
            </MenuItem>
          )}
          {checkBusStopManagementPermissions() && (
            <MenuItem
              className={pathname === '/network' ? 'selectedNavbarItem' : ''}
              button
              onClick={() => history.push('/network')}
              selected={pathname === '/network'}
            >
              <FontAwesomeIcon icon={faMapSigns} fixedWidth />
              &ensp; {props.t('network_title')}
            </MenuItem>
          )}
          {checkDriverManagementPermissions() && (
            <MenuItem
              className={pathname === '/drivers' ? 'selectedNavbarItem' : ''}
              button
              onClick={() => history.push('/drivers')}
              selected={pathname === '/drivers'}
            >
              <FontAwesomeIcon icon={faUserTie} fixedWidth />
              &ensp; {props.t('driver_management_title')}
            </MenuItem>
          )}
          {checkDriverShiftManagementPermissions() && (
            <MenuItem
              className={pathname === '/driverShifts' ? 'selectedNavbarItem' : ''}
              button
              onClick={() => history.push('/driverShifts')}
              selected={pathname === '/driverShifts'}
            >
              <Badge color="secondary" variant="dot" invisible={!driverShiftsAreEndingSoon}>
                <FontAwesomeIcon icon={faClock} fixedWidth />
              </Badge>
              &ensp; {props.t('driver_shifts_title')}
            </MenuItem>
          )}
          {checkHistoryManagementPermissions() && (
            <MenuItem
              className={pathname === '/history' ? 'selectedNavbarItem' : ''}
              button
              onClick={() => history.push('/history')}
              selected={pathname === '/history'}
              data-testid="rideManagmentButton"
            >
              <FontAwesomeIcon icon={faHistory} fixedWidth />
              &ensp; {props.t('ride_management_title')}
            </MenuItem>
          )}
          {checkRouteManagmentPermissions() && (
            <MenuItem
              className={pathname === '/routes' ? 'selectedNavbarItem' : ''}
              button
              onClick={() => history.push('/routes')}
              selected={pathname === '/routes'}
            >
              <FontAwesomeIcon icon={faRoute} fixedWidth />
              &ensp; {props.t('route_management_title')}
            </MenuItem>
          )}
          {checkCalledInManagementPermissions() && (
            <MenuItem
              className={pathname === '/requestByPhone' ? 'selectedNavbarItem' : ''}
              button
              onClick={() => history.push('/requestByPhone')}
              selected={pathname === '/requestByPhone'}
            >
              <FontAwesomeIcon icon={faPhone} fixedWidth />
              &ensp; {props.t('called_in_requests')}
            </MenuItem>
          )}
          {checkSystemParametersManagementPermissions() && (
            <MenuItem
              className={pathname === '/settings' ? 'selectedNavbarItem' : ''}
              button
              onClick={() => history.push('/settings')}
              selected={pathname === '/settings'}
            >
              <FontAwesomeIcon icon={faCog} fixedWidth />
              &ensp; {props.t('system_parameters_title')}
            </MenuItem>
          )}
          {checkPassengerManagementPermissions() && (
            <MenuItem
              className={pathname === '/passengers' ? 'selectedNavbarItem' : ''}
              button
              onClick={() => history.push('/passengers')}
              selected={pathname === '/passengers'}
              data-testid="passengersManagment"
            >
              <FontAwesomeIcon icon={faUsers} fixedWidth />
              &ensp; {props.t('passenger_management')}
            </MenuItem>
          )}
          {checkEmployeeManagementPermissions() && (
            <MenuItem
              className={pathname === '/employees' ? 'selectedNavbarItem' : ''}
              button
              onClick={() => history.push('/employees')}
              selected={pathname === '/employees'}
              data-testid="employeeManagment"
            >
              <FontAwesomeIcon icon={faUserTie} fixedWidth />
              &ensp; {props.t('employee_management_title')}
            </MenuItem>
          )}
          {/* {checkParatransitManagementPermissions() &&
              <MenuItem className={pathname === '/paratransit' ? 'selectedNavbarItem' : ''} button onClick={() => history.push("/paratransit" )}
                selected={pathname === '/paratransit'}>
                <FontAwesomeIcon icon={faWheelchair} fixedWidth />
                &ensp; {props.t('paratransit_management')}
              </MenuItem>
            } */}
          {checkMyProfilePermissions() && (
            <MenuItem
              className={pathname === '/' ? 'selectedNavbarItem' : ''}
              button
              id="profileMenu"
              onClick={() => history.push('/')}
              selected={pathname === '/'}
            >
              <FontAwesomeIcon icon={faUser} fixedWidth />
              &ensp; {props.t('profile_title')}
            </MenuItem>
          )}
          {currentUser !== null && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://tally.so/r/mVdDJw?app=engine&email=${currentUser.email}&agency=${currentUser.transit_agency_id}`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <MenuItem id="feedback" button>
                <FontAwesomeIcon icon={faCommentDots} fixedWidth />
                &ensp; {props.t('give_feedback')}
              </MenuItem>
            </a>
          )}
        </Box>
        <div>
          {!isAuthenticated && (
            <Button id="loginButton" variant="outlined" onClick={() => loginWithRedirect()}>
              {props.t('login')}&ensp;
              <FontAwesomeIcon icon={faSignOutAlt} color={colors.red} fixedWidth />
            </Button>
          )}

          {isAuthenticated && (
            <Button
              id="logoutButton"
              variant="outlined"
              onClick={() => {
                setCurrentUser(null);
                logout({ returnTo: window.location.origin });
              }}
            >
              {props.t('logout')}&ensp;
              <FontAwesomeIcon icon={faSignOutAlt} color={colors.red} fixedWidth />
            </Button>
          )}
        </div>
        <Box style={{ padding: 10 }}>
          <Typography variant="caption" style={{ color: colors.blaiseGray }}>
            {config.version || ''}
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
};

export default withTranslation('common')(withRouter(NavBar));
