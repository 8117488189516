import React, { useState, useEffect } from 'react';
import './Setup.scss';
import { Box, CircularProgress } from '@material-ui/core';
import SetupForm from '../../components/Setup/SetupForm/SetupForm';
import SetupMap from '../../components/Setup/SetupMap/SetupMap';
import config from '../../config';
import { currentUserStore } from '../../store/user';
import authenticatedAxiosInstance from '../../axios/axios-authorized';
import { useTranslation } from 'react-i18next';

const Setup = () => {
  const [transitAgencyDetails, setTransitAgencyDetails] = useState(null);
  const { currentUser } = currentUserStore();
  const { t } = useTranslation('common');

  useEffect(() => {
    document.title = `${t('system_parameters_title')} - ${t('trip_source_engine')}`;
    getTransitAgencyInfo();
  }, []);

  const getTransitAgencyInfo = async () => {
    try {
      const transitAgencyDetails = (
        await authenticatedAxiosInstance.axios.get(
          `/transitagencies/${currentUser.transit_agency_id}`
        )
      ).data;

      setTransitAgencyDetails(transitAgencyDetails);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {!transitAgencyDetails && (
        <div className="blaise-progress-top centered">
          <CircularProgress />
        </div>
      )}

      {transitAgencyDetails && (
        <>
          <div className="pageTitle">{t('system_parameters_title')}</div>
          <Box flexDirection="row" display="flex" mb={2}>
            <Box>
              <img
                src={config.apiServer + transitAgencyDetails.logo_path}
                id="agencyLogo"
                alt="transitAgencyLogo"
                style={{ height: '60px', objectFit: 'contain' }}
              />
            </Box>
            <Box alignSelf="center">
              <div className="agencyTitle">{transitAgencyDetails.name}</div>
            </Box>
          </Box>
          <Box
            flexDirection="row"
            flexWrap="wrap"
            display="flex"
            alignContent="stretch"
            alignItems="stretch"
          >
            <Box flexWrap="wrap" display="flex" justifyContent="flex-start" flexShrink={0}>
              <SetupForm transitAgencyId={currentUser.transit_agency_id} />
            </Box>
            <Box flexWrap="wrap" display="flex" flexGrow={1}>
              <SetupMap
                transitAgencyId={currentUser.transit_agency_id}
                centerLon={transitAgencyDetails.center_lon}
                centerLat={transitAgencyDetails.center_lat}
                getTransitAgencyInfo={getTransitAgencyInfo}
              />
            </Box>
          </Box>
        </>
      )}
    </div>
  );
};

export default Setup;
