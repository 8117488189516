import React from 'react';
import create from 'zustand';
import shallow from 'zustand/shallow';

export const currentUserStore = create((set) => ({
  currentUser: null,
  setCurrentUser: (userInfo) => set({ currentUser: userInfo })
}));

export const currentUserHOC = (BaseComponent) => (props) => {
  const store = currentUserStore(
    (state) => ({
      currentUser: state.currentUser,
      setCurrentUser: state.setCurrentUser
    }),
    shallow
  );

  return <BaseComponent {...props} currentUserStore={store} />;
};
