import React, { Fragment } from 'react';
import CustomSnackbar from '../components/Snackbar/CustomSnackbar';
import errorMessages from '../translations/en/errors.json';

export const withKaboom = (WrappedComponent) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        snackbar: {
          open: false,
          message: '',
          color: ''
        }
      };
    }

    openSnackbar = (snackbarString, color) => {
      this.setState({
        snackbar: {
          open: true,
          message: snackbarString,
          color: color
        }
      });
    };

    closeSnackbar = (reason) => {
      if (reason !== 'clickaway') {
        this.setState({ snackbar: { open: false } });
      }
    };

    kaboom = (error, color) => {
      const errorKey = error.response.data.error.errorKey;
      if (
        typeof errorKey !== 'undefined' &&
        Object.prototype.hasOwnProperty.call(errorMessages, errorKey)
      ) {
        this.openSnackbar(this.props.t(`${errorKey}`, { ns: 'errors' }), color);
      } else {
        this.openSnackbar(this.props.t('oops_error'), color);
      }
    };

    render() {
      return (
        <Fragment>
          <CustomSnackbar
            message={this.state.snackbar.message}
            open={this.state.snackbar.open}
            onClose={() => this.closeSnackbar()}
            snackbarColor={this.state.snackbar.color}
          />
          <WrappedComponent
            snackbar={this.state.snackbar}
            {...this.props}
            openSnackbar={this.openSnackbar}
            closeSnackbar={this.closeSnackbar}
            kaboom={this.kaboom}
          />
        </Fragment>
      );
    }
  };
};

// kaboom is highly coupled along with the withKaboom HOC
export const kaboom = (error, color) => {
  const errorKey = error.response.data.error.errorKey;
  if (Object.prototype.hasOwnProperty.call(errorMessages, errorKey)) {
    this.props.openSnackbar(this.props.t(`${errorKey}`), color);
  } else {
    this.props.openSnackbar(this.props.t('oops_error'), color);
  }
};
