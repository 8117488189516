import colors from '../assets/sass/colors';
import { TripType } from '../enums/tripType';

export const getRouteColor = (routeType) => {
  if (routeType === TripType.ON_FOOT) {
    return colors.blaiseGray;
  } else if (routeType === TripType.EXTERNAL_FIXED || routeType === TripType.INTERNAL_FIXED) {
    return colors.fixedRouteOrange;
  } else {
    return colors.blaiseGreen;
  }
};

export const getCenter = (dLat, dLon, oLat, oLon) => {
  let cLat = (Number(dLat) + Number(oLat)) / 2;
  let cLon = (Number(dLon) + Number(oLon)) / 2;

  return [cLon, cLat];
};
