import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DataGridTable from '../DataGrid/DataGridTable';
import colors from '../../assets/sass/colors';

// Material ui
import { CircularProgress, Grid, Modal, Paper } from '@material-ui/core';

// i18n
import { withTranslation } from 'react-i18next';
import authenticatedAxiosInstance from '../../axios/axios-authorized';

const PassengerModal = ({ t, stop, openModal, setModalVisible }) => {
  const [passengers, setPassengers] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPassengersByBusStopId();
  }, []);

  const getPassengersByBusStopId = async () => {
    try {
      setLoading(true);

      const response = (
        await authenticatedAxiosInstance.axios.get(
          `/rides/${stop.route_id}/rideProposalByBusStopId/${stop.stop_sequence}`
        )
      ).data;

      // Filter out cancelled trips (but include no shows)
      const filteredPassengers = response.filter(
        (item) => !(item.is_cancelled === 1 && item.has_boarded === null)
      );

      setPassengers(filteredPassengers);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const columnStyles = { flex: 1, align: 'center', headerAlign: 'center' };

  const columns = [
    {
      field: 'trip_request_id',
      headerName: t('id'),
      ...columnStyles,
      editable: false,
      renderCell: (params) => {
        const tripRequest = params.row.trip_request_id;

        return (
          <Link
            to={`/history/${tripRequest}`}
            style={{ color: colors.blaiseGreen, textDecoration: 'none' }}
          >
            {tripRequest}
          </Link>
        );
      }
    },
    {
      field: 'email',
      headerName: t('email'),
      ...columnStyles,
      editable: false,
      valueGetter: (params) => {
        return params.row.passenger.email;
      }
    },
    {
      field: 'phoneNumber',
      headerName: t('phone_number'),
      ...columnStyles,
      editable: false,
      valueGetter: (params) => {
        return params.row.passenger.phone_number;
      }
    },
    {
      field: 'firstName',
      headerName: t('first_name'),
      ...columnStyles,
      editable: false,
      valueGetter: (params) => {
        return params.row.passenger.first_name;
      }
    },
    {
      field: 'lastName',
      headerName: t('last_name'),
      ...columnStyles,
      editable: false,
      valueGetter: (params) => {
        return params.row.passenger.last_name;
      }
    },
    {
      field: 'type',
      headerName: t('type'),
      ...columnStyles,
      editable: false,
      valueGetter: (params) => {
        return stop.stop_sequence === params.row.dropoff_sequence ? t('dropoff') : t('pickup');
      }
    }
  ];

  let passengersCopy;
  if (passengers) {
    passengersCopy = passengers.map((passenger, index) => {
      return {
        ...passenger,
        id: index
      };
    });
  }

  return (
    <Modal
      open={openModal}
      onClose={() => {
        setModalVisible(false);
      }}
      className="modal"
    >
      <Paper style={{ overflow: 'auto', height: '50%', width: '60%' }}>
        <h4>{t('passenger_info')}</h4>
        {loading && (
          <Grid container item xs={12} justify="center">
            <CircularProgress />
          </Grid>
        )}
        {passengersCopy && !loading && <DataGridTable rows={passengersCopy} columns={columns} />}
      </Paper>
    </Modal>
  );
};

export default withTranslation('common')(PassengerModal);
