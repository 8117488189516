import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withAuth0 } from '@auth0/auth0-react';

class Callback extends Component {
  constructor(props) {
    super(props);
    this.createInDB = false;
  }

  async componentDidMount() {
    await this.props.auth0.handleAuthentication();

    // await axios.get(`${config.blaiseApiUrl}/passengers/${auth0Client.getProfile().sub}`, { headers: { 'Authorization': `Bearer ${auth0Client.getIdToken()}` } })
    //   .catch(() => {
    //     this.createInDB = true;
    //   })

    // if(this.createInDB) {
    //   this.props.history.replace('/signUp');
    // } else {
    this.props.history.replace('/');
    // }
  }

  render() {
    return <p></p>;
  }
}

export default withRouter(withAuth0(Callback));
