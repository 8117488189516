import React from 'react';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';

const CustomSnackbar = ({ open, snackbarColor, autoHideDuration, onClose, message }) => {
  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <SnackbarContent
        style={{
          backgroundColor: snackbarColor,
          maxWidth: '475px'
        }}
        message={message}
        action={
          <FontAwesomeIcon
            icon={faWindowClose}
            onClick={handleClose}
            aria-label="close"
            color="inherit"
          />
        }
      />
    </Snackbar>
  );
};

export default CustomSnackbar;
