import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrown } from '@fortawesome/free-solid-svg-icons';
import colors from '../../assets/sass/colors';

const NotFound = ({ title, message }) => {
  return (
    <Grid
      container
      direction="column"
      justifycontent="center"
      alignItems="center"
      style={{ marginTop: '100px' }}
    >
      <Grid item style={{ paddingBottom: '10px' }}>
        <Typography variant="h1">{title}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          <FontAwesomeIcon icon={faFrown} color={colors.blaiseGreen} size="2x" /> {message}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NotFound;
