import React, { useEffect, useState } from 'react';
import { withAuth0 } from '@auth0/auth0-react';
import { withTranslation } from 'react-i18next';
import {
  Box,
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Modal,
  TextField
} from '@material-ui/core';
import { mailToTypes } from '../../models/MailToModel';
import colors from '../../assets/sass/colors';
import { currentUserHOC } from '../../store/user';
import authenticatedAxiosInstance from '../../axios/axios-authorized';
import { busStopModalType } from '../../enums/busStopModalType';

const busStopRequestTypeTranslation = Object.freeze({
  ADD_A_BUS_STOP: 'add_a_bus_stop',
  EDIT_A_BUS_STOP: 'edit_a_bus_stop',
  DELETE_A_BUS_STOP: 'delete_a_bus_stop'
});

const StopModal = (props) => {
  const [stopId, setStopId] = useState(null);
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [origin, setOrigin] = useState(null);
  const [hasShelter, setHasShelter] = useState(false);
  const [isBreak, setIsBreak] = useState(false);
  const [isDepot, setIsDepot] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editedName, setEditedName] = useState(false);
  const [editedFields, setEditedFields] = useState(false);

  useEffect(() => {
    if (
      (props.type === busStopModalType.EDIT || props.type === busStopModalType.DELETE) &&
      props.row
    ) {
      const rowInfo = props.row;
      setStopId(rowInfo.stopId);
      setCode(rowInfo.code);
      setName(rowInfo.name);
      setOrigin(rowInfo.origin);
      setHasShelter(rowInfo.hasShelter);
      setIsBreak(rowInfo.isBreak);
      setIsDepot(rowInfo.isDepot);
    }
  }, []);

  let isEdit;
  if (props.type === busStopModalType.EDIT) {
    isEdit = true;
  }

  const translatedModalType = (modalType) => {
    let translatedType;

    switch (modalType) {
      case 'add':
        translatedType = busStopRequestTypeTranslation.ADD_A_BUS_STOP;
        break;
      case 'edit':
        translatedType = busStopRequestTypeTranslation.EDIT_A_BUS_STOP;
        break;
      case 'delete':
        translatedType = busStopRequestTypeTranslation.DELETE_A_BUS_STOP;
        break;
      default:
        translatedType = 'Unknown';
        break;
    }

    return translatedType;
  };

  const handleRequest = async () => {
    const { currentUser } = props.currentUserStore;

    try {
      setIsSubmitting(true);

      const reqBody = {
        transitAgencyName: currentUser.transit_agency.transit_agency_name,
        transitAgencyNumber: currentUser.transit_agency_id,
        firstName: currentUser.first_name,
        busStop: {
          name,
          code: Number(code),
          lon: origin.lon,
          lat: origin.lat,
          location_type: false, // todo: find out what this is
          display: false, // todo: same ^
          abribus: hasShelter,
          is_active: true,
          break_location: isBreak,
          is_depot: isDepot,
          stop_id: stopId
        }
      };

      if (props.type === busStopModalType.DELETE) {
        // Request to delete a stop.
        reqBody.mailTo = mailToTypes.BUS_STOP_DELETE_REQUEST;

        await authenticatedAxiosInstance.axios.post(`/specialRequest`, reqBody);
        props.openSnackbar(props.t('stop_deleted_success'), colors.blaiseGreen);
      } else {
        if (isEdit && editedName) {
          // Edit a stop name only (without changing anything else)
          await authenticatedAxiosInstance.axios.put(`/busstops/${stopId}`, { name });
        }

        if (editedFields) {
          // Request to add a stop or edit fields (other than stop name) of an existing stop.
          reqBody.mailTo = isEdit
            ? mailToTypes.BUS_STOP_EDIT_REQUEST
            : mailToTypes.BUS_STOP_REQUEST;

          await authenticatedAxiosInstance.axios.post(`/specialRequest`, reqBody);
        }

        props.openSnackbar(
          props.t(
            isEdit && editedName && !editedFields
              ? 'stop_name_edit_success'
              : isEdit
              ? 'stop_edit_success'
              : 'stop_request_success'
          ),
          colors.blaiseGreen
        );
      }

      props.setRefreshStops(true);
      props.setModalOpen(false);
    } catch (err) {
      console.log(err);
      if (props.type === busStopModalType.DELETE) {
        props.openSnackbar(props.t('stop_deleted_error'), colors.red);
      } else {
        props.openSnackbar(props.t(isEdit ? 'stop_edit_error' : 'stop_request_error'), colors.red);
      }
    }

    setIsSubmitting(false);
  };

  const handleEditBusName = (newName) => {
    setName(newName);
    setEditedName(true);
  };

  const handleEdit = () => {
    setEditedFields(true);
  };

  return (
    <Modal open={props.modalOpen} onClose={() => props.setModalOpen(false)} className="modal">
      <Box m={4}>
        <Paper elevation={0} style={{ padding: '32px' }}>
          <Box>
            <h2>{props.t(translatedModalType(props.type))}</h2>
            <Grid container spacing={2}>
              <Grid item xs={12} md={10}>
                <TextField
                  name="name"
                  value={name}
                  variant="outlined"
                  required
                  fullWidth
                  id="name"
                  label={props.t('name')}
                  disabled={isSubmitting || props.type === busStopModalType.DELETE}
                  onChange={(e) => handleEditBusName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  name="code"
                  value={code}
                  variant="outlined"
                  required
                  fullWidth
                  id="code"
                  label={props.t('code')}
                  disabled={isSubmitting || props.type === busStopModalType.DELETE}
                  onChange={(e) => {
                    setCode(e.target.value);
                    handleEdit();
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  value="busShelter"
                  control={
                    <Checkbox
                      color="primary"
                      checked={hasShelter}
                      onChange={(e) => {
                        setHasShelter(e.target.checked);
                        handleEdit();
                      }}
                    />
                  }
                  disabled={isSubmitting || props.type === busStopModalType.DELETE}
                  label={props.t('bus_shelter')}
                  labelPlacement="start"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  value="breakLocation"
                  control={
                    <Checkbox
                      color="primary"
                      checked={isBreak}
                      onChange={(e) => {
                        setIsBreak(e.target.checked);
                        handleEdit();
                      }}
                    />
                  }
                  disabled={isSubmitting || props.type === busStopModalType.DELETE}
                  label={props.t('break_location')}
                  labelPlacement="start"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  value="busDepot"
                  control={
                    <Checkbox
                      color="primary"
                      checked={isDepot}
                      disabled={isSubmitting || props.type === busStopModalType.DELETE}
                      onChange={(e) => {
                        setIsDepot(e.target.checked);
                        handleEdit();
                      }}
                    />
                  }
                  label={props.t('bus_depot')}
                  labelPlacement="start"
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  name="lon"
                  type="number"
                  value={origin?.lon}
                  variant="outlined"
                  required
                  fullWidth
                  id="lon"
                  label={props.t('lon')}
                  disabled={isSubmitting || props.type === busStopModalType.DELETE}
                  onChange={(e) => {
                    if (e.target.value >= -180 && e.target.value <= 180) {
                      setOrigin({ ...origin, lon: parseFloat(e.target.value) });
                      handleEdit();
                    }
                  }}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  name="lat"
                  type="number"
                  value={origin?.lat}
                  variant="outlined"
                  required
                  fullWidth
                  id="lat"
                  label={props.t('lat')}
                  disabled={isSubmitting || props.type === busStopModalType.DELETE}
                  onChange={(e) => {
                    if (e.target.value >= -90 && e.target.value <= 90) {
                      setOrigin({ ...origin, lat: parseFloat(e.target.value) });
                      handleEdit();
                    }
                  }}
                />
              </Grid>
              {props.isSubmitting ? (
                <Grid container item xs={12} justify="center">
                  <CircularProgress />
                </Grid>
              ) : (
                <Box
                  pt={4}
                  display="flex"
                  justifyContent="center"
                  style={{ width: '100%', gap: '32px', marginBottom: '8px' }}
                >
                  <Button
                    onClick={() => props.setModalOpen(false)}
                    variant="outlined"
                    color="secondary"
                  >
                    {props.t('cancel')}
                  </Button>
                  <Button color="primary" variant="contained" onClick={handleRequest}>
                    {props.t('request')}
                  </Button>
                </Box>
              )}
            </Grid>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default withTranslation('common')(withAuth0(currentUserHOC(StopModal)));
