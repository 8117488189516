import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withAuth0 } from '@auth0/auth0-react';
import ProgressDots from '../../components/UI/ProgressDots';
import { Grid, Box, Button, CircularProgress, TextField } from '@material-ui/core';
import colors from '../../assets/sass/colors';

// searchType
import Fuse from 'fuse.js';

const PassengerSearch = ({
  selectPassenger,
  progress,
  t,
  listOfPassengers,
  hasSelection,
  resetHasSelection
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedPassenger, setSelectedPassenger] = useState(null);
  const [fuse, setFuse] = useState(null);
  const searchInputRef = useRef(null);

  const searchPassengers = () => {
    const newList = fuse.search(searchValue);

    setSearchResults(newList);
  };

  const loadFuse = () => {
    const options = {
      includeScore: true,
      minMatchCharLength: 2,
      threshold: 0.1,
      keys: ['passenger_id', 'first_name', 'last_name', 'phone_number', 'email']
    };

    let fuse;
    if (listOfPassengers) {
      fuse = new Fuse(listOfPassengers, options);
    } else {
      fuse = new Fuse([], options);
    }
    setFuse(fuse);
  };

  useEffect(() => {
    resetHasSelection();
  }, []);

  useEffect(() => {
    loadFuse();

    searchInputRef.current.focus();
  }, []);

  useEffect(() => {
    loadFuse();
  }, [listOfPassengers]);

  useEffect(() => {
    if (listOfPassengers.length > 0 && searchValue.length >= 2) {
      searchPassengers();
    }

    if (searchValue.length === 0) {
      setSearchResults([]);
    }
  }, [searchValue]);

  useEffect(() => {
    // Once listOfPassengers loads, if there is a search query in the input already, search for it.
    if (fuse?._docs.length > 0 && searchValue.length >= 2) {
      searchPassengers();
    }
  }, [fuse]);

  const styles = {
    selectedPassengerFalse: {
      border: `1px solid ${colors.blaiseGray}`,
      borderRadius: '5px',
      padding: '15px',
      margin: '7px',
      cursor: 'pointer'
    },
    selectedPassengerTrue: {
      border: `2px solid ${colors.blaiseGreen}`,
      borderRadius: '5px',
      padding: '15px',
      margin: '7px',
      cursor: 'pointer'
    },
    loader: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    }
  };

  return (
    <>
      <Grid container spacing={2} id="tripModal">
        <Grid item xs={12}>
          <h2>{t('search_passenger')}</h2>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="searchValue"
            variant="outlined"
            value={searchValue}
            fullWidth
            label={t('search_email_phone_name')}
            onChange={(e) => setSearchValue(e.target.value)}
            inputProps={{ 'data-testid': 'searchForPassenger' }}
            inputRef={searchInputRef}
          />
        </Grid>
        {listOfPassengers.length === 0 && searchValue !== '' && (
          <div style={styles.loader}>
            <CircularProgress />
          </div>
        )}
        {searchResults &&
          searchResults.map((item, i) => {
            return (
              <Grid
                container
                style={
                  selectedPassenger
                    ? item.item.passenger_id === selectedPassenger.passenger_id
                      ? styles.selectedPassengerTrue
                      : styles.selectedPassengerFalse
                    : styles.selectedPassengerFalse
                }
                onClick={() => setSelectedPassenger(item.item)}
                key={item.item.passenger_id}
                data-testid={`passenger-${i}`}
              >
                <Grid item xs={6}>
                  <h5>{t('name')}</h5>
                  {/* Should we remove item.passenger_name? Since we assume it's always first_name and last_name? */}
                  <p>
                    {item.item.passenger_name
                      ? item.item.passenger_name
                      : item.item.first_name + ' ' + item.item.last_name}
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <h5>{t('passenger_email')}</h5>
                  <p>{item.item.email}</p>
                </Grid>
                <Grid item xs={6}>
                  <h5>{t('passenger_id')}</h5>
                  <p>{item.item.passenger_id}</p>
                </Grid>
                <Grid item xs={6}>
                  <h5>{t('phone_number')}</h5>
                  <p>{item.item.phone_number}</p>
                </Grid>
                {item?.item?.passenger_id === selectedPassenger?.passenger_id && (
                  <Box display="flex" justifyContent="center" style={{ width: '100%' }}>
                    <Button
                      disabled={hasSelection}
                      color="primary"
                      variant="contained"
                      onClick={() => selectPassenger(selectedPassenger)}
                      data-testid="toStep2"
                    >
                      {t('select')}
                    </Button>
                  </Box>
                )}
              </Grid>
            );
          })}
        <ProgressDots currentStep={progress.currentStep} totalSteps={progress.totalSteps} />
      </Grid>
    </>
  );
};

export default withTranslation('common')(withAuth0(PassengerSearch));
